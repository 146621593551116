<template>
  <ValidationObserver ref="form" tag="div">
    <div class="step__item__content">
      <div class="step__item__index">{{ $t('match_candidate.step_1') }}</div>
      <div class="step__item__title">
        {{ $t('applicant_portal.enter_campaign_details') }}
      </div>
      <div class="step__item__description">
        {{ $t('applicant_portal.set_ownership') }}
      </div>
      <!-- <b-button
        variant="outline-primary"
        size="md"
        v-b-toggle.step-1
        class="step__item__trigger"
        >Edit</b-button
      > -->

      <b-button
        v-if="!showEditBtn"
        variant="primary"
        size="md"
        v-b-toggle.step-1
        class="step__item__trigger"
        >{{ $t('general.setup') }}</b-button
      >

      <b-button
        v-if="showEditBtn"
        variant="outline-primary"
        size="md"
        v-b-toggle.step-1
        class="step__item__trigger"
        >{{ $t('dashboard.edit') }}</b-button
      >

      <b-collapse ref="step1" id="step-1" class="steptoggle__content mb-0">
        <b-row>
          <b-col lg="12">
            <!-- job details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <b-form>
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('create_campaign.job_details') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/resume.svg"
                      class="pl-2"
                      alt="Resume"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">
                  {{ $t('create_campaign.job_details') }}
                </div> -->
                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <app-text-input
                    :readonly="isActivated"
                    :name="$t('create_campaign.job_title_short')"
                    :label="$t('create_campaign.job_title_short') + '*'"
                    rules="required"
                    type="text"
                    vid="job_title_short"
                    v-model="form.job_title_short"
                  ></app-text-input>

                  <b-form-group :label="$t('create_campaign.job_title_long')">
                    <b-form-textarea
                      type="text"
                      v-model="form.job_title_long"
                    ></b-form-textarea>
                  </b-form-group>

                  <app-text-input
                    :name="$t('create_campaign.internal_job_id') + ' #1'"
                    :label="$t('create_campaign.internal_job_id') + ' #1*'"
                    rules="required"
                    vid="internal_job_id_1"
                    type="text"
                    v-model="form.internal_job_id_1"
                  ></app-text-input>

                  <app-select-input
                    :name="$t('create_campaign.job_complexity')"
                    :label="$t('create_campaign.job_complexity') + '*'"
                    rules="required"
                    vid="job_category"
                    v-model="form.job_complexity"
                    :options="jobComplexity"
                  ></app-select-input>

                  <b-form-group>
                    <b-row>
                      <b-col>
                        <app-select-input
                          :label="$t('create_campaign.job_arrangement')"
                          v-model="form.job_arrangement_type"
                          :options="jobArrangementTypes"
                          @change="onJobArragmentTypeChange"
                        ></app-select-input>
                      </b-col>
                      <b-col v-if="showJobArrangementPeriod">
                        <app-select-input
                          :label="$t('general.time')"
                          v-model="form.job_arrangement_period"
                          :options="jobArrangementPeriods"
                        ></app-select-input>
                      </b-col>
                    </b-row>
                    <!-- <b-form-datepicker
                      v-model="form.job_start_date"
                      :date-format-options="{
                        year: '2-digit',
                        month: 'short',
                        day: '2-digit',
                      }"
                      :min="new Date()"
                      size="sm"
                    ></b-form-datepicker> -->
                  </b-form-group>

                  <b-form-group>
                    <app-select-input
                      :label="
                        $t('create_campaign.application_default_language')
                      "
                      v-model="form.lang"
                      :options="languagesList"
                    ></app-select-input>
                  </b-form-group>

                  <b-form-group :label="$t('create_campaign.job_start_date')">
                    <b-form-datepicker
                      v-model="form.job_start_date"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }"
                      :min="new Date()"
                      size="sm"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group>
                    <b-form-checkbox v-model="form.block_pictures" class="mt-2">
                      {{ $t('create_campaign.block_pictures') }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <hr />

              <!-- location details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('create_campaign.remuneration_range') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/income_settings.svg"
                      alt="Income"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">
                  {{ $t('create_campaign.remuneration_range') }}
                </div> -->

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-row>
                    <b-col lg="3" md="3" sm="12">
                      <b-form-group :label="$t('general.type')">
                        <multiselect
                          label="text"
                          track-by="value"
                          selectLabel=""
                          selectedLabel=""
                          deselectLabel=""
                          v-model="remunerationRange.type"
                          :preselectFirst="true"
                          :placeholder="$t('general.select')"
                          :options="jobRangeTypes"
                          @select="onRemunerationRangeTypeChange"
                        ></multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <app-text-input
                        :readonly="disableRemunerationRange"
                        :name="$t('create_campaign.job_title_short')"
                        :label="$t('general.min')"
                        :placeholder="$t('general.enter')"
                        type="number"
                        rules="numeric"
                        v-model="remunerationRange.min"
                      ></app-text-input>
                    </b-col>
                    <b-col>
                      <app-text-input
                        :readonly="disableRemunerationRange"
                        :name="$t('create_campaign.job_title_short')"
                        :label="$t('general.max')"
                        :placeholder="$t('general.enter')"
                        type="number"
                        rules="numeric"
                        v-model="remunerationRange.max"
                      ></app-text-input>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                      <b-form-group :label="$t('general.currency')">
                        <multiselect
                          label="isoCode"
                          selectLabel=""
                          selectedLabel=""
                          deselectLabel=""
                          v-model="remunerationRange.currency"
                          :preselectFirst="true"
                          :placeholder="$t('general.select')"
                          :disabled="disableRemunerationRange"
                          :options="currencies"
                        ></multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <hr />

              <!-- location details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('create_campaign.location_office') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/user_location.svg"
                      alt="Location"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">
                  {{ $t('create_campaign.location_office') }}
                </div> -->
                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <app-text-input
                    :name="$t('auth_pages.zip_code')"
                    :label="$t('auth_pages.zip_code')"
                    type="text"
                    vid="job_zip"
                    v-model="form.job_zip"
                  ></app-text-input>

                  <app-text-input
                    :name="$t('create_campaign.location_office')"
                    :label="$t('create_campaign.location_office')"
                    vid="location"
                    type="text"
                    v-model="form.location"
                  ></app-text-input>

                  <b-form-group :label="$t('auth_pages.country')">
                    <multiselect
                      label="text"
                      v-model="jobCountry"
                      :options="availableCountries"
                      :placeholder="$t('multiselect_lib.select_option')"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>
              <hr />

              <!-- client contact ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('create_campaign.client_contact') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/on_call.svg"
                      alt="Call"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">
                  {{ $t('create_campaign.client_contact') }}
                </div> -->

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <app-text-input
                    :name="$t('create_campaign.client_name')"
                    :label="$t('create_campaign.client_name') + '*'"
                    rules="required"
                    vid="client_name"
                    type="text"
                    :title="$t('create_campaign.client_name_description')"
                    v-model="form.client_name"
                  ></app-text-input>

                  <app-select-input
                    :name="$t('general.title')"
                    :label="$t('general.title') + '*'"
                    :options="clientTitles"
                    v-model="form.client_contact_title"
                    vid="client_contact_title"
                    rules="required"
                  ></app-select-input>

                  <app-text-input
                    :name="$t('general.first_name')"
                    :label="$t('general.first_name') + '*'"
                    :title="$t('create_campaign.can_be_yourself')"
                    vid="client_contact_first_name"
                    rules="required"
                    type="text"
                    v-model="form.client_contact_first_name"
                  ></app-text-input>

                  <app-text-input
                    :name="$t('general.last_name')"
                    :label="$t('general.last_name') + '*'"
                    vid="client_contact_last_name"
                    rules="required"
                    type="text"
                    v-model="form.client_contact_last_name"
                  ></app-text-input>

                  <app-text-input
                    :name="$t('general.email')"
                    :label="$t('general.email') + '*'"
                    vid="client_contact_email"
                    rules="required|email"
                    type="email"
                    v-model="form.client_contact_email"
                  ></app-text-input>

                  <app-text-input
                    :name="$t('create_campaign.phone_number')"
                    :label="$t('create_campaign.phone_number') + '*'"
                    :title="$t('create_campaign.phone_number_description')"
                    :rules="{ required: true, regex: /^[+][0-9]{7,15}$/ }"
                    type="text"
                    vid="client_contact_phone"
                    v-model="form.client_contact_phone"
                  ></app-text-input>

                  <app-select-input
                    :name="$t('create_campaign.role')"
                    :label="$t('create_campaign.role') + '*'"
                    :options="clientContactRoles"
                    v-model="form.client_contact_role"
                    rules="required"
                    vid="client_contact_role"
                  ></app-select-input>
                </div>
              </div>

              <div class="col-12 p-0 d-flex justify-content-end">
                <b-button
                  class="wide"
                  variant="primary"
                  size="md"
                  @click="validateForm()"
                  >{{ $t('general.save_changes') }}</b-button
                >
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTextInput from '@/components/TextInput';
import AppMultiselect from '@/components/CustomMultiselect';
import AppSelectInput from '@/components/SelectInput';
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';
import { JOB_ARRANGEMENT_TYPES, JOB_RANGE_TYPES } from '../../common/constants';
import { languages } from '@/common/languages';

export default {
  name: 'EnterCampaignDetails',
  props: {
    isActivated: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    current: {
      type: Object,
      default: {},
    },
  },
  mixins: [CampaignHelpersMixin],
  components: {
    AppMultiselect,
    AppTextInput,
    AppSelectInput,
  },
  data() {
    return {
      keywords_options: [],
      form: {},
      remunerationRange: {},
      jobCountry: null,
      isValid: false,
      step: 1,
    };
  },
  computed: {
    ...mapGetters('global', ['availableCountries', 'currentLanguage']),
    ...mapGetters({
      campaign: 'campaign/current',
    }),
    disableRemunerationRange() {
      return (
        !this.remunerationRange.type ||
        this.remunerationRange.type.value === JOB_RANGE_TYPES.NO_RANGE
      );
    },
    showEditBtn() {
      return this.isActivated || this.isValid;
    },
    languagesList() {
      return languages.map((i) => {
        return {
          text: i.label,
          value: i.value,
        };
      });
    },
    showJobArrangementPeriod() {
      return (
        this.form.job_arrangement_type === JOB_ARRANGEMENT_TYPES.TEMPORARY ||
        this.form.job_arrangement_type === JOB_ARRANGEMENT_TYPES.PROJECT
      );
    },
  },
  created() {
    if (
      this.campaign.id ||
      (this.campaign && this.campaign.info && this.campaign.info.name)
    ) {
      this.form = this.campaign.info;
      this.remunerationRange = {
        max: this.campaign.info.remuneration_range_max,
        min: this.campaign.info.remuneration_range_min,
        type: this.jobRangeTypes.find(
          (item) => item.value === this.campaign.info.remuneration_range_type
        ),
        currency: this.currencies.find(
          (item) =>
            item.isoCode === this.campaign.info.remuneration_range_currency
        ),
      };

      const tmpForm = Object.assign({}, this.form);

      // Format Remuneration Range
      if (this.remunerationRange.type)
        tmpForm.remuneration_range_type = this.remunerationRange.type.value;
      if (this.remunerationRange.min)
        tmpForm.remuneration_range_min = this.remunerationRange.min;
      if (this.remunerationRange.max)
        tmpForm.remuneration_range_max = this.remunerationRange.max;
      if (this.remunerationRange.currency)
        tmpForm.remuneration_range_currency =
          this.remunerationRange.currency.isoCode;

      this.form.lang = this.campaign.info.lang || this.currentLanguage;
      if (this.campaign.info.job_country) {
        this.jobCountry = this.availableCountries.find(
          (c) => c.value === this.campaign.info.job_country
        );
      }

      this.$emit('currentForm', {
        info: this.form,
      });
    }
  },
  methods: {
    asyncFind(value) {
      // TODO: Check if we need this function in the future
      // ApiCampaign.getKeywords(value).then((response) => {
      //   this.keywords_options = response.data.campaign_keywords;
      // });
    },
    onJobArragmentTypeChange() {
      if (!this.showJobArrangementPeriod)
        this.form.job_arrangement_period = null;
    },
    onRemunerationRangeTypeChange() {
      this.$nextTick(() => {
        if (this.remunerationRange.type.value === JOB_RANGE_TYPES.NO_RANGE) {
          this.remunerationRange.min = this.remunerationRange.max = '';
        }
      });
    },
    addTag(item) {
      this.keywords_options.push(item);
      this.$emit('add-keyword', item);
    },
    setIsValid(value) {
      this.isValid = value;
    },
    validateForm() {
      this.$refs.form.validate().then((res) => {
        const emitData = {
          id: 0,
          isValidForm: false,
          step: 1,
          disabled: true,
          data: {
            info: this.form,
          },
        };

        if (res) {
          emitData.isValidForm = true;
          emitData.disabled = false;

          // Format Remuneration Range
          if (this.remunerationRange.type)
            this.form.remuneration_range_type =
              this.remunerationRange.type.value;
          // if (this.remunerationRange.min)
          this.form.remuneration_range_min = this.remunerationRange.min;
          // if (this.remunerationRange.max)
          this.form.remuneration_range_max = this.remunerationRange.max;
          if (this.remunerationRange.currency)
            this.form.remuneration_range_currency =
              this.remunerationRange.currency.isoCode;

          if (this.jobCountry) this.form.job_country = this.jobCountry.value;

          this.$root.$emit('bv::toggle::collapse', 'step-1', false);
          this.$root.$emit('bv::toggle::collapse', 'step-2', true);
          this.formChangeCount = 0;
        }

        this.$emit('isvalidform', emitData);
      });
    },
  },
};
</script>
