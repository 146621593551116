<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center border-bottom pb-3">
          <b-icon icon="dash" variant="primary" font-scale="2"></b-icon>
          <p class="m-0 ml-3 text-center campaign__updated-info">
            {{ $t('seeker_account.delete_document') }}
          </p>
          <button
            @click="close"
            type="button"
            aria-label="Close"
            class="close xmz-close"
          >
            ×
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="xmz-text">Are you sure you want to delete the document?</p>
        <div class="d-flex align-items-center justify-content-center">
          <b-button
            block="block"
            @click="deleteDocument"
            variant="primary"
            v-b-tooltip.hover
            :title="$t('seeker_account.delete_document')"
          >
            {{ $t('seeker_account.delete_document') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteDocument() {
      this.$emit('deletedocument', this.id);
    },
  },
};
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 25px;
}
</style>
