<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center border-bottom pb-3">
          <b-icon icon="plus-square" variant="primary" font-scale="2"></b-icon>
          <p class="m-0 ml-3 text-center campaign__updated-info">
            {{ $t('dashboard.new_assessment') }}
          </p>
          <button
            @click="goTo('close')"
            type="button"
            aria-label="Close"
            class="close xmz-close"
          >
            ×
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="xmz-text">{{ $t('dashboard.kind_of_assessment') }}</p>
        <div class="d-flex align-items-center justify-content-between">
          <b-button
            class="mx-2 xxl"
            @click="goTo('gotofirst')"
            variant="primary"
            v-b-tooltip.hover
            :title="secondBtnTitle"
            >{{ secondBtnTitle }}
          </b-button>
          <p class="text-uppercase">{{ $t('create_campaign.or') }}</p>
          <b-button
            class="mx-2 xxl"
            @click="goTo('gotosecond')"
            variant="primary"
            v-b-tooltip.hover
            :title="firstBtnTitle"
            >{{ firstBtnTitle }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstBtnTitle: {
      type: String,
      required: true,
    },
    secondBtnTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    goTo(name) {
      this.$emit(name);
    },
  },
};
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 25px;
}
</style>
