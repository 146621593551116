<template>
  <div class="survey survey__content__block">
    <h5 class="mb-4">{{ scenarioDescription }}</h5>

    <div class="pb-5">
      <b>{{ section }}</b>
    </div>

    <ValidationProvider
      :name="$t('create_campaign.competencies')"
      vid="questions"
      tag="div"
    >
      <app-multiselect
        v-model="form"
        :options="questions"
        :label="'answer_2_text'"
        :placeholder="$t('create_campaign.competencies_placeholder')"
        tag-placeholder="answer_2_text"
        class="mb-4"
        trackBy="key"
      >
      </app-multiselect>

      <test-slider-list
        :questions="form"
        :tooltips="rangeTips"
      ></test-slider-list>
    </ValidationProvider>

    <div class="survey__footer">
      <b-btn
        :disabled="isNextDisabled || !isFill"
        class="btn-lg"
        variant="primary"
        @click="next"
      >
        <b-spinner v-if="isNextDisabled"></b-spinner>
        {{ $t('general.next') }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import findValueRange from '../../../common/findValueRange';
import AppMultiselect from '../../../components/CustomMultiselect.vue';

export default {
  components: { AppMultiselect },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    leavingPerson: {
      type: Object,
      required: true,
    },
    participantId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isNextDisabled: false,
      form: [],
      marks: [0, 100],
    };
  },
  computed: {
    isFill(){
      return this.form.every(obj => obj.value)
    },
    scenario() {
      return this.question.scenario;
    },
    section() {
      return this.question.parent_question
        ? this.question.parent_question.answer_2_text
        : null;
    },
    rangeTips() {
      return [
        {
          from: 90,
          label: this.$t('match_references.situation_volume_slider_tooltip'),
        },
      ];
    },
    disableButton() {
      return !!this.questions.find((q) => q.value === 0 || !q.value);
    },
    scenarioDescription() {
      return this.participantId === this.leavingPerson.id
        ? this.scenario.description_selftest
        : this.scenario.description.replace(
            /%first name%/gi,
            this.leavingPerson.name.split(' ').slice(0, -1).join(' ')
          );
    },
  },
  methods: {
    tooltip(value) {
      const tooltip = findValueRange(this.rangeTips, value);
      return tooltip ? tooltip : null;
    },
    tooltipText(value) {
      const tooltip = this.tooltip(value);
      return tooltip ? tooltip.label : '';
    },
    showTooltip(value) {
      return this.tooltipText(value) ? 'active' : 'none';
    },
    next() {
      this.isNextDisabled = true;
      this.$store
        .dispatch('exit/saveAnswers', {
          token: this.$route.params.token,
          exit_campaign_participant_answers: this.questions.map((q) => {
            return {
              id: q.exit_answer.id,
              exit_question_id: q.id,
              value: q.value || 0,
            };
          }),
        })
        .then(() => {
          this.isNextDisabled = false;
        })
        .catch(() => (this.isNextDisabled = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  &.survey__content__block {
    max-width: 1024px;
  }
}
</style>