<template>
  <div>
    <!-- <div class="candidate__rank__index new">{{ index }}</div> -->

    <div class="row pb-2">
      <div class="col candidate__info pr-0">
        <div class="single__candidate__index">
          {{ index }}
        </div>
        <div class="report__chapter single__candidate">
          <div class="report__section__grouptitle single__candidate">
            <span>{{ $t('applicant_portal.candidate_details') }}</span>
          </div>
          <h1 class="single__candidate">{{ candidate.candidate_full_name }}</h1>
          <div class="col p-0 d-flex">
            <div class="examiz__match__score">
              <div class="ems__label">MATCH <br />{{ $t('report.score') }}</div>
              <div class="ems__value accent">
                {{ candidate.ap_data.match_score }}
              </div>
            </div>
            <div class="examiz__match__score">
              <div class="ems__label">
                {{ $t('applicant_portal.professional') }} <br />{{
                  $t('report.score')
                }}
              </div>
              <div class="ems__value">
                {{ candidate.ap_data.professional_score }}
              </div>
            </div>
            <div class="examiz__match__score">
              <div class="ems__label">
                {{ $t('applicant_portal.personality') }} <br />{{
                  $t('report.score')
                }}
              </div>
              <div class="ems__value">
                {{ candidate.ap_data.personality_score }}
              </div>
            </div>
          </div>
        </div>

        <div class="candidate_profile_wrapp">
          <img
            v-if="avatar && !hidePerson"
            class="candidate_generic"
            :src="avatar"
            :alt="pageTitle + ` Match Score graphic 1`"
          />
          <img
            v-else
            class="candidate_generic"
            :src="undrawMaleAvatar"
            :alt="pageTitle + ` Match Score graphic 2`"
          />
        </div>
      </div>
    </div>

    <div v-if="!apData.bulk_hiring" class="report__chapter pb-3">
      <div class="row">
        <div class="col">
          <h3 class="report__chapter__title">
            <span class="mdi mdi-account-star"></span>
            <span>{{ $t('seeker_account.wizard_title_step_4') }}</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="report__table table-responsive-sm">
            <table
              role="table"
              aria-busy="false"
              aria-colcount="2"
              class="table b-table table-borderless b-table-fixed"
              id="__BVID__163"
            >
              <!----><!---->
              <thead role="rowgroup" class="">
                <!---->
                <tr role="row" class="">
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    class="attribute__name"
                  >
                    {{ $t('applicant_portal.job_area') }}
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="2"
                    class="attribute__name"
                  >
                    {{ $t('dashboard.job_title') }}
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    class="attribute__name"
                  >
                    {{ $t('applicant_portal.seniority_level') }}
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    class="attribute__name text-right"
                  >
                    {{ $t('applicant_portal.years_of_experiences') }}
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="(experience, key) in candidatecandidateExperiences"
                  :key="key"
                  role="row"
                  class=""
                >
                  <td aria-colindex="1" role="cell" class="">
                    {{ experience.job_specialization.title }} ?
                  </td>
                  <td aria-colindex="1" role="cell" class="">
                    {{ experience.job_role }}
                  </td>
                  <td aria-colindex="2" role="cell" class="">
                    {{ experience.seniority_level.title }}
                  </td>
                  <td aria-colindex="1" role="cell" class="text-right">
                    {{ experience.years_of_experience.title }}
                  </td>
                </tr>
              </tbody>
              <!---->
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="report__chapter pb-4">
      <div class="row">
        <div class="col-6 custom_width">
          <PersonalityType :candidate="candidate" />
        </div>
        <div class="col-6">
          <CoreCompetencies :candidate="candidate" />
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-12">
        <div class="toggle">
          <h2 class="report__section__title toggle__trigger">
            <span class="toggle__trigger__text">{{
              $t('applicant_portal.qualifications')
            }}</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="row pb-4">
      <!-- education -->
      <div class="col-4">
        <h3 class="report__chapter__title">
          <img
            src="../../../../images/auth/education.svg"
            width="30"
            height="30"
            alt="educations"
            style="margin-right: 5px"
          />
          {{ $t('applicant_portal.educations') }}
        </h3>

        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('applicant_portal.education_levels') }}
          </h4>
          <!-- .gray .blue .blue_light .orange .red .strike .full_w -->
          <div
            v-for="item in mergedEducationLevels"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              v-if="item.color.gray !== true"
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
        <!-- simple_tags_wrapper -->

        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('applicant_portal.special_field') }}
          </h4>

          <!-- .gray .blue .blue_light .orange .red .strike -->
          <div
            v-for="item in mergedEducationGroups"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              v-if="item.color.gray !== true"
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
        <!-- simple_tags_wrapper -->

        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('report.additional_information') }}
          </h4>

          <!-- .gray .blue .blue_light .orange .red .strike .full_w -->
          <div
            v-for="item in mergedEducationKeywords"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>

        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('general.languages') }}
          </h4>
          <div
            v-for="language in mergedLanguages"
            :key="uniqueIdCandidate + language.text"
            class="simple_tag with_progress_bar"
            :class="language.color"
          >
            <div class="progress_bar" :style="{ width: language.width }"></div>
            <div class="label">{{ language.text }}</div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-print-12 col-md-12 col-lg-6">
            <!-- <h3 class="report__chapter__title">
              <span>Legend</span>
            </h3> -->
            <p class="campaign__results__info">
              <span class="tag_legend">
                <span class="legend darkblue">&#8205;</span>
                <span class="tag_label">{{
                  $t('applicant_portal.fulfilled')
                }}</span>
              </span>

              <span class="tag_legend">
                <span class="legend">&#8205;</span>
                <span class="tag_label">{{
                  $t('applicant_portal.additional')
                }}</span>
              </span>

              <span class="tag_legend">
                <span class="legend">&#10033; &nbsp;</span>
                <span class="tag_label">{{ $t('general.required') }}</span>
              </span>
            </p>
          </div>
        </div>
        <!-- simple_tags_wrapper -->
      </div>
      <!-- col-4 -->

      <!-- qualification -->
      <div class="col-4">
        <h3 class="report__chapter__title">
          <img
            src="../../../../images/auth/expeience_jobs_2.svg"
            width="30"
            height="30"
            alt="expeience"
            style="margin-right: 5px"
          />
          {{ $t('applicant_portal.job_experience') }}
        </h3>

        <!--
        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('applicant_portal.job_group') }}
          </h4>
          <div
            v-for="(item, index) in mergedJobGroups"
            :key="index"
          >
            <div v-if="item.color.gray !== true"
              class="simple_tag"
              :class="item.color">
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>

      -->
        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('applicant_portal.job_specializ') }}
          </h4>
          <div
            v-for="item in mergedJobSpecializations"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              v-if="item.color.gray !== true"
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
        <!-- simple_tags_wrapper -->

        <div class="simple_tags_wrapper pb-2 bott_gray">
          <div class="col-7 p-0">
            <h5 class="qualification__subtitle">
              {{ $t('applicant_portal.seniority_level') }}
            </h5>
            <div
              v-for="(item, index) in mergedSeniorityLevels"
              :key="uniqueIdCandidate + item.text + `_${index}`"
              class="simple_tag"
              :class="item.color"
            >
              <!--<div v-if="item.color.gray !== true">-->
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
          <div class="col-5 pr-0">
            <h5 class="qualification__subtitle">
              {{ $t('applicant_portal.years_of_experiences') }}
            </h5>
            <div
              v-for="(item, index) in mergedSeniorityLevels"
              :key="
                uniqueIdCandidate + item.years_of_experience.id + `_${index}`
              "
            >
              <div
                class="simple_tag"
                :class="item.years_of_experience.color"
                v-if="item.years_of_experience"
              >
                {{ item.years_of_experience.title
                }}{{ item.years_of_experience.required ? '*' : '' }}
              </div>
              <div class="simple_tag none" v-else></div>
            </div>
          </div>
        </div>
        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('report.confirmed') }}
          </h4>
          <div
            v-for="item in confirmedCertificates"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
      </div>
      <!-- col-4 -->

      <!-- SKILLS & CERIFICATES -->
      <div class="col-4">
        <h3 class="report__chapter__title">
          <img
            src="../../../../images/auth/languages.svg"
            width="30"
            height="30"
            alt="languages"
            style="margin-right: 5px"
          />
          {{ $t('applicant_portal.skill_certificates') }}
        </h3>

        <!--
            seniority level & years of experience treba zapakovati na sledeci nacin
            mislim da nam ne treba "Seniority Level" & "Years of experience" u naslovu
        -->
        <!-- <div class="simple_tag split gray">
          <span class="tag_label">Team Leader <strong>junior</strong></span>
          <span class="tag_no">3-5</span>
        </div>

        <div class="simple_tag split blue">
          <span class="tag_label">Web developer <strong>senior</strong></span>
          <span class="tag_no">3-5</span>
        </div> -->
        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('applicant_portal.special_sills') }}
          </h4>
          <!-- mergedJobKeywords -->
          <div
            v-for="item in mergedJobKeywords"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              class="simple_tag label"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
        <div class="simple_tags_wrapper pb-2 bott_gray">
          <h4 class="qualification__subtitle">
            {{ $t('report.unconfirmed') }}
          </h4>
          <!-- .gray .blue .blue_light .orange .red .strike .full_w -->
          <div
            v-for="item in unconfirmedCertificates"
            :key="uniqueIdCandidate + item.value"
          >
            <div
              class="simple_tag"
              :class="item.color"
            >
              {{ item.text }}{{ item.required ? '*' : '' }}
            </div>
          </div>
        </div>
      </div>
      <!-- col-4 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderBy, uniqBy, flatten } from 'lodash';

import UndrawMaleAvatar from '../../../../images/logoAuthor.svg';

import PersonalityType from '../Candidate/PersonalityType';
import CoreCompetencies from '../Candidate/CoreCompetencies';
import franchise from '../../../conf/franchise';

export default {
  components: {
    PersonalityType,
    CoreCompetencies,
  },
  props: {
    apData: {
      type: Object,
      required: true,
    },
    candidate: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    report: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('global', [
      'senorityLevel',
      'yearsOfExperience',
      'jobSpecializations',
      'languageProficiencyLevels',
    ]),
    uniqueIdCandidate() {
      return `${this.candidate.candidate_id}_`;
    },
    pageTitle() {
      return franchise.pageTitle;
    },
    undrawMaleAvatar() {
      return UndrawMaleAvatar;
    },
    hidePerson() {
      return this.candidate.candidate_full_name.startsWith('Person');
    },
    avatar() {
      const avatar = this.candidate.ap_data.documents
        .reverse()
        .find((d) => d.document_name === 'xmz-avatar.jpg');
      if (!avatar) return null;
      const avatarUrl = avatar.file.url;
      // handle dev enviroment
      if (!avatarUrl.includes('https') && !avatarUrl.includes('http')) {
        return `http://app.examiz.local${avatarUrl}`;
      }
      return avatarUrl;
    },
    languageProficiencyLevelsLength() {
      return this.languageProficiencyLevels.length;
    },
    educationLevels() {
      return this.apData.education_levels;
    },
    candidateEducationLevels() {
      return orderBy(
        this.candidate.ap_data.educations,
        (i) => (i.education ? i.education.id : null),
        ['desc']
      );
    },
    mergedEducationLevels() {
      let educationLevels = [].concat(this.educationLevels);
      educationLevels.forEach((level) => {
        const match = this.candidateEducationLevels.find(
          (item) => item.education && item.education.id >= level.value
        );
        if (match) {
          level.color = { darkblue: true };
        } else {
          level.color = { gray: true };
        }
      });

      educationLevels = educationLevels.concat(
        this.candidateEducationLevels
          .filter(
            (item) =>
              !educationLevels.find(
                (item2) =>
                  item2 && item.education && item2.value <= item.education.id
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.education ? item.education.title : '',
              value: item.education ? item.education.id : null,
            };
          })
      );

      return uniqBy(
        orderBy(educationLevels, (i) => i.value, ['desc']).slice(0, 7),
        'value'
      );
    },
    educationGroups() {
      return this.apData.education_groups;
    },
    candidateEducationGroups() {
      return orderBy(
        this.candidate.ap_data.education_group,
        (i) => i.education_group.title
      );
    },
    mergedEducationGroups() {
      let educationGroups = [].concat(this.educationGroups);
      educationGroups.forEach((group) => {
        const match = this.candidateEducationGroups.find(
          (item) => item.education && item.education.id === group.value
        );
        if (match) {
          group.color = { darkblue: true };
        } else {
          group.color = { gray: true };
        }
      });

      educationGroups = educationGroups.concat(
        this.candidateEducationGroups
          .filter(
            (item) =>
              !educationGroups.find(
                (item2) => item2.value === item.education_group.id
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.education.title,
              value: item.education ? item.education.id : null,
            };
          })
      );

      return uniqBy(
        orderBy(educationGroups, (i) => i.value).slice(0, 7),
        'value'
      );
    },
    educationKeywords() {
      return this.apData.education_keywords;
    },
    candidateEducationKeywords() {
      return flatten(
        this.candidate.ap_data.educations.map((i) => i.education_keywords)
      );
    },
    mergedEducationKeywords() {
      let keywords = [].concat(this.educationKeywords);
      keywords.forEach((keyword) => {
        const match = this.candidateEducationKeywords.find(
          (item) => item.title.toLowerCase() === keyword.value.toLowerCase()
        );

        if (match) {
          keyword.color = { darkblue: true };
        } else {
          keyword.color = { gray: true };
        }
      });

      keywords = keywords.concat(
        this.candidateEducationKeywords
          .filter(
            (item) =>
              !keywords.find(
                (item2) =>
                  item2.value.toLowerCase() === item.title.toLowerCase()
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.title,
              value: item.title,
            };
          })
      );

      return uniqBy(
        orderBy(keywords, (i) => i.value.toLowerCase()).slice(0, 7),
        'value'
      );
    },
    jobGroups() {
      return this.apData.job_groups;
    },
    candidateJobGroups() {
      return this.jobGroups.filter((i) =>
        this.candidateJobSpecializations.find(
          (s) => s && s.job_group_id === i.value
        )
      );
    },
    mergedJobGroups() {
      let jobGroups = [].concat(this.jobGroups);
      jobGroups.forEach((group) => {
        const match = this.candidateJobGroups.find(
          (item) => item.value === group.value
        );
        if (match) {
          group.color = { darkblue: true };
          group.sort = 1;
        } else {
          group.color = { gray: true };
          group.sort = 2;
        }
      });

      return uniqBy(orderBy(jobGroups, (i) => i.sort).slice(0, 7), 'value');
    },
    jobSpecializations() {
      return this.apData.job_specializations;
    },
    candidateJobSpecializations() {
      return this.candidate.ap_data.experiences.map(
        (i) => i.job_specialization
      );
    },
    mergedJobSpecializations() {
      let jobSpecializations = [].concat(this.jobSpecializations);
      jobSpecializations.forEach((specializations) => {
        const match = this.candidateJobSpecializations.find(
          (item) => item && item.id === specializations.value
        );
        if (match) {
          specializations.color = { darkblue: true };
          specializations.sort = 1;
        } else {
          specializations.color = { gray: true };
          specializations.sort = 2;
        }
      });

      jobSpecializations = jobSpecializations.concat(
        this.candidateJobSpecializations
          .filter(
            (item) =>
              item &&
              !jobSpecializations.find(
                (item2) => item2 && item2.value === item.id
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.title,
              value: item.id,
              sort: 3,
            };
          })
      );

      return uniqBy(
        orderBy(jobSpecializations, (i) => i.sort).slice(0, 7),
        'value'
      );
    },
    certificates() {
      return this.apData.certificates;
    },
    candidateCertificates() {
      return flatten(this.candidate.ap_data.certificates);
    },
    mergedCertificates() {
      let keywords = [].concat(this.certificates);
      keywords.forEach((keywrod) => {
        const match = this.candidateCertificates.find(
          (item) => item.title.toLowerCase() === keywrod.value.toLowerCase()
        );
        if (match) {
          keywrod.color = { darkblue: true };
        } else {
          keywrod.color = { gray: true };
        }
      });

      keywords = keywords.concat(
        this.candidateCertificates
          .filter(
            (item) =>
              !keywords.find(
                (item2) =>
                  item2.value.toLowerCase() === item.title.toLowerCase()
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.title,
              value: item.title,
            };
          })
      );

      return uniqBy(
        orderBy(keywords, (i) => i.value.toLowerCase()).slice(0, 7),
        'value'
      );
    },
    confirmedCertificates() {
      return this.mergedCertificates.filter(el => el.color.darkblue)
    },
    unconfirmedCertificates() {
      return this.mergedCertificates.filter(el => !el.color.darkblue)
    },
    jobKeywords() {
      return this.apData.job_keywords;
    },
    candidateJobKeywords() {
      return flatten(
        this.candidate.ap_data.experiences.map((i) => i.job_keywords)
      );
    },
    mergedJobKeywords() {
      let keywords = [].concat(this.jobKeywords);
      keywords.forEach((keywrod) => {
        const match = this.candidateJobKeywords.find(
          (item) => item.title.toLowerCase() === keywrod.value.toLowerCase()
        );
        if (match) {
          keywrod.color = { darkblue: true };
        } else {
          keywrod.color = { gray: true };
        }
      });

      keywords = keywords.concat(
        this.candidateJobKeywords
          .filter(
            (item) =>
              !keywords.find(
                (item2) =>
                  item2.value.toLowerCase() === item.title.toLowerCase()
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.title,
              value: item.title,
            };
          })
      );

      return uniqBy(
        orderBy(keywords, (i) => i.value.toLowerCase()).slice(0, 7),
        'value'
      );
    },
    requestedSeniorityLevels() {
      return this.apData.seniority_levels;
    },
    candidateSeniorityLevels() {
      let exp = this.candidate.ap_data.experiences.filter((i) => i);
      exp = exp.map((i) => {
        if (i.seniority_level) {
          return Object.assign(i.seniority_level, {
            years_of_experience: i.years_of_experience,
          });
        } else {
          return null;
        }
      });
      return exp.filter((i) => i);
    },
    mergedSeniorityLevels() {
      let seniorityLevels = [].concat(this.requestedSeniorityLevels);
      seniorityLevels.forEach((level) => {
        const match = this.candidateSeniorityLevels.find(
          (item) => item.id >= level.value
        );
        if (match) {
          level.color = { darkblue: true };
          // level.sort = 1;

          const yearsOfExperience = match.years_of_experience;
          const matchYearsOfExeprience = this.requestedYearsOfExepriences.find(
            (i) => i.value <= match.years_of_experience.id
          );
          if (matchYearsOfExeprience) {
            yearsOfExperience.color = { darkblue: true };
          } else {
            yearsOfExperience.color = { gray: true };
          }
          level.years_of_experience = yearsOfExperience;
        } else {
          level.color = { gray: true };
          // level.sort = 2;
          level.years_of_experience = null;
        }
      });

      seniorityLevels = seniorityLevels.concat(
        this.candidateSeniorityLevels
          .filter(
            (item) =>
              !seniorityLevels.find(
                (item2) => item && item2 && item2.value === item.id
              )
          )
          .map((item) => {
            return {
              color: {},
              required: false,
              text: item.title,
              value: item.id,
              // sort: 3,
              years_of_experience: item.years_of_experience,
            };
          })
      );

      seniorityLevels = seniorityLevels.filter(word => word.years_of_experience)
      // seniorityLevels = seniorityLevels.filter(
      //   (word) =>
      //     (word.color.gray !== true && word.color.darkblue !== true) ||
      //     word.color === undefined
      // );
      return seniorityLevels
        .sort(function (a, b) {
          return (
            b.value - a.value ||
            b.years_of_experience.value - a.years_of_experience.value
          );
        })
        .slice(0, 7);
    },
    requestedYearsOfExepriences() {
      return this.apData.years_of_experiences;
    },
    candidatecandidateExperiences() {
      const exp = this.candidate.ap_data.experiences.filter(
        (i) =>
          i &&
          i.seniority_level &&
          i.seniority_level &&
          i.years_of_experience &&
          i.job_specialization
      );
      return orderBy(exp, (i) => i.seniority_level.id, ['desc']).slice(0, 5);
    },
    languages() {
      return this.apData.languages;
    },
    candidateLanguages() {
      return orderBy(
        this.candidate.ap_data.languages,
        (i) => i.language_proficiency_level.id,
        ['desc']
      ).slice(0, 7);
    },
    mergedLanguages() {
      let languages = [].concat(this.languages);

      languages.forEach((language) => {
        const match = this.candidateLanguages.find(
          (item) => item.value === language.value
        );
        if (match) {
          const width =
            (match.language_proficiency_level.id * 100) /
            this.languageProficiencyLevelsLength;
          if (
            match.language_proficiency_level.id >=
            language.language_proficiency_level_id
          ) {
            language.color = { darkblue: true };
            language.width = (width <= 100 ? width : 100) + '%';
          } else {
            language.color = { gray: true };
            language.width = (width <= 100 ? width : 100) + '%';
          }
        } else {
          language.color = { gray: true };
          language.width = '0%';
        }
      });

      languages = languages.concat(
        this.candidateLanguages
          .filter(
            (item) => !languages.find((item2) => item2.value === item.value)
          )
          .map((item) => {
            return {
              text: item.title,
              value: item.value,
              width: '0%',
              color: {},
            };
          })
      );

      return orderBy(languages, (i) => i.width, ['asc']).slice(0, 7);
    },
  },
};
</script>