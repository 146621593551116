const ReferencesHelpersMixin = {
  computed: {
    tableFields() {
      return [
        // {
        //   key: "info.gender",
        //   label: this.$t("general.title")
        // },
        {
          key: "info.first_name",
          label: this.$t("general.first_name")
        },
        {
          key: "info.last_name",
          label: this.$t("general.last_name")
        },
        {
          key: "info.email",
          label: this.$t("general.email")
        },
        {
          key: "info.relationship",
          label: this.$t("match_references.relationship")
        }
      ];
    },
    acquaintancePeriods() {
      return [
        this.$t("match_references.intro_page.1_3_month"),
        this.$t("match_references.intro_page.up_to_6_month"),
        this.$t("match_references.intro_page.up_to_1_year"),
        this.$t("match_references.intro_page.more_than_1_year")
      ];
    },
    candidateRelationshipTypes() {
      return [
        this.$t("match_references.intro_page.peer"),
        this.$t("match_references.intro_page.colleague_worker"),
        this.$t("match_references.intro_page.contractor"),
        this.$t("match_references.intro_page.supervisor_boss")
      ]
    },
    referencesRelationshipTypes() {
      return this.candidateRelationshipTypes.concat([
        this.$t("match_references.intro_page.friend"),
        this.$t("match_references.intro_page.family_member")
      ]);
    },
    relationshipStatuses() {
      return [
        this.$t("match_references.intro_page.good_times"),
        this.$t("match_references.intro_page.challenging_or_difficult_times"),
        this.$t("match_references.intro_page.both_good_and_difficult_times")
      ];
    }
  }
};

export default ReferencesHelpersMixin;
