const report = state => state.report;
const reportCampaign = state => state.reportCampaign;
const reportAvgValues = state => state.reportAvgValues;
const selfTestReport = state => state.selfTestReport;

export default {
  report,
  reportCampaign,
  reportAvgValues,
  selfTestReport,
};
