<template >
  <b-dropdown :text="$t(`campaign_type.${currentApp.type}`)" variant="link">
    <b-dropdown-item @click="switchApp(apps.DEFAULT)">{{ $t(`campaign_type.${apps.DEFAULT.type}`) }}</b-dropdown-item>
    <b-dropdown-item @click="switchApp(apps.EXIT)">{{ $t(`campaign_type.${apps.EXIT.type}`) }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { EXAMIZ_APPS } from '../common/constants';
export default {
  data() {
    return {
      currentApp: EXAMIZ_APPS.DEFAULT
    }
  },
  computed: {
    apps() {
      return EXAMIZ_APPS
    }
  },
  created() {
    const currentAppType = localStorage.getItem('app')
    if (currentAppType) {
      this.currentApp = EXAMIZ_APPS[currentAppType.toUpperCase()]
    }
  },
  methods: {
    switchApp(app) {
      localStorage.setItem('app', app.type)
      window.location.href = '/' + app.url
    }
  }
};
</script>
