import axios from "axios";

const fetchTemplates = (companyId, pagination) => {
  return axios.get(`/api/v1/companies/${companyId}/campaign_templates`, {
    params: {
      search_field: pagination.field || null,
      search_value: pagination.value || null,
      per_page: pagination.perPage,
      sort_by: pagination.sortBy,
      order: pagination.order,
      page: pagination.page || 1
    }
  });
};

// const getTemplate = id => {
//   return axios.get(`/api/v1/campaign_templates/${id}`);
// };

const createTemplate = (companyId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/campaign_templates`, {
    campaign_template: params
  });
};

const updateTemplate = (companyId, id, params) => {
  return axios.patch(`/api/v1/companies/${companyId}/campaign_templates/${id}`, {
    campaign_template: params
  });
};

// Merge `createTemplate` and `updateTemplate`
const save = (companyId, params) => {
  const isUpdate = !!params.id;
  const method = isUpdate ? "PATCH" : "POST";
  const append = isUpdate ? `/${params.id}` : "";

  return axios({
    url: `/api/v1/companies/${companyId}/campaign_templates/${append}`,
    method,
    data: { campaign_template: params }
  });
};

const deleteTemplate = (companyId, id) => {
  return axios.delete(`/api/v1/companies/${companyId}/campaign_templates/${id}`);
};

const toggleFavorite =  (companyId, id) => {
  return axios.put(`/api/v1/companies/${companyId}/campaign_templates/${id}/toggle_favorite`);
};

export default {
  fetchTemplates,
  fetchAll: fetchTemplates,
  // getTemplate,
  // fetch: getTemplate,
  createTemplate,
  updateTemplate,
  save,
  deleteTemplate,
  delete: deleteTemplate,
  toggleFavorite
};
