<template>
  <div class="report" :id="pageId">

    <div class="print__container">

      <b-button
          @click="printReport()"
          v-b-tooltip.hover
          variant="primary"
          :title="$t('report.print_report')"
          class="print__button">
        <span class="mdi mdi-printer"></span>
      </b-button>

    </div>

    <div class="report__content">
      <!-- // page: campaign cover ///////////////////////////////////////// -->

      <section class="page_campaign_cover">
        <print-header :client-name="fullName"></print-header>
        <coverpage-candidate :fullName="fullName" :report="report"></coverpage-candidate>
      </section>

      <!-- // page: campaign summary /////////////////////////////////////// -->

      <section class="page-break-before-always page-break-inside-avoid page_campaign_summary">

        <print-header :client-name="fullName"></print-header>
        <report-page-header :report="report"></report-page-header>

        <b-row>
          <b-col>

            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle">

              <h2 class="report__section__title toggle__trigger">
                <span class="toggle__trigger__text">{{ $t("js_report.values") }} & {{ $t("js_report.competencies") }}</span>
              </h2>

              <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <b-collapse id="candidate-values" class="toggle__content" visible>

                <!-- // candidate values ~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-candidate-values
                    :candidate-values="values"
                    :talents-attr="talentsAttributes"
                    :current-lang="currentLanguage"></report-candidate-values>

                <!-- // core competencies ~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-core-competencies
                    :competencies="competenciesB1"
                    :talents-attr="talentsAttributes"
                    :current-lang="currentLanguage"></report-core-competencies>

                <!-- // managerial competencies ~~~~~~~~~~~~~~~~ -->

                <report-managerial-competencies
                    :competencies="competenciesB2"
                    :talents-attr="talentsAttributes"
                    :current-lang="currentLanguage"></report-managerial-competencies>

                <!-- // traits competencies ~~~~~~~~~~~~~~~~~~~~ -->

                <report-traits-competencies
                    :competencies="traits"
                    :talents-attr="talentsAttributes"
                    :current-lang="currentLanguage"></report-traits-competencies>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </section>

      <section class="page-break-before-always page-break-inside-avoid page_campaign_summary">
        <print-header :client-name="fullName"></print-header>
        <report-page-header :report="report"></report-page-header>

        <b-row>
          <b-col>
            <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <div class="toggle">
              <h2 class="report__section__title toggle__trigger">
                <span class="toggle__trigger__text">{{ $t("report.potential_analytics") }}</span>
              </h2>

              <b-collapse id="potential-analytics" class="toggle__content" visible>
                <!-- // potential analytics ~~~~~~~~~~~~~~~~~~~~ -->

                <report-potential-analytics
                    :report="report"
                    :full-name="fullName"></report-potential-analytics>

                <report-candidate
                    :full-name="fullName"
                    :report="report"
                    :current-lang="currentLanguage"
                    :talents-attr="talentsAttributes"
                    :is-registered="true"></report-candidate>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </section>

      <!-- // page: interview questions ////////////////////////////////// -->
      <section class="page-break-before-always page-break-inside-avoid page_interview_questions">
        <print-header :client-name="fullName"></print-header>
        <report-page-header :report="report"></report-page-header>
        <print-interview-questions
            :interviewQuestions="interviewQuestions.slice(0, 5)"></print-interview-questions>
      </section>
    </div>
  </div>
</template>

<script>
import SelfTestMixin from '@/common/mixins/SelfTestMixin';
import { TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING } from '@/common/constants';
import PrintHeader from "../Report/Print/PrintHeader";
import CoverpageCandidate from './Print/CoverpageCandidate';
import ReportPageHeader from './Candidate/ReportPageHeader';
import CandidateValues from './CandidateValues';
import CoreCompetencies from './Candidate/CoreCompetencies';
import ManagerialCompetencies from './Candidate/ManagerialCompetencies';
import TraitsCompetencies from './Candidate/TraitsCompetencies';
import PotentialAnalytics from './PotentialAnalytics';
import PrintInterviewQuestions from './Print/PrintInterviewQuestions';
import Candidate from './Candidate/Index';

export default {
  name: 'SelfTestResultsPrint',
  props: {
    pageId: {
      type: String,
      default: "printreport__fullview",
    },
  },
  components: {
    PrintHeader,
    CoverpageCandidate,
    ReportPageHeader,
    ReportCandidateValues: CandidateValues,
    ReportCoreCompetencies: CoreCompetencies,
    ReportManagerialCompetencies: ManagerialCompetencies,
    ReportTraitsCompetencies: TraitsCompetencies,
    ReportPotentialAnalytics: PotentialAnalytics,
    ReportCandidate: Candidate,
    PrintInterviewQuestions
  },
  mixins: [ SelfTestMixin ],
  created() {
    this.$store.dispatch("global/populateTalentsAttributes");
    document.body.classList.add("print__report");
  },
  methods: {
    filterCompetenices(items) {
      return items.filter(
        (item) => item.value > TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING
      );
    },
    printReport() {
      window.print();
    },
  }
}
</script>
<style lang="scss">
  @import "/styles/print";

  body.print__report .report .report__chapter__title {
      margin: 26px 0 8px 0;
  }
</style>
