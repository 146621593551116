<template>
  <ValidationObserver ref="form" class="create-campaign-wrp mb-3" tag="div">
    <!-- // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row>
      <b-col lg="12">
        <div class="mb-3">
          <img
            src="../../../images/campaign_icons/deadline.svg"
            class="mr-3 mb-3 ml-3"
            alt="Details"
            width="60"
          />
          <span class="form__title">{{
            $t('create_campaign.campaign_details')
          }}</span>
        </div>
        <b-row>
          <b-col lg="2">
            <legend>
              <label for="references_requested"
                >{{ $t('create_campaign.campaign_type') }}*</label
              >
              <span
                v-b-tooltip.hover
                :title="$t('create_campaign.campaign_type_tooltip')"
                class="ml-2 mr-3"
                ><app-tooltip></app-tooltip
              ></span>
            </legend>

            <app-select-input
              id="$t('create_campaign.campaign_type')"
              v-model="campaign_type"
              :name="''"
              :options="campaignTypes"
              rules="required"
              size="sm"
              tag="div"
              :readonly="disableOnUpdate"
            ></app-select-input>
          </b-col>

          <b-col lg="2">
            <legend>
              <label for="references_requested"
                >{{ $t('create_campaign.min_references_requested') }}*</label
              >
              <span
                v-b-tooltip.hover
                :title="
                  $t('create_campaign.min_references_requested_description')
                "
                class="ml-2 mr-3"
                ><app-tooltip></app-tooltip
              ></span>
            </legend>
            <app-select-input
              id="$t('create_campaign.min_references_requested')"
              v-model="info.references_requested"
              :name="$t('create_campaign.min_references_requested')"
              :options="references"
              rules="required"
              size="sm"
              :readonly="disableMinReferencesInput"
              tag="div"
            ></app-select-input>
          </b-col>

          <b-col lg="2">
            <ValidationProvider
              :name="$t('create_campaign.starting_date')"
              class="datepicker-wrp"
              rules="required"
              tag="div"
            >
              <b-form-group
                slot-scope="{ errors }"
                :label="$t('create_campaign.starting_date') + '*'"
              >
                <b-form-datepicker
                  v-model="start_date"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  }"
                  :min="new Date()"
                  size="sm"
                ></b-form-datepicker>

                <div class="error-message">{{ errors[0] }}</div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="2">
            <ValidationProvider
              :name="$t('create_campaign.end_date')"
              :rules="{
                required: true,
                after: { limit: start_date, included: true },
              }"
              class="datepicker-wrp"
              tag="div"
            >
              <b-form-group slot-scope="{ errors }">
                <legend class="col-form-label">
                  {{ $t('create_campaign.end_date') }}*
                  <span
                    v-b-tooltip.hover
                    :title="$t('create_campaign.end_date_description')"
                  >
                    <i class="mdi mdi-information-outline"></i>
                  </span>
                </legend>
                <b-form-datepicker
                  v-model="end_date"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  }"
                  :min="new Date()"
                  size="sm"
                ></b-form-datepicker>
                <div class="error-message">{{ errors[0] }}</div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="3">
            <ValidationProvider
              :name="$t('create_campaign.starting_date')"
              rules="required"
              tag="div"
            >
              <b-form-group slot-scope="{ errors }">
                <legend class="col-form-label">
                  {{ $t('general.timezone') }}*
                </legend>
                <multiselect
                  v-model="timeZone"
                  :options="timeZones"
                  @select="onTimeZoneSelect"
                  label="name"
                  :class="{ 'multiselect-error': !!errors[0] }"
                  :selectLabel="''"
                  :deselectLabel="''"
                  :selectedLabel="$t('general.selected')"
                  :disabled="disableOnUpdate"
                ></multiselect>

                <div class="error-message">{{ errors[0] }}</div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <!--
        <b-row>
          <b-col lg="3">
            <b-form-checkbox
              id="checkbox-1"
              v-model="unwanted_behavior"
              name="checkbox-1"
              class="mt-5"
            >
              {{ $t('create_campaign.unwanted_behavior') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
-->
        <hr />

        <b-row>
          <b-col lg="6"> * {{ campaignTypeDescription }} </b-col>
        </b-row>

        <hr />

        <!-- // candidates ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <b-form class="candidates-wrp pr-2">
          <div class="form__title mb-0 mt-2">
            <img
              src="../../../images/campaign_icons/applicants_screening.svg"
              class="mr-3 mb-3 ml-3"
              alt="Screening"
              width="60"
            />
            {{ candidatesTitle }}
          </div>

          <ValidationProvider
            :name="candidateMinValue"
            mode="lazy"
            :rules="`minAmountItems: ${defaultMinCandidates}`"
          >
            <div slot-scope="{ errors }" class="form-group form-group-input">
              <input :value="contactsLength" type="hidden" />
              <div v-if="errors" class="error-message ml-0 mt-1">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>

          <div
            v-for="(contact, index) in contacts"
            :key="index"
            class="candidates-item"
          >
            <div class="mr-3 pt-4 mt-2">
              <span class="ml-2 candidates-item-index">{{
                index + 1 + '.'
              }}</span>
            </div>

            <div
              class="mr-3 candidates-item-field candidates-item-field__select"
            >
              <app-select-input
                v-model="contact.gender"
                :label="$t('general.title')"
                :name="$t('general.title')"
                :options="titles"
                rules="required"
                mode="lazy"
                vid="title"
              ></app-select-input>
            </div>

            <div
              class="mr-3 candidates-item-field candidates-item-field__select"
            >
              <app-select-input
                v-model="contact.lang"
                :label="$t('general.language')"
                :name="$t('general.language') + index"
                :options="languages"
                rules="required"
                mode="lazy"
                vid="title"
              ></app-select-input>
            </div>

            <div class="mr-3 candidates-item-field">
              <app-text-input
                v-model="contact.first_name"
                :label="$t('general.first_name') + '*'"
                :vid="`first_name${index}`"
                mode="lazy"
                name="$t('general.first_name')"
                rules="required"
                size="sm"
                type="text"
              ></app-text-input>
            </div>

            <div class="mr-3 candidates-item-field">
              <app-text-input
                v-model="contact.last_name"
                :label="$t('general.last_name') + '*'"
                :name="$t('general.last_name')"
                :vid="`last_name${index}`"
                mode="lazy"
                rules="required"
                size="sm"
                type="text"
              ></app-text-input>
            </div>

            <div class="mr-3 candidates-item-field">
              <app-text-input
                v-model="contact.email_1"
                :label="$t('general.email') + '*'"
                :name="$t('general.email')"
                :vid="`email${index}`"
                mode="lazy"
                rules="required|email"
                size="sm"
                type="text"
                @input="findEmails(contact.email_1)"
                :disabled="isPrimaryEmailDisabled"
              ></app-text-input>
            </div>

            <div class="candidates-item-field">
              <app-text-input
                v-model="contact.email_2"
                :label="$t('general.alternative_email')"
                :name="$t('general.alternative_email')"
                :rules="`email|notConfirmedBy:@email${index}`"
                :vid="`alternative_email${index}`"
                mode="lazy"
                size="sm"
                type="text"
                @input="findEmails(contact.email_2)"
              ></app-text-input>
            </div>

            <span
              class="ml-3 candidate-remove-icon"
              @click="removeContact(index)"
              ><i class="mdi mdi-delete"></i
            ></span>
          </div>

          <b-row>
            <b-col>
              <hr />
              <div
                v-if="showAddCandidateButton"
                class="d-flex align-items-center"
              >
                <b-btn
                  class="mt-3 mr-2"
                  variant="secondary"
                  @click="addContact"
                  v-if="showAddCandidateButton"
                >
                  {{ addCandidateTitle }}
                </b-btn>
                <CsvImport
                  class="mt-3 mr-2"
                  @imported-data="handleImport"
                  :headings="importHeadings"
                  v-if="showAddCandidateButton"
                />
              </div>

              <b-col lg="6" v-if="!showAddCandidateButton"
                >{{ addCandidatesDisabledText }}
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <!--      <b-col class="step-3-border-left" lg="4">
              <div class="skills-item-wrp">
                <div class="form__title">{{ $t('create_campaign.skills_competencies') }}</div>
                <div class="skills-item traits d-flex align-items-center" v-for="(item, index) in personality_attrs.traits" :key="'traits' + index">
                  <div class="skills-item-name">{{ item[`name_${currentLanguage}`] }}</div>
                  <div class="skills-item-progress-wrp">
                    <div class="skills-item-progress" :style="{ width: item.value + '%' }"></div>
                  </div>
                  <div class="skills-item-title ml-2 font-weight-bold">{{ item.value }}</div>
                </div>
                <div class="skills-item values d-flex align-items-center" v-for="(item, index) in personality_attrs.values" :key="'values' + index">
                  <div class="skills-item-name">{{ item[`name_${currentLanguage}`] }}</div>
                  <div class="skills-item-progress-wrp">
                    <div class="skills-item-progress" :style="{ width: item.value + '%' }"></div>
                  </div>
                  <div class="skills-item-title ml-2 font-weight-bold">{{ item.value }}</div>
                </div>
                <div class="skills-item competencies d-flex align-items-center" v-for="(item, index) in personality_attrs.competencies" :key="'competencies' + index">
                  <div class="skills-item-name">{{ item[`name_${currentLanguage}`] }}</div>
                  <div class="skills-item-progress-wrp">
                    <div class="skills-item-progress" :style="{ width: item.value + '%' }"></div>
                  </div>
                  <div class="skills-item-title ml-2 font-weight-bold">{{ item.value }}</div>
                </div>
              </div>
            </b-col>-->
    </b-row>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from '../../components/CustomMultiselect';
import AppSelectInput from '../../components/SelectInput';
import AppTextInput from '../../components/TextInput';
import AppTooltip from '../../components/Tooltip';
import AppCheckbox from '../../components/Checkbox';
import CsvImport from '../../components/CsvImport';
import CampaignHelpersMixin from '../../common/CampaignHelpersMixin';
import { mapGetters } from 'vuex';
import { languages } from '@/common/languages';
import {
  CAMPAIGN_STATE,
  CAMPAIGN_MIN_CANDIDATES,
  CAMPAIGN_MIN_TEAMS_CANDIDATES,
  CAMPAIGN_TYPES,
} from '@/common/constants';

export default {
  components: {
    AppCheckbox,
    AppMultiselect,
    AppSelectInput,
    AppTextInput,
    AppTooltip,
    CsvImport,
  },
  mixins: [CampaignHelpersMixin],
  props: {
    campaignId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      references: [3, 4, 5, 6, 7],
      duplicatedEmails: false,
      timeZone: null,
      importHeadings: [
        'gender',
        `lang`,
        'first_name',
        'last_name',
        'email',
        'alternative_email',
      ],
    };
  },
  computed: {
    ...mapGetters('campaign', ['current']),
    ...mapGetters('global', ['currentLanguage', 'timeZones', 'titles']),
    contactsLength() {
      return this.contacts.length;
    },
    info() {
      return this.current.info || {};
    },
    personality_attrs() {
      return this.current.personality_attrs || {};
    },
    languages() {
      return (
        languages.map((lang) => ({
          value: lang.value,
          text: lang.label,
        })) || []
      );
    },
    isPrimaryEmailDisabled() {
      return !(
        this.current.state === CAMPAIGN_STATE.OPEN ||
        this.current.state === CAMPAIGN_STATE.ACTIVE
      );
    },
    showAddCandidateButton() {
      return (
        this.current.state === CAMPAIGN_STATE.OPEN ||
        this.current.state === CAMPAIGN_STATE.ACTIVE
      );
    },
    campaign_type: {
      get() {
        return this.current.campaign_type || CAMPAIGN_TYPES.JOB;
      },
      set(value) {
        this.$nextTick(() => {
          this.$store.dispatch('campaign/updateStateCampaign', {
            ...this.current,
            campaign_type: value,
            info: Object.assign(this.info, {
              references_requested: value === CAMPAIGN_TYPES.JOB ? 4 : 1,
            }),
          });
        });
      },
    },
    unwanted_behavior: {
      get() {
        return this.current.info.include_analysis || false;
      },
      set(value) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          info: {
            ...this.current.info,
            include_analysis: value,
          },
        });
      },
    },
    contacts: {
      get() {
        return this.current.contacts || [];
      },
      set(value) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          contacts: value,
          references_requested: value.length - 1,
        });
      },
    },
    start_date: {
      get() {
        return this.current.start_date || '';
      },
      set(value) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          start_date: value,
        });
      },
    },
    end_date: {
      get() {
        return this.current.end_date || '';
      },
      set(value) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          end_date: value,
        });
      },
    },
    candidateMinValue() {
      return `${this.defaultMinCandidates} ${this.$t(
        'create_campaign.candidates'
      )}`;
    },
    defaultMinCandidates() {
      return this.current.campaign_type === CAMPAIGN_TYPES.TEAM
        ? CAMPAIGN_MIN_TEAMS_CANDIDATES
        : CAMPAIGN_MIN_CANDIDATES;
    },
    disableMinReferencesInput() {
      return (
        (this.current.campaign_type || CAMPAIGN_TYPES.JOB) !==
        CAMPAIGN_TYPES.JOB
      );
    },
    campaignTypeDescription() {
      let description = '';
      switch (this.current.campaign_type) {
        case CAMPAIGN_TYPES.TEAM:
          description = this.$t(
            'create_campaign.campaign_type_team_description'
          );
          break;
        case CAMPAIGN_TYPES.SELFTEST:
          description = this.$t(
            'create_campaign.campaign_type_selftest_description'
          );
          break;
        default:
          description = this.$t(
            'create_campaign.campaign_type_job_description'
          );
      }
      return description;
    },
    addCandidatesDisabledText() {
      let description = '';
      switch (this.current.state) {
        case CAMPAIGN_STATE.FINALIZED:
          description = this.$t('dashboard.statuses.finalized');
          break;
        case CAMPAIGN_STATE.CLOSED:
          description = this.$t('dashboard.statuses.closed');
          break;
        case CAMPAIGN_STATE.CANCELED:
          description = this.$t('dashboard.statuses.canceled');
          break;
        case CAMPAIGN_STATE.ARCHIVED:
          description = this.$t('dashboard.statuses.archived');
          break;
      }
      return `*${this.$t(
        'create_campaign.add_candidates_disabled_text'
      )} ${description.toLowerCase()}.`;
    },
    disableOnUpdate() {
      return this.current.state && this.current.state !== CAMPAIGN_STATE.OPEN;
    },
    candidatesTitle() {
      let titleKey = '';
      switch (this.current.campaign_type) {
        case CAMPAIGN_TYPES.TEAM:
          titleKey = 'create_campaign.team_members';
          break;
        case CAMPAIGN_TYPES.SELFTEST:
          titleKey = 'create_campaign.attendees';
          break;
        default:
          titleKey = 'create_campaign.candidates';
          break;
      }
      return this.$t(titleKey);
    },
    addCandidateTitle() {
      let titleKey = '';
      switch (this.current.campaign_type) {
        case CAMPAIGN_TYPES.TEAM:
          titleKey = 'create_campaign.add_team_member';
          break;
        case CAMPAIGN_TYPES.SELFTEST:
          titleKey = 'create_campaign.add_attendee';
          break;
        default:
          titleKey = 'create_campaign.add_candidate';
          break;
      }
      return this.$t(titleKey);
    },
  },
  created() {
    this.$store
      .dispatch('global/fetchTimeZones')
      .then(() => this.selectTimezone());
  },
  methods: {
    handleImport(data) {
      this.contacts = this.contacts.concat(data.map(i => ({
        ...i,
        email_1: i.email,
        email_2: i.alternative_email
      })))
    },
    selectTimezone() {
      const currentTimeZone =
        this.current.time_zone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timeZone =
        this.timeZones.find((tz) => tz.value === currentTimeZone) || null;
      this.onTimeZoneSelect(this.timeZone);
    },
    onTimeZoneSelect(timeZone) {
      this.$store.dispatch('campaign/updateStateCampaign', {
        ...this.current,
        time_zone: timeZone ? timeZone.value : null,
      });
    },
    validateForm() {
      if (this.duplicatedEmails) {
        this.$bvToast.toast(this.$t('create_campaign.email_error_msg'), {
          autoHideDelay: 5000,
          variant: 'warning',
          title: 'Error',
        });
        return false;
      } else {
        return this.$refs.form.validate();
      }
    },
    findEmails(email) {
      const arr = this.current.contacts.filter(
        (item) => item.email_1 === email || item.email_2 === email
      );
      this.duplicatedEmails = arr && arr.length >= 2;
    },
    getForm() {
      return this.form;
    },
    addContact() {
      const params = {
        title: '',
        lang: this.currentLanguage || 'en',
        first_name: '',
        last_name: '',
        email_1: '',
        email_2: '',
        gender: 'male',
      };

      // concat force vue to rerender
      this.contacts = this.contacts.concat([params]);

      if (this.campaign_type === CAMPAIGN_TYPES.TEAM) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          info: Object.assign(this.info, {
            references_requested: this.contacts.length - 1,
          }),
        });
      }
    },
    removeContact(index) {
      this.contacts.splice(index, 1);

      if (this.campaign_type === CAMPAIGN_TYPES.TEAM) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          info: Object.assign(this.info, {
            references_requested: this.contacts.length - 1,
          }),
        });
      }
    },
  },
};
</script>


<style lang="scss">
.multiselect-error {
  .multiselect__tags {
    border: 1px solid #e64646 !important;
  }
}
</style>
