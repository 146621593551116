<template>
  <div>
    <div class="row">
      <div class="col">
        <h6 class="mt-4" v-if="strategy.label">
          {{ strategy.label }}
          <!-- <span class="font-weight-bold"> {{ strategy.key }}</span> -->
        </h6>
        <h6 v-if="strategy.label">
          {{ $t('exit.report.problem_level') }}:
          <span class="font-weight-bold">
            {{ prepareLevel(strategy.level) }}
          </span>
        </h6>
        <p class="pre_line">{{ strategy.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    strategy: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('exit', ['report']),
  },
  methods: {
    prepareLevel(level) {
      return `${level}/100`;
    },
  },
};
</script>
