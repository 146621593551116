<template>
  <header class="auth-header">
    <b-container>
      <b-navbar class="px-0 flex-wrap xk-navbar" type="light" variant="light">
        <b-navbar-brand>
          <template>
            <b-img
              :src="headerLogo"
              :style="logoSize"
              fluid="fluid"
              :alt="title"
            ></b-img>
          </template>
        </b-navbar-brand>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import AuthMixin from '@/common/mixins/AuthMixin';
import franchise from '../conf/franchise';

export default {
  mixins: [AuthMixin],
  computed: {
    title() {
      return franchise.title;
    },
    headerLogo() {
      return franchise.logo;
    },
    logoSize() {
      return franchise.logoSize ? { height: franchise.logoSize } : {};
    },
  },
};
</script>
