import axios from 'axios';

const userRegister = params => {
  return axios.post('/users', params);
};

const userLogin = params => {
  return axios.post('/users/sign_in', params);
};

const userInfo = () => {
  return axios.get('/users/user_info');
};

const companyInfo = (id) => {
  return axios.get(`/api/v1/companies/${id}`);
};

const userValidate = email => {
  return axios.get('/users/validate_email', { params: { email } });
};

const resetPassword = email => {
  return axios.post('/users/password', { user: { email: email } });
};

const updatePassword = params => {
  return axios.put('/users/password', params);
};

const updateUser = params => {
  return axios.patch('/users', params);
};

const resendConfirmation = email => {
  return axios.post('/users/resend_confirmation', { params: { email } });
};

const userLogout = () => {
  return axios.delete('/users/sign_out');
};

const loginByToken = token => {
  return axios.post('/users/login_by_token', { token: token });
};

export default {
  userRegister,
  userLogin,
  userInfo,
  userValidate,
  resetPassword,
  updatePassword,
  updateUser,
  resendConfirmation,
  userLogout,
  companyInfo,
  loginByToken
};
