<template>
  <b-table :items="items" :fields="summaryFields"></b-table>
</template>

<script>
import SeekerHelpersMixin from "../../../common/SeekerHelpersMixin";
export default {
  mixins: [SeekerHelpersMixin],
  props: {
    experienceItems: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return this.experienceItems.map((experienceItem) => ({
        job_area: this.jobSeekerJobArea(experienceItem.data.job_area),
        job_name: experienceItem.data.job_name,
        job_level: this.jobSeekerJobLevel(experienceItem.data.job_level),
        keywords: experienceItem.data.keywords
          .map((keyword) => keyword.name)
          .join(", "),
        company_size: this.jobSeekerCompanySize(
          experienceItem.data.company_size
        ),
        years_of_experience: this.jobSeekerExperience(
          experienceItem.data.experience
        ),
        most_important_job: experienceItem.data.most_significant ? "x" : "",
        current_job: experienceItem.data.current_position ? "x" : ""
      }));
    }
  },
  methods: {
    jobSeekerJobArea(jobAreaValue) {
      const jobAreaObj = this.jobArea.find(
        (item) => item.value === jobAreaValue
      );
      if (jobAreaObj && "text" in jobAreaObj) {
        return jobAreaObj.text;
      }
    },
    jobSeekerJobLevel(jobLevel) {
      const jobLevelObj = this.jobLevels.find(
        (item) => "" + item.value === jobLevel
      );
      if (jobLevelObj && "text" in jobLevelObj) {
        return jobLevelObj.text;
      }
    },
    jobSeekerCompanySize(companySize) {
      const companySizeObj = this.companySize.find(
        (item) => item.value === companySize
      );
      if (companySizeObj && "text" in companySizeObj) {
        return companySizeObj.text;
      }
    },
    jobSeekerExperience(experience) {
      const experienceObj = this.yearsOfExperience.find(
        (item) => item.value === experience
      );
      if (experienceObj && "text" in experienceObj) {
        return experienceObj.text;
      }
    }
  }
};
</script>
