<template>
  <b-table class="dashboard-table" :items="items" :fields="tableFields"
           :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
           @sort-changed="sortingChanged" hover="hover"
           :no-local-sorting="true">

    <template v-slot:cell(select)="data">
      <b-form-checkbox :key="data.item.id"
                       name="checkbox" :checked="isSelected(data.item.id)"
                       @change="$emit('populateStateArray', data.item.id)"></b-form-checkbox>
    </template>

    <template v-slot:cell(campaign_id)="data">
      {{ data.item.info.internal_job_id_1 }}
      <span v-if="data.item.is_applicant_portal">*</span>
    </template>

    <template v-slot:cell(state)="data">
      <div :class="'table-label table-label__' + data.value.toLowerCase()">
        {{ $t(`dashboard.statuses.${data.value.toLowerCase()}`) }}
      </div>
    </template>

    <template v-slot:cell(job_title_short)="data">
      <router-link :to="`/campaigns/${data.item.id}`">{{ data.item.info.job_title_short }}</router-link>
    </template>

    <template v-slot:cell(recruiter_name)="data">
      <template v-if="data.item.recruiter_name === ' '">{{user.first_name}} {{user.last_name}}</template>
      {{ data.item.recruiter_name }}</template>

    <template v-slot:cell(client_name)="data">{{ data.item.info.client_name }}</template>

    <template v-slot:cell(start_date)="data">
      <div class="td-min-width text-left">{{ data.item.start_date | format-date }}</div>
    </template>

    <template v-slot:cell(end_date)="data">
      <div class="td-min-width text-left">{{ data.item.end_date | format-date }}</div>
    </template>

    <template v-slot:cell(progress)="data">
      <!--
      <radial :width="3"
              :value="+data.item.references_passed === 0 ? 0 : +((data.item.references_passed / data.item.references_requested) * 100).toFixed(0)"
              :show-percent="true" color="#89B8A9"></radial>
              -->

      <div class="progress__bar">
        <div class="progress__value">{{ data.item.references_passed }} ({{ calculateCampaignProgress(data.item.references_passed, data.item.references_requested) + '%' }})</div>
        <div class="progress__line"
             v-bind:style="{ width: calculateCampaignProgress(data.item.references_passed, data.item.references_requested) + '%' }"
             v-bind:class="{ 'progress__line--finished': calculateCampaignProgress(data.item.references_passed, data.item.references_requested) == 100  }"></div>
        <div class="progress__background"></div>
      </div>
    </template>
    <template v-slot:cell(comment)="data">
        <CampaignCommentModal v-model="data.item.info.comment" @change="(comment) => updateComment(data.item, comment)" />
    </template>

    <template v-slot:cell(participants)="data">
      <div class="text-center">{{ data.item.references_requested }}</div>
    </template>

    <template v-slot:cell(report)="data">
      <div class="table-icon-wrp text-right">
        <div v-if="data.item.report_present">
          <b-link :to="`/campaigns/${data.item.id}#report`" class="iconlink">
            <span class="mdi mdi-chart-box"></span>
          </b-link>
        </div>
        <div v-else><span class="mdi mdi-chart-box"></span></div>
      </div>
    </template>

    <template v-slot:cell(edit)="data">
      <b-link v-if="data.item.is_applicant_portal" :to="`/campaigns/ap/${data.item.id}/edit`" class="iconlink text-right">
        <span class="mdi mdi-pencil"></span>
      </b-link>
      <b-link v-if="!data.item.is_applicant_portal" :to="`/campaigns/${data.item.id}/edit`" class="iconlink text-right">
        <span class="mdi mdi-pencil"></span>
      </b-link>
    </template>
  </b-table>
</template>

<script>
import DashboardHelperMixin from "@/common/DashboardHelperMixin";
import Radial from "@/components/RadialProgress";
import CampaignCommentModal from "@/components/CampaignCommentModal";
import {calculateCampaignProgress} from "@/common/utils"
import { mapGetters } from 'vuex';
export default {
  components: {
    Radial,
    CampaignCommentModal
  },
  mixins: [DashboardHelperMixin],
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      user: {
        first_name: '',
        last_name: '',
      },
    };
  },
  computed:{
    ...mapGetters('global', [
      'currentUserFirstName',
      'currentUserLastName',
    ]),
  },
  created() {
    this.user.first_name = this.currentUserFirstName;
    this.user.last_name = this.currentUserLastName;
  },
  methods: {
    sortingChanged(ctx) {
      this.$emit("sort", ctx);
    },
    isSelected(id) {
      return this.selected.some(itemId => itemId === id);
    },
    updateComment(item, comment) {
      console.log('comment', comment)
       this.$store.dispatch('campaign/updateCampaignComment', {
        id: item.id,
        comment
       });
    },
    calculateCampaignProgress: calculateCampaignProgress
  }
};
</script>