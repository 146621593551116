import axios from "axios";
import { store } from "../store/index";
import franchise from "../conf/franchise";


axios.defaults.baseURL = franchise.apiUrl;
axios.defaults.headers.common["X-Examiz-Auth"] = localStorage.getItem("user-token");
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Locale"] = store.getters["global/currentLanguage"];

// Redirect middleware
axios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data && data.redirect) {
      window.location = `${data.redirect}/login?wrongdomain=true`;
      return;
    }
    return response;
  },
  error => {
    if ((error.response.status === 401) &&
        !location.pathname.startsWith('login') &&
        !location.pathname.startsWith('registration')) {

          store.commit('global/LOGOUT');
          location.href = '/login';
    }
    return Promise.reject(error)
  }
);
