<template>
  <div class="has__illustration">
    <!-- modals ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-modal centered v-model="modalNext" hide-footer="hide-footer">
      <h3 class="modal-title mb-3">{{ $t('self_test.lets_get_started') }}</h3>
      <p class="modal-text">{{ $t('self_test.introduction_page.text_1') }}</p>
      <p class="modal-text mt-5">
        {{ $t('self_test.introduction_page.text_2') }}
      </p>
      <div class="text-center">
        <router-link to="/self-test/example">
          <b-btn class="mt-4" variant="primary">{{ $t('general.next') }}</b-btn>
        </router-link>
      </div>
    </b-modal>

    <b-modal
      centered
      v-model="displayExperienceModal"
      size="xl"
      no-close-on-backdrop="no-close-on-backdrop"
      hide-footer="hide-footer"
      hide-header="hide-header"
    >
      <app-form
        :start-index="startIndex"
        :index="index"
        @close-modal="displayExperienceModal = false"
        @change="formChange"
        @update-index="updateIndex"
      ></app-form>

      <div
        class="close modal-close"
        @click="displayExperienceModal = !displayExperienceModal"
      >
        ×
      </div>
    </b-modal>

    <!-- talents dashboard ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="xk-headline">{{ $t('seeker_account.my_dashboard') }}</div>

    <b-row class="mt-5">
      <b-col lg="4" xl="4" md="4" class="mb-5">
        <a class="talents__dashboard__card" @click="openSelftestSurvey">
          <div class="talents__dashboard__card__icon">
            <i class="mdi mdi-card-account-details-outline"></i>
          </div>
          <h3 class="talents__dashboard__card__title text-truncate">
            {{ $t('seeker_account.my_skill_profile') }}
          </h3>
          <p>{{ $t('seeker_account.my_skill_profile_infotext') }}.</p>
        </a>
      </b-col>

      <b-col lg="4" xl="4" md="4" class="mb-5">
        <a
          class="talents__dashboard__card"
          @click="showTalentProfile"
          :title="$t('seeker_account.talent_profile_icon_subscription')"
        >
          <div class="talents__dashboard__card__icon">
            <i class="mdi mdi-card-account-details-star-outline"></i>
          </div>
          <h3 class="talents__dashboard__card__title">
            {{ $t('seeker_account.my_talent_profile') }}
          </h3>
          <p>{{ $t('seeker_account.my_talent_profile_infotext') }}.</p>
        </a>
      </b-col>

      <b-col lg="4" xl="4" md="4" class="mb-5">
        <router-link class="talents__dashboard__card" to="/profile">
          <div class="talents__dashboard__card__icon">
            <i class="mdi mdi-account-cog"></i>
          </div>
          <h3 class="talents__dashboard__card__title">
            {{ $t('seeker_account.my_account') }}
          </h3>
          <p>{{ $t('seeker_account.my_account_infotext') }}.</p>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { NOT_REGISTED_USER_TYPES } from '@/common/constants';
import Map from '../../components/Map';
import Icon1 from '../../../images/report/performance.svg';
import Icon2 from '../../../images/report/potential.svg';
import Icon3 from '../../../images/report/rock-star.svg';
import AppForm from '../JobSeeker/Steps/Index';
import ApiSurvey from '../../api/survey';
import ApiJobSeeker from '../../api/jobSeeker';
import { mapGetters } from 'vuex';
export default {
  components: {
    AppForm,
    Map,
  },
  data() {
    return {
      displayExperienceModal: false,
      modalNext: false,
      index: 0,
      startIndex: 0,
      // TODO: Remove jobSeekerForm
      jobSeekerForm: {},
      runTest: false,
    };
  },
  computed: {
    ...mapGetters('global', [
      'currentUserId',
      'currentUserSelftestSurveyPassed',
      'currentLanguage',
      'currentUserToken',
      'userRole',
    ]),
    icon_1() {
      return Icon1;
    },
    icon_2() {
      return Icon2;
    },
    icon_3() {
      return Icon3;
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.getJobSeekerInfo(this.currentUserId);
      this.checkUserRoles();
    }
  },
  methods: {
    checkUserRoles() {
      if (this.userRole) {
        const redirectToSelftest = NOT_REGISTED_USER_TYPES.map((t) =>
          t.toLowerCase()
        ).includes(this.userRole.toLowerCase());

        if (redirectToSelftest) this.$router.push('/self-test/results');
      }
    },
    openSelftestSurvey() {
      if (!this.runTest) {
        this.$router.push('/self-test');
      } else {
        this.modalNext = !this.modalNext;
      }
    },
    formChange(newForm) {
      this.jobSeekerForm = newForm;
    },
    showTalentProfile() {
      this.index = 0;
      this.startIndex = 0;
      this.displayExperienceModal = true;
    },
    updateIndex(value) {
      this.index = value + 1;
    },
    getJobSeekerInfo(id) {
      ApiJobSeeker.getJobSeeker(id)
        .then((response) => {
          response.data.job_seeker.work_items.forEach((workItem) => {
            workItem.data.keywords = workItem.data.keywords.map((name) => ({
              name,
            }));
          });
          response.data.job_seeker.job_types = response.data.job_seeker.job_types.map(
            function (jt) {
              return {
                value: jt.id,
                text: _.find(window.seeker_job_types, { value: jt.id })[
                  'label_' + (localStorage.getItem('language') || 'en')
                ],
              };
            }
          );
          response.data.job_seeker.preferred_countries = response.data.job_seeker.preferred_countries.map(
            function (pc) {
              let c = _.find(
                window.available_countries,
                (c) => c[1] === pc.country_code
              );

              return {
                value: pc.country_code,
                text: c ? c[0] : 'N/A',
              };
            }
          );
          this.jobSeekerForm = {
            ...this.jobSeekerForm,
            ...response.data.job_seeker,
          };
          if (!this.jobSeekerForm.experience_profile_finished) {
            this.displayExperienceModal = true;
          }
          ApiSurvey.getSelftestSurveyReport({
            token: this.currentUserToken,
            lang: this.currentLanguage,
          })
            .then(() => {
              this.runTest = false;
            })
            .catch((err) => console.log(err));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
