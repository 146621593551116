import axios from 'axios';

const fetchConfig = () => {
  return axios.get(`/api/fe/config?lang=${localStorage.getItem('language') || 'en'}`);
}

const fetcJobTypes = () => {
  return axios.get('/api/fe/job_types');
};

const fetchTimeZones = () => {
  return axios.get('/api/fe/time_zones');
}

const fetchTitles = () => {
  return axios.get(`/api/fe/titles?lang=${localStorage.getItem('language') || 'en'}`);
}

const fetchApplicantPortalConfig = () => {
  return axios.get('/api/fe/ap');
}

const fetchExitConfig = () => {
  return axios.get('/api/fe/exit');
}

const searchEducationAreas = (query) => {
  return axios.get(`/api/v1/search/education_areas?query=${query}`);
}

const searchEducationGroups = (query) => {
  return axios.get(`/api/v1/search/education_groups?query=${query}`);
}

const searchEducationSpecializations = (query) => {
  return axios.get(`/api/v1/search/education_specializations?query=${query}`);
}

const searchJobAreas = (query) => {
  return axios.get(`/api/v1/search/job_areas?query=${query}`);
}

const searchJobGroups = (query) => {
  return axios.get(`/api/v1/search/job_groups?query=${query}`);
}

const searchJobSpecializations = (query) => {
  return axios.get(`/api/v1/search/job_specializations?query=${query}`);
}

export default {
  fetchConfig,
  fetcJobTypes,
  fetchTimeZones,
  fetchTitles,
  fetchApplicantPortalConfig,
  searchEducationAreas,
  searchEducationGroups,
  searchEducationSpecializations,
  searchJobAreas,
  searchJobGroups,
  searchJobSpecializations,
  fetchExitConfig,
};
