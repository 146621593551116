import authApi from '@/api/auth';
import configAPI from '@/api/config';
import meApi from '@/api/me';

const authUser = ({ commit }, params) => {
  return authApi.userLogin(params).then(response => {
    commit('AUTH_USER', response.data.data);
  })
};

const logout = ({ commit }) => {
  return new Promise((resolve, reject) => {
    authApi
      .userLogout()
      .then(response => {
        commit('LOGOUT');
        resolve(response);
      })
      .catch(reject);
  });
};

// const resetPassword = ({ commit }, email) => {
//   return authApi.resetPassword(email);
// };

// const updatePassword = ({ commit }, params) => {
//   return authApi.updatePassword(params);
// };

const updateUser = ({ commit }, params) => {
  return authApi.updateUser(params).then(response => {
    commit('AUTH_USER', response.data);
  });
};

const setAuthHeader = ({ commit }) => {
  return commit('SET_AUTH_HEADER');
};

const setLanguage = ({ commit, state }, lang) => {
  if (state.currentUserId) {
    return meApi.patchUser(
      {
        lang,
      }
    ).then(success => commit('CHANGE_LANGUAGE', lang));
  }

  return commit('CHANGE_LANGUAGE', lang);
};

const loadUserInfo = ({ commit }) => {
  return authApi.userInfo().then((response) => {
    commit('AUTH_USER', response.data);
  })
};

const loadCompanyInfo = ({ commit }, companyId) => {
  return authApi.companyInfo(companyId).then((response) => {
    commit('AUTH_USER_COMPANY', response.data);
  });
};

const authAdminUser = ({ commit }, params) => {
  return commit('AUTH_ADMIN', params);
};

const removeUserToken = ({ commit }) => {
  return commit('LOGOUT');
};

const authReferenceUser = ({ commit }, params) => {
  return commit('AUTH_REFERENCE', params);
};

const authSelfTest = ({ commit }, params) => {
  return commit('AUTH_SELF_TEST', params);
};

const logoutReference = ({ commit }) => {
  return commit('LOGOUT_REFERENCE');
};

const populateAttributes = ({ commit }) => {
  return commit('POPULATE_ATTRIBUTES');
};

const populatePermanentAttributes = ({ commit }) => {
  return commit('POPULATE_PERMANENT_ATTRIBUTES');
};

const populateTalentsAttributes = ({ commit }) => {
  return commit('POPULATE_TALENTS_ATTRIBUTES');
};

const redirectTo = ({ commit }, path) => {
  return commit('SET_REDIRECT_TO', path);
};

// Config
const fetchConfig = ({ commit }) => {
  return configAPI
    .fetchConfig()
    .then((success) => {
      const keys = Object.keys(success.data);
      for (const key of keys) {
        try {
          window[key] = JSON.parse(success.data[key])
        } catch (err) {
          window[key] = success.data[key]
        }
      }
    })
}

const fetchJobTypes = ({ commit }) => {
  return configAPI
    .fetcJobTypes()
    .then(success => {
      commit('SET_JOB_TYPES', success.data.seeker_job_types || []);
    })
}

const fetchTimeZones = ({ commit }) => {
  return configAPI
    .fetchTimeZones()
    .then(success => {
      commit('SET_TIME_ZONES', success.data.time_zones || []);
    })
}

const fetchTitles = ({ commit }) => {
  return configAPI
    .fetchTitles()
    .then(success => {
      commit('SET_TITLES', success.data.titles || []);
    })
}

const fetchApplicantPortalConfig = ({ commit }) => {
  return configAPI
    .fetchApplicantPortalConfig()
    .then(success => {
      commit('SET_AVAILABLE_LANGUAGES', success.data.available_languages);
      commit('SET_AVAILABLE_COUNTRIES', success.data.available_countries);
      commit('SET_LANGUAGE_PROFICIENCY_LEVELS', success.data.language_proficiency_levels);
      commit('SET_EDUCATION_GENERAL_AREAS', success.data.education_general_areas);
      commit('SET_JOB_AREAS_OLD', success.data.job_areas);
      commit('SET_YEARS_OF_EXPERIENCE', success.data.years_of_experience);
      commit('SET_SENIORITY_LEVEL', success.data.seniority_level);
      commit('SET_BULK_HIRING_TYPES', success.data.fast_track.types);
      commit('SET_BULK_HIRING_PROFILES', success.data.fast_track.profiles);
    })
}

const fetchExitConfig = ({ commit }) => {
  return configAPI
    .fetchExitConfig()
    .then(success => {
      commit('SET_EXIT_QUESTIONS', success.data.questions);
      commit('SET_AVAILABLE_COUNTRIES', success.data.available_countries);
    })
}

const searchEducationAreas = ({ commit }, query) => {
  return configAPI
    .searchEducationAreas(query)
    .then(success => {
      commit('SET_EDUCATION_AREAS', success.data.education_areas);
    })
}

const searchEducationGroups = ({ commit }, query) => {
  return configAPI
    .searchEducationGroups(query)
    .then(success => {
      commit('SET_EDUCATION_GROUPS', success.data.education_groups);
    })
}

const searchEducationSpecializations = ({ commit }, query) => {
  return configAPI
    .searchEducationSpecializations(query)
    .then(success => {
      commit('SET_EDUCATION_SPECIALIZATIONS', success.data.education_specializations);
    })
}

const searchJobAreas = ({ commit }, query) => {
  return configAPI
    .searchJobAreas(query)
    .then(success => {
      commit('SET_JOB_AREAS', success.data.job_areas);
    })
}

const searchJobGroups = ({ commit }, query) => {
  return configAPI
    .searchJobGroups(query)
    .then(success => {
      commit('SET_JOB_GROUPS', success.data.job_groups);
    })
}

const searchJobSpecializations = ({ commit }, query) => {
  return configAPI
    .searchJobSpecializations(query)
    .then(success => {
      commit('SET_JOB_SPECIALIZATIONS', success.data.job_specializations);
    })
}

export default {
  authUser,
  authAdminUser,
  logout,
  updateUser,
  setAuthHeader,
  setLanguage,
  loadUserInfo,
  authReferenceUser,
  removeUserToken,
  logoutReference,
  authSelfTest,
  loadCompanyInfo,
  populateAttributes,
  populatePermanentAttributes,
  populateTalentsAttributes,
  redirectTo,
  // Config
  fetchJobTypes,
  fetchConfig,
  fetchTimeZones,
  fetchTitles,
  fetchApplicantPortalConfig,
  searchEducationAreas,
  searchEducationGroups,
  searchEducationSpecializations,
  searchJobAreas,
  searchJobGroups,
  searchJobSpecializations,
  fetchExitConfig,
};
