<template>

  <div class="settings-wrap">

    <!-- INFO: Comented until the avatar upload feature is completed -->
    <!-- <b-row class="mb-5">
      <b-col lg="3">
        <div class="settings-top d-flex">
          <div class="settings-top-img mr-3"></div>
          <div class="settings-top-info">
            <div class="h4 settings-top-info-name">{{ user.first_name }} {{ user.last_name }}</div>
            <span class="settings-top-info-location">Berlin, Germany</span>
          </div>
        </div>
      </b-col>
    </b-row> -->

    <ValidationObserver class="pr-3" ref="form" tag="div">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col lg="3">
            <div class="form__title">{{ $t('auth_pages.profile') }}</div>
            <app-text-input :name="$t('general.first_name')"
                            :label="$t('general.first_name')" vid="first_name"
                            rules="required" type="text"
                            v-model="user.first_name"></app-text-input>

            <app-text-input :name="$t('general.last_name')"
                            :label="$t('general.last_name')" vid="last_name"
                            rules="required" type="text"
                            v-model="user.last_name"></app-text-input>

            <app-text-input :name="$t('general.email')"
                            :label="$t('general.email')" vid="email"
                            rules="required|email" type="text"
                            v-model="user.email"
                            :readonly="true"></app-text-input>
          </b-col>

          <b-col lg="3">
            <div class="pl-3">
              <div class="form__title">{{ $t('auth_pages.change_password') }}</div>
              <app-text-input :name="$t('general.old_password')"
                              :label="$t('general.old_password')"
                              vid="old_password" mode="lazy"
                              rules="required|min:6" type="password"
                              v-model="old_password"></app-text-input>

              <app-text-input :name="$t('general.password')"
                              :label="$t('general.password')" vid="password"
                              mode="lazy" rules="min:6" type="password"
                              v-model="new_password"></app-text-input>

              <app-text-input :name="$t('auth_pages.repeat_password')"
                              :label="$t('auth_pages.repeat_password')"
                              vid="repeat_password" mode="lazy"
                              rules="confirmedBy:@password" type="password"
                              v-model="repeat_password"></app-text-input>
            </div>
          </b-col>
        </b-row>

         <b-row>
          <b-col lg="6">
            <hr />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="3">
            <h2 class="form__title">{{ $t('general.language') }}</h2>
            <b-dropdown variant="outline-primary">
              <template v-slot:button-content>
                <span>{{ currentLanguageItem }}</span>
              </template>

              <b-dropdown-item
                v-for="item in languagesList"
                @click="changeLocale(item.value)"
                :key="item.value"
                :disabled="item.value === currentLanguage"
                >{{ item.label }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>


        <b-row>
          <b-col lg="6">
            <hr>
            <b-button type="submit" variant="primary" class="settings-save-btn">
              <b-spinner v-if="isInprogress" variant="light" small="small" label="Spinning"></b-spinner>
              {{ $t('general.save') }}
            </b-button>


          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>

</template>

<script>
import AuthApi from "@/api/auth";
import ImageBgSeeker from "../../../images/settings-seeker.svg";
import AppTextInput from "../../components/TextInput";
import Logo from "../../../images/auth/logo.svg";
import { mapGetters } from "vuex";
import { languages } from '@/common/languages';
import franchise from '../../conf/franchise';

export default {
  components: {
    AppTextInput
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: ""
      },
      old_password: "",
      new_password: "",
      repeat_password: "",
      isInprogress: false
    };
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'global/currentLanguage',
    }),
    languagesList() {
      return languages;
    },
    currentLanguageItem() {
      const lang = languages.find(
        (item) => item.value === this.currentLanguage
      );
      return lang ? lang.label : 'en';
    },
    imageSeeker() {
      return ImageBgSeeker;
    },
    logoTest() {
      return Logo;
    },
    ...mapGetters("global", [
      "currentUserFirstName",
      "currentUserLastName",
      "currentUserEmail",
      "currentUserCompanyName",
      "companyLogoUrl"
    ]),
    companyLogo() {
      return this.companyLogoUrl ? this.companyLogoUrl : franchise.logo;
    }
  },
  created() {
    this.user.first_name = this.currentUserFirstName;
    this.user.last_name = this.currentUserLastName;
    this.user.email = this.currentUserEmail;
    this.user.company_name = this.currentUserCompanyName;
  },
  methods: {
    onSubmit() {
      const user = {
        ...this.user,
        current_password: this.old_password,
        password: this.new_password
      };
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInprogress = true;
        AuthApi.updateUser({ user })
          .then((response) => {
            const message = response.data.message;
            this.$bvToast.toast(message, {
              autoHideDelay: 4000,
              variant: "success",
              title: "Success"
            });
            this.isInprogress = false;
            this.$store.commit('global/AUTH_USER', response.data.data);
          })
          .catch((e) => {
            const error = e.response.data.message;
            this.$bvToast.toast(error, {
              autoHideDelay: 4000,
              variant: "warning",
              title: "Error"
            });
            this.isInprogress = false;
          });
      });
    },
    changeLocale(lang) {
      this.$store.dispatch('global/setLanguage', lang).then(() => {
        location.reload();
      })
    }
  }
};
</script>
