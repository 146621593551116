<template>
  <section
    class="page-break-inside-avoid page_campaign_summary new"
  >
    <div class="row">
      <div class="col-2">
        <figure>
          <img :src="companyLogoUrl" alt="logo" class="logo" />
        </figure>
      </div>
      <div class="col-7 pr-0">
        <h1 class="strictly">
          - {{ $t('exit.report.strictly') }} -
        </h1>
        <img class="report__exit__front__page"
          src="../../../../images/report/match-report_new.svg"
          alt="front page"
        >
      </div>
      <div class="col-3 report__info__attributes">
        <div class="report__info__attributes__label">
          {{ $t('exit.report.report_id') }}
        </div>
        <div class="report__info__attributes__value">
          {{ campaign.id }}
        </div>
        <hr>
        <div class="report__info__attributes__label">
          {{ $t('exit.report.report_title') }}
        </div>
        <div class="report__info__attributes__value">
          {{ campaign.name }}
        </div>
        <hr>
        <div class="report__info__attributes__label">
          {{ $t('exit.report.report_owner') }}
        </div>
        <div class="report__info__attributes__value">
          {{ campaign.admin.first_name }} {{ campaign.admin.last_name }}
        </div>
        <hr>
        <div class="report__info__attributes__label">
          {{ $t('exit.report.date') }}
        </div>
        <div class="report__info__attributes__value">
          {{ date }}
        </div>
      </div>
    </div>
    <div class="row report__analytics">
      <div class="offset-1 col-3 mb-5">
        <table class="table report__ems__table has__lines">
          <tbody>
            <tr>
              <td><img src="../../../../images/report/references_new.svg"  alt="references" width="50px" style="float:right"></td>
              <td>{{ campaign.exit_campaign_participants.length }}</td>
            </tr>
            <tr>
              <td><img src="../../../../images/report/date-start_new.svg"  alt="date-start" width="50px" style="float:right"></td>
              <td>{{ campaign.start_date }}</td>
            </tr>
            <tr>
              <td><img src="../../../../images/report/date-end_new.svg"  alt="date-end" width="50px" style="float:right"></td>
              <td>{{ campaign.end_date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-5">
        <div class="report__analytics__head">{{ $t('exit.report.analytics_report') }}</div>
      </div>
      <div class="offset-1 col-11 mt-5">
        <div class="report__analytics__filters">{{ $t('exit.report.filters_criteria') }}</div>
        <div class="report__analytics__filters__line"></div>
      </div>
    </div>
    <!--  Filters & Criteria -->
    <div class="row pb-4 report__chapter__exit">
      <div class="offset-2 col-3 mt-5">
        <div class="mb-4"
          v-if="campaign.info.job_names && campaign.info.job_names.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.job_names') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.job_names" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4"
          v-if="campaign.info.job_levels && campaign.info.job_levels.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.create_survey.job_level') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.job_levels" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>
      </div>

      <div class="col-3 mt-5">
        <div class="mb-4"
          v-if="campaign.info.location_city && campaign.info.location_city.length">
          <div class="report__chapter__exit__title">
            {{ $t('general.city') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.location_city" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4"
          v-if="campaign.info.location_country && campaign.info.location_country.length">
          <div class="report__chapter__exit__title">
            {{ $t('dashboard.country') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.location_country" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4"
          v-if="campaign.info.keywords && campaign.info.keywords.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.create_survey.keywords') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.keywords" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4"
          v-if="campaign.info.performance_levels && campaign.info.performance_levels.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.evaluation') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.performance_levels" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.performance_peer') }}
          </div>
          <div class="report__chapter__exit__value">
            {{ report.performance.exit_13_1_1 }}
          </div>
        </div>

        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.potential_peer') }}
          </div>
          <div class="report__chapter__exit__value">
            {{ report.performance.exit_13_1_2 }}
          </div>
        </div>
      </div>
      <div class="col-3 mt-5">
        <div class="mb-4"
          v-if="campaign.info.departments && campaign.info.departments.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.create_survey.department') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.departments" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>

        <div class="mb-4"
          v-if="campaign.info.units && campaign.info.units.length">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.units') }}
          </div>
          <div
            v-for="(name, index) in campaign.info.units" :key="index"
            class="report__chapter__exit__value">
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as moment from "moment";
export default {
  props: {
    companyLogoUrl: {
      type: String,
      require: true
    },
    campaign: {
      type: Object,
      require: true
    },
    report: {
      type: Object,
      require: true
    }
  },
  computed:{
    date(){
      return moment().format("DD MMM YYYY")
    }
  }
}
</script>