<template>
  <multiselect
    :class="{ 'exit-campaign-tag': extendedHeight }"
    v-model="checkValue"
    :options="options"
    :label="label"
    :trackBy="trackBy"
    selectLabel=""
    deselectLabel=""
    selectedLabel=""
    :multiple="true"
    :taggable="false"
    :searchable="true"
  >
  </multiselect>
</template>

<script>
export default {
  props: {
    value: Array,
    options: Array,
    label: {
      type: String,
      default: '',
    },
    trackBy: {
      type: String,
      default: '',
    },
    extendedHeight: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    checkValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss">
.exit-campaign-tag {
  .multiselect__tags {
    height: 100px;
  }
}
</style>