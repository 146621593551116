<template>
  <div class="survey__slider">
    <!--    <div class="d-none d-md-block survey__answers">
      <div class="row justify-content-between">
        <div class="col-6 col-md-5 text-left"><slot name="labelLeft">{{ labelLeft }}</slot></div>
        <div class="col-6 col-md-5 text-right"><slot name="labelRight">{{ labelRight }}</slot></div>
      </div>
    </div>-->

    <div class="d-flex bars two-bars">
      <app-volume-bar
        type="bars"
        :max="max"
        :min="min"
        :value="firstBar"
        :height="100"
        :backplateColor="backplateColor"
        primaryColor="#E8710F"
        :delimitersSize="10"
        :smoothing="4"
      ></app-volume-bar>

      <app-volume-bar
        type="bars"
        :max="max"
        :min="min"
        :value="secondBar"
        :height="100"
        :backplateColor="backplateColor"
        primaryColor="#E8710F"
        :delimitersSize="10"
        :smoothing="4"
      ></app-volume-bar>
    </div>

    <vue-slider
      :value="value"
      @change="onChange"
      dotSize="16"
      :min="min"
      :max="max"
      :interval="1"
      :drag-on-click="true"
      :height="6"
      :tooltip-placement="'bottom'"
      :tooltip="showTooltip"
      :process="process"
    >
      <template v-slot:tooltip="{ value, focus }">
        <div :class="['custom-tooltip', { focus }]" v-if="examplePage">{{ tooltipText }}</div>
        <div :class="['custom-tooltip', { focus }]" v-else>{{ tooltipValue }}</div>
      </template>
    </vue-slider>

    <div class="survey__answers">
      <div class="row justify-content-between">
        <div class="col-6 col-md-6 text-left">
          <slot name="labelLeft">
            {{ labelLeft }}
            <div class="meta-data">{{ leftAttribute }}</div>
          </slot>
        </div>
        <div class="col-6 col-md-6 text-right">
          <slot name="labelRight">
            {{ labelRight }}
            <div class="meta-data">{{ rightAttribute }}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import env from '../../config/enviroment';
import AppVolumeBar from './VolumeBar';
import findValueRange from '../common/findValueRange';

export default {
  components: {
    AppVolumeBar,
  },
  props: {
    labelLeft: {
      type: String,
      default: "",
    },
    labelRight: {
      type: String,
      default: "",
    },
    rangeTips: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      required: true,
    },
    backplateColor: {
      type: String,
      default: "#F6C69F",
    },
    question: {
      type: Object,
      default: null
    },
    attributes: {
      type: Array,
      default: () => []
    },
    examplePage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      height: 27,
      min: -100,
      max: 100,
    };
  },
  computed: {
    ...mapGetters("global", ["currentLanguage"]),
    tooltip() {
      const tooltip = findValueRange(this.rangeTips, this.value);
      return tooltip ? tooltip : null;
    },
    tooltipText() {
      return this.tooltip ? this.tooltip.label : "";
    },
    tooltipValue() {
      return Math.abs(this.value);
    },
    showTooltip() {
      if (this.examplePage) {
        return this.tooltipText ? 'active' : 'none';
      } else {
        return 'active'
      }

    },
    firstBar() {
      return this.value < 0 ? Math.abs(this.value) : 0;
    },
    secondBar() {
      return this.value > 0 ? Math.abs(this.value) : 0;
    },
    showAttributeKeys() {
      return env.NAME !== 'production'
    },
    leftAttribute() {
      if (!this.showAttributeKeys || !this.question) return '';
      const attr = this.attributes.find(item => {
        return (item.attribute || item.key) === this.question.data.attribute_1_id
      });
      return attr ? `[${attr.attribute || attr.key} - ${attr['name_' + this.currentLanguage] || attr.name}]` : `[${this.question.data.attribute_1_id} - negative]`;
    },
    rightAttribute() {
      if (!this.showAttributeKeys || !this.question) return '';
      const attr = this.attributes.find(item => {
        return (item.attribute || item.key) === this.question.data.attribute_2_id
      });
      return attr ? `[${attr.attribute || attr.key} - ${attr['name_' + this.currentLanguage] || attr.name}]` : `[${this.question.data.attribute_2_id} - negative]`;
    },
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    process(dots) {
      return [[50, dots[0]]];
    },
  },
};
</script>

<style lang="scss" scoped>
.two-bars {
  .volume-bar {
    width: 50%;
    padding-left: 5px;
  }
}

.bars > :first-child {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.meta-data {
  color: #eb9817;
  font-size: 1.2rem;
}
</style>
