<template>
  <div>
    <div class="row report__reasons">
      <div class="offset-1 col-9">
        <div class="report__reasons__title">
          {{ $t('exit.report.reasons_leaving') }}
        </div>
        <div
          class="report__reasons__description"
          v-if="reasonsLeaving"
          v-html="
            $t('exit.report.reasons_description', {
              Top_1: reasonsLeaving[0].label,
              score_1: reasonsLeaving[0].value,
              Top_2: reasonsLeaving[1].label,
              score_2: reasonsLeaving[1].value,
              Top_3: reasonsLeaving[2].label,
              score_3: reasonsLeaving[2].value,
            })
          "
        ></div>
      </div>
    </div>
    <div class="row mt-2 p-4">
      <div class="col-3 pr-4 mt-3">
        <img
          src="../../../../images/report/exit/exit.svg"
          alt="exit"
          width="75%"
          style="float: right"
        />
      </div>
      <div class="col-9">
        <candidate-details-competencies-table
          report_title=""
          :items="reasonsLeaving"
          :fields="exitChartColumns"
          icon=""
          :job_table="false"
          :max="max"
          :hideTitle="true"
          :twoHeight="true"
        >
        </candidate-details-competencies-table>
      </div>
    </div>

    <!-- progress detailed analytics -->
    <div class="row mt-2 py-5 report__detailed">
      <div class="col-12">
        <div class="report__detailed__head">
          {{ $t('exit.report.detailed_analytics') }}
        </div>
      </div>
      <div
        class="mt-4 wrapper col-6"
        v-for="(value, key) in detailedAnalytics"
        :key="key"
      >
        <div>
          <div class="report__detailed__header">
            {{ value.label }}
          </div>
          <candidate-details-competencies-table
            v-if="value.values.length"
            report_title=""
            :items="value.values"
            :fields="exitChartColumns"
            icon=""
            :job_table="false"
            :max="max"
            :hideTitle="true"
            :twoHeight="true"
          >
          </candidate-details-competencies-table>
          <h5 v-else class="report__detailed__empty">
            {{ $t('exit.report.insufficient_data') }}
          </h5>
        </div>
      </div>
    </div>

    <!-- progress Compliance Risks -->
    <div class="row pt-3 report__compliance">
      <div class="col-12 my-3">
        <div class="report__compliance__head">
          {{ $t('exit.report.compliance') }}
        </div>
      </div>
      <div class="offset-2 col-7 mt-2">
        <div class="report__candidate">
          <b-table
            class="report__candidate__attr__table"
            ref="table"
            :fields="isAnalyticsReport ? exitChartColumns : complianceRisksColumns"
            :items="complianceRisks"
            :borderless="true"
            responsive="sm"
          >
            <template #head(value)>
              <div class="table__head_lower">{{ $t('exit.report.lower') }}</div>
              <div class="table__head_hight">
                {{ $t('exit.report.higher') }}
              </div>
            </template>
            <template #head(flags)>
              <div class="table__head_flags">{{ $t('exit.report.flags') }}</div>
            </template>

            <template v-slot:cell(key)="{ item }">
              <span class="text-truncate">{{ item.label }}</span>
            </template>

            <template v-slot:cell(value)="{ item }">
              <div class="double-progress d-flex">
                <b-progress
                  class="progress-left"
                  :value="calculateProgressBarLeftValue(item.value.value)"
                  variant="green3"
                  height="1.8rem"
                  :max="max"
                ></b-progress>
                <div class="progress__wrp__point"></div>
                <b-progress
                  class="progress-right"
                  :value="calculateProgressBarRightValue(item.value.value)"
                  variant="success"
                  height="1.8rem"
                  :max="max"
                ></b-progress>
              </div>
            </template>
            <template v-slot:cell(flags)="{ item }">
              <span class="text-truncate">{{ item.value.flags }}</span>
            </template>
          </b-table>
        </div>
      </div>
      <div class="col-2 mt-5 ml-4">
        <img
          src="../../../../images/report/exit/protest.svg"
          alt="protest"
          width="100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CandidateDetailsCompetenciesTable from '@/components/CandidateDetailsCompetenciesTable';
import ExitReportHelpersMixin from '../../../common/ExitReportHelpersMixin';
import { EXIT_CAMPAIGN_TYPES } from '../../../common/constants';
export default {
  mixins: [ExitReportHelpersMixin],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  components: {
    CandidateDetailsCompetenciesTable,
  },
  computed: {
    isAnalyticsReport() {
      return this.campaign.campaign_type === EXIT_CAMPAIGN_TYPES.ANALYTICS;
    }
  }
};
</script>