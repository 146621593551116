import axios from 'axios';
import { PROMO_CODE_PREFIXES } from '@/common/constants'

const redeemSelftestPromoCode = (commit, payload) => {
  return axios.post('/api/v1/selftest_promo_code/redeem', {
    selftest_promo_code: payload
  })
}

const redeemPromoCode = ({ commit }, payload) => {
  const codePrefix = payload.code.slice(0, 2).toLowerCase();
  let action = null;
  switch(codePrefix) {
    case PROMO_CODE_PREFIXES.SELFTEST:
      action = redeemSelftestPromoCode;
      break;
  }
  return action ? action(commit, payload) : Promise.reject('SELFTEST_PROMO_CODE_INVALID');
}


export default {
  redeemPromoCode,
};
