import { CAMPAIGN_STATE, EXIT_CAMPAIGN_STATE } from '@/common/constants'

const SET_CAMPAIGN_LIST = (state, data) => {
  state.campaigns = data.campaigns;
  state.campaignsMeta = data.meta;
};

const SET_CAMPAIGN = (state, data) => {
  state.exitCampaign = data.exit_campaign;
};

const RESET_CAMPAIGN = (state) => {
  state.exitCampaign = {
    id: "",
    last_date: "",
    start_date: "",
    end_date: "",
    time_zone: null,
    info: {},
    admin: {},
    team_leader: {},
    leaving_person: {},
    keywords: [],
    departments: [],
    status: EXIT_CAMPAIGN_STATE.OPEN
  }
};

const UPDATE_CAMPAIGN = (state, data) => {
  state.exitCampaign = {
    ...state.exitCampaign,
    ...data.exit_campaign,
  }
};

const SET_TEAM_MEMBERS = (state, data) => {
  state.teamMembers = data.team_members
}

const SET_QUESTIONS = (state, questions) => {
  state.questions = questions
}

const SET_LEAVING_PERSON = (state, leavingPerson) => {
  state.leavingPerson = leavingPerson
}

const SET_PARTICIPANT_ID = (state, id) => {
  state.participantId = id
}


const SET_QUESTIONS_COUNT = (state, count) => {
  state.questionsCount = count
}

const SET_REPORT = (state, report) => {
  state.report = report
}

const UPDATE_STATE_CAMPAIGN = (state, data) => {
  // state.exitCampaign = data;
  state.exitCampaign.id = (data.id).toString()
  state.exitCampaign.name = data.name
  state.exitCampaign.status = data.status
  if (data.time_zone) {
    state.exitCampaign.time_zone = data.time_zone
  }

};

const SET_ANALYTICS_CAMPAIGN_OPTIONS = (state, options) => {
  state.analyticsCampaignOptions = options;
}

const USE_TEMPLATE = (state, data) => {
  data.id = '';
  data.info.references_requested = 4;

  state.currentCampaign = data;
};

const UPDATE_AP_REFRESH_KEY = (state) => {
  state.apCampaignRefreshKey = new Date().getTime()
};

const SET_CAMPAIGN_STATE = (state, value) => {
  state.currentCampaign.state = value;
}

const SET_CAMPAIGN_CANDIDATES_PAGINATION = (state, pagination) => {
  state.campaignCandidatesPagination = pagination;
}

const SET_CANDIDATE_AP_DATA = (state, data) => {
  state.campaignCandidatesAPData = Object.assign(
    {},
    state.campaignCandidatesAPData,
    data
  )
}

const SET_STRATEGIES = (state, strategies) => {
  state.strategies = strategies

  if (!state.queryStrategies.length) {
    state.queryStrategies = Object.keys(strategies).filter(
      (item) => strategies[item]
    );
  }
}

const RESET_QUERY_STRATEGIES = (state) => {
  state.queryStrategies = []
};

const SET_QUERY_STRATEGIES = (state, queryStrategies) => {
  state.queryStrategies = queryStrategies
}

export default {
  SET_CAMPAIGN_LIST,
  SET_CAMPAIGN,
  RESET_CAMPAIGN,
  UPDATE_CAMPAIGN,
  SET_TEAM_MEMBERS,
  SET_QUESTIONS,
  SET_LEAVING_PERSON,
  SET_QUESTIONS_COUNT,
  SET_REPORT,
  SET_ANALYTICS_CAMPAIGN_OPTIONS,
  SET_PARTICIPANT_ID,
  UPDATE_STATE_CAMPAIGN,
  USE_TEMPLATE,
  SET_CAMPAIGN_STATE,
  SET_CAMPAIGN_CANDIDATES_PAGINATION,
  SET_CANDIDATE_AP_DATA,
  UPDATE_AP_REFRESH_KEY,
  SET_STRATEGIES,
  SET_QUERY_STRATEGIES,
  RESET_QUERY_STRATEGIES
};
