<template>
  <div class="acountDetails-wrp">
    <div class="text-center">
      <h2 class="acountDetails-title mb-3">{{ $t('match_references.thank_you_page_title') }}</h2>
      <h4 class="thank-subtitle mb-3">{{ $t('match_references.thank_you_page_subtitle') }}</h4>
    </div>
    <b-container>
      <b-row class="justify-content-center">
        <b-col lg="8">
          <i18n class="talents-text" path="match_references.thank_you_page_description" tag="p"><span>{{ $t('applicant_portal.examiz_score') }}</span></i18n>
          <i18n class="talents-text" path="match_references.thank_you_page_description_price" tag="p"><template v-slot:name><span>{{ $t('applicant_portal.examiz_score') }}</span></template><template v-slot:cost><span>$75</span></template></i18n>
          <div class="px-3">
            <b-button class="mt-4" size="lg" block="block" variant="primary">{{ $t("match_references.redeem_personality_test") }}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AppStart from "./Start";
export default {
  components: {
    AppStart
  },
  data() {
    return {
      check: false,
      modalShow: false,
      step: 1
    };
  },
  destroyed() {
    this.$store.dispatch("global/logoutReference");
  },
  methods: {
    onSubmit() {
      this.modalShow = true;
    }
  }
};
</script>
