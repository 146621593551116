<template lang="pug">
div(class="h-100")
  div(v-if="step === 1" class="h-100")
    app-step-first(:data="form", @next="nextStep", @change="updateForm")
  div(v-if="step === 2" class="h-100")
    app-step-second(
      :data="form",
      @next="nextStep",
      @prev="step--",
      @change="updateForm"
    )
  div(v-if="step === 3", @change="updateForm" class="h-100")
    app-step-third(:data="form")
</template>

<script>
import { clearLocalStorage } from "../../../../common/utils";
import AppStepFirst from "./StepFirst";
import AppStepSecond from "./StepSecond";
import AppStepThird from "./StepThird";
import AuthHeader from "../../../../components/Header";
export default {
  components: { AuthHeader, AppStepFirst, AppStepSecond, AppStepThird },
  data() {
    return {
      form: {},
      step: 1
    };
  },
  mounted() {
    if (localStorage.getItem("email")) {
      this.form['email'] = localStorage.getItem("email");
    }
    if (localStorage.getItem("first-name")) {
      this.form['first_name'] = localStorage.getItem("first-name");
    }
    if (localStorage.getItem("last-name")) {
      this.form['last_name'] = localStorage.getItem("last-name");
    }
    if (localStorage.getItem("form")) {
      this.form = JSON.parse(localStorage.getItem("form"));
    }
    if (localStorage.getItem("step")) {
      this.step = Number(localStorage.getItem("step"));
    }

    // Clear localStorage if jwt token exist
    if (localStorage.getItem('user-token')) clearLocalStorage();
  },
  methods: {
    updateForm(value) {
      const result = {
        ...value
      };
      if (JSON.stringify(result) === JSON.stringify(this.form)) return;
      this.form = result;
    },
    nextStep() {
      localStorage.setItem("form", JSON.stringify(this.form));
      localStorage.setItem("step", String(this.step));
      this.step++;
    }
  }
};
</script>
