<template>
  <b-row class="h-100">
    <b-col md="6" class="d-md-flex h-100 image__scroll"></b-col>

    <b-col md="6" class="h-100 bg-white">
      <div class="login__dialog">
        <b-alert
          v-model="resendEmailAlert"
          :variant="resendEmailAlertVariant"
          dismissible="dismissible"
          >{{ resendEmailAlertMessage }}</b-alert
        >

        <h1 class="xk-headline">{{ $t('general.congratulations') }}</h1>
        <img class="illustration" :src="imageSvg" alt="Illustration" />

        <p>{{ $t('auth_pages.congratulations_text') }}</p>
        <p>{{ $t('auth_pages.confirmation_email_text') }}</p>

        <hr />

        <b-link class="btn btn-lg btn-primary btn-block mb-3" to="/">{{
          $t('general.login')
        }}</b-link>
        <a href="javascript: void(0)" @click="resendConfirmationEmail">{{
          $t('auth_pages.resend_email')
        }}</a>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import congratulationsSvg from '../../../../../images/happycheck_new.svg';

import AuthApi from '../../../../api/auth';
export default {
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      resendEmailAlert: false,
      resendEmailAlertVariant: null,
      resendEmailAlertMessage: '',
    };
  },
  computed: {
    imageSvg() {
      return congratulationsSvg;
    },
  },
  destroyed() {
    document.body.classList.remove('candidate__welcome');
  },
  methods: {
    resendConfirmationEmail() {
      AuthApi.resendConfirmation(this.data.email)
        .then((response) => {
          this.resendEmailAlertVariant = 'success';
          this.resendEmailAlert = true;
          this.resendEmailAlertMessage = response.data.message;
        })
        .catch((e) => {
          this.resendEmailAlertVariant = 'danger';
          this.resendEmailAlert = true;
          this.resendEmailAlertMessage = e.response.data.error;
        });
    },
  },
};
</script>
