<template>
  <div class="skill-profile-wrp">
    <ValidationObserver ref="form">
      <b-form class="create-campaign-wrp my-3">
        <div class="mb-4">
<!--          <p>{{ $t("create_campaign.info_title") }}</p>-->

          <!-- // ask an expert ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <template>
            <ValidationObserver ref="askExpert" tag="div">
              <b-form class="ask-expert-form">
                <div class="mb-3">
                  <b-btn variant="outline-primary" @click="isExpertCollapseShown = !isExpertCollapseShown">{{ $t("create_campaign.share") }}</b-btn>
                </div>

                <b-collapse v-model="isExpertCollapseShown">
                  <div class="ask-expert-block">

                    <b-row>
                      <b-col lg="1">
                        <!--suppress XmlInvalidId -->
                        <label for="firstName">{{ $t('general.title') }}</label>
                        <app-select-input v-model="expert.title"
                                          :name="$t('general.title')"
                                          :options="clientTitles" mode="lazy"
                                          vid="title"></app-select-input>
                      </b-col>

                      <b-col lg="2">
                        <ValidationProvider
                            :name="$t('create_campaign.values')"
                            v-slot="{ errors }" vid="firstName"
                            :rules="isExpertCollapseShown ? 'required' : ''"
                            tag="div">
                          <div class="form-group form-group__custom" :class="errors[0] ? 'form-group-error' : ''">
                            <!--suppress XmlInvalidId -->
                            <label for="firstName">{{ $t('general.first_name') }}</label>
                            <b-input name="firstName" id="firstName" v-model="expert.firstName" type="text"></b-input>
                            <div class="error-message" v-if="errors[0]">* {{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </b-col>

                      <b-col lg="2">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('create_campaign.values')"
                          vid="lastName"
                          :rules="isExpertCollapseShown ? 'required' : ''"
                          tag="div">
                          <div class="form-group form-group__custom" :class="errors[0] ? 'form-group-error' : ''">
                            <!--suppress XmlInvalidId -->
                            <label for="lastName">{{ $t('general.last_name') }}</label>
                            <b-input name="lastName" id="lastName" v-model="expert.lastName" type="text"></b-input>
                            <div class="error-message" v-if="errors[0]">* {{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </b-col>

                      <b-col lg="2">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('create_campaign.values')"
                          vid="email"
                          :rules="isExpertCollapseShown ? 'required|email' : ''"
                          tag="div">
                          <div class="form-group form-group__custom" :class="errors[0] ? 'form-group-error' : ''">
                            <!--suppress XmlInvalidId -->
                            <label for="email">{{ $t('general.email') }}</label>
                            <b-input name="email" v-model="expert.email" id="email" type="email" ></b-input>
                            <div class="error-message" v-if="errors[0]">* {{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </b-col>

                      <b-col lg="1" class="text-center align-top">
                        <div class="or-divider">
                          <div class="or-divider-text">{{ $t('create_campaign.or') }}</div>
                        </div>
                      </b-col>

                      <b-col lg="2">
                        <label for="select-email">{{ $t('general.select_contact') }}</label>
                        <multiselect
                            name="select-email"
                            id="select-email"
                            v-model="expert"
                            :options="experts"
                            label="name"
                            track-by="name"
                            placeholder=""
                            @select="onExpertSelected"
                            :selectLabel="$t('general.select')"
                            :deselectLabel="$t('general.remove')">
                        </multiselect>
                      </b-col>

                      <b-col lg="2">
                        <b-btn variant="primary mt-4" @click="sendEmail">{{ $t("create_campaign.send_expert_invitation") }}</b-btn>
                      </b-col>
                    </b-row>

<!--                    <b-row>
                      <b-col lg="1" class="text-right">
                        <b-btn variant="outline-primary" @click="sendEmail">{{ $t("create_campaign.send_expert_invitation") }}</b-btn>
                      </b-col>
                    </b-row>-->

                  </div>
                </b-collapse>
              </b-form>
            </ValidationObserver>
          </template>

          <!-- // ask an expert ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        </div>

        <b-row>

          <!-- // standard attributes ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-col md="6" lg="4">
            <div class="values-column">
              <div>
                <img
                  src="../../../images/campaign_icons/problem_solving.svg"
                  class="mr-3 mb-3 ml-4"
                  alt="Attributes"
                  width="60"
                />
                <span class="form__title">{{ $t("create_campaign.standard_attributes") }}</span>
                <a
                  :title="$t('match_references.candidate_info')"
                  href="https://www.examiz.com/personality_attributes/"
                  target="_blank"
                >
                  <i class="mdi mdi-information-outline icon_info"></i>
                </a>
              </div>
              <div class="progress-item" v-for="(item, index) in sortedPermanentAttributes" :key="index">
                <span class="progress-item-badge">{{ item.name }}</span>
              </div>
              <p class="pt-3">{{ $t("create_campaign.standard_attributes_info") }}</p>
            </div>
          </b-col>

          <!-- // competencies ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-col md="6" lg="4">
            <div class="competencies-column">
              <div>
                <img
                  src="../../../images/campaign_icons/user_settings.svg"
                  class="mr-3 mb-3 ml-4"
                  alt="Competencies"
                  width="60"
                />
                <span class="form__title">{{ $t("create_campaign.competencies") }}</span>
              </div>

              <ValidationProvider
                :name="$t('create_campaign.competencies')"
                vid="competencies"
                rules="required"
                tag="div"
                v-slot="{ errors }">
                <app-multiselect
                  v-model="personality_attrs.competencies"
                  :disabled="isActivated"
                  :options="competenciesAttributes"
                  :label="`name_${currentLanguage}`"
                  :placeholder="$t('create_campaign.competencies_placeholder')"
                  :max="5"
                  trackBy="attribute">
                </app-multiselect>
                <div class="error-message">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <div class="mt-5">
              <div v-for="(item, index) in personality_attrs.competencies" :key="index">
                <div class="skill-label">{{ item[`name_${currentLanguage}`] }}</div>
                <vue-slider
                  v-model="item.value"
                  dotSize="12"
                  :height="3"
                  :lazy="true"
                  :interval="1"
                  :marks="marks"
                  :tooltip="'always'"
                  :tooltip-placement="'top'">
                </vue-slider>
              </div>
            </div>
          </b-col>

          <!-- // traits ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-col md="6" lg="4">
            <div class="traits-column">
              <div class="">
                <img
                  src="../../../images/campaign_icons/creative_idea.svg"
                  class="mr-3 mb-3 ml-4"
                  alt="Traits"
                  width="60"
                />
                <span class="form__title">{{ $t("create_campaign.traits") }}</span>
              </div>

              <ValidationProvider
                :name="$t('create_campaign.traits')"
                vid="traits"
                rules="required"
                tag="div"
                v-slot="{ errors }">
                <app-multiselect
                  v-model="personality_attrs.traits"
                  :disabled="isActivated"
                  :options="traitsAttributes"
                  :placeholder="$t('create_campaign.competencies_placeholder')"
                  :max="5"
                  :label="`name_${currentLanguage}`"
                  trackBy="attribute">
                </app-multiselect>
                <div class="error-message">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <div class="mt-5">
              <div v-for="(item, index) in personality_attrs.traits" :key="index">
                <div class="skill-label">{{ item[`name_${currentLanguage}`] }}</div>
                <vue-slider
                  v-model="item.value"
                  dotSize="12"
                  :height="3"
                  :lazy="true"
                  :interval="1"
                  :marks="marks"
                  :tooltip="'always'"
                  :tooltip-placement="'top'">
                </vue-slider>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import AppCheckbox from '../../components/Checkbox';
import AppMultiselect from '../../components/CustomMultiselect';
import AppSelectInput from '../../components/SelectInput';
import AppShareModal from './ShareModal';
import CampaignHelpersMixin from '../../common/CampaignHelpersMixin';
import ApiCampaign from '../../api/campaign';
import { mapGetters } from 'vuex';
export default {
  components: {
    AppCheckbox,
    AppMultiselect,
    AppSelectInput,
    AppShareModal,
  },
  mixins: [CampaignHelpersMixin],
  props: {
    campaignId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      marks: [0, 100],
      smart_fit: false,
      shareModal: false,
      expert: {},
      isExpertCollapseShown: false,
    };
  },
  computed: {
    ...mapGetters('campaign', ['current']),
    ...mapGetters('global', [
      'currentLanguage',
      'permanentAttributes',
      'currentUserCompanyName',
      'currentUserCompanyId',
    ]),
    info() {
      return this.current.info || {};
    },
    personality_attrs: {
      get() {
        if (!this.current.personality_attrs) {
          return {};
        }
        return this.filterDepricatedAttributes(this.current.personality_attrs) || {};
      },
      set(value) {
        this.$emit('change', {
          ...this.current,
          personality_attrs: value,
        });
      },
    },
    sortedPermanentAttributes() {
      return this.permanentAttributes || [];
    },
    isActivated() {
      return this.current.state !== 'open' && this.current.state !== undefined;
    },
    experts() {
      const expertsArr = [];

      if (this.current.info.client_contact_email) {
        expertsArr.push({
          email: this.current.info.client_contact_email,
          name:
            this.current.info.client_contact_first_name +
            ' ' +
            this.current.info.client_contact_last_name,
          firstName: this.current.info.client_contact_first_name,
          lastName: this.current.info.client_contact_last_name,
        });
      }
      if (this.current.info.campaign_admin_1_email) {
        expertsArr.push({
          email: this.current.info.campaign_admin_1_email,
          name:
            this.current.info.campaign_admin_1_first_name +
            ' ' +
            this.current.info.campaign_admin_1_last_name,
          firstName: this.current.info.campaign_admin_1_first_name,
          lastName: this.current.info.campaign_admin_1_last_name,
        });
      }
      if (this.current.info.campaign_admin_2_email) {
        expertsArr.push({
          email: this.current.info.campaign_admin_2_email,
          name:
            this.current.info.campaign_admin_2_first_name +
            ' ' +
            this.current.info.campaign_admin_2_last_name,
          firstName: this.current.info.campaign_admin_2_first_name,
          lastName: this.current.info.campaign_admin_2_last_name,
        });
      }

      return expertsArr;
    },
  },
  methods: {
    onExpertSelected(expert) {
      this.expert = expert;
    },
    validateForm() {
      const b = this.$refs.form.validate();
      b.then((res) => console.log(res)).catch((err) => console.log('err', err));
      return this.$refs.form.validate();
    },
    sendEmail() {
      ApiCampaign.inviteCampaignExpert(
      this.currentUserCompanyId,
      {
        email: this.expert.email,
        first_name: this.expert.firstName,
        last_name: this.expert.lastName,
        campaign_id: this.current.id,
        campaign_internal_id: this.campaignId,
        company_name: this.currentUserCompanyName,
        job_title: this.current.info.job_title_short,
        client_name: this.current.info.client_name
      })
        .then(() => {
          this.shareModal = false;
          this.$bvToast.toast(
            this.$t('dashboard.campaign_expert_invite_success'),
            {
              autoHideDelay: 4000,
              variant: 'success',
              title: 'OK',
            }
          );
          this.isExpertCollapseShown = false
        })
        .catch((err) => {
          this.$bvToast.toast(
            this.$t('dashboard.campaign_expert_invite_error'),
            {
              autoHideDelay: 4000,
              variant: 'warning',
              title: 'Oops',
            }
          );
        });
    },
    /*
     * HACK: Tmp solution to prevent empty labels
     * for depricated/removed/hidden attributes
     */
    filterDepricatedAttributes(attributes) {
      if (attributes.competencies)
        attributes.competencies =
          attributes.competencies.filter(obj => `name_${this.currentLanguage}` in obj);

      if (attributes.traits)
        attributes.traits =
          attributes.traits.filter(obj => `name_${this.currentLanguage}`in obj);

      return attributes;
    }
  },
};
</script>
