<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }" tag="div">
    <b-form @submit.prevent="handleSubmit(saveTemplate)">
        <app-text-input name="name" :label="$t('create_campaign.template_name') + '*'" rules="required" type="text" v-model="info.name"></app-text-input>
        <div class="text-right">
            <b-button class="mr-2" variant="secondary" @click="$emit('close')">{{ $t('general.cancel') }}</b-button>
            <b-button type="submit" variant="primary">{{ $t('general.save') }}</b-button>
        </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from "../../components/CustomMultiselect";
import AppTextInput from "../../components/TextInput";
import AppSelectInput from "../../components/SelectInput";
import ApiTemplate from "../../api/template";
import { mapGetters } from 'vuex';
export default {
  components: {
    AppMultiselect,
    AppTextInput,
    AppSelectInput
  },
  props: {
    current: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('global', ['currentUserCompanyId']),
    info: {
      get() {
        return this.current.info || {};
      },
      set(value) {
        this.$emit("change", {
          ...this.current,
          info: value
        });
      }
    }
  },
  methods: {
    saveTemplate() {
      let keywords =
        this.current.keywords && this.current.keywords.length
          ? this.current.keywords.map((item) => item.name)
          : [];
      ApiTemplate.createTemplate(this.currentUserCompanyId, {
        info: this.current.info,
        personality_attrs: this.current.personality_attrs,
        keywords: keywords
      })
        .then(() => {
          const message = this.$t("templates.template_saved");
          this.$bvToast.toast(message, {
            autoHideDelay: 5000,
            variant: "success",
            title: "Success"
          });
          this.$emit("close");
          // this.$store.dispatch("campaign/resetCampaign");
        })
        .catch((e) => {
          // this.$refs.form.setErrors(e.response.data.error);
          this.$bvToast.toast((e.response.data.message), {
            autoHideDelay: 5000,
            variant: "warning",
            title: "Error"
          });
        });
    }
  }
};
</script>
