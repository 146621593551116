export const prepareQuestions = (questions, format = q => q) => {
  let result = [];

  const firstType = {
    type: 1,
    key: "first",
    data: questions.filter(q => q.answer_slider_type === "1").map(format)
  };

  const secondType = {
    type: 2,
    key: "second",
    data: questions.filter(q => q.answer_slider_type === "2").map(format)
  };

  const thirdType = questions
    .filter(q => q.answer_slider_type === "3")
    .map(question => ({
      type: 3,
      key: question.key,
      data: format(question)
    }));

  result = thirdType;

  if (firstType.data.length) {
    result.push(firstType);
  }

  if (secondType.data.length) {
    result.push(secondType);
  }

  return result;
};
