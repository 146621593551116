export default {
  url: 'app.prophr.io',
  apiUrl: 'https://api.prophr.io',
  faq: 'https://www.prop-hr.com',
  baseUrl: 'https://www.prop-hr.com',
  favicon: 'prophrfavicon.png?v=2',
  logoSize: '28px',
  pageTitle: 'Prophr',
  matchTitle:  'Prophr Match',
  matchReportTitle: 'Prophr MATCH Report',
  pulseTitle: 'Prophr Pulse',
  pulseReportTitle: 'Prophr PULSE Report',
  termsAndConditionLink: 'https://ccml.io/PROP_General_Terms',
  userAgreementLink: 'https://ccml.io/PROP_End_User_Agreement',
  privacyPolicyLink: 'https://ccml.io/PROP_Data_protection'
}
