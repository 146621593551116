<template>
  <div>
    <template v-if="isMatch">
      <app-match></app-match>
    </template><template v-else>
      <app-talents></app-talents>
    </template>
  </div>
</template>

<script>
import AppMatch from "./Match";
import AppTalents from "./Talents";
import { mapGetters } from "vuex";
export default {
  components: {
    AppMatch,
    AppTalents,
  },
  computed: {
    ...mapGetters("global", ["isMatch"]),
  },
};
</script>