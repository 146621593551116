<template>
  <div class="xmz-date-interview-component">
    <!-- <div role="button" @click="openDatePicker">
      <span v-if="date">{{ date }}</span>
      <b-icon v-else icon="calendar-plus" font-scale="1.4"></b-icon>
    </div> -->
    <b-form-datepicker
      v-model="form.interview_date"
      boundary="window"
      @input="onDateChange"
      :date-format-options="{
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }"
      size="sm"
    >
      <template v-slot:button-content>
        <span ref="datePickerRef"></span>
        <div ref="datePickerRef" @click="openDatePicker" class="text-justify">
          <span v-if="date">{{ date }}</span>
          <b-icon v-else icon="calendar-plus" font-scale="1.4"></b-icon>
        </div>
      </template>
      <b-form-radio-group
        v-model="state"
        aria-controls="ex-disabled-readonly"
      >
        <b-form-radio value="disabled">Disabled</b-form-radio>
        <b-form-radio value="readonly">Readonly</b-form-radio>
        <b-form-radio value="normal">Normal</b-form-radio>
      </b-form-radio-group>
    </b-form-datepicker>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: null,
      form: {},
      state: null
    };
  },
  watch: {
    candidate() {
      this.setForm();
    },
  },
  mounted() {
    this.setForm();
  },
  methods: {
    setForm() {
      if (!this.candidate) return;

      this.form.campaignId = this.candidate.campaign_id;
      this.form.candidateId = this.candidate.id;
      this.form.interview_date = this.candidate.interview_date;
      if (this.form.interview_date) {
        this.date = moment(this.form.interview_date).format('DD MMM YYYY');
      }
    },
    openDatePicker() {
      this.$refs.datePickerRef.click();
    },
    onDateChange(date) {
      this.date = moment(date).format('DD MMM YYYY');
      this.updateCampaign();
    },
    updateCampaign() {
      this.$store.dispatch('campaign/updateCandidateAP', this.form);
    },
  },
};
</script>
<style lang="scss">
.xmz-date-interview-component {
  .b-form-btn-label-control {
    width: 0;
    height: 0;
    border: none !important;

    label {
      display: none;
    }
  }
}
</style>