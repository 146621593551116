<template>
  <b-row class="h-100">
    <b-col class="col-6-lg d-lg-flex h-100 d-none image__scroll container-bg"></b-col>

    <b-col class="col-6-lg h-100 bg-white">
      <ValidationObserver ref="form" tag="div" class="login__dialog">
        <b-form @submit.prevent="onSubmit">
          <h1 class="xk-headline">
            {{ $t('auth_pages.account_details') }}
          </h1>
          <p>{{ $t('auth_pages.provide_personal_information_text') }}</p>

          <hr />
          <ValidationProvider
            :rules="{ required: { allowFalse: false } }"
            vid="title"
          >
            <b-form-group slot-scope="{ errors }">
              <b-row>
                <b-col>
                  <b-form-radio
                    v-model="data.gender"
                    name="title-radios"
                    value="male"
                    :class="errors[0] ? 'form-radio-error' : ''"
                    >{{ $t('general.mr') }}</b-form-radio
                  >
                </b-col>
                <b-col>
                  <b-form-radio
                    v-model="data.gender"
                    name="title-radios"
                    value="female"
                    :class="errors[0] ? 'form-radio-error' : ''"
                    >{{ $t('general.mrs') }}</b-form-radio
                  >
                </b-col>
                <b-col>
                  <b-form-radio
                    v-model="data.gender"
                    name="title-radios"
                    value="diverse"
                    :class="errors[0] ? 'form-radio-error' : ''"
                    >{{ $t('general.ms') }}</b-form-radio
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </ValidationProvider>

          <app-text-input
            :name="$t('general.first_name')"
            :label="`${$t('general.first_name')}*`"
            rules="required"
            vid="first_name"
            type="text"
            v-model="first_name"
          ></app-text-input>
          <app-text-input
            :name="$t('general.last_name')"
            :label="`${$t('general.last_name')}*`"
            rules="required"
            vid="last_name"
            type="text"
            v-model="last_name"
          ></app-text-input>
          <app-text-input
            :name="$t('auth_pages.position')"
            :label="`${$t('auth_pages.position')}*`"
            rules="required"
            vid="position"
            type="text"
            v-model="data.position"
          ></app-text-input>
          <app-text-input
            :name="$t('general.alternative_email')"
            :label="$t('general.alternative_email')"
            rules="email"
            vid="alternative_email"
            type="email"
            v-model="data.alt_email"
          ></app-text-input>
          <app-text-input
            :name="$t('auth_pages.phone_number')"
            :label="`${$t('auth_pages.phone_number')}*`"
            rules="required"
            vid="phone_number"
            type="tel"
            v-model="data.phone_number"
          ></app-text-input>
          <app-text-input
            :name="$t('auth_pages.cell_phone_number')"
            :label="$t('auth_pages.cell_phone_number')"
            vid="cell_phone_number"
            type="tel"
            v-model="data.cell_number"
          ></app-text-input>
          <app-select-input
            :name="$t('auth_pages.country')"
            :label="`${$t('auth_pages.country')}*`"
            :options="countriesList"
            v-model="country"
            rules="required"
            vid="country"
          ></app-select-input>
          <app-text-input
            :name="$t('auth_pages.zip_code')"
            :label="`${$t('auth_pages.zip_code')}*`"
            rules="required"
            vid="zip_code"
            type="text"
            v-model="zip_code"
          ></app-text-input>

          <hr />
          <!--
      <b-col>
        <b-button block="block" variant="link" @click="toPrevStep()">{{ $t('general.cancel') }}</b-button>
      </b-col>
      -->
          <b-button type="submit" variant="primary" class="btn-lg" block>
            <b-spinner
              class="mr-2"
              v-if="isInProgress"
              variant="light"
              label="Spinning"
            ></b-spinner
            >{{ $t('general.next') }}
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>

<script>
import AuthApi from '../../../../api/auth';
import SeekerHelpersMixin from '../../../../common/SeekerHelpersMixin';
import AppTextInput from '../../../../components/TextInput';
import AppSelectInput from '../../../../components/SelectInput';
export default {
  components: { AppTextInput, AppSelectInput },
  mixins: [SeekerHelpersMixin],
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      isInProgress: false,
    };
  },
  computed: {
    countriesList() {
      return [
        { value: null, text: this.$t('auth_pages.select_country') },
        ...this.countries,
      ];
    },
    gender: {
      get() {
        return this.data.gender || 'mr';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          gender: value,
        });
      },
    },
    first_name: {
      get() {
        return this.data.first_name || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          first_name: value,
        });
      },
    },
    last_name: {
      get() {
        return this.data.last_name || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          last_name: value,
        });
      },
    },
    title: {
      get() {
        return this.data.title || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          title: value,
        });
      },
    },
    email: {
      get() {
        return this.data.email || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          email: value,
        });
      },
    },
    email_2: {
      get() {
        return this.data.email_2 || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          email_2: value,
        });
      },
    },
    telephone_number: {
      get() {
        return this.data.telephone_number || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          telephone_number: value,
        });
      },
    },
    cell_phone_number: {
      get() {
        return this.data.cell_phone_number || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          cell_phone_number: value,
        });
      },
    },
    country: {
      get() {
        return this.data.country || null;
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          country: value,
        });
      },
    },
    zip_code: {
      get() {
        return this.data.zip_code || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          zip_code: value,
        });
      },
    },
  },
  methods: {
    toPrevStep() {
      this.$emit('prev');
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInProgress = true;
        AuthApi.userRegister({
          user: {
            email: this.data.email,
            password: this.data.password,
            gender: this.data.gender,
            password_confirmation: this.data.password_confirmation,
            type: 'JobSeeker',
            personal_data: {
              first_name: this.data.first_name,
              last_name: this.data.last_name,
              position: this.data.position,
              alt_email: this.data.alt_email,
              phone_number: this.data.phone_number,
              cell_number: this.data.cell_number,
              country: this.data.country,
              zip_code: this.data.zip_code,
            },
          },
        })
          .then(() => {
            this.$emit('next');
            localStorage.removeItem('step');
            localStorage.removeItem('form');
          })
          .catch((e) => {
            let errors = e.response.data.error;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.isInProgress = false;
          });
      });
    },
  },
};
</script>
