import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from 'vee-validate'
import { required, email, regex, size, numeric } from 'vee-validate/dist/rules';
import * as moment from 'moment';
import i18n from '@/i18n';
import { EventBus, EventBusEvents } from '@/event-bus';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const setValidErrMsgLng = () => {

  const dateFormat = 'YYYY-MM-DD';

  configure({
    defaultMessage: (field, values) => {
      // override the field name.
      values._field_ = i18n.t(field);
      return i18n.t(`validation.${values._rule_}`, values);
    }
  });

  extend('min', {
    validate(value, { length }) {
      return value.length >= length;
    },
    params: ['length'],
    message: (target, { length }) => {
      return i18n.t('validation.min', { length: length })
    }
  });

  extend('size', size);
  extend('email', email);
  extend('numeric', numeric)

  extend('confirmedBy', {
    params: ['target'],
    // Target here is the value of the target field
    validate(value, { target }) {
      return value === target;
    },
    // here it is its name, because we are generating a message
    message: (target) => {
      return i18n.t('validation.confirmed_by', { target: target })
    }
  });

  extend('notConfirmedBy', {
    params: ['target'],
    // Target here is the value of the target field
    validate(value, { target }) {
      return value !== target;
    },
    // here it is its name, because we are generating a message
    message: (target) => {
      return i18n.t('validation.not_confirmed_by', target)
    }
  });

  extend('required', {
    ...required,
    message: i18n.t('validation.required')
  });

  extend('regex', {
    ...regex,
    message: i18n.t('validation.regex')
  });

  extend('customRule', {
    validate: (value, { isValid }) => {
      return isValid
    },
    params: ['isValid', 'message'],
    message: (isValid, placeholders) => {
      return placeholders.message
    }
  });

  extend('minAmountItems', {
    validate: (value, { min }) => {
      return value >= min;
    },
    params: ['min'],
    message: (min) => {
      return i18n.t('validation.min_value', { min: min })
    }
  });

  extend('is_not', {
    validate: (value, { compare }) => {
      return value !== compare;
    },
    params: ['compare'],
    message: i18n.t('validation.is_not')
  });

  extend('before', {
    params: ['limit', 'included'],
    validate: (value, { limit, included }) => {
      limit = moment(limit, dateFormat);
      value = moment(value, dateFormat);
      return included ? value.isSameOrBefore(limit) : value.isBefore(limit);
    },
    message: (fieldName, placeholders) => {
      let limit = moment(placeholders.limit).format(dateFormat);
      return i18n.t(`validation.before`, {fieldName: fieldName, limit: limit});
    }
  });

  extend('after', {
    params: ['limit', 'included'],
    validate: (value, { limit, included }) => {
      limit = moment(limit, dateFormat);
      value = moment(value, dateFormat);
      return included ? value.isSameOrAfter(limit) : value.isAfter(limit);
    },
    message: (fieldName, placeholders) => {
      let limit = moment(placeholders.limit).format(dateFormat);
      return i18n.t(`validation.after`, {fieldName: fieldName, limit: limit});
    }
  });
}

//Initial func. call on the app start
setValidErrMsgLng();

//Call affter lang is switched
EventBus.$on(EventBusEvents.localeChanged, () => {
  setValidErrMsgLng();
});
