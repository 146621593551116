<template>
  <div class="h-100">
    <b-row class="h-100">
      <b-col lg="8" class="d-md-flex h-100 image__scroll"></b-col>

      <b-col md="12" lg="4" class="h-100 bg-white">
        <div class="welcome__page__content h-100">
          <h1>{{ welcomeTitle }}</h1>

          <p class="intro">{{ welcomeExamizDescriptionText }}</p>
          <p class="intro">{{ welcomeGiftDescriptionText }}</p>
          <p class="intro">{{ welcomeLearnMoreText }}</p>

          <ValidationObserver ref="form" v-slot="{ handleSubmit, errors }">
            <b-form @submit.prevent="handleSubmit(next)">
              <hr />

              <ValidationProvider
                :rules="{ required: { allowFalse: false } }"
                vid="terms"
              >
                <b-checkbox v-model="acceptTermsAndCondition">
                  <i18n path="match_candidate.agree_terms" tag="span">
                    <template v-slot:terms_and_condition_link
                      ><a
                        :href="termsAndConditionLink"
                        target="_blank"
                        >{{ $t('match_candidate.terms_and_condition_link') }}</a
                      ></template
                    >
                    <template v-slot:user_agreement
                      ><a
                        :href="userAgreementLink"
                        target="_blank"
                        >{{ $t('match_candidate.user_agreement') }}</a
                      ></template
                    >
                  </i18n>
                </b-checkbox>
              </ValidationProvider>

              <ValidationProvider
                :rules="{ required: { allowFalse: false } }"
                vid="privacy"
              >
                <b-checkbox v-model="acceptDataProtectionAndPrivacy">
                  <i18n path="match_candidate.agree_cookies" tag="span">
                    <template v-slot:data_protection_and_privacy_link
                      ><a
                        :href="privacyPolicyLink"
                        target="_blank"
                        >{{
                          $t('match_candidate.data_protection_and_privacy_link')
                        }}</a
                      ></template
                    >
                  </i18n>
                </b-checkbox>
              </ValidationProvider>

              <b-alert
                class="alert-light mt-3"
                variant="danger"
                :show="hasErrors(errors)"
                >{{ $t('match_candidate.agree_error') }}</b-alert
              >

              <div class="mt-4 py-4 border-top">
                <b-btn type="submit" class="xxl mb-5" variant="primary">{{
                  $t('general.next')
                }}</b-btn>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import franchise from '../../conf/franchise';
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    firstName: {
      type: String,
      require: true,
    },
    lastName: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      acceptTermsAndCondition: false,
      acceptDataProtectionAndPrivacy: false,
    };
  },
  computed: {
    termsAndConditionLink() {
      return franchise.termsAndConditionLink;
    },
    userAgreement() {
      return franchise.userAgreementLink;
    },
    privacyPolicyLink() {
      return franchise.privacyPolicyLink;
    },
    welcomeTitle() {
      return this.$t("match_references.welcome_title");
    },
    welcomeExamizDescriptionText() {
      return this.$t('match_references.welcome_examiz_description', {
        firstName: this.firstName,
        lastName: this.lastName,
      });
    },
    welcomeGiftDescriptionText() {
      return this.$t("match_references.welcome_text_gift_description");
    },
    welcomeLearnMoreText() {
      return this.$t("match_references.welcome_text_learn_more");
    }
  },
  created() {
    document.body.classList.add('candidate__welcome');
  },
  destroyed() {
    document.body.classList.remove('candidate__welcome');
  },
  methods: {
    hasErrors(errors) {
      return !!Object.values(errors).reduce((r, i) => [...r, ...i], []).length;
    },

    next() {
      this.$emit('next');
    },
  },
};
</script>