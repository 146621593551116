import axios from "axios";

const fetchSurveyQuestions = params => {
  return axios.get("/api/v1/survey_questions/questions", {
    params: params
  });
};

const fetchSelfTestSurveyQuestions = params => {
  return axios.get("/api/v1/selftest_surveys/questions", {
    params: params
  });
};

const saveAnswer = params => {
  return axios.post("/api/v1/survey_questions/answer", params);
};

const saveSelfTestAnswer = params => {
  return axios.post("/api/v1/selftest_surveys/answer", params);
};

const saveBatchAnswers = params => {
  return axios.post("/api/v1/survey_questions/batch_answers", params);
};

const saveSeflTestBatchAnswers = params => {
  return axios.post("/api/v1/selftest_surveys/batch_answers", params);
};

const getReferenceCandidateInfo = params => {
  return axios.get("/api/v1/survey_questions/candidate_info", {
    params: params
  });
};

const getReferenceRelationship = params => {
  return axios.get("/api/v1/survey_questions/candidate_relationship", {
    params: params
  });
};

const saveReferenceRelationship = params => {
  return axios.post("/api/v1/survey_questions/candidate_relationship", params);
};

const finalizeSurvey = params => {
  return axios.patch("/api/v1/survey_questions/finalize", params);
};

const finalizeSelfTestSurvey = params => {
  return axios.patch("/api/v1/selftest_surveys/finalize", params);
};

const getSelftestSurveyReport = params => {
  return axios.get("/api/v1/selftest_surveys/report", {
    params: params
  });
};

const getInfo = (token) => {
  return axios.get('/api/v1/survey_questions/info', {
    params: {
      token: token
    }
  });
}

export default {
  fetchSurveyQuestions,
  saveAnswer,
  saveBatchAnswers,
  getReferenceRelationship,
  saveReferenceRelationship,
  getReferenceCandidateInfo,
  finalizeSurvey,
  fetchSelfTestSurveyQuestions,
  saveSelfTestAnswer,
  saveSeflTestBatchAnswers,
  finalizeSelfTestSurvey,
  getSelftestSurveyReport,
  getInfo
};
