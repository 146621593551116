<template>
  <ValidationObserver ref="form" tag="div">
    <b-row>
      <b-col>
        <h1 class="campaign__title">{{ campaign.job_title_short }}</h1>
      </b-col>
    </b-row>
    <div>
      <b-row v-if="campaign && campaign.id">
        <b-col lg="12">
          <div class="form__title">{{ $t('exit.complete.summary') }}</div>
        </b-col>

        <b-col lg="4" md="4">
          <div class="campaign__attribute__label">
            {{ $t('exit.complete.exit_report_title') }}
          </div>

          <div class="campaign__attribute__value">
            {{ campaign.name }}
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.leaving_person') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.leaving_person.name }}
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.complete.job_name') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.leaving_person.info ? form.leaving_person.info.job_title : '' }}
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.keywords') }}
          </div>
          <div
            class="campaign__attribute__value"
            v-for="word in campaign.info.keywords"
            :key="word.name"
          >
            {{ word }};
          </div>
        </b-col>

        <b-col lg="4" md="4">
          <div class="campaign__attribute__label">
            {{ $t('exit.create_survey.department_id') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.info.department_id }}
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.department') }}
          </div>
          <div
            class="campaign__attribute__value"
            v-for="department in campaign.info.departments"
            :key="department.name"
          >
            {{ department }};
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.unit_id') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.info.unit_id }}
          </div>

          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.unit') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.info.unit }}
          </div>
        </b-col>
        <b-col lg="4" md="4">
          <div class="campaign__attribute__label mt-3">
            {{ $t('exit.create_survey.location') }}
          </div>
          <div class="campaign__attribute__value">
            {{ form.info.location_country }}
          </div>
          <div class="campaign__attribute__value mt-2">
            {{ form.info.location_city }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <ValidationProvider
            :name="$t('create_campaign.starting_date')"
            class="datepicker-wrp"
            rules="required"
            tag="div"
          >
            <b-form-group
              slot-scope="{ errors }"
              :label="$t('create_campaign.starting_date') + '*'"
            >
              <b-form-datepicker
                v-model="form.start_date"
                :date-format-options="{
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }"
                :min="new Date()"
                size="sm"
              ></b-form-datepicker>

              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            :name="$t('create_campaign.end_date')"
            rules="required"
            class="datepicker-wrp"
            tag="div"
          >
            <b-form-group slot-scope="{ errors }">
              <legend class="col-form-label">
                {{ $t('create_campaign.end_date') }}*
              </legend>
              <b-form-datepicker
                v-model="form.end_date"
                :date-format-options="{
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }"
                size="sm"
              ></b-form-datepicker>
              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            :name="$t('create_campaign.starting_date')"
            rules="required"
            tag="div"
          >
            <b-form-group slot-scope="{ errors }">
              <legend class="col-form-label">
                {{ $t('general.timezone') }}*
              </legend>
              <multiselect
                v-model="timeZone"
                :options="timeZones"
                @select="onTimeZoneSelect"
                label="name"
                :class="{ 'multiselect-error': !!errors[0] }"
                :selectLabel="''"
                :deselectLabel="''"
                :selectedLabel="$t('general.selected')"
                :disabled="disableOnUpdate"
              ></multiselect>

              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXIT_CAMPAIGN_STATE, EXIT_CAMPAIGN_ROLE } from '@/common/constants';

export default {
  data() {
    return {
      timeZone: null,
      form: {
        info: {},
        leaving_person: {
          info: {},
        },
        team_leader: {
          info: {},
        },
        exit_campaign_participants: [],
      },
    };
  },
  computed: {
    ...mapGetters('global', ['timeZones']),
    ...mapGetters('exit', ['campaign']),
    disableOnUpdate() {
      return this.campaign.status !== EXIT_CAMPAIGN_STATE.OPEN;
    },
  },
  created() {
    this.$store.dispatch('global/fetchTimeZones').then(() => {
      this.selectTimezone();
      this.populateForm();
    });
  },
  methods: {
    populateForm() {
      this.$store.dispatch('global/fetchTimeZones').then(() => {
        this.selectTimezone();

        const { id, start_date, end_date, status, info } = this.campaign;
        this.form = {
          id,
          start_date,
          end_date,
          time_zone: this.timeZone ? this.timeZone.value : null,
          status,
          info: info || {},
          leaving_person: {
            ...(this.campaign.exit_campaign_participants || []).find(
              (i) => i.role === EXIT_CAMPAIGN_ROLE.LEAVING_PERSON
            ),
          },
          team_leader: {
            ...(this.campaign.exit_campaign_participants || []).find(
              (i) => i.role === EXIT_CAMPAIGN_ROLE.TEAM_LEAD
            ),
          },
        };
      });
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    selectTimezone() {
      const currentTimeZone =
        this.campaign.time_zone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timeZone =
        this.timeZones.find((tz) => tz.value === currentTimeZone) || null;
    },
    onTimeZoneSelect(timeZone) {
      this.form.time_zone = timeZone ? timeZone.value : null;
    },
  },
};
</script>
