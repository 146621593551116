<template>
  <div class="coverpage__content">
    <b-row>
      <b-col>

        <div class="report__chapter">
          <figure>
            <img style="margin-top: 5rem" :src="campaignCoverIllustration" alt="Campaign Report">
          </figure>

          <div class="report__section__grouptitle">
            <span>{{ $t("report.campaign_summary") }}</span>
          </div>

          <h1>{{ $t("report.campaign_results") }}</h1>

          <div class="coverpage__attributes">

            <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">{{ $t("report.position") }}</div>
              <div class="coverpage__attribute__value">{{ campaign.job_title_short }}</div>
            </div>

            <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">{{ $t("report.job_id") }}</div>
              <div class="coverpage__attribute__value">{{ campaign.internal_job_id_1 }}</div>
            </div>

            <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">{{ $t("report.campaign_id") }}</div>
              <div class="coverpage__attribute__value">{{ campaign.campaign_id }}</div>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>

    <div class="campaign__statistics">
      <b-row>
        <b-col md="12" lg="12">
          <div class="toggle">
            <h2 class="report__section__title toggle__trigger">
              <span class="toggle__trigger__text">{{ $t("report.campaign_statistics") }}</span>
            </h2>
          </div>
        </b-col>

        <b-col md="6" lg="6" class="col-print-6">
          <print-skills-competencies class="job__competencies__table" :attributes="attributes"></print-skills-competencies>
        </b-col>


      </b-row>

      <b-row>

          <b-col md="12" lg="12" class="col-print-6">
          <div class="report__chapter">
            <b-row>
              <b-col>
                <h3 class="report__chapter__title">
                  <span>{{ $t("create_campaign.campaign_details") }}</span>
                </h3>

                <table class="table report__ems__table has__lines">
                  <thead>
                  <tr>
                    <th>{{ $t("report.campaign_statistics") }}</th>
                    <th class="text-right"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ $t("report.candidates") }}</td>
                    <td class="text-right">{{ candidateCount }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("create_campaign.references") }}</td>
                    <td class="text-right">{{ candidateReferences }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("create_campaign.starting_date") }}</td>
                    <td class="text-right">{{ campaignDates.start_date }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("create_campaign.end_date") }}</td>
                    <td class="text-right">{{ campaignDates.end_date | format-date }}</td>
                  </tr>
                  </tbody>
                </table>

                <h3 class="report__chapter__title">
                  <span>{{ $t("report.notes") }}</span>
                </h3>
                <p class="campaign__results__info">{{ $t("report.campaign_results_info") }}</p>

              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import CampaignCoverIllustration from '../../../images/report/print/report_new.svg';
import SkillsCompetencies from '@/views/Report/Print/SkillsCompetencies';

export default {
  components: {
    PrintSkillsCompetencies: SkillsCompetencies
  },
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    },
    attributes: {
      type: Array,
      required: true,
      default: () => []
    },
    candidateCount: {
      type: Number,
      required: true,
      default: 0
    },
    candidateReferences: {
      type: Number,
      required: true,
      default: 0
    },
    campaignDates: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    campaignCoverIllustration() {
      return CampaignCoverIllustration;
    }
  }
};
</script>
