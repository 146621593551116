const type = state => state.type;
const candidate = state => state.candidate;
const candidates = state => state.candidates;
const inProgressCandidates = state => state.inProgressCandidates;

export default {
  type,
  candidate,
  candidates,
  inProgressCandidates,
};
