<template>
  <article class="campaign">
    <b-alert class="custom-alert" variant="warning" v-model="alertShow">
      <ul class="mb-0">
        <li v-for="(item, key) in alertMessage" :key="key">{{ item[0] }}</li>
      </ul>
    </b-alert>
    <b-modal centered v-model="showDraftModal" hide-footer no-close-on-backdrop>
      <app-save-draft-modal></app-save-draft-modal>
    </b-modal>

    <b-modal
      centered
      v-model="saveModal"
      :title="$t('create_campaign.modal_title')"
      hide-footer
      hide-header
    >
      <app-save-modal
        @gotodashboard="closeEditCampaign"
        @gotonewcampaign="createNewCampaign"
        @gotoreport="goToReport(campaignId)"
        @close="() => (saveModal = false)"
        :title="titleText"
        :currentCampaign="campaign"
      ></app-save-modal>
    </b-modal>

    <b-modal centered v-model="noResultModal" hide-footer hide-header>
      <no-result-modal @close="() => (noResultModal = false)"></no-result-modal>
    </b-modal>

    <ModalCloneAPandExitCampaign
      v-if="showCloneModal"
      :currentCampaign="campaign"
      @onSubmit="() => (showCloneModal = false)"
      :title="''"
    />
    <!-- // campaign info header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row v-if="campaign.id">
      <b-col>
        <header class="campaign__info__header">
          <div class="campaign__info__item">
            {{ $t('report.campaign_id') }}: {{ campaignId }}
          </div>
        </header>
      </b-col>
    </b-row>

    <div v-if="showLoader" class="campaign__loader">
      <b-spinner
        variant="primary"
        label="Spinning"
        class="campaign__loader__spinner"
      ></b-spinner>
    </div>

    <div v-else>
      <AnalyticsCampaignForm
        ref="analyticsCampaignFormRef"
        :isNameUnique="isNameUnique"
        @onNameUniquesChange="isNameUnique = true"
      />

      <hr />

      <div class="d-flex flex-wrap align-items-end flex-row-reverse">
        <b-btn
          class="ml-2 xxl"
          variant="primary"
          @click="onComplete"
          v-b-tooltip.hover
          :disabled="loading"
          >{{
            campaign.id ? $t('general.update') : $t('general.create')
          }}</b-btn
        >
        <!-- // clone button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <b-btn
          class="mx-2 xxl"
          :disabled="false"
          v-if="campaign.id"
          variant="outline-secondary"
          @click="cloneCampaign"
        >
          <span class="mdi mdi-puzzle-check"></span>
          <span class="ml-2">{{ $t('create_campaign.clone_campaign') }}</span>
        </b-btn>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXIT_CAMPAIGN_STATE } from '@/common/constants';
import ModalExitIcon from '../../../../icons/modalExit';
import AppSaveModal from '../SaveModal';
import NoResultModal from '../NoResultModal';
import ModalCloneAPandExitCampaign from '../../../../components/ModalCloneAPandExitCampaign';

import AnalyticsCampaignForm from './Form';

export default {
  components: {
    ModalExitIcon,
    AppSaveModal,
    AnalyticsCampaignForm,
    ModalCloneAPandExitCampaign,
    NoResultModal,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    alertMessage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isNameUnique: true,
      showLoader: true,
      index: 0,
      startIndex: 0,
      saveTemplateModal: false,
      alertShow: false,
      saveModal: false,
      noResultModal: false,
      showCloneModal: false,
      showDraftModal: false,
      useModal: false,
      loading: false,
      titleText: '',
    };
  },
  computed: {
    ...mapGetters('exit', ['campaign']),
    ...mapGetters('global', ['currentLanguage', 'currentUserCompanyId']),
    wizardTitle() {
      return this.$t(`exit.wizard_title_step_${this.index + 1}`);
    },
    activateBtnText() {
      return this.campaign.id &&
        this.campaign.status === EXIT_CAMPAIGN_STATE.ACTIVE
        ? this.$t('create_campaign.update_campaign')
        : this.$t('create_campaign.activate_campaign');
    },
    campaignId() {
      if (this.campaign.id) {
        return this.campaign.id;
      }
      return null;
    },
  },
  created() {
    this.$store.dispatch('exit/getAnalyticsCampaignOptions').then(() => {
      this.showLoader = false;
    });
  },
  methods: {
    onComplete() {
      const form = this.$refs.analyticsCampaignFormRef.form;

      this.$refs.analyticsCampaignFormRef.validateForm().then((valid) => {
        if (valid) {
          const action = this.campaign.id
            ? 'exit/updateCampaign'
            : 'exit/createCampaign';

          const params = {
            ...form,
            ...(this.campaign.id ? { id: this.campaign.id } : {}), // Update after create case
            ...(this.campaign.id ? {} : { status: EXIT_CAMPAIGN_STATE.ACTIVE }),
            info: {
              ...form.info,
              countries: form.info.countries.map((i) => i.value),
              performance_levels: form.info.performance_levels.map(
                (i) => i.value
              ),
              genders: form.info.genders.map((i) => i.value),
              job_levels: form.info.job_levels.map((i) => i.value),
            },
          };

          this.$store
            .dispatch(action, params)
            .then(() => {
              this.loading = false;
              if (!form.id) {
                this.$nextTick(() => {
                  this.$router.push(
                    `/exit/campaigns/analytics/${this.campaign.id}/edit`
                  );
                });
              }
              this.saveModal = true;
            })
            .catch((error) => {
              this.loading = false;
              const alertMessage = Object.values(error.response.data.error);
              if (error.response && error.response.status === 422) {
                this.isNameUnique = false;
              } else {
                this.$bvToast.toast(alertMessage[0], {
                  autoHideDelay: 2000,
                  variant: 'warning',
                  title: 'Error',
                });
              }
            });
        }
      });
    },

    modalTitleText() {
      this.titleText =
        this.campaign.id && this.campaign.status === EXIT_CAMPAIGN_STATE.OPEN
          ? this.$t('create_campaign.activated_campaign')
          : this.$t('create_campaign.campaign_updated_successfully');
    },
    closeEditCampaign() {
      this.$router.push({ name: 'ExitDashboard' });
    },
    createNewCampaign() {
      location.href = '/exit/campaign/analytics/new';
    },
    cloneCampaign() {
      this.showCloneModal = !this.showCloneModal;
    },
    goToReport(id) {
      if (this.campaign.report_present) {
        this.saveModal = false;
        window.open(`/exit/campaigns/${id}#report`);
      } else {
        this.saveModal = false;
        this.noResultModal = true;
      }
    },
  },
};
</script>
