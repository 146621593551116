/**
 * Return most better match by condition between "from" and "to"
 *
 * Example:
 *
 * const values = [
 *   { from: 0, to: 10, label: "Between 0 and 10" },
 *   { from: 10, label: "More then 10" },
 *   { to: 0, label: "Low than zero" },
 * ];
 *
 * findValueRange(values, 10) => { from: 0, to: 10, label: "Between 0 and 10" }
 * findValueRange(values, -2) => { from: 10, label: "More then 10" }
 * findValueRange(values, 11) => { from: 0, to: 10, label: "Between 0 and 10" }
 *
 * @param {array} values Array of objects { form: Number, to: Number }
 * @param {number} value
 */
const findValueRange = (ranges, value) => {
  return (
    ranges.find((item) => {
      const hasFrom = typeof item.from === "number";
      const hasTo = typeof item.to === "number";

      if (hasFrom && hasTo) {
        return item.from <= value && item.to >= value;
      }

      if (hasFrom) {
        return item.from <= value;
      }

      if (hasTo) {
        return item.to >= value;
      }

      return false;
    }) || null
  );
};

export default findValueRange;
