<template>
  <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  @todo
  1. add i18n
  2. add confirm dialog for delete
  3. add create member dialog
  4. display disabled member status in table
  -->

  <div class="settings__page">
    <b-row>
      <b-col>
        <div class="dashboard-table-top p-0 border-top-0">
          <h1 class="page__title">{{ $t('dashboard.settings') }}</h1>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <b-tabs>
          <b-tab :title="$t('settings.members_roles')">
            <b-row>
              <b-col>
                <div class="dashboard-table-wrap">
                  <div class="dashboard-table-top d-flex flex-wrap">
                    <!-- search roles ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <div class="d-flex flex-wrap">
                      <div class="search-input-wrp">
                        <b-input-group>
                          <b-form-input
                            v-model="searchTerm"
                            class="dashboard-table-top-search role__search"
                            :placeholder="$t('dashboard.search_placeholder')"
                          ></b-form-input>
                          <b-input-group-append
                            @click.prevent="clearSearch"
                            class="clear__search"
                            ><b-btn><i class="mdi mdi-close"></i></b-btn
                          ></b-input-group-append>
                          <b-input-group-append
                            ><b-btn variant="primary"
                              ><search-icon></search-icon></b-btn
                          ></b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>

                    <div class="d-flex flex-wrap">
                      <div class="search-input-wrp">
                        <b-form-select
                          class="role__filter"
                          v-model="roleFilter"
                          :options="roleOptions"
                        ></b-form-select>
                      </div>
                    </div>

                    <!-- action buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <!-- <div class="d-flex flex-wrap" v-if="hideForMember">
                      <b-button
                        v-b-tooltip.hover
                        :disabled="disableBatchActions"
                        @click.prevent="restrictSelected(true)"
                        :title="$t('settings.disable_members')"
                        class="mr-3 btn-action"
                      >
                        <span class="mdi mdi-account-lock"></span>
                      </b-button>

                      <b-button
                        v-b-tooltip.hover
                        :disabled="disableBatchActions"
                        @click.prevent="restrictSelected(false)"
                        :title="$t('settings.enable_members')"
                        class="mr-3 btn-action"
                      >
                        <span class="mdi mdi-account-key"></span>
                      </b-button>

                      <b-button
                        v-b-tooltip.hover
                        :disabled="disableBatchActions"
                        @click.prevent="deleteSelected()"
                        :title="$t('settings.delete_members')"
                        class="mr-3 btn-action"
                      >
                        <span class="mdi mdi-account-remove"></span>
                      </b-button>
                    </div> -->

                    <!-- new campaign button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <div class="d-flex flex-wrap ml-auto">
                      <b-button
                        v-b-modal.modal-prevent-closing
                        v-b-tooltip.hover
                        :title="$t('settings.add_member')"
                        v-if="currentRecruiter.role != recruitersRoles.MEMBER"
                        class="btn-action primary"
                        variant="primary"
                      >
                        <i class="mdi mdi-account-plus"></i>
                      </b-button>
                    </div>
                  </div>
                </div>

                <b-table
                  id="recruiters-table"
                  responsive="responsive"
                  hover
                  :per-page="recruitersPerPage"
                  :current-page="currentPage"
                  :items="filteredRecruiters"
                  :fields="fields"
                >
                  <template #cell(checkbox)="data">
                    <b-form-checkbox
                      v-if="data.item.id !== currentUserId && hideForMember"
                      v-model="checkboxStates[data.item.id]"
                    ></b-form-checkbox>
                  </template>

                  <template #cell(fullname)="data">
                    <div>
                      {{ data.item.first_name + ' ' + data.item.last_name }}
                    </div>
                  </template>

                  <template #cell(role)="data">
                    <div
                      class="role__label"
                      :class="{
                        owner: data.item.role === recruitersRoles.OWNER,
                        administrator:
                          data.item.role === recruitersRoles.ADMINISTRATOR,
                      }"
                    >
                      {{ data.item.role }}
                    </div>
                    <div
                      v-if="data.item.restricted"
                      class="disabled__label ml-3"
                    >
                      {{ $t('settings.restricted') }}
                    </div>
                  </template>

                  <template #cell(actions)="data">
                    <b-dropdown
                      right
                      :text="$t('report.action')"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content>
                        <div
                          v-if="
                            !isOwner(data.item.role) &&
                            (currentRecruiter.role != recruitersRoles.MEMBER ||
                              currentRecruiter.email === data.item.email)
                          "
                          class="btn btn-action"
                        >
                          <span class="mdi mdi-dots-horizontal"></span>
                        </div>
                        <div else></div>
                      </template>

                      <li
                        v-if="
                          !isInvitation(data.item.role) &&
                          currentRecruiter.role != recruitersRoles.MEMBER &&
                          (!isOwner(data.item.role) ||
                            (isOwner(data.item.role) &&
                              isOwner(currentRecruiter.role)))
                        "
                        role="presentation"
                        class="settings__page__changerole"
                      >
                        <b-link @click.stop="showUserRoles">
                          <span
                            v-if="showroles && !isInvitation(data.item.role)"
                            class="mdi mdi-chevron-left"
                          ></span>
                          {{ $t('settings.change_role') }}
                        </b-link>
                      </li>

                      <span v-if="isInvitation(data.item.role)">
                        <b-dropdown-item @click="resendInvitation(data.item)">{{
                          $t('settings.resend_invitation')
                        }}</b-dropdown-item>
                        <b-dropdown-item @click="deleteInvitation(data.item)">{{
                          $t('settings.delete_invitation')
                        }}</b-dropdown-item>
                      </span>

                      <span>
                        <b-dropdown-item
                          v-if="
                            !isOwner(data.item.role) &&
                            !isInvitation(data.item.role) &&
                            currentRecruiter.email === data.item.email
                          "
                          @click="leaveTeam(data.item)"
                          >{{ $t('settings.leave_team') }}</b-dropdown-item
                        >
                      </span>

                      <span
                        v-if="
                          !showroles &&
                          !isInvitation(data.item.role) &&
                          currentRecruiter.role != recruitersRoles.MEMBER
                        "
                      >
                        <b-dropdown-item
                          v-if="!isOwner(data.item.role)"
                          @click="showConfirmDelete(data.item)"
                          >{{ $t('settings.delete_member') }}</b-dropdown-item
                        >
                        <!-- TODO: Remove or refactor it -->
                        <!-- <b-dropdown-item
                          @click="restrictRecruiter(data.item, true)"
                          v-if="
                            !data.item.restricted && !isOwner(data.item.role)
                          "
                          >{{ $t('settings.restrict') }}</b-dropdown-item
                        > -->
                        <b-dropdown-item
                          @click="
                            restrictRecruiter(data.item, false) &&
                              !isOwner(data.item.role)
                          "
                          v-if="data.item.restricted"
                          >{{ $t('settings.unrestrict') }}</b-dropdown-item
                        >
                      </span>

                      <span v-if="showroles && !isInvitation(data.item.role)">
                        <span
                          v-for="(item, index) in newRecruiterRoleOptions.slice(
                            0,
                            2
                          )"
                          :key="index"
                        >
                          <b-dropdown-item
                            :hidden="
                              isOwner(data.item.role) &&
                              !isOwner(currentRecruiter.role)
                            "
                            @click="changeUserRole(data.item.id, item.value)"
                          >
                            <span
                              :class="{
                                'text-secondary': item.value !== data.item.role,
                              }"
                              >{{ $t(`settings.${item.value}`) }}</span
                            >
                          </b-dropdown-item>
                        </span>
                        <b-dropdown-item
                          v-if="isOwner(currentRecruiter.role)"
                          @click="transferOwnership(data.item.id)"
                        >
                          <span
                            :class="{
                              'text-secondary':
                                newRecruiterRoleOptions[2].value !==
                                data.item.role,
                            }"
                            >{{
                              $t(`settings.${newRecruiterRoleOptions[2].value}`)
                            }}</span
                          >
                        </b-dropdown-item>
                      </span>
                    </b-dropdown>
                  </template>
                </b-table>

                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredRecruiters.length"
                  :per-page="recruitersPerPage"
                  aria-controls="recruiters-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            ref="reportTabRef"
            id="container"
            :title="$t('settings.company_profile')"
          >
            <b-row>
              <b-col>
                <b-card
                  :title="companyInfo.name"
                  :img-src="companyLogo"
                  img-alt="Company logo image"
                  img-left
                  tag="article"
                  class="settings__page__card"
                >
                  <b-card-text v-if="companyInfo.address">
                    <p>
                      <span class="mdi mdi-map-marker"></span>
                      {{ companyInfo.address }}
                    </p>
                  </b-card-text>

                  <b-card-text v-if="companyInfo.email">
                    <p>
                      <span class="mdi mdi-email mr-2"></span>
                      <b-link :href="`mailto: ${companyInfo.email}`">{{
                        companyInfo.email
                      }}</b-link>
                    </p>
                  </b-card-text>

                  <b-card-text v-if="companyInfo.phone_number">
                    <p>
                      <span class="mdi mdi-phone mr-2"></span>
                      <b-link :href="`tel: ${companyInfo.phone_number}`">{{
                        companyInfo.phone_number
                      }}</b-link>
                    </p>
                  </b-card-text>

                  <b-card-text v-if="companyInfo.fax_number">
                    <p>
                      <span class="mdi mdi-fax"></span>
                      {{ companyInfo.fax_number }}
                    </p>
                  </b-card-text>

                  <b-card-text v-if="companyInfo.employees">
                    <p>
                      <span class="mdi mdi-account-group"></span>
                      {{ companyInfo.employees }}
                    </p>
                  </b-card-text>

                  <b-link
                    v-if="companyInfo.website"
                    :href="companyInfo.website"
                    class="card-link"
                    target="_blank"
                    >{{ $t('settings.company_website') }}</b-link
                  >
                </b-card>

                <!--<div class="emptystate">
                  <i class="mdi mdi-information-outline"></i>
                  <p>No data available yet.</p>
                </div>-->
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>

      <!--  // aside ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col lg="3" class="aside">
        <div class="aside__content vh-100">
          <!--  // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <!--
          <h6 class="aside__title">
            <span>{{ $t("create_campaign.campaign_details") }}</span>
          </h6>
          -->
        </div>
      </b-col>
    </b-row>

    <!--
        @todo: add i18n
    -->

    <b-modal
      centered
      id="modal-prevent-closing"
      ref="modal"
      :title="$t('settings.add_new_member')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <ValidationObserver ref="form">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col lg="6">
              <ValidationProvider
                rules="required"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="gender">{{ $t('general.title') }}</label>
                  <app-select-input
                    v-model="formData.gender"
                    :name="$t('general.title')"
                    :options="titlesSelect"
                    mode="lazy"
                    vid="gender"
                  ></app-select-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="6">
              <ValidationProvider
                rules="required"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="lang">{{ $t('general.language') }}</label>
                  <app-select-input
                    v-model="formData.lang"
                    :name="$t('general.language')"
                    :options="languages"
                    mode="lazy"
                    vid="lang"
                  ></app-select-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="12">
              <ValidationProvider
                vid="firstName"
                rules="required"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="firstName">{{ $t('general.first_name') }}</label>
                  <b-input
                    v-model="formData.first_name"
                    name="firstName"
                    id="firstName"
                    type="text"
                  ></b-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="12">
              <ValidationProvider
                vid="lastName"
                rules="required"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="lastName">{{ $t('general.last_name') }}*</label>
                  <b-input
                    v-model="formData.last_name"
                    name="lastName"
                    id="lastName"
                    type="text"
                  ></b-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="12">
              <ValidationProvider
                vid="position"
                rules="required"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="position">{{ $t('auth_pages.position') }}*</label>
                  <b-input
                    v-model="formData.position"
                    name="position"
                    id="position"
                    type="text"
                  ></b-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="12">
              <ValidationProvider
                vid="email"
                rules="required|email"
                mode="lazy"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="email">{{ $t('general.email') }}*</label>
                  <b-input
                    v-model="formData.email"
                    name="email"
                    id="email"
                    type="text"
                  ></b-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col lg="12">
              <div class="form-group">
                <label for="role">{{ $t('create_campaign.role') }}</label>
                <ValidationProvider
                  rules="required"
                  mode="lazy"
                  v-slot="{ errors }"
                >
                  <app-select-input
                    v-model="formData.role"
                    :name="$t('general.role')"
                    :options="newRecruiterRoleOptions"
                    mode="lazy"
                    vid="role"
                  ></app-select-input>
                  <div class="error-message" v-if="errors[0]">
                    * {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthMixin from '@/common/mixins/AuthMixin';
import ConfigMixin from '@/common/mixins/ConfigMixin';
import { languages } from '@/common/languages';
import SearchIcon from '../../icons/searchIcon';
import AppSelectInput from '../../components/SelectInput';
import defaultCompanyImg from '../../../images/dashboard/company-default.svg';
import { RECRUITERS_ROLES } from '../../common/constants';

const blankFormData = {
  gender: 'male',
  lang: 'en',
  position: '',
  first_name: '',
  last_name: '',
  email: '',
  role: RECRUITERS_ROLES.MEMBER,
};

export default {
  components: {
    SearchIcon,
    AppSelectInput,
  },
  mixins: [AuthMixin, ConfigMixin],
  data() {
    return {
      searchTerm: '',
      roleFilter: '',
      recruitersPerPage: 10,
      currentPage: 1,
      checkboxStates: {},
      confirmDelete: '',
      formData: Object.assign({}, blankFormData),
      roleOptions: [
        { value: '', text: 'Any role' },
        { value: RECRUITERS_ROLES.OWNER, text: 'Owner' },
        { value: RECRUITERS_ROLES.ADMINISTRATOR, text: 'Administrator' },
        { value: RECRUITERS_ROLES.MEMBER, text: 'Member' },
        { value: RECRUITERS_ROLES.PENDING, text: 'Pending' },
      ],
      newRecruiterRoleOptions: [
        { value: RECRUITERS_ROLES.MEMBER, text: 'Member' },
        { value: RECRUITERS_ROLES.ADMINISTRATOR, text: 'Administrator' },
        { value: RECRUITERS_ROLES.OWNER, text: 'Owner' },
      ],
      showroles: false,
    };
  },
  computed: {
    ...mapGetters('global', [
      'currentLanguage',
      'campaignAttributes',
      'currentUserCompanyId',
      'currentUserId',
      'companyInfo',
    ]),
    ...mapGetters({
      companyRecruiters: 'company/recruiters',
      companyInvitations: 'company/invitations',
    }),
    recruitersRoles() {
      return RECRUITERS_ROLES;
    },
    recruiters() {
      const invitations = this.companyInvitations.map((item) => ({
        id: item.id,
        first_name: item.user.first_name,
        last_name: item.user.last_name,
        email: item.user.email,
        role: 'pending',
      }));
      return this.companyRecruiters.concat(invitations);
    },
    languages() {
      return (
        languages.map((lang) => ({
          value: lang.value,
          text: lang.label,
        })) || []
      );
    },
    filteredRecruiters() {
      let filteredRecruiters = this.recruiters;

      if (this.searchTerm != '') {
        filteredRecruiters = _.filter(filteredRecruiters, (r) => {
          if (
            `${r.first_name} ${r.last_name}`
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          ) {
            return true;
          } else if (
            r.email.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      }

      if (this.roleFilter != '') {
        filteredRecruiters = _.filter(filteredRecruiters, (r) => {
          return r.role == this.roleFilter;
        });
      }

      return filteredRecruiters;
    },
    currentRecruiter() {
      return _.find(this.recruiters, (r) => r.id == this.currentUserId);
    },
    fields() {
      const fields = [
        {
          // key: 'checkbox',
          label: '',
          tdClass: 'checkbox',
          thClass: 'checkbox',
        },
        {
          key: 'fullname',
          label: this.$t('auth_pages.full_name'),
        },
        {
          key: 'email',
          label: this.$t('general.email'),
        },
        {
          key: 'role',
          label: this.$t('create_campaign.role'),
        },
        {
          key: 'actions',
          label: this.$t('report.action'),
          tdClass: 'text-right',
          thClass: 'text-right',
        },
      ];
      return fields;
    },
    userKeys() {
      return Object.keys(this.checkboxStates);
    },
    disableBatchActions() {
      let disable = true;
      this.userKeys.forEach((key) => {
        if (this.checkboxStates[key] === true) {
          disable = false;
          return;
        }
      });
      return disable;
    },
    hideForMember() {
      return this.currentRecruiter.role !== RECRUITERS_ROLES.MEMBER;
    },
    companyLogo() {
      return this.companyInfo.logo.url
        ? this.companyInfo.logo.url
        : defaultCompanyImg;
    },
  },
  methods: {
    isOwner(role) {
      return role === RECRUITERS_ROLES.OWNER;
    },
    isAdmin(role) {
      return role === RECRUITERS_ROLES.ADMINISTRATOR;
    },
    isInvitation(role) {
      return role === RECRUITERS_ROLES.PENDING;
    },
    clearSearch() {
      this.searchTerm = '';
    },
    showUserRoles() {
      this.showroles = !this.showroles;
    },
    recruiterRole(recruiter) {
      return _.find(recruiter.companies, {
        company_id: this.currentUserCompanyId,
      }).role;
    },
    resendInvitation(invitation) {
      this.$store
        .dispatch('company/resendInvitation', {
          companyId: this.currentUserCompanyId,
          invitationId: invitation.id,
        })
        .then(() => {
          this.$bvToast.toast('Invitation succefuly resent', {
            autoHideDelay: 1000,
            variant: 'success',
            title: 'Success',
          });
        });
    },
    deleteInvitation(invitation) {
      this.$bvModal
        .msgBoxConfirm('Please confirm to delete the selected invitation', {
          title: 'Confirm Delete',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('company/deleteInvitation', {
              companyId: this.currentUserCompanyId,
              invitationId: invitation.id,
            });
            // TODO: Add toast messages
          }
        });
    },
    leaveTeam(recruiter) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to leave the team', {
          title: 'Confirm Leave Team',
          centered: true,
        })
        .then((value) => {
          if (value) {
            return this.$store
              .dispatch('company/deleteCompanyRecruiter', {
                companyId: this.currentUserCompanyId,
                recruiterId: recruiter.id,
              })
              .then(() => {
                localStorage.removeItem('company-id');
                this.$nextTick(() => {
                  location.reload();
                });
              });
          }
        });
    },
    deleteSelected() {
      var recruiterIds = _.filter(
        this.filteredRecruiters,
        (r) => this.checkboxStates[r.id]
      ).map((r) => r.id);

      if (recruiterIds.length == 0) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm('Please confirm to delete the selected members', {
          title: 'Confirm Delete',
          centered: true,
        })
        .then((value) => {
          if (value) {
            return this.$store
              .dispatch('company/batchDeleteCompanyRecruiters', {
                companyId: this.currentUserCompanyId,
                recruiterIds,
              })
              .then(() => {
                this.checkboxStates = {};
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    restrictSelected(restricted) {
      var recruiterIds = _.filter(
        this.filteredRecruiters,
        (r) => this.checkboxStates[r.id]
      ).map((r) => r.id);

      this.$store
        .dispatch('company/batchUpdateCompanyRecruiters', {
          companyId: this.currentUserCompanyId,
          recruiterIds,
          data: { restricted },
        })
        .then(() => {
          this.checkboxStates = {};
        });
    },
    resetModal() {
      this.formData = Object.assign({}, blankFormData);
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$refs.form.validate().then((valid) => {
        if (!valid) return;

        this.$store
          .dispatch('company/createCompanyRecruiter', {
            companyId: this.currentUserCompanyId,
            data: this.formData,
          })
          .then(() => {
            this.$bvModal.hide('modal-prevent-closing');
          });
      });
    },
    showConfirmDelete(recruiter) {
      this.$bvModal
        .msgBoxConfirm('Please confirm to delete this member', {
          title: 'Confirm Delete',
          centered: true,
        })
        .then((value) => {
          if (value) {
            return this.$store.dispatch('company/deleteCompanyRecruiter', {
              companyId: this.currentUserCompanyId,
              recruiterId: recruiter.id,
            });
          }
        });
    },
    // TODO: Restrict user for company
    // restrictRecruiter(recruiter, restricted) {
    //   recruiter.restricted = restricted;
    //   return this.$store.dispatch('company/updateCompanyRecruiter', {
    //     companyId: this.currentUserCompanyId,
    //     data: recruiter,
    //   });
    // },
    transferOwnership(newOwnerId) {
      //Transfer the ownership to other member
      var newOwner = _.find(this.recruiters, (r) => r.id == newOwnerId);

      newOwner.role = RECRUITERS_ROLES.OWNER;

      this.$store.dispatch('company/updateCompanyRecruiter', {
        companyId: this.currentUserCompanyId,
        data: newOwner,
      });

      this.currentRecruiter.role = RECRUITERS_ROLES.ADMINISTRATOR;

      this.$store.commit('UPDATE_COMPANY_RECRUITER', this.currentRecruiter);
    },
    changeUserRole(userId, newRole) {
      let user = this.recruiters.filter((item) => {
        if (userId === item.id) {
          item.role = newRole;
          return item;
        }
      });

      this.$store
        .dispatch('company/updateCompanyRecruiter', {
          companyId: this.currentUserCompanyId,
          data: user[0],
        })
        .then((_) => {
          this.recruiters.forEach((item) => {
            if (item.id == userId) {
              item.role = newRole;
            }
          });
        });
    },
  },
};
</script>
