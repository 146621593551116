<template>

  <b-table class="dashboard-table template-table" :items="items"
           :fields="tableFields" :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc" @sort-changed="sortingChanged"
           hover="hover" no-local-sorting="no-local-sorting">

    <template v-slot:cell(name)="data">
      <div class="text-left">{{ data.item.info.name }}</div>
    </template>

    <template v-slot:cell(job_category)="data">
      <div>{{ data.item.info.job_category }}</div>
    </template>

    <template v-slot:cell(created_at)="data">
      <div>{{ $moment(data.item.created_at) | format-date }}</div>
    </template>

    <template v-slot:cell(favorite)="data">
      <a href="javascript:void(0)" class="table-icon-wrp iconlink favourite" @click="$emit('favorite', data.item.id)" :class="{ active: data.item.info.favorite }">
        <i class="mdi mdi-star"></i>
      </a>
    </template>

    <template v-slot:cell(use_template)="data">
      <a href="javascript:void(0)" class="table-icon-wrp iconlink" @click="$emit('use', data.item)">
        <i class="mdi mdi-puzzle-check"></i>
      </a>
    </template>

    <template v-slot:cell(edit)="data">
      <a href="javascript:void(0)" class="table-icon-wrp iconlink" @click="$emit('edit', data.item)">
        <i class="mdi mdi-pencil"></i>
      </a>
    </template>

    <template v-slot:cell(remove)="data">
      <a href="javascript:void(0)" class="table-icon-wrp iconlink" @click="$emit('remove', data.item.id)">
        <i class="mdi mdi-delete"></i>
      </a>
    </template>
  </b-table>

</template>

<script>
import TemplatesHelpersMixin from "../../common/TemplatesHelpersMixin";
export default {
  mixins: [TemplatesHelpersMixin],
  props: {
    items: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: "id",
      sortDesc: false
    };
  },
  methods: {
    sortingChanged(ctx) {
      this.$emit("sort", ctx);
    }
  }
};
</script>
