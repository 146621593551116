<template>

  <div class="survey__slider__list">
    <div class="survey__slider__list__item" v-for="item in questions" :key="item.key">
      <div class="w-100">
        <div class="row align-items-center">
          <div class="col-12 col-md-4" style="font-size: 16px;">{{ item.answer_2_text }}</div>
          <div class="col-12 col-md-8 pb-3">

            <vue-slider
              v-model="item.value"
              dotSize="16"
              :min="0"
              :max="100"
              :interval="1"
              :drag-on-click="true"
              :height="6"
              :tooltip-placement="'top'"
              :tooltip="'active'"
              >

              <!--<template v-slot:tooltip="{ value, focus }">
                <div :class="['custom-tooltip custom-tooltip-top', { focus }]">{{ tooltipText(item.value) }}</div>
              </template>-->

            </vue-slider>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import findValueRange from "../common/findValueRange";

export default {
  props: {
    questions: {
      type: Array,
      defautl: []
    },
    tooltips: {
      type: Array,
      defautl: []
    }
  },

  methods: {
    tooltip(value) {
      const tooltip = findValueRange(this.tooltips, value);
      return tooltip ? tooltip : null;
    },

    tooltipText(value) {
      const tooltip = this.tooltip(value);
      return tooltip ? tooltip.label : "";
    },

    showTooltip(value) {
      return this.tooltipText(value) ? "active" : "none";
    }
  }
};
</script>
