<template>
  <div class="content__wrapper">
    <b-container>
      <b-row>
        <b-col>
          <b-card class="card__terms" :title="$t('match_candidate.congratulations')" :sub-title="$t('match_candidate.your_references_text')" v-if="endDate">
            <b-card-text><div class="congratulations__illustration"></div></b-card-text>
            <b-card-text>
              <i18n path="match_candidate.congratulations_page_text_ask_feedback" tag="p"><strong>{{ endDate | format-date }}</strong></i18n>
            </b-card-text>
            <b-card-text>{{ $t('match_candidate.congratulations_page_text_recommend') }}</b-card-text>
            <b-card-text>{{ $t('match_candidate.congratulations_page_text_check_spam') }}</b-card-text>
            <b-card-text>
              <tip-alert class="mt-2">{{ $t('match_candidate.congratulations_page_text_change_information') }}</tip-alert>
            </b-card-text>
            <b-card-text align="center">
              <b-button class="min-w-260" size="xl" variant="primary" @click="goToLandingPage">{{ $t('general.thank_you') }}</b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApiReference from '../../../api/references';
import franchise from '../../../conf/franchise';

export default {
  props: ['token'],
  data() {
    return {
      endDate: null,
    }
  },
  created() {
    ApiReference.getEndDate({
        token: this.token,
      })
        .then((response) => {
          this.endDate = response.data.end_date
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            autoHideDelay: 5000,
            variant: "danger",
            title: "Error"
          });
        });
  },
  methods: {
    goToLandingPage() {
      location.href = franchise.baseUrl
    }
  }
};
</script>
