<template>
  <b-row>
    <b-col>
      <div class="report__chapter">

        <figure>
          <img :src="candidateCoverIllustration" alt="Campaign Report">
        </figure>

        <div class="report__section__grouptitle">
          <span>{{ $t("report.candidate_details") }}</span>
        </div>


        <h1>{{ fullName }}</h1>
        <div class="examiz__match__score">
          <div class="ems__label">{{ $t("report.best_match_score") }}</div>
          <div class="ems__value" v-if="isCandidateSurveyValid">{{ candidateMatchScore }}</div>
          <div class="ems__hint" v-else>{{ $t("report.campaign_results_info") }}</div>
        </div>

        <div class="coverpage__attributes">

          <div class="coverpage__attribute__group">
            <div class="coverpage__attribute__label">{{ $t("report.position") }}</div>
            <div class="coverpage__attribute__value">{{ campaign.job_title_short }}</div>
          </div>

          <div class="coverpage__attribute__group">
            <div class="coverpage__attribute__label">{{ $t("report.job_id") }}</div>
            <div class="coverpage__attribute__value">{{ campaign.internal_job_id_1 }}</div>
          </div>

          <div class="coverpage__attribute__group">
            <div class="coverpage__attribute__label">{{ $t("report.campaign_id") }}</div>
            <div class="coverpage__attribute__value">{{ campaign.campaign_id }}</div>
          </div>
        </div>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import CandidateCoverIllustration from "../../../../images/report/print/report_candidate_new.svg";

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    },
    fullName: {
      type: String,
      required: true,
      default: ''
    },
    isCandidateSurveyValid: {
      type: Boolean,
      required: true,
      default: false
    },
    candidateMatchScore: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    candidateCoverIllustration() {
      return CandidateCoverIllustration;
    }
  },
};
</script>
