const SET_COMPANY_ID = (state, id) => {
    state.companyId = id;
};

const SET_COMPANY_RECRUITERS = (state, recruiters) => {
    state.recruiters = recruiters;
};

const ADD_COMPANY_RECRUITER = (state, recruiter) => {
    state.recruiters.push(recruiter);
}

const REMOVE_COMPANY_RECRUITER = (state, id) => {
    state.recruiters = state.recruiters.filter(r => r.id != id);
};

const UPDATE_COMPANY_RECRUITER = (state, recruiter) => {
    state.recruiters = state.recruiters.map(r => r.id == recruiter.id ? recruiter : r);
}

const SET_COMPANY_INVITATIONS = (state, invitations) => {
  state.invitations = invitations;
}

const ADD_COMPANY_INVITATION = (state, invitation) => {
  state.invitations.push(invitation);
}

const DELETE_INVITATION = (state, id) => {
  state.invitations = state.invitations.filter(i => i.id !== id)
}

export default {
    SET_COMPANY_ID,
    SET_COMPANY_RECRUITERS,
    ADD_COMPANY_RECRUITER,
    REMOVE_COMPANY_RECRUITER,
    UPDATE_COMPANY_RECRUITER,
    SET_COMPANY_INVITATIONS,
    ADD_COMPANY_INVITATION,
    DELETE_INVITATION,
};