<template>
  <div class="d-flex">
    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.job_names && campaign.info.job_names.length"
    >
      <label>{{ $t('exit.create_survey.job_title') }}</label>
      <div v-for="(name, index) in campaign.info.job_names" :key="index">
        <b>{{ name }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.cities && campaign.info.cities.length"
    >
      <label>{{ $t('general.city') }}</label>
      <div v-for="(name, index) in campaign.info.cities" :key="index">
        <b>{{ name }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.countries && campaign.info.countries.length"
    >
      <label>{{ $t('dashboard.country') }}</label>
      <div v-for="(key, index) in campaign.info.countries" :key="index">
        <b>{{ getCountryName(key) }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.departments && campaign.info.departments.length"
    >
      <label>{{ $t('exit.create_survey.department') }}</label>
      <div v-for="(name, index) in campaign.info.departments" :key="index">
        <b>{{ name }}</b>
      </div>
    </div>

    <div v-if="campaign.info.units && campaign.info.units.length">
      <label>{{ $t('exit.create_survey.department') }}</label>
      <div v-for="(name, index) in campaign.info.units" :key="index">
        <b>{{ name }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.job_levels && campaign.info.job_levels.length"
    >
      <label>{{ $t('exit.create_survey.job_level') }}</label>
      <div v-for="(key, index) in campaign.info.job_levels" :key="index">
        <b>{{ getJobLevel(key) }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.keywords && campaign.info.keywords.length"
    >
      <label>{{ $t('exit.create_survey.keywords') }}</label>
      <div v-for="(name, index) in campaign.info.keywords" :key="index">
        <b>{{ name }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="
        campaign.info.performance_levels &&
        campaign.info.performance_levels.length
      "
    >
      <label>{{ $t('exit.create_survey.performance_level') }}</label>
      <div
        v-for="(key, index) in campaign.info.performance_levels"
        :key="index"
      >
        <b>{{ getPerformanceLevel(key) }}</b>
      </div>
    </div>

    <div
      class="mr-5 mb-5 ml-2"
      v-if="campaign.info.examiz_score && campaign.info.examiz_score.length"
    >
      <label>{{ $t('report.score') }}</label>
      <div>
        <b
          >{{ campaign.info.examiz_score[0] }} -
          {{ campaign.info.examiz_score[1] }}</b
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    campaign: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters('global', ['availableCountriesWithKey']),
  },
  methods: {
    getCountryName(key) {
      return this.availableCountriesWithKey[key];
    },
    getJobLevel(key) {
      return this.$t(`exit.job_level.${key}`);
    },
    getPerformanceLevel(key) {
      return this.$t(`exit.performance_level.${key}`);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
