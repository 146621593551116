<template>
  <b-table :items="items" :fields="fields" hover responsive>
    <template v-slot:cell(email)="data">
      <div>{{ data.value[0] }}</div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: this.$t('general.title'),
        },
        {
          key: 'first_name',
          label: this.$t('general.first_name'),
        },
        {
          key: 'last_name',
          label: this.$t('general.last_name'),
        },
        {
          key: 'email_1',
          label: this.$t('general.primary_email'),
        },
        {
          key: 'email_2',
          label: this.$t('general.secondary_email'),
        },
        {
          key: 'references_requested_formated',
          label: this.$t('dashboard.received_responses'),
        },
      ],
    };
  },
  methods: {},
};
</script>
