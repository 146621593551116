<template>
  <div>

    <b-row v-if="!isBulkHiring">
      <b-col>
        <h3 class="report__chapter__title first__chapter">
          <span class="mdi mdi-account-multiple"></span>
          <span>{{ $t("report.candidates") }}</span>
        </h3>
      </b-col>
    </b-row>

    <b-row class="no-print">
      <b-col>
        <p>{{ $t('report.not_more_than_x_candidates', {count: maxNumOfCandidates}) }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-table
            class="report__ems__table"
            ref="table"
            :fields="fields"
            :items="items"
            :borderless="true"
            responsive="sm"
            fixed="fixed">

          <template v-slot:cell(candidate)="{item, index}">
            <b-form-checkbox :value="index"
                             v-model="selectCandidates"
                             name="candidates"
                             class="mt-2"
                             @input="changeCandidate()"
                             :disabled="(disableCheckbox && isDisabledProgressBar(index))">

              <div class="ml-3 text-truncate" :class="item.score === 0 ? 'disabled' : ''" :title="item.candidate">{{ item.candidate }}</div>
            </b-form-checkbox>
          </template>

          <template v-slot:cell(chart)="{item, index}">
            <b-progress class="mt-2" v-if="isCandidateSurveyValid(index)" :max="max" :variant="checkForCandidateSuccess(item.score)" :class="{ 'progress__disabled': isDisabledProgressBar(index) }">
              <b-progress-bar v-if="item.score === matchScoreMin" variant="success" :value="0"></b-progress-bar>
              <b-progress-bar v-else variant="success" :value="item.score"></b-progress-bar>
            </b-progress>
            <div v-if="!isCandidateSurveyValid(index)">{{ $t('report.not_enough_data') }}</div>
          </template>

          <template v-slot:cell(score)="{item, index}">
            <div class="mt-2" v-if="isCandidateSurveyValid(index) && item.score !== matchScoreMin">{{ item.score }}</div>
            <div class="mt-2" v-if="isCandidateSurveyValid(index) && item.score === matchScoreMin">{{ 0 }}</div>
          </template>

          <template v-slot:cell(status_key)="{item}">
            <div v-for="score in bestMatchStatuses" :key="score.user_id">
              <div v-if="item.score >= score.min && item.score <= score.max" class="text-nowrap mt-2">
                {{ $t(`best_match_statuses.${score.status_key}`) }}
              </div>
            </div>
            <!-- <div v-if="isCandidateSurveyValid(index)" class="text-nowrap mt-2">{{ $t(`best_match_statuses.${item.status_key}`) }}</div> -->
          </template>

        </b-table>
      </b-col>
    </b-row>

    <report-candidate-overview :report="report"
                               :attributes="attributes"
                               :campaign="campaign"
                               :filteredAttr="filteredAttr"
                               :filteredCandidates="filteredCandidates"
                               v-if="isSelectedCandidate && !isBulkHiring"></report-candidate-overview>
  </div>
</template>

<script>
import CandidateOverview from "./CandidateOverview";
import { MAX_PRINT_CANDIDATES_ALLOWED, BEST_MATCH_SCORE_SUCCESS, MATCH_SCORE_MIN } from "@/common/constants";
import { CAMPAIGN_TYPES, BEST_MATCH_STATUSES } from '../../common/constants';

export default {
  components: {
    ReportCandidateOverview: CandidateOverview,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    filteredAttr: {
      type: Array,
      default: () => [],
    },
    filteredCandidates: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      max: 100,
      selectCandidates: [],
      maxNumOfCandidates: MAX_PRINT_CANDIDATES_ALLOWED,
      matchScoreMin: MATCH_SCORE_MIN,
      fields: [
        {
          "key": "candidate",
          "label" : this.$t("match_candidate.candidate_name"),
          "thClass": "candidate",
          "tdClass" : "candidate"
        },
        {
          "key": "chart",
          "label" : this.$t("report.best_match_score"),
          "thClass": "chart"
        },
        {
          "key": "score",
          "label" : "",
          "thClass": "score"
        },
        {
          "key": "status_key",
          "label" : this.$t("general.rating"),
          "thClass": "rating"
        }
      ]
    }
  },
  computed: {
    disableCheckbox() {
      return this.selectCandidates.length >= MAX_PRINT_CANDIDATES_ALLOWED;
    },
    isSelectedCandidate() {
      return this.selectCandidates.length > 0;
    },
    isBulkHiring() {
      return this.campaign.ap_data && this.campaign.ap_data.bulk_hiring
    },
    bestMatchStatuses(){
      return BEST_MATCH_STATUSES;
    }
  },
  mounted() {
    this.maxNumberOfCandidates();

  },
  methods: {
    changeCandidate() {
      this.$emit('change-candidates', this.selectCandidates);
    },

    checkForCandidateSuccess(value) {
      return (value > BEST_MATCH_SCORE_SUCCESS) ? 'success' : 'secondary';
    },

    maxNumberOfCandidates() {
      const candidatesAllowed = this.report.candidates.length < MAX_PRINT_CANDIDATES_ALLOWED ?
        this.report.candidates.length : MAX_PRINT_CANDIDATES_ALLOWED;
      this.selectCandidates = [...Array(candidatesAllowed).keys()];
    },

    isDisabledProgressBar(value) {
      return this.filteredCandidates.indexOf(value) === -1;
    },

    isCandidateSurveyValid(index) {
      return this.report.candidates[index].is_survey_valid ||
        this.campaign.campaign_type === CAMPAIGN_TYPES.SELFTEST;
    },
  }
};
</script>
