<template lang="pug">
  .tip-wrp(
    :class="className"
  )
    .tip-text {{ text }}
    .tip-exit(
      @click="$emit('close')"
    ) x
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>
