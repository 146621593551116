<template>
  <b-modal
    centered
    :visible="true"
    :title="$t('applicant_portal.add_documents')"
    hide-footer
  >
    <ValidationObserver ref="form" tag="div">
      <b-form @submit.prevent="onSubmit">
        <div class="d-block">
          <div class="form-section">
            <div>
              <b-form-row>
                <b-col sm="12" md="12">
                  <LabeledSelect
                    v-model="form.document_type"
                    :rules="{ required: true }"
                    :label="`${$t('seeker_account.document_type')}`"
                    :options="documentTypesWithPlaceholder"
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col sm="12" md="12">
                  <LabeledFileUpload v-model="form.file" label="File" />
                </b-col>
              </b-form-row>
              <b-form-row v-if="isExpire">
                <b-col sm="6" md="6">
                  <LabeledDatepicker
                    v-model="form.date_expiry"
                    :rules="{ required: form.not_expire ? false : true }"
                    name="date_expiry"
                    :label="`${$t('applicant_portal.expiration_date')}`"
                  />
                </b-col>
                <b-col sm="6" md="6" class="mt-5">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: form.date_expiry ? false : true }"
                  >
                    <b-form-checkbox
                      v-model="form.not_expire"
                      :rules="{ required: true }"
                      :unchecked-value="false"
                    >
                      {{ $t('applicant_portal.not_expire') }}
                    </b-form-checkbox>
                    <div class="error-message">{{ errors[0] }}</div>
                  </ValidationProvider>
                </b-col>
              </b-form-row>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-btn type="submit" variant="primary" size="md">{{
            $t('general.save')
          }}</b-btn>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';
import LabeledSelect from './LabeledSelect';
import LabeledFileUpload from './LabeledFileUpload';
import LabeledDatepicker from './LabeledDatepicker';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    LabeledSelect,
    LabeledFileUpload,
    LabeledDatepicker,
  },
  data() {
    return {
      form: {},
      documentTypesWithPlaceholder: [],
    };
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'global/currentLanguage',
    }),
    documentTypes() {
      return window.seeker_document_types.map((docType) => ({
        text: docType[`label_${this.currentLanguage.toLowerCase()}`],
        value: docType.value,
        slug: docType.slug,
      }));
    },
    isExpire() {
      return (
        this.form.document_type &&
        (this.form.document_type.value === 3 ||
          this.form.document_type.value === 5)
      );
    },
  },
  mounted() {
    this.documentTypesWithPlaceholder = [
      {
        value: null,
        text: `${this.$t('seeker_account.select_document')}`,
        disabled: true,
      },
      ...this.documentTypes,
    ];
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$emit('submit', {
          ...this.form,
          document_type: {
            ...this.form.document_type,
            title: this.form.document_type.text,
          },
        });
      });
    },
    validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>