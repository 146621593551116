import developmentEnv from './development';
import stagingEnv from './staging';
import productionEnv from './production';


const getEnviroment = () => {
  let env;
  switch(process.env.NODE_ENV) {
    case 'production':
      env = productionEnv
      break;
    case 'staging':
      env = stagingEnv
      break;
    default:
      env = developmentEnv
  }

  return env;
}

export default getEnviroment();