<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" ref="provider" v-slot="{ errors, validate }">
    <label class="btn btn-primary"><input type="file" ref="file" :accept="acceptFileTypes" @change="handleFileUpload($event, validate)" />{{ $t('seeker_account.upload') }}</label>
    <div class="error-message">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    name: {
      type: String,
      default: ""
    },
    vid: {
      type: String,
      default: ""
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    acceptFileTypes: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleFileUpload(e, validate) {
      this.$emit("file", e.target.files[0]);
      validate(e);
    }
  }
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
.btn {
  margin-top: 26px;
  padding: 9px 10px;
}
</style>
