<template>
  <div>
    <!--
    <b-row>
      <b-col>
        <h3 class="report__chapter__title">
          <span class="mdi mdi-text-account"></span>
          <span>{{ $t("report.candidates_overview") }}</span>
        </h3>
      </b-col>
    </b-row>
    <img src="../../../images/personality_traits.svg" width="400">-->
    <!--
    <b-row>
      <b-col>
        <div class="report__candidate">

          <b-table
              class="report__candidate__attr__table"
              ref="table"
              :fields="fields"
              :items="items"
              :borderless="true"
              responsive="sm"
              fixed="fixed">

            <template class="item" v-slot:cell(traits)="data">
              <div :class="{ 'show-row': showOnPrint(data.item), 'hide-row': !showOnPrint(data.item) }">
                {{ data.item.traits }}
              </div>
            </template>

            <template v-slot:cell()="{ item, field }">
              <div class="double-progress d-flex">
                <b-progress
                    class="progress-left"
                    :value="calculateProgressBarLeftValue(item[field.key].score, item.id)"
                    variant="info2"
                    :max="max"></b-progress>

                <b-progress
                    class="progress-right"
                    :value="calculateProgressBarRightValue(item[field.key].score, item.id)"
                    variant="success"
                    :max="max"></b-progress>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    -->
  </div>
</template>

<script>
import {get} from "lodash";
import {mapGetters} from "vuex";
import { BAR_CENTER_CAMPAIGN_REPORT } from "@/common/constants";
import { calculateTwoSideProgressBarValue } from "@/common/utils"

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    filteredAttr: {
      type: Array,
      default: () => [],
    },
    filteredCandidates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      max: 100,
      rightProgressBarWidth: null,
      leftProgressBarWidth: null,
      filteredCand: []
    };
  },
  computed: {
    ...mapGetters("global", ["permanentAttributes"]),
    ...mapGetters("report", ["reportAvgValues"]),

    candidates() {
      return get(this.report, "candidates", []).map((candidate) => {
        const list = {};
        if (candidate.permanent_attrs) {
          candidate.permanent_attrs.forEach((attr) => {
            list[attr.attribute] = attr;
          });
        }
        return {
          ...candidate,
          perm_attrs_list: list,
        };
      });
    },
    attributesList() {
      return this.permanentAttributes.map((item) => ({
        id: item.key,
        label: item.name,
      }));
    },
    filterSelectedCandidates() {
      let counter = 0;
      return this.candidates.filter(() => {
        return this.filteredCandidates.indexOf(counter++) > -1;
      });
    },
    fields() {
      const candidates = this.filterSelectedCandidates.map((candidate) => ({
        key: `candidate_${candidate.candidate_id}`,
        label: candidate.candidate_full_name,
        variant: !candidate.permanent_attrs ? "danger" : "",
      }));

      return [{ key: "traits", "label" : this.$t("create_campaign.traits"), "thClass": "traits" }, ...candidates];
    },
    items() {
      return Object.values(this.attributesList).map((attr) => {
        const skills = {};

        this.filterSelectedCandidates.forEach((candidate) => {
          if (candidate.is_survey_valid) {
            skills[`candidate_${candidate.candidate_id}`] = candidate.perm_attrs_list[attr.id];
          } else {
            skills[`candidate_${candidate.candidate_id}`] = {};
          }
        });

        return {
          id: attr.id,
          traits: attr.label,
          ...skills,
        };
      });
    },
  },
  watch: {
    filteredAttr() {
      setTimeout(() => this.toggleShowAttributesInPrint(), 500);
    },
    filteredCandidates() {
      setTimeout(() => this.toggleShowCandidatesInPrint(), 500);
    },
  },
  mounted() {
    this.toggleShowCandidatesInPrint();
    this.setProgressBarWidthValue();
  },
  methods: {
    setProgressBarWidthValue() {
      this.rightProgressBarWidth = this.max - BAR_CENTER_CAMPAIGN_REPORT;
      this.leftProgressBarWidth = this.max - this.rightProgressBarWidth;
    },

    calculateProgressBarLeftValue(value, key) {
      const calc = calculateTwoSideProgressBarValue(value, this.reportAvgValues[key]);
      return calc < 0 ? Math.abs(calc) : 0;
    },

    calculateProgressBarRightValue(value, key) {
      const calc = calculateTwoSideProgressBarValue(value, this.reportAvgValues[key]);
      return calc >= 0 ? calc : 0;
    },

    showOnPrint(item) {
      return !!this.filteredAttr.find((f) => f.attribute === item.id);
    },
    toggleShowAttributesInPrint() {
      const hideRows = document.getElementsByClassName("hide-row");
      for (const row of hideRows) {
        row.parentElement.parentElement.classList.add("no-print");
      }

      const showRows = document.getElementsByClassName("show-row");
      for (const row of showRows) {
        row.parentElement.parentElement.classList.remove("no-print");
      }
    },
    toggleShowCandidatesInPrint() {
      const hideColumnsHeaders = document.querySelectorAll('[abbr="xmz-abbr"]');
      const hideColumns = document.getElementsByClassName("xmz-cell");
      for (const column of [...hideColumnsHeaders, ...hideColumns]) {
        const colIndex = column.getAttribute("aria-colindex") - 2;
        const showOnPrint = this.filteredCandidates.indexOf(colIndex) > -1;

        if (showOnPrint) {
          column.classList.remove("no-print");
        } else {
          column.classList.add("no-print");
        }
      }
    },
  },
};
</script>
