<template>
  <div class="survey__illustration" :style="style"></div>
</template>

<script>
// Illustrations
import beach from "../../images/test_illustrations/beach_new.svg";
import mountain from "../../images/test_illustrations/group_new.svg";
import fairy from "../../images/test_illustrations/fairy_new.svg";
import company from "../../images/test_illustrations/company_new.svg";
import charity from "../../images/test_illustrations/charity_new.svg";
import think from "../../images/test_illustrations/idea_new.svg";
import party from "../../images/test_illustrations/cheers_new.svg";

/**
  1 - A group of survivors of a ship accident stranded on an island…
  2 - A group got lost in the mountains on a hiking trip…
  3 - We are in a fairy tale: the Animal Kingdom with the Lion as the King…
  4 - You start together a job in a new company…
  5 - You are working together in a charity project…
  6 - What would describe %title% %last name% best? %title% %last name% is a…
  7 - You are on a coffee break with your team…
 */

const image_per_scenario = {
  '1': beach,
  '2': mountain,
  '3': fairy,
  '4': company,
  '5': charity,
  '6': think,
  '7': party
}

export default {
  props: {
    image: {
      type: String,
      default: 0
    }
  },

  data() {
    return {
      index: 0
    };
  },

  computed: {
    imagesList() {
      return Object.values(image_per_scenario);
    },

    imageUrl() {
      if (this.image in Object.keys(image_per_scenario)) {
        return image_per_scenario[this.image];
      }
      return this.imagesList[this.index];
    },

    style() {
      return { backgroundImage: `url('${this.imageUrl}')` };
    }
  }
};
</script>
