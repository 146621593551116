<template>
  <footer class="footer">
    <b-container>
      <div class="footer-copyright">&copy; {{ currentYear }} {{title}}</div>
    </b-container>
  </footer>
</template>

<script>
import franchise from '../conf/franchise';
export default {
  data() {
    return {};
  },
  computed: {
    title() {
      return franchise.pageTitle;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  }
};
</script>
