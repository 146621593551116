<template>
  <ValidationObserver tag="div" ref="form">
    <b-form class="create-campaign-wrp template-edit p-5" @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <div class="error-message text-center" v-if="templateLimitErr">{{ templateLimitErr }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <div class="create-campaign-left h-100">

            <div class="form__title">{{ $t("create_campaign.template_details") }}</div>
            <app-text-input name="name"
                            :label="$t('create_campaign.template_name') + '*'"
                            type="text" rules="required"
                            v-model="info.name"></app-text-input>

            <div class="form__title">{{ $t("create_campaign.job_details") }}</div>

            <app-text-input :name="$t('create_campaign.job_title_short')"
                            :label="$t('create_campaign.job_title_short')"
                            v-model="info.job_title_short"></app-text-input>

            <b-row>
              <b-col>
                <app-select-input :name="$t('create_campaign.job_level')"
                                  :label="$t('create_campaign.job_level')"
                                  :options="jobLevels"
                                  v-model="info.job_level"></app-select-input>
              </b-col>
              <b-col>
                <app-select-input :name="$t('create_campaign.job_category')"
                                  :label="$t('create_campaign.job_category')"
                                  v-model="info.job_category"
                                  :options="jobCategories"></app-select-input>
              </b-col>
            </b-row>

            <b-form-group :label="$t('create_campaign.keywords')">
              <app-multiselect v-model="current.keywords"
                               :options="keywords_options" :taggable="true"
                               :searchable="true" @add="addTag"
                               @async-find="asyncFind"></app-multiselect>
            </b-form-group>

          </div>
        </b-col>

        <b-col class="mt-3" lg="6">
          <div class="create-campaign-right">
            <div class="form__title">{{ $t('create_campaign.campaign_management_details') }}</div>

            <app-text-input :name="$t('create_campaign.client_id')"
                            :label="$t('create_campaign.client_id')" type="text"
                            title="e.g. a contract number"
                            v-model="info.client_id"></app-text-input>

            <app-text-input :name="$t('create_campaign.client_name')"
                            :label="$t('create_campaign.client_name')"
                            type="text"
                            :title="$t('create_campaign.client_name_description')"
                            v-model="info.client_name"></app-text-input>

            <app-text-input :name="$t('create_campaign.department')"
                            :label="$t('create_campaign.department')"
                            vid="department" type="text"
                            v-model="info.department"></app-text-input>

            <app-text-input :name="$t('create_campaign.location_office')"
                            :label="$t('create_campaign.location_office')"
                            vid="location" type="text"
                            v-model="info.location"></app-text-input>

            <div class="form__title">{{$t("create_campaign.client_contact")}}</div>

            <b-row>
              <b-col>
                <app-select-input :name="$t('general.title')"
                                  :label="$t('general.title') + '*'"
                                  :options="clientTitles"
                                  v-model="info.client_contact_title"
                                  vid="client_contact_title"></app-select-input>
              </b-col>
              <b-col>
                <app-text-input :name="$t('general.first_name')"
                                :label="$t('general.first_name')"
                                :title="$t('create_campaign.tooltip_title_3')"
                                vid="client_contact_first_name" type="text"
                                v-model="info.client_contact_first_name"></app-text-input>
              </b-col>
            </b-row>
            <app-text-input :name="$t('general.last_name')"
                            :label="$t('general.last_name')"
                            vid="client_contact_last_name" type="text"
                            v-model="info.client_contact_last_name"></app-text-input>
            <app-select-input :name="$t('create_campaign.role')"
                              :label="$t('create_campaign.role')"
                              :options="clientContactRoles"
                              v-model="info.client_contact_role"
                              vid="client_contact_role"></app-select-input>
            <b-row>
              <b-col>
                <app-text-input :name="$t('general.email')"
                                :label="$t('general.email')"
                                vid="client_contact_email" type="text"
                                v-model="info.client_contact_email"></app-text-input>
              </b-col>
              <b-col>
                <app-text-input :name="$t('create_campaign.phone_number')"
                                :label="$t('create_campaign.phone_number')"
                                :title="$t('create_campaign.phone_number_description')"
                                :rules="{ regex: /^[+][0-9]{7,15}$/ }"
                                type="text" vid="client_contact_phone"
                                v-model="info.client_contact_phone"></app-text-input>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <hr>

      <b-row>
        <!-- standard attributes -->
        <b-col md="4" lg="4">
          <div class="values-column">
            <div class="form__title">{{ $t("create_campaign.standard_attributes") }}</div>
            <div class="progress-item" v-for="(item, index) in permanentAttributes" :key="index">
              <span class="progress-item-badge">{{ item.name }}</span>
            </div>
            <p class="pt-3">{{ $t("create_campaign.standard_attributes_info") }}</p>
          </div>
        </b-col>

        <!-- competencies -->
        <b-col md="4" lg="4">
          <div class="competencies-column">
            <div class="form__title">
              {{ $t("create_campaign.competencies") }}
            </div>

            <ValidationProvider
              :name="$t('create_campaign.competencies')"
              vid="competencies"
              rules="required"
              tag="div"
              v-slot="{ errors }">
              <app-multiselect
                v-model="personality_attrs.competencies"
                :options="competenciesAttributes"
                :label="`name_${currentLanguage}`"
                :placeholder="$t('create_campaign.competencies_placeholder')"
                :max="5"
                trackBy="attribute">
              </app-multiselect>
              <div class="error-message">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>

          <b-row>
            <!-- competencies sliders -->
            <b-col>
              <div class="mt-5">
                <div v-for="(item, index) in personality_attrs.competencies" :key="index">
                  <div class="skill-label">{{ item[`name_${currentLanguage}`] }}</div>
                  <vue-slider v-model="item.value" dotSize="12" :height="3" :lazy="true" :interval="1" :marks="marks">
                  </vue-slider>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <!-- traits -->
        <b-col md="4" lg="4">
          <div class="traits-column">
            <div class="form__title">{{ $t("create_campaign.traits") }}</div>

            <ValidationProvider
              :name="$t('create_campaign.traits')"
              vid="traits"
              rules="required"
              tag="div"
              v-slot="{ errors }">
              <app-multiselect
                v-model="personality_attrs.traits"
                :options="traitsAttributes"
                :placeholder="$t('create_campaign.competencies_placeholder')"
                :max="5"
                :label="`name_${currentLanguage}`"
                trackBy="attribute">
              </app-multiselect>
              <div class="error-message">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <!-- traits sliders -->
          <b-row>
            <b-col>
              <div class="mt-5">
                <div v-for="(item, index) in personality_attrs.traits" :key="index">
                  <div class="skill-label">{{ item[`name_${currentLanguage}`] }}</div>
                  <vue-slider v-model="item.value" dotSize="12" :height="3" :lazy="true" :interval="1" :marks="marks">
                  </vue-slider>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from "../../components/CustomMultiselect";
import CampaignHelpersMixin from "../../common/CampaignHelpersMixin";
import AppTextInput from "../../components/TextInput";
import AppSelectInput from "../../components/SelectInput";
import ApiCampaign from "../../api/campaign";
import { mapGetters } from "vuex";

export default {
  components: {
    AppMultiselect,
    AppTextInput,
    AppSelectInput
  },
  mixins: [CampaignHelpersMixin],
  props: {
    current: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      marks: [0, 100],
      keywords_options: [],
      templateLimitErr: null
    };
  },
  computed: {
    ...mapGetters("global", ["currentLanguage","permanentAttributes"]),
    info: {
      get() {
        return this.current.info || {};
      },
      set(value) {
        this.$emit("change", {
          ...this.current,
          info: value
        });
      }
    },
    personality_attrs: {
      get() {
        if (!this.current.personality_attrs) {
          return {}
        }
        return (this.current.personality_attrs || {});
      },
      set(value) {
        this.$emit("change", {
          ...this.current,
          personality_attrs: value
        });
      }
    },
  },
  methods: {
    asyncFind(value) {
      ApiCampaign.getKeywords(value).then((response) => {
        this.keywords_options = response.data.campaign_keywords;
      });
    },
    addTag(item) {
      this.keywords_options.push(item);
      this.$emit("add-keyword", item);
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$emit("save", this.current.id, this.current);
      });
    },
    pushError(errors) {
      this.templateLimitErr = errors;
      this.$refs.form.setErrors(errors);
    },
    validateForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
