<template>
  <candidate-details-table
    :report_title="$t('report.rock_star_score')"
    :items="rockStar_attrs"
    :fields="fields"
    :icon="'mdi mdi-medal'"
    :candidate_score="candidate.rock_star_score"
    :highScore="report.bestRockStarScore"></candidate-details-table>
</template>

<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";
import { calculateTwoSideProgressBarValue } from "@/common/utils";
import CandidateDetailsTable from "@/components/CandidateDetailsTable";

export default {
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    CandidateDetailsTable,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("create_campaign.traits"),
          thClass: "attribute__name",
        },
        {
          key: "progress",
          thClass: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("report", ["reportAvgValues", "report"]),
    rockStar_attrs() {
      if (!this.candidate.rock_star_attrs) return [];

      const values = this.candidate.rock_star_attrs.map((item) => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.score,
          this.reportAvgValues[item.attribute]
        );

        return {
          progress: progressBarWidth,
          name: item.name,
          score: item.score,
          attribute: item.attribute,
        };
      });

      return orderBy(values, ['name'], ['asc']);
    },
  },
};
</script>
