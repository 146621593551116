<template>
  <b-row>
    <b-col v-if="ready">
      <template v-if="currentStep === 1">
        <div class="content__wrapper">
          <b-container fluid>
            <welcome
              :title="candidateTitle"
              :firstName="candidate.first_name"
              :lastName="candidate.last_name"
              @next="next"
            ></welcome>
          </b-container>
        </div>
      </template>
      <template v-if="currentStep === 2">
        <div class="xk-view xk-view--no-sidebar container">
          <app-intro
            :title="candidateTitle"
            :firstName="candidate.first_name"
            :lastName="candidate.last_name"
            :reference-relationship="referenceRelationship"
            @next="saveCandidateReferenceRelations"
          ></app-intro>
        </div>
      </template>
      <template v-if="currentStep === 3">
        <div class="xk-view xk-view--no-sidebar container">
          <app-start
            :firstName="candidate.first_name"
            :lastName="candidate.last_name"
            :link="exampleLink"
          ></app-start>
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import Welcome from './Welcome';
import AppIntro from './Intro';
import AppStart from './Start';
import ApiSurvey from '../../api/survey';
import ApiCampaign from '../../api/campaign';
import { mapGetters } from 'vuex';
import { CAMPAIGN_TYPES } from '../../common/constants';

export default {
  components: {
    Welcome,
    AppIntro,
    AppStart,
  },

  props: ['token', 'step'],

  data() {
    return {
      ready: false,
      check: false,
      modalShow: false,
      currentStep: null,
      referenceRelationship: {},
      surveyQuestions: [],
      surveyInfo: null,
    };
  },

  computed: {
    ...mapGetters({
      surveyType: 'survey/type',
      surveyCandidates: 'survey/candidates',
      surveyCandidate: 'survey/candidate',
      surveryInProgressCandidates: 'survey/inProgressCandidates',
    }),
    candidate() {
      return this.surveyCandidate || {};
    },
    candidateTitle() {
      return this.candidate.title;
    },
    exampleLink() {
      return `/reference/${this.token}/example`;
    },
  },

  created() {
    this.currentStep = Number(this.step || 1);
    // Resolver will collect all data
    this.ready = true;
  },

  mounted() {
    if (this.surveyType === CAMPAIGN_TYPES.TEAM) {
      if (
        this.surveryInProgressCandidates.length < this.surveyCandidates.length
      ) {
        this.$router.push({ name: 'situation' });
      }
    }
  },

  methods: {
    next() {
      if (this.currentStep === 1 && this.surveyType !== CAMPAIGN_TYPES.JOB) {
        this.currentStep = 3; // Don't ask for reference relation
      } else {
        this.currentStep++;
      }

      this.$router.push(`/reference/${this.token}/step/${this.currentStep}`);
    },

    getCandidateReferenceRelations() {
      ApiSurvey.getReferenceRelationship({ token: this.token }).then(
        (response) => {
          this.referenceRelationship = response.data;
          this.ready = true;
        }
      );
    },

    saveCandidateReferenceRelations() {
      ApiSurvey.saveReferenceRelationship({
        token: this.token,
        relationship_hash: this.referenceRelationship,
      }).then(() => {
        this.next();
      });
    },
  },
};
</script>
