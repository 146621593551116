<template>
  <div>
    <div>
      <b-form-group
        class="position-relative"
        :label="$t('seeker_account.enter_location')"
      >
        <div>
          <multiselect
            v-model="value"
            label="name"
            :options="options"
            :placeholder="$t('dashboard.search_placeholder')"
            :taggable="false"
            @select="onSelect"
            @search-change="searchLocations"
          ></multiselect>
        </div>
      </b-form-group>
    </div>

    <Map
      ref="map"
      @onClick="onMapClick"
      :location="location"
      class="gmap-map"
    />
  </div>
</template>

<script>
import tt from "@tomtom-international/web-sdk-maps";
import tts from "@tomtom-international/web-sdk-services";

import { mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import Multiselect from "vue-multiselect";
import Map from "../../../components/Map";
import _pick from "lodash/pick";
export default {
  components: {
    Map: Map,
    Multiselect,
  },
  props: {
    newLocation: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      marker: null,
      value: {},
      options: [],
      searchTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      jobSeeker: "jobSeeker/jobSeeker",
    }),
    currentLocation() {
      return this.jobSeeker.location;
    },
    location() {
      return (
        (this.newLocation && this.newLocation.id
          ? this.newLocation
          : this.currentLocation) || {}
      );
    },
    google: gmapApi,
  },
  mounted() {
    this.setCurrentAddress();
  },
  methods: {
    findPositionAddress(position) {
      this.setLocation(position);
      tts.services
        .reverseGeocode({
          key: "P7ZeyJ76f6tivhAfcR9AmCYDykmaifD2",
          position: position,
        })
        .go()
        .then((success) => this.setAddress(success.addresses));
    },
    onMapClick(event) {
      this.findPositionAddress(event.position);
    },
    onSelect(item) {
      this.findPositionAddress(item.position);
    },
    setLocation(position) {
      this.$refs.map.setMarker(position);
      this.$emit("pick-location", {
        lat: position.lat,
        lng: position.lng,
      });
    },
    searchLocations(query) {
      if (query.length < 3) return;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        tts.services
          .fuzzySearch({
            key: "P7ZeyJ76f6tivhAfcR9AmCYDykmaifD2",
            query: query,
          })
          .go()
          .then((success) => this.mapSerachFields(success.results));
      }, 500);
    },
    setAddress(addresses) {
      if (!addresses.length) return;
      const item = addresses[0];

      const address = `${item.address.localName || item.address.municipality},
        ${item.address.countrySubdivision}, ${item.address.country}`;
      this.value = {
        name: address,
      };
      this.updateForm(item);
    },
    setCurrentAddress() {
      if (!this.currentLocation) return;

      const address =
        `${
          this.currentLocation.local_name || this.currentLocation.municipality
        }, ` +
        `${this.currentLocation.country_subdivision}, ` +
        `${this.currentLocation.country}`;
      this.value = {
        name: address,
      };
    },
    mapSerachFields(data) {
      this.options = data.map((item) => {
        return Object.assign(item, {
          name: `${item.address.localName || item.address.municipality},
            ${item.address.countrySubdivision}, ${item.address.country}`,
        });
      });
    },
    updateForm(item) {
      this.$store.commit("jobSeeker/ASSIGN_FORM_LOCATION", {
        lat: item.position.lat,
        lng: item.position.lng,
        country: item.address.country,
        country_code: item.address.countryCode,
        country_code_iso3: item.address.countryCodeISO3,
        country_secondary_subdivision: item.address.countrySecondarySubdivision,
        country_subdivision: item.address.countrySubdivision,
        country_tertiary_subdivision: item.address.countryTertiarySubdivision,
        local_name: item.address.localName,
        municipality: item.address.municipality,
        postal_code: item.address.postalCode,
      });
    },
  },
};
</script>

<style lang="scss">
.map-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.gmap-map {
  width: 100%;
  height: 300px;
}
</style>
