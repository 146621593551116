<template>
  <div>
    <div class="interview__question" v-for="(item, index) in interviewQuestions" :key="index" :class="index % 2 !== 0 ? 'report-section__white' : ''">

      <b-row>
        <b-col md="12" lg="9">
          <h2 class="report__chapter__title" :class="{ 'mt-4': index === 0 }">
            <span class="mdi mdi-message-question"></span>
            <span>{{ $t("js_report.interview_question") }} {{ index + 1 }}</span>
          </h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" lg="9">
          <div class="interview__question__content">
            <h3 class="interview__question__topic">{{ item.title }}</h3>
            <p class="interview__question__example">{{ item.text[0] }}</p>
            <ul>
              <li v-for="(item, index) in item.text.slice(1, item.text.lenght)" :key="index">{{ item }}</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    interviewQuestions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      lang: 'global/currentLanguage',
      permanentAttributes: 'global/permanentAttributes',
      campaignAttributes: 'global/campaignAttributes',
    }),
    attributes() {
      let obj = {};
      for (const key of Object.keys(this.campaignAttributes)) {
        obj = Object.assign(obj, this.campaignAttributes[key])
      }
      this.permanentAttributes.forEach(pa => {
        const po = {};
        po[`name_${this.lang}`] = pa.name;

        obj[pa.key] = po
      })
      return obj;
    },
    questions() {
      return this.interviewQuestions.map((question) => {
        return {
          title: this.attributes[question.attribute_id][`name_${this.lang}`],
          text: question.question.split(";")
        };
      }).slice(0, 5);
    }
  }
};
</script>
