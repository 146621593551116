<template>
  <b-row class="h-100">
    <b-col class="col-6-lg d-lg-flex d-none h-100 container-bg"></b-col>

    <b-col class="col-6-lg h-100 bg-white">
      <ValidationObserver ref="form" tag="div" class="login__dialog">
        <h1 class="xk-headline pt-md-4">{{ $t('general.login') }}</h1>
        <img
          class="illustration"
          :src="illustration"
          alt="Security Illustration"
          width="150"
        />

        <b-form @submit.prevent="onSubmit">
          <b-alert
            v-model="confirmationAlert"
            variant="success"
            dismissible="dismissible"
            >{{ $t('auth_pages.confirm_email_alert') }}
          </b-alert>

          <b-alert
            v-model="confirmationEmailError"
            variant="danger"
            dismissible="dismissible"
            >{{ $t('auth_pages.confirm_email') }}
          </b-alert>

          <b-alert
            v-model="errorAlert"
            variant="danger"
            dismissible="dismissible"
            >{{ errorMessage }}
          </b-alert>

          <ValidationProvider
            :name="$t('general.email')"
            vid="email"
            rules="required|email"
            mode="lazy"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="form-group form-group__custom"
              :class="errors[0] ? 'form-group-error' : ''"
            >
              <label for="email">{{ $t('general.email') }}</label>
              <input
                class="form-control input__big"
                id="email"
                type="email"
                v-model="email"
                :placeholder="$t('general.email')"
              />
              <div class="error-message" v-if="errors[0]">
                * {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            :name="$t('general.password')"
            rules="required"
            mode="lazy"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="form-group form-group__custom"
              :class="errors[0] ? 'form-group-error' : ''"
            >
              <label for="password">{{ $t('general.password') }}</label>
              <input
                class="form-control input__big"
                id="password"
                type="password"
                v-model="password"
                :placeholder="$t('general.password')"
              />

              <div class="error-message" v-if="errors[0]">
                * {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>

          <hr />

          <router-link class="forgot-link" to="/reset-password">{{
            $t('auth_pages.forgot_password_question')
          }}</router-link>

          <b-button type="submit" variant="primary" block class="btn-lg">
            <b-spinner v-if="isInProgress" variant="light" label="Spinning">
            </b-spinner
            >{{ $t('header.sign_in') }}
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthMixin from '@/common/mixins/AuthMixin';
import { clearLocalStorage } from '@/common/utils';
import AuthHeader from '@/components/Header';
import Illustration from '../../../../images/security_new.svg';

export default {
  components: { AuthHeader },
  mixins: [AuthMixin],
  data() {
    return {
      email: '',
      password: '',
      isInProgress: false,
      errorMessage: '',
      confirmationAlert: false,
      errorAlert: false,
      confirmationEmailError: false,
    };
  },
  computed: {
    ...mapGetters('global', ['companyLogoUrl']),
    illustration() {
      return Illustration;
    },
    /*companyLogo() {
      // TODO: uncomment this when company subdomain is allowed
      // return this.companyLogoUrl ? this.companyLogoUrl : LogoExamiz;
      return LogoExamiz;
    },*/
  },
  created() {
    document.body.classList.add('candidate__welcome');
    clearLocalStorage();

    if (this.$route.query.wrongdomain === 'true') {
      const msg = this.$t('auth_pages.wrong_domain');
      this.$bvToast.toast(msg, {
        autoHideDelay: 4000,
        variant: 'warning',
        title: 'Oops',
      });
      this.$router.replace('/login');
    }

    if (this.$route.query.confirmed === 'true') {
      this.confirmationAlert = true;
      this.$router.replace('/login');
    }

    if (this.$route.query.confirmed === 'false') {
      this.errorAlert = true;
      this.errorMessage =
        this.$t('general.email') + ' ' + this.$route.query.error;
    }
  },
  destroyed() {
    document.body.classList.remove('candidate__welcome');
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInProgress = true;
        this.$store
          .dispatch('global/authUser', {
            user: {
              email: this.email,
              password: this.password,
            },
          })
          .then(() => {
            this.isInProgress = false;
            this.$router.push(this.$route.query.redirect || '/');

            this.$store.dispatch('global/fetchConfig');
          })
          .catch((e) => {
            let errors = '';

            if (
              e.response &&
              e.response.data &&
              e.response.data.code === 'UNCONFIRMED_USER_ACCOUNT'
            ) {
              this.confirmationEmailError = true;
              this.isInProgress = false;
              return;
            } else if (e.response && e.response.status === 422) {
              this.errorMessage = this.$t(
                'controllers.user_session.wrong_creditionals'
              );

              this.errorAlert = true;
            }

            this.$refs.form.setErrors(errors);
            this.isInProgress = false;
          });
      });
    },
  },
};
</script>