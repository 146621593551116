import env from '../../config/enviroment';
import { MATCH_TWO_SIDE_SLIDER_BREAK_POINT } from './constants'

const resourceAbsUrl = (url) => {
  return url.startsWith('http') ? url : `${env.API_URL}${url}`
}

const checkAbsUrl = (url) => {
  return (url.startsWith('http') || url.startsWith('https')) ? url : 'https://' + url 
}

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const clearLocalStorage = () => {
  const lang = localStorage.getItem('language');
  localStorage.clear();
  localStorage.setItem('language', lang);
}

const calculateCampaignProgress = (passed, requested) => {
  if (requested === 0) requested = 1;
  let progress = ((passed / requested) * 100);
  if (progress > 100) progress = 100;
  return progress.toFixed(0);
}

const calculateTwoSideProgressBarValue = (value, breakPoint = MATCH_TWO_SIDE_SLIDER_BREAK_POINT) => {
  const spanLeft = breakPoint;
  const spanRight = 100 - breakPoint;
  const diff = value - breakPoint;
  return diff >= 0 ? Math.round((diff * 100 / spanRight)) : Math.round((diff * 100 / spanLeft))
}

export {
  resourceAbsUrl,
  randomIntFromInterval,
  clearLocalStorage,
  calculateCampaignProgress,
  calculateTwoSideProgressBarValue,
  checkAbsUrl
}
