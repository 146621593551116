<template>
  <div class="report">
    <div class="report__buttonbar__fixed">
      <b-row>
        <b-col>
          <b-button-toolbar>
            <div class="report__buttons__wrapper justify-content-end">
              <b-button
                @click="goToReport(id)"
                v-b-tooltip.hover
                :title="$t('report.print_report')"
                class="btn-action report__redirect__btn primary"
              >
                <span class="mdi mdi-printer"></span>
              </b-button>
            </div>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </div>
    <div
      class="report__content"
      v-for="mainStrategy in cascadeStrategies"
      :key="mainStrategy.attr"
    >
      <div class="toggle">
        <h2
          class="report__section__title toggle__trigger mb-1 mt-4"
          v-b-toggle="`uid-${mainStrategy.attr}`"
        >
          <span class="toggle__trigger__text">
            {{ mainStrategy.label }}
          </span>
        </h2>

        <b-collapse
          :id="`uid-${mainStrategy.attr}`"
          class="toggle__content"
          :visible="mainStrategy.active"
          v-model="mainStrategy.active"
        >
          <b-form-checkbox
            name="checkbox"
            v-model="queryStrategy"
            :key="mainStrategy.attr"
            :value="mainStrategy.attr"
          >
            <h6>
              {{ $t('exit.report.problem_level') }}:
              <span class="ml-2">{{ prepareLevel(mainStrategy.level) }}</span>
            </h6>
          </b-form-checkbox>
          <p class="mb-5 pre_line">{{ mainStrategy.value }}</p>

          <div v-for="subStrategy in mainStrategy.sub" :key="subStrategy.attr">
            <div class="toggle">
              <h2
                class="report__section__title toggle__trigger mb-1 mt-4"
                v-b-toggle="`uid-${subStrategy.attr}`"
              >
                <span class="toggle__trigger__text">
                  {{ subStrategy.label }}
                </span>
              </h2>

              <b-collapse
                :id="`uid-${subStrategy.attr}`"
                class="toggle__content py-2"
                :visible="subStrategy.active"
                v-model="subStrategy.active"
              >
                <b-form-checkbox
                  name="checkbox"
                  v-model="queryStrategy"
                  :key="subStrategy.attr"
                  :value="subStrategy.attr"
                >
                  <h6>
                    {{ $t('exit.report.problem_level') }}:
                    <span class="ml-2">{{
                      prepareLevel(subStrategy.level)
                    }}</span>
                  </h6>
                </b-form-checkbox>
                <p class="pre_line">{{ subStrategy.value }}</p>
              </b-collapse>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Checkbox from '../../../components/Checkbox.vue';
import ExitReportHelpersMixin from '@/common/ExitReportHelpersMixin';
import { EXIT_MAX_RISK } from '../../../common/constants';
export default {
  components: { Checkbox },
  mixins: [ExitReportHelpersMixin],
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('exit', ['strategies', 'report', 'queryStrategies']),

    isRiskFit() {
      return (
        Math.max(
          ...Object.keys(this.strategies)
            .filter(
              (item) =>
                this.strategies[item] &&
                !item.includes('sub') &&
                !item.includes('main')
            )
            .map((item) => this.problemLevel(item))
        ) >= EXIT_MAX_RISK
      );
    },

    cascadeStrategies() {
      return Object.keys(this.strategies)
        .filter((item) =>
          this.isRiskFit
            ? this.strategies[item] && !item.includes('sub')
            : this.strategies[item] && item.includes('main')
        )
        .map((mainKey) => {
          const key = mainKey.includes('main')
            ? mainKey.split('_').reverse()[0]
            : mainKey;
          const subKeys = Object.keys(this.strategies).filter(
            (subKey) => subKey.includes('sub') && subKey.includes(key)
          );

          return {
            label:
              this.getKeyName(mainKey) ||
              this.complianceRisksTranslations[mainKey],
            attr: mainKey,
            active: false,
            checked: true,
            value: this.strategies[mainKey].replace(/\\n/g, '\n'),
            level: this.problemLevel(mainKey),
            sub: subKeys.map((subKey) => ({
              label: this.getKeyName(subKey),
              attr: subKey,
              active: false,
              checked: true,
              value: this.strategies[subKey].replace(/\\n/g, '\n'),
              level: this.problemLevel(mainKey, subKey),
            })),
          };
        })
        .sort((a, b) => b.level - a.level);
    },
    queryStrategy: {
      get() {
        return this.queryStrategies;
      },
      set(value) {
        this.$store.commit('exit/SET_QUERY_STRATEGIES', value);
      },
    },
  },
  mounted() {
    if (!this.isRiskFit) {
      const query = this.queryStrategies.filter(
        (item) => item.includes('main') || item.includes('sub')
      );
      this.$store.commit('exit/SET_QUERY_STRATEGIES', query);
    }
  },
  methods: {
    problemLevel(mainAttr, subAttr) {
      if (subAttr) {
        return this.report.detailed_analytics[mainAttr][subAttr];
      }
      if (mainAttr.includes('main')) {
        return this.report.reasons_for_leaving[mainAttr];
      }
      return this.report.compliance_risks[mainAttr].value;
    },
    prepareLevel(level) {
      return `${level}/100`;
    },
    goToReport(id) {
      const route = this.$router.resolve({
        name: 'ExitReportPrint',
        params: { id },
        query: {
          strategies: this.queryStrategies.join(','),
        },
      });

      window.open(route.href, '_blank');
    },
  },
};
</script>