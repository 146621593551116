<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center border-bottom pb-3">
          <b-icon icon="check-square" variant="primary" font-scale="2"></b-icon>
          <p class="m-0 ml-3 text-center campaign__updated-info">{{ title }}</p>
          <button @click="close" type="button" aria-label="Close" class="close xmz-close">×</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="xmz-text">{{ $t("create_campaign.invitation_email") }}</p>
        <p class="xmz-text">{{ $t("create_campaign.what_next") }}</p>
        <div class="d-flex align-items-center justify-content-between">
          <b-button class="mx-2 xxl" @click="redirectToDashboard" variant="primary" v-b-tooltip.hover :title="$t('create_campaign.to_dashboard_btn_description')">{{ $t("create_campaign.to_dashboard") }}</b-button>
          <p class="text-uppercase">{{ $t("create_campaign.or") }}</p>
          <b-button class="mx-2 xxl" @click="redirectToNewCampaign" variant="primary" v-b-tooltip.hover :title="$t('create_campaign.to_new_campaign_btn_description')">{{ $t("create_campaign.to_new_campaign") }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    methods: {
      redirectToDashboard() {
        this.$emit('gotodashboard')
      },
      redirectToNewCampaign() {
        this.$emit('gotonewcampaign')
      },
      close() {
        this.$emit('close');
      }
    }
  }
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 25px;
}
</style>
