import { mapGetters } from 'vuex';
const AuthMixin = {
  computed: {
    ...mapGetters('global', [
      'currentLanguage',
      'isMatch',
      'isAuthenticated',
      'isRegistered',
      'userRole',
      'currentApp'
    ]),
  },
};

export default AuthMixin;
