<template>
  <ValidationObserver ref="form">
    <div>
      <h6 class="text-uppercase">{{ $t('create_campaign.job_details') }}</h6>
      <p>
        {{ $t('exit.create_survey.leaving_person') }}
        <strong>{{ leavingPerson.name }}</strong>
        <br />
        {{ $t('create_campaign.job_title_short') }}
        <strong>{{ campaign.name }}</strong>
      </p>
      <div class="my-3">
        <div
          class="candidates-item pt-3"
          v-for="(teamMember, index) in form.team_members"
          :key="index"
        >
          <div class="pt-3 mt-3 mr-2">
            <span
              v-b-tooltip.hover
              :title="$t('match_references.delete_person')"
              @click="removeTeamMember(index)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                <path
                  fill="#68808d"
                  d="M4.478 8.113h9.044v1.784H4.478zM9 0c1.644 0 3.166.411 4.565 1.233a8.53 8.53 0 013.202 3.202A8.851 8.851 0 0118 9a8.851 8.851 0 01-1.233 4.565 8.53 8.53 0 01-3.202 3.202A8.851 8.851 0 019 18a8.851 8.851 0 01-4.565-1.233 8.691 8.691 0 01-3.202-3.224A8.812 8.812 0 010 9c0-1.63.411-3.144 1.233-4.543a8.858 8.858 0 013.224-3.224A8.812 8.812 0 019 0zm.005 16.236a7.038 7.038 0 003.637-.996 7.011 7.011 0 002.598-2.598 7.038 7.038 0 00.996-3.637 7.037 7.037 0 00-.996-3.637 7.011 7.011 0 00-2.598-2.598 7.038 7.038 0 00-3.637-.996 7.038 7.038 0 00-3.637.996A7.011 7.011 0 002.77 5.368a7.038 7.038 0 00-.996 3.637c0 1.299.332 2.511.996 3.637a7.011 7.011 0 002.598 2.598 7.037 7.037 0 003.637.996z"
                />
              </svg>
            </span>
          </div>
          <div class="pt-3 mt-3 mr-2">{{ `${index + 1}.` }}</div>
          <div class="candidates-item-field candidates-item-field__select">
            <app-select-input
              :name="$t('general.title')"
              :vid="`title${index}`"
              :label="$t('general.title')"
              rules="required"
              mode="lazy"
              :options="titles"
              v-model="teamMember.gender"
            ></app-select-input>
          </div>
          <div class="candidates-item-field candidates-item-field__select">
            <app-select-input
              :name="$t('general.language')"
              :vid="`title${index}`"
              :label="$t('general.language')"
              rules="required"
              mode="lazy"
              :options="languages"
              v-model="teamMember.lang"
            ></app-select-input>
          </div>
          <div class="candidates-item-field candidates-item-field__select">
            <app-select-input
              :name="$t('create_campaign.role')"
              :vid="`title${index}`"
              :label="$t('create_campaign.role')"
              rules="required"
              mode="lazy"
              :options="role"
              v-model="teamMember.role"
            ></app-select-input>
          </div>
          <div class="candidates-item-field">
            <app-text-input
              name="$t('auth_pages.full_name')"
              :label="$t('auth_pages.full_name')"
              mode="lazy"
              :vid="`first_name${index}`"
              type="text"
              rules="required"
              v-model="teamMember.name"
            ></app-text-input>
          </div>
          <div class="candidates-item-field">
            <app-text-input
              :name="$t('general.email')"
              :label="$t('general.email')"
              mode="lazy"
              :vid="`email${index}`"
              :rules="{
                required: true,
                email: true,
                is_not: leavingPerson.email,
              }"
              type="email"
              v-model="teamMember.email"
              @input="findEmails(teamMember.email)"
            ></app-text-input>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-auto">
          <b-btn
            class="min-w-140"
            variant="outline-primary"
            @click="add"
            size="xl"
          >
            +
            {{ $t('exit.add_team_member.add_member') }}
          </b-btn>
        </div>
        <div class="col-auto">
          <b-btn class="min-w-140" variant="primary" @click="next" size="xl">
            {{ $t('general.next') }}
          </b-btn>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import CampaignHelpersMixin from '../../../../common/CampaignHelpersMixin';
import ReferencesHelpersMixin from '../../../../common/ReferencesHelpersMixin';
import AppSelectInput from '../../../../components/SelectInput';
import AppTextInput from '../../../../components/TextInput';
import ExitAddTeamMembersHelpersMixin from '../../../../common/ExitAddTeamMembersHelpersMixin';
import { TEAM_MEMBERS_ROLE } from '../../../../common/constants';
import { languages } from '@/common/languages';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppSelectInput,
    AppTextInput,
  },
  mixins: [CampaignHelpersMixin, ReferencesHelpersMixin, ExitAddTeamMembersHelpersMixin],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    leavingPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      duplicatedEmails: false,
      form: {
        team_members: [],
      },
    };
  },
  mounted() {
     this.form.team_members = this.teamMembers.filter(m => m.role !== 'team_lead' && m.role !== 'leaving_person') // TODO: Add to config
  },
  computed: {
    ...mapGetters('global', ['titles', 'currentLanguage']),
    referencesRequested() {
      return +this.campaign.references_requested;
    },
    languages() {
      return (
        languages.map((lang) => ({ value: lang.value, text: lang.label })) || []
      );
    },

    referencesRequestedErrMsg() {
      return `${this.referencesRequested} ${this.$t(
        'create_campaign.references'
      )}`;
    },
  },

  methods: {
    add() {
      this.form.team_members.push({
        lang: this.currentLanguage,
        role: TEAM_MEMBERS_ROLE.TEAM_MEMBER,
        gender: this.titles[0].value
      });
    },
    next() {
      this.validate().then((result) => {
        if (!result) return;
        this.$emit('next', this.form);
      });
    },
    validate() {
       if (this.duplicatedEmails) {
        this.$bvToast.toast(this.$t('create_campaign.email_error_msg'), {
          autoHideDelay: 5000,
          variant: 'warning',
          title: 'Error',
        });
        return Promise.resolve(false);
      } else {
        return this.$refs.form.validate();
      }
    },
    findEmails(email) {
      const arr = this.form.team_members.filter(
        (item) => item.email === email
      );
      this.duplicatedEmails = !!(arr && arr.length >= 2);
    },
    async removeTeamMember(index) {
      try {
        if (this.form.team_members[index].id) {
          await this.$store.dispatch('exit/removeTeamMember', {
            token: this.$route.params.token,
            id: this.form.team_members[index].id
          })
        }
        this.form.team_members.splice(index, 1);
      } catch (err) {
         this.$bvToast.toast(this.$t('auth_pages.resend_email_alert_fail'), {
          autoHideDelay: 5000,
          variant: 'error',
          title: 'Error',
        });
      }
    },
  },
};
</script>
