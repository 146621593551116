<template>
  <div class="report__chapter">
    <b-row v-if="!hideTitle">
      <b-col>
        <h3 class="report__chapter__title">
          <span :class="icon"></span>
          <span>{{ report_title }}</span>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          class="report__ems__table has__lines"
          ref="table"
          :fields="fields"
          :items="items"
          :borderless="true"
          responsive="sm"
          fixed="fixed"
          :tbody-tr-class="{ 'h-40': twoHeight }"
        >
          <template #head(name)>
            <span class="text-head">{{ $t('create_campaign.traits') }}</span>
          </template>

          <template #head(score)>
            <div class="table__head">
              <!--<span>{{ $t('report.relative_weaker') }}</span>
              <span>{{ $t('report.relative_stronger') }}</span>-->
            </div>
          </template>

          <template v-slot:cell(name)="{ item }">
            <span class="text-truncate">{{ item.name }}</span>
          </template>

          <template v-slot:cell(value)="{ item }">
            <!-- EXIT REPORT -->
            <b-progress :max="max" height="1.8rem">
              <b-progress-bar
                variant="custom2"
                :value="item.value"
                :show-value="true"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(score)="{ item }">
            <!-- CORE COMPETENCIES -->
            <b-progress :max="max" v-if="!job_table">
              <b-progress-bar
                variant="success"
                :value="item.score"
              ></b-progress-bar>
            </b-progress>

            <!-- JOB-SPECIFIC COMPETENCIES -->
            <div class="progress__wrp" v-if="job_table">
              <b-progress
                :max="max"
                :variant="item.score >= item.value ? 'success' : 'info2'"
              >
                <b-progress-bar :value="item.score"></b-progress-bar>
              </b-progress>
              <div
                class="progress__wrp__point"
                :style="{ left: item.value + '%' }"
              ></div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExitReportHelpersMixin from '../common/ExitReportHelpersMixin';

export default {
  mixins: [ExitReportHelpersMixin],
  props: {
    items: {
      type: Array,
      required: true,
      default: [],
    },
    fields: {
      type: Array,
      required: true,
      default: [],
    },
    icon: {
      type: String,
      required: true,
      default: '',
    },
    report_title: {
      type: String,
      required: true,
      default: '',
    },
    job_table: {
      type: Boolean,
      required: true,
      default: false,
    },
    max: {
      type: Number,
      required: true,
      default: 100,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    twoHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('global', ['exitQuestions']),
  },
};
</script>