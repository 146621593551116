<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <div class="form-group" :class="errors[0] ? 'form-group-error' : ''">
      <label>{{ label }}</label>
      <b-form-file
        id="file-default"
        v-model="currentValue"
        placeholder="Drag and drop or click to upload file"
        accept=".pdf, .doc, .docx, image/jpeg, image/png, image/gif"
        class="file-upload-area"
      >
      </b-form-file>
      <span v-if="errors[0]" class="error-message">* {{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, File],
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>