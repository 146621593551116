<template>
  <div class="pt-4">
    <p>{{ $t("seeker_account.please_add_references") }}</p>
    <ValidationObserver ref="form" tag="div">
      <b-form class="candidates-wrp">
        <b-row>
          <b-col lg="6">
            <app-map
              :currentLocation="null"
            ></app-map>
          </b-col>
          <b-col lg="6">
            <app-select-input
              :name="$t('seeker_account.offer_radius')"
              :label="$t('seeker_account.offer_radius')"
              mode="lazy"
              vid="job_offer_radius"
              rules="required"
              :options="offerRadius"
              v-model="form.personal_data.job_offer_radius"
            ></app-select-input>
            <legend class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("seeker_account.preferred_countries") }}
            </legend>
            <app-multiselect
              mode="lazy"
              vid="preferred_countries"
              rules="required"
              :placeholder="$t('seeker_account.preferred_countries')"
              :options="countriesOptions"
              :multiple="true"
              label="text"
              track-by="value"
              v-model="form.preferred_countries"
            ></app-multiselect>
            <legend class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("seeker_account.interested_in") }}
            </legend>
            <app-multiselect
              label="text"
              mode="lazy"
              vid="job_types"
              rules="required"
              :options="interestedIn"
              track-by="value"
              v-model="form.job_types"
            ></app-multiselect>
            <app-select-input
              :name="$t('seeker_account.be_available_in')"
              :label="$t('seeker_account.be_available_in')"
              mode="lazy"
              vid="be_available_in"
              rules="required"
              :options="availableIn"
              v-model="form.personal_data.available_in"
            ></app-select-input>
          </b-col>
        </b-row>
        <div class="row justify-content-center align-items-center mt-3">
          <app-checkbox
            v-model="form.personal_data.opened_for_jobs"
          ></app-checkbox>
          <div class="ml-3">{{ $t("seeker_account.i_am_open") }}</div>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SeekerHelpersMixin from "../../../common/SeekerHelpersMixin";
import AppSelectInput from "../../../components/SelectInput";
import AppMultiselect from "../../../components/CustomMultiselect";
import AppCheckbox from "../../../components/Checkbox";
import AppMap from "./Map";
export default {
  components: {
    AppSelectInput,
    AppMultiselect,
    AppCheckbox,
    AppMap,
  },
  mixins: [SeekerHelpersMixin],
  data() {
    return {
      countriesOptions: [],
      form: {
        job_types: [],
        preferred_countries: [],
        personal_data: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      jobTypes: "jobSeeker/jobTypes",
      workItems: "jobSeeker/workItems",
      jobSeeker: "jobSeeker/jobSeeker",
    }),
  },
  mounted() {
    this.countriesOptions = this.countries;
    this.form = {
      job_types: this.jobTypes,
      preferred_countries: this.jobSeeker.preferred_countries.map(preferredCountry => {
        return this.countriesOptions.find(country => country.value === preferredCountry.country_code)
      }),
      personal_data: {
        job_offer_radius: this.jobSeeker.job_offer_radius,
        opened_for_jobs: this.jobSeeker.opened_for_jobs,
        available_in: this.jobSeeker.available_in
      },
      location: this.jobSeeker.location
    }
    this.$store.commit('jobSeeker/ASSIGN_FORM', this.form);
  },
  methods: {
    validateForm() {
      this.$store.commit('jobSeeker/ASSIGN_FORM', this.form);
      return this.$refs.form.validate();
    },
  },
};
</script>
