<template>
  <div class="self__test">
    <app-header :authBtn="false" @toggle-nav="setIsActiveSideNav"></app-header>

    <div class="content__wrapper">
      <app-sidebar-navigation :is-active="isActive"></app-sidebar-navigation>
      <b-container class="xk-view" :class="{'xk-view--is-squeezed': !isActive}">
        <Spinner v-if="showSpinner" :options="{transparent: true}"/>
        <router-view v-else></router-view>
      </b-container>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import { USER_TYPES } from '@/common/constants';
import Spinner from "../../components/Spinner";
import AppHeader from "../../components/Header";
import AppSidebarNavigation from "@/components/SidebarNavigation";
import AppFooter from "../../components/Footer";
import { mapGetters } from "vuex";
import AuthApi from "../../api/auth";

export default {
  components: {
    AppHeader,
    AppSidebarNavigation,
    AppFooter,
    Spinner,
  },
  data() {
    return {
      isActive: true,
      showSpinner: true
    };
  },
  computed: {
    ...mapGetters("global", ["isAuthenticated", "userRole"]),
    ...mapGetters({
      report: "report/selfTestReport"
    })
  },
  created() {
    this.checkRoles();
  },
  mounted() {
    if (this.report.is_survey_finished) {
       this.$router.push({
        name: 'self-test.situation'
      })
    } else if (this.report.total_answered_questions > 0) {
      this.$router.push({
        name: 'self-test.situation'
      })
    } else {
     this.$router.push({
        name: 'self-test.start'
      })
    }

    this.showSpinner = false;
  },
  methods: {
    checkRoles() {
      if (!USER_TYPES.map(t => t.toLowerCase()).includes(this.userRole.toLowerCase())) {
        this.$router.push("/");
      }
    },
    setIsActiveSideNav(payload) {
      this.isActive = payload;
    }
  }
};
</script>
