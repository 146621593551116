<template>
  <div>
    <p>
      {{ $t('match_candidate.candidate_name') }} <strong>{{ campaign.candidate_full_name }}</strong>
      <br>
      {{ $t('create_campaign.job_title_short') }} <strong>{{ campaign.job_title_short }}</strong>
    </p>
    <p class="text-muted py-2">{{ $t('match_candidate.please_check_text') }}</p>
    <b-table class="table-lite table-embed my-5" :items="actualReferences" :fields="tableFields"><template v-slot:cell(email)="data"><div>{{ data.value }}</div></template></b-table>
    <div class="text-right">
      <b-btn class="min-w-140 mr-3" variant="outline-primary" @click="prev" size="xl">{{ $t('general.back') }}</b-btn>
      <b-btn class="min-w-140" variant="primary" @click="done" size="xl">{{ $t('general.next') }}</b-btn>
    </div>
  </div>
</template>

<script>
import ReferencesHelpersMixin from "../../../common/ReferencesHelpersMixin";

export default {
  mixins: [ReferencesHelpersMixin],

  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  computed: {
    actualReferences() {
      return this.campaign.references.filter((item) => !item.info.deleted);
    }
  },

  methods: {
    prev() {
      this.$emit("prev");
    },

    done() {
      this.$emit("done");
    }
  }
};
</script>
