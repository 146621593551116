<template>
  <multiselect
    v-model="checkValue"
    :options="options"
    :label="label"
    :track-by="uniquekey"
    :multiple="true"
    :searchable="searchable"
    :close-on-select="false"
    :show-labels="false"
    :placeholder="placeholder"
    :max="max"
    :taggable="taggable"
    :tag-placeholder="tagPlaceholder"
    :disabled="disabled"
    @tag="addTag"
    @search-change="asyncFind"
    ><template slot="option" slot-scope="data"
      ><div class="d-flex">
        <div>
          <div class="option-checkbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="7.607" height="6.071"><path d="M7.313.293a1 1 0 00-1.413 0L2.536 3.657l-.829-.828A1 1 0 00.292 4.243l1.537 1.535a1 1 0 001.414 0l4.07-4.071a1 1 0 000-1.414" fill="#fff"/></svg>
          </div>
        </div>
        <div class="option-descr ml-2">{{ translatedLabel(data) }}</div>
      </div></template>
      <template slot="maxElements">
        <p class="max-elements-allowed">{{ $t("validation.max_allowed", {max: max}) }}</p>
      </template>
    </multiselect>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 10,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "name",
    },
    trackBy: {
      type: String,
      default: "name",
    },
    tagPlaceholder: {
      type: String,
      default: "Press enter to create a tag",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", ["currentLanguage"]),
    checkValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    uniquekey: {
      get() {
        return this.$props.trackBy || this.$props.label;
      },
    },
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
      };
      this.$emit("add", tag);
    },
    asyncFind: _.debounce(function (item) {
      if (item !== "") {
        this.$emit("async-find", item);
      }
    }, 500),
    translatedLabel(props) {
      return (
        props.option[`name_${this.currentLanguage}`] ||
        props.option.answer_2_text ||
        props.option.name ||
        props.label ||
        props.option.candidate_full_name ||
        props.option.text
      );
    },
  },
};
</script>
