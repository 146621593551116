<template>
  <div class="app-wrap error__404">

      <header>
        <b-container>
          <b-row>
            <b-col>
              <img class="logo" :src="logo" :alt="pageTitle">
            </b-col>
          </b-row>
        </b-container>
      </header>

      <b-container class="text-center">
        <b-row class="align-items-center">
          <b-col>
            <img class="illustration" :src="illustration" alt="Illustration">
            <h1>{{ $t('errors.error') }} 404</h1>
            <p class="error__message">{{ $t('errors.page_not_found') }}</p>
          </b-col>
        </b-row>
      </b-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from "../../components/Footer";
import Illustration from "../../../images/direction_new.svg"
import franchise from '../../conf/franchise';

export default {
  components: {
    AppFooter,
  },
  data() {
    return {};
  },
  computed: {
    pageTitle() {
      return franchise.pageTitle;
    },
    logo() {
      return franchise.logo;
    },
    illustration() {
      return Illustration;
    },
  }
};
</script>
