<template>
  <nav id="sidenav" :hidden="hideSidemenu" class="h-100" :class="{ active: isActive }">

    <!-- // navigation for role: recruiter ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <ul class="list-unstyled components" v-if="userRole === 'recruiter'">
      <li class="sidenav__groupname">{{ $t('dashboard.navigation') }}</li>

      <li>
        <router-link :title="$t('dashboard.dashboard')"  to="/dashboard" exact-active-class="active">
          <i class="mdi mdi-home"></i>
          <span>{{ $t('dashboard.dashboard') }}</span>
        </router-link>
      </li>

      <li v-if="showTemplates">
        <router-link :title="$t('dashboard.my_templates')" to="/templates" exact-active-class="active">
          <i class="mdi mdi-puzzle"></i>
          <span>{{ $t('dashboard.my_templates') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :title="$t('dashboard.settings')"  to="/settings" exact-active-class="active">
          <i class="mdi mdi-cog"></i>
          <span>{{ $t('dashboard.settings') }}</span>
        </router-link>
      </li>
      <li>
        <a :title="$t('match_references.candidate_info')" :href="faq" target="_blank">
          <i class="mdi mdi-information-outline"></i>
          <span>{{ $t('match_references.candidate_info') }}</span>
        </a>
      </li>
    </ul>

    <!-- // navigation for role: jobseeker ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <ul class="list-unstyled components" v-if="userRole === 'jobseeker'">
      <li class="sidenav__groupname">{{ $t('dashboard.navigation') }}</li>

      <li>
        <router-link :title="$t('dashboard.dashboard')" to="/dashboard" exact-active-class="active">
          <i class="mdi mdi-home"></i>
          <span>{{ $t('dashboard.dashboard') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :title="$t('seeker_account.my_skill_profile')" to="/self-test/results" exact-active-class="active">
          <i class="mdi mdi-card-account-details-outline"></i>
          <span>{{ $t("seeker_account.my_skill_profile") }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import AuthMixin from '@/common/mixins/AuthMixin';
import { mapGetters } from 'vuex';
import { EXAMIZ_APPS } from '../common/constants';
import franchise from '../conf/franchise';

export default {
  props: {
    isActive: {
      type: Boolean,
      default: true
    }
  },
  mixins: [ AuthMixin ],
  computed: {
    ...mapGetters('global', ['currentApp']),
    hideSidemenu() {
      return !this.isRegistered;
    },
    showTemplates() {
      return this.currentApp.type === EXAMIZ_APPS.DEFAULT.type
    },
    faq() {
      return franchise.faq
    }
  }
}
</script>
