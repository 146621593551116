import ApiCampaign from "../../../api/campaign";
import ApiSurvey from "../../../api/survey";


const getReport = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.getReport(companyId, params.id, params.mode, params.isApplicantPortal, params.candidates).then((response) => {
    if (response.data.ap_data) {
      response.data.campaign.ap_data = response.data.ap_data

      // HACK: anonymized name for candidates are not correct
      if (params.mode) {
         const report =  response.data.report;
         report.best_matches.forEach(bestMatch => {
           const candidate = report.candidates.find(c => c.candidate_id === bestMatch.candidate_id)
           if (candidate) {
             candidate.candidate_full_name = bestMatch.candidate
           }
         })
      }
    }
    commit("SET_REPORT", response.data.report);
    commit("SET_REPORT_CAMPAIGN", response.data.campaign);
  });
}

const getReportPreview = ({ commit }, params) => {
  return ApiCampaign.getReportPreview(params.token, params.candidates).then((response) => {
    if (response.data.ap_data) {
      response.data.campaign.ap_data = response.data.ap_data

      // HACK: anonymized name for candidates are not correct
      if (params.mode) {
         const report =  response.data.report;
         report.best_matches.forEach(bestMatch => {
           const candidate = report.candidates.find(c => c.candidate_id === bestMatch.candidate_id)
           if (candidate) {
             candidate.candidate_full_name = bestMatch.candidate
           }
         })
      }
    }
    commit("SET_REPORT", response.data.report);
    commit("SET_REPORT_CAMPAIGN", response.data.campaign);
  });
}

const getSelfTestReport = ({ commit }, params) => {
  return ApiSurvey.getSelftestSurveyReport({
    token: params.token,
    lang: params.lang,
  }).then((response) => {
    commit("SET_SELF_TEST_REPORT", response.data);
  });
}

export default {
  getReport,
  getReportPreview,
  getSelfTestReport,
};
