import ApiCompany from "../../../api/company";

const getCompanyRecruiters = ({ commit }, companyId) => {
    return ApiCompany.getCompanyRecruiters(companyId)
        .then((response) => {
            const recruiters = response.data.recruiters;

            commit('SET_COMPANY_ID', companyId);
            commit('SET_COMPANY_RECRUITERS', recruiters);
        });
}

const createCompanyRecruiter = ({ commit }, { companyId, data }) => {
    return ApiCompany.createCompanyRecruiter(companyId, data)
        .then((response) => {
            if (response.data.status === 'pending') {
              commit('ADD_COMPANY_INVITATION', response.data);
            } else {
              commit('ADD_COMPANY_RECRUITER', response.data.recruiter);
            }
        });
}

const updateCompanyRecruiter = ({ commit }, { companyId, data }) => {
    return ApiCompany.updateCompanyRecruiter(companyId, data.id, data)
        .then((response) => {
            commit('UPDATE_COMPANY_RECRUITER', response.data.recruiter);
        });
}

const batchUpdateCompanyRecruiters = ({ commit }, { companyId, recruiterIds, data }) => {
    return ApiCompany.batchUpdateCompanyRecruiters(companyId, recruiterIds, data)
        .then((response) => {
            response.data.recruiters.forEach(r => commit('UPDATE_COMPANY_RECRUITER', r));
        });
}

const deleteCompanyRecruiter = ({ commit }, { companyId, recruiterId }) => {
    return ApiCompany.deleteCompanyRecruiter(companyId, recruiterId)
        .then((response) => {
            commit('REMOVE_COMPANY_RECRUITER', recruiterId);
        });
}

const batchDeleteCompanyRecruiters = ({ commit }, { companyId, recruiterIds }) => {
    return ApiCompany.batchDeleteCompanyRecruiters(companyId, recruiterIds)
        .then(() => {
            recruiterIds.forEach(rid => commit('REMOVE_COMPANY_RECRUITER', rid));
        });
}

const getCompanyInvitations = ({ commit }, companyId) => {
  return ApiCompany.getCompanyInvitations(companyId)
      .then((response) => {
          commit('SET_COMPANY_INVITATIONS', response.data);
      });
}

const deleteInvitation = ({ commit }, params) => {
  return ApiCompany.deleteCompanyInvitation(params.companyId, params.invitationId)
      .then((response) => {
          commit('DELETE_INVITATION', params.invitationId);
      });
}

const resendInvitation = ({ commit }, params) => {
  return ApiCompany.resendCompanyInvitation(params.companyId, params.invitationId);
}

const acceptInvitation = ({ commit }, companyId) => {
  return ApiCompany.acceptInvitation(companyId);
}

export default {
    getCompanyRecruiters,
    createCompanyRecruiter,
    updateCompanyRecruiter,
    batchUpdateCompanyRecruiters,
    deleteCompanyRecruiter,
    batchDeleteCompanyRecruiters,
    getCompanyInvitations,
    deleteInvitation,
    resendInvitation,
    acceptInvitation,
}