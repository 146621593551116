<template>
  <div class="app-wrap app_match">
    <!-- // header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <header class="auth-header">
      <b-container>
        <b-navbar class="px-0 flex-wrap xk-navbar" type="light" variant="light">
          <b-navbar-brand>
            <template>
              <b-img :src="headerLogo" fluid="fluid" :alt="pageTitle"></b-img>
            </template>
          </b-navbar-brand>

          <b-navbar-nav class="ml-auto">
            <app-language-switcher></app-language-switcher>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </header>

    <!-- // content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="content__wrapper">
      <div class="container has__paddding">
        <article class="campaign">
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>

          <ValidationObserver ref="form">
            <b-form>
              <b-row>
                <b-col lg="12">
                  <header class="campaign__header dashboard-table-top mb-0">
                    <h1 class="campaign__title">{{ info.job_title_short }}</h1>
                  </header>
                </b-col>
              </b-row>

              <!--
              <b-row class="campaign__attributes">
                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("dashboard.status") }}
                  </div>
                  <div class="campaign__attribute__value">
                    <div :class="'table-label table-label__' + currentState">
                      {{ $t(`dashboard.statuses.${currentState}`) }}
                    </div>
                  </div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("report.campaign_id") }}
                  </div>
                  <div class="campaign__attribute__value">{{ campaignId }}</div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("create_campaign.internal_job_id") }}
                  </div>
                  <div class="campaign__attribute__value">
                    {{ info.internal_job_id_1 }}
                  </div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("create_campaign.min_references_requested") }}
                  </div>
                  <div class="campaign__attribute__value">
                    {{ info.references_requested }}
                  </div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("create_campaign.starting_date") }}
                  </div>
                  <div class="campaign__attribute__value">{{ startDate }}</div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("create_campaign.end_date") }}
                  </div>
                  <div class="campaign__attribute__value">{{ endDate }}</div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("dashboard.client_name") }}
                  </div>
                  <div class="campaign__attribute__value">
                    {{ info.client_name }}
                  </div>
                </b-col>

                <b-col class="col-lg-2 col-md-4 col-12">
                  <div class="campaign__attribute__label">
                    {{ $t("dashboard.recruiter_name") }}
                  </div>
                  <div class="campaign__attribute__value">
                    {{ recruiterName }}
                  </div>
                </b-col>
              </b-row>
              -->
              <b-row>
                <b-col md="12" lg="9">
                  <b-row>
                    <b-col md="4">
                      <div>
                        <div class="form__title">
                          {{ $t('create_campaign.standard_attributes') }}
                        </div>

                        <div
                          class="progress-item"
                          v-for="(item, index) in sortedPermanentAttributes"
                          :key="index"
                        >
                          <span class="progress-item-badge">{{
                            item.name
                          }}</span>
                        </div>

                        <p class="pt-3">
                          {{ $t('create_campaign.standard_attributes_info') }}
                        </p>

                        <!--
                        <div v-for="(item, index) in personality_attrs.values":key="index">
                          <div class="skill-label">{{ item[`name_${currentLanguage}`] }}</div>
                          <vue-slider
                              v-model="item.value"
                              :lazy="true"
                              dotSize="12"
                              :height="3"
                              :interval="1"
                              :marks="marks"></vue-slider>
                        </div>
                        -->
                      </div>
                    </b-col>

                    <b-col md="4">
                      <div class="form__title">
                        {{ $t('create_campaign.competencies') }}
                      </div>

                      <ValidationProvider
                        :name="$t('create_campaign.competencies')"
                        vid="competencies"
                        rules="required"
                        tag="div"
                        v-slot="{ errors }"
                      >
                        <app-multiselect
                          v-model="personality_attrs.competencies"
                          :disabled="disableElement"
                          :options="competenciesAttributes"
                          :label="`name_${currentLanguage}`"
                          :placeholder="
                            $t('create_campaign.competencies_placeholder')
                          "
                          :max="5"
                          class="mb-4"
                          trackBy="attribute"
                        >
                        </app-multiselect>

                        <div class="error-message">{{ errors[0] }}</div>

                        <div
                          v-for="(
                            item, index
                          ) in personality_attrs.competencies"
                          :key="index"
                        >
                          <div class="skill-label">
                            {{ item[`name_${currentLanguage}`] }}
                          </div>
                          <vue-slider
                            v-model="item.value"
                            :lazy="true"
                            dotSize="12"
                            :height="3"
                            :interval="1"
                            :marks="marks"
                          ></vue-slider>
                        </div>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                      <div class="form__title">
                        {{ $t('create_campaign.traits') }}
                      </div>
                      <ValidationProvider
                        :name="$t('create_campaign.traits')"
                        vid="traits"
                        rules="required"
                        tag="div"
                        v-slot="{ errors }"
                      >
                        <app-multiselect
                          v-model="personality_attrs.traits"
                          :disabled="disableElement"
                          :options="traitsAttributes"
                          :placeholder="
                            $t('create_campaign.competencies_placeholder')
                          "
                          :max="5"
                          class="mb-4"
                          :label="`name_${currentLanguage}`"
                          trackBy="attribute"
                        >
                        </app-multiselect>

                        <div class="error-message">{{ errors[0] }}</div>

                        <div
                          v-for="(item, index) in personality_attrs.traits"
                          :key="index"
                        >
                          <div class="skill-label">
                            {{ item[`name_${currentLanguage}`] }}
                          </div>
                          <vue-slider
                            v-model="item.value"
                            :lazy="true"
                            dotSize="12"
                            :height="3"
                            :interval="1"
                            :marks="marks"
                          ></vue-slider>
                        </div>
                      </ValidationProvider>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12" lg="12">
                      <b-btn
                        class="mx-1 xxl mt-5 float-right"
                        variant="primary"
                        @click="saveAttrs"
                        :disabled="isBtnEnabled"
                        >{{ $t('general.save') }}</b-btn
                      >
                    </b-col>
                  </b-row>
                </b-col>

                <!--  // aside ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <b-col lg="3" class="aside">
                  <div class="aside__content ask__expert__screen">
                    <!--  // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <h6 class="aside__title">
                      <span>{{ $t('create_campaign.campaign_details') }}</span>
                    </h6>

                    <div class="campaign__attributes">
                      <div class="campaign__attribute__item">
                        <div class="campaign__attribute__label">
                          {{ $t('dashboard.status') }}
                        </div>
                        <div class="campaign__attribute__value">
                          <div
                            :class="'table-label table-label__' + currentState"
                          >
                            {{ $t(`dashboard.statuses.${currentState}`) }}
                          </div>
                        </div>
                      </div>

                      <div class="campaign__attribute__item">
                        <div class="campaign__attribute__label">
                          {{ $t('report.campaign_id') }}
                        </div>
                        <div class="campaign__attribute__value">
                          {{ campaignId }}
                        </div>
                      </div>

                      <div class="campaign__attribute__item">
                        <div class="campaign__attribute__label">
                          {{ $t('create_campaign.internal_job_id') }}
                        </div>
                        <div class="campaign__attribute__value">
                          {{ info.internal_job_id_1 }}
                        </div>
                      </div>

                      <div class="campaign__attribute__item">
                        <div class="campaign__attribute__label">
                          {{ $t('dashboard.client_name') }}
                        </div>
                        <div class="campaign__attribute__value">
                          {{ info.client_name }}
                        </div>
                      </div>

                      <div class="campaign__attribute__item">
                        <div class="campaign__attribute__label">
                          {{ $t('dashboard.recruiter_name') }}
                        </div>
                        <div class="campaign__attribute__value">
                          {{ recruiterName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </article>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import AppMultiselect from '../../components/CustomMultiselect';
import CampaignHelpersMixin from '../../common/CampaignHelpersMixin';
import ApiCampaign from '../../api/campaign';
import { mapGetters } from 'vuex';

import AppLanguageSwitcher from '../../components/LanguageSwitcher';
import AppFooter from '@/components/Footer';
import franchise from '../../conf/franchise';

export default {
  components: {
    AppLanguageSwitcher,
    AppMultiselect,
    AppFooter,
  },
  mixins: [CampaignHelpersMixin],
  data() {
    return {
      marks: [0, 100],
      smart_fit: false,
      shareModal: false,
      personality_attrs: {},
      expertToken: null,
      disableElement: false,
      breadcrumbs: [
        {
          text: this.$t('dashboard.edit_campaign'),
          active: true,
        },
        {
          text: '',
          active: true,
        },
      ],
      current: {},
    };
  },
  computed: {
    ...mapGetters('global', ['currentLanguage', 'permanentAttributes']),
    isBtnEnabled() {
      return Object.keys(this.personality_attrs).length < 1;
    },
    pageTitle() {
      return franchise.title;
    },
    headerLogo() {
      return franchise.logoMatch;
    },
    currentState() {
      return this.current && this.current.state
        ? this.current.state.toLowerCase()
        : '';
    },
    info() {
      return this.current.info || {};
    },
    campaignId() {
      return this.current.campaign_id;
    },
    startDate() {
      return this.current.start_date || '';
    },
    endDate() {
      return this.current.end_date || '';
    },
    recruiterName() {
      return this.current.id
        ? `${this.current.campaign_admin_1_first_name} ${this.current.campaign_admin_1_last_name}`
        : '';
    },
    sortedPermanentAttributes() {
      return this.permanentAttributes || [];
    },
  },
  created() {
    this.expertToken = this.$route.query.token;
  },
  mounted() {
    ApiCampaign.validateCampaignExpertLink({ token: this.expertToken })
      .then((res) => {
        this.current = res.data.campaign;
        this.setJobTitleShort();
      })
      .catch((errors) => {
        this.disableElement = true;
        let error = errors.response.data.message;
        this.$bvToast.toast(error, {
          autoHideDelay: 4000,
          variant: 'warning',
          title: 'Oops',
        });
      });
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate();
    },
    saveAttrs() {
      ApiCampaign.updateCampaignExpertAttributes({
        token: this.expertToken,
        personality_attrs: this.personality_attrs,
      })
        .then(() => {
          this.$router.push('/');
        })
        .catch((errors) => {
          let error = errors.response.data.message;
          this.$bvToast.toast(error, {
            autoHideDelay: 4000,
            variant: 'warning',
            title: 'Oops',
          });
        });
    },
    setJobTitleShort() {
      this.breadcrumbs[1].text = this.current.info.job_title_short;
    },
  },
};
</script>
