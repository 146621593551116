<template>
  <div>
    <div v-if="showReport" class="report" :id="pageId">
      <!-- // go to report button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="report__buttonbar__fixed" :style="{ width: this.$route.meta.sharedCampaign ? '100%' : '' }">
        <b-row>
        <b-col>
          <b-button-toolbar>
            <div class="report__buttons__wrapper justify-content-start">
              <b-form-checkbox
                 v-if="!this.$route.meta.sharedCampaign"
                v-model="useCandidateScoresAsBenchmark"
                @change="onCandidatesScoreChange"
                :disabled="disableAvgStatisticsCheckbox"
                class="mr-3 report__anonymizer__checkbox"
                >{{ centerValueLabelText }}&nbsp
                <span
                  v-b-tooltip.hover
                  :title="$t('report.center_value_label_tooltip')"
                  class="mdi mdi-information-outline"
                ></span>
              </b-form-checkbox>
            </div>
          </b-button-toolbar>
        </b-col>

        <b-col>
          <b-button-toolbar>
            <div class="report__buttons__wrapper justify-content-end">
              <b-form-checkbox
                v-if="!this.$route.meta.sharedCampaign"
                class="mr-3 report__anonymizer__checkbox"
                v-model="anonymizeCandidates"
                @input="getReport(anonymous)"
                >{{ $t('report.anonymize_candidates') }}&nbsp
                <span
                  v-b-tooltip.hover
                  :title="$t('report.anonymize_candidates_tooltip')"
                  class="mdi mdi-information-outline"
                ></span>
              </b-form-checkbox>

              <b-button
                @click="goToReport(current.id, anonymous)"
                v-b-tooltip.hover
                :title="$t('report.print_report')"
                class="btn-action report__redirect__btn"
                :class="{ primary: current.report_present }"
                :disabled="!current.report_present || !isSelectedCandidate"
              >
                <span class="mdi mdi-printer"></span>
              </b-button>
            </div>
          </b-button-toolbar>
        </b-col>
      </b-row>
      </div>
      <!-- // report content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="report__content">
        <!-- // 1. dashboard cards /////////////////////////////////////////// -->

        <b-row>
          <b-col>
            <!-- // report cards ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <report-campaign-overview
              :campaign="campaign"
              :report="report"
              :attributes="attributes"
              :filteredAttr="filteredAttr"
            ></report-campaign-overview>
          </b-col>
        </b-row>

        <!-- // 1. campaign summary ////////////////////////////////////////// -->

        <b-row>
          <b-col md="12" lg="6">
            <div class="report__section__grouptitle">
              <span>{{ $t('report.campaign_summary') }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle">
              <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <h2
                class="report__section__title toggle__trigger"
                v-b-toggle.best-match-score
              >
                <span class="toggle__trigger__text"
                  >{{ $t('report.best_match_score') }}
                  <span
                    class="mdi mdi-help-circle-outline"
                    v-b-tooltip.hover
                    :title="$t('report.best_match_annotation')"
                  ></span>
                </span>
              </h2>

              <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <b-collapse
                ref="collapsibleCandidate"
                id="best-match-score"
                class="toggle__content"
                visible
              >
                <!-- // best match score ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-best-match
                  :report="report"
                  :items="report.best_matches"
                  :campaign="campaign"
                  :attributes="attributes"
                  :filteredAttr="filteredAttr"
                  @change-candidates="changeCandidates"
                  :filteredCandidates="filteredCandidates"
                ></report-best-match>
              </b-collapse>
            </div>

            <!-- // Empty message when no users are selected ~~~~~~~~~~~~~~~ -->
            <b-row>
              <b-col>
                <div class="emptystate" v-if="!isSelectedCandidate">
                  <i class="mdi mdi-information-outline"></i>
                  <p>{{ $t('report.select_min_candidate_notice') }}</p>
                </div>
              </b-col>
            </b-row>

            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle" v-if="isSelectedCandidate">
              <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <h2
                class="report__section__title toggle__trigger"
                v-b-toggle.potential-analytics
              >
                <span class="toggle__trigger__text">{{
                  $t('report.potential_analytics')
                }}</span>
              </h2>

              <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <b-collapse
                ref="collapsiblePotential"
                id="potential-analytics"
                class="toggle__content"
              >
                <!-- // potential analytics ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-potential-analytics
                  :report="report"
                  :filteredCandidates="filteredCandidates"
                  @hideLegendChange="onHideLegendChange"
                ></report-potential-analytics>
              </b-collapse>
            </div>
          </b-col>
        </b-row>

        <!-- // 2. candidate details ///////////////////////////////////////// -->
        <b-row>
          <b-col md="12" lg="6">
            <div
              class="report__section__grouptitle candidate__details"
              v-if="isSelectedCandidate"
            >
              <span>{{ $t('report.candidate_details') }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- // candidates sections ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <section
              v-for="(candidate, index) in filterCandidates()"
              :key="candidate.candidate_id"
            >
              <b-row v-if="candidate.best_match_score !== 0">
                <b-col>
                  <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                  <div class="toggle">
                    <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <h2
                      class="
                        report__section__title
                        toggle__trigger
                        candidate__toggle__trigger
                      "
                      v-b-toggle="`uid-${candidate.candidate_id}`"
                    >
                      <span class="toggle__trigger__text"
                        ><span class="report__candidate__index"
                          >{{ index + 1 }}.</span
                        >{{ candidate.candidate_full_name }}</span
                      >
                    </h2>

                    <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                    <b-collapse
                      ref="collapsible"
                      :id="`uid-${candidate.candidate_id}`"
                      class="toggle__content"
                    >
                      <!-- candidate-details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                      <print-candidate-details-page
                        :candidate="candidate"
                        :report="report"
                        :index="index"
                        :campaign="campaign"
                      ></print-candidate-details-page>

                      <CandidateDetails
                        :item='candidate.ap_data'
                        :availableLanguages="availableLanguages"
                        :anonymous="anonymizeCandidates"
                        :isAnalytic="true"
                      >
                      </CandidateDetails>

                      <report-candidate
                        :candidate="candidate"
                        :campaign="campaign"
                      ></report-candidate>
                    </b-collapse>
                  </div>
                </b-col>
              </b-row>
            </section>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-else class="emptystate">
      <i class="mdi mdi-information-outline"></i>
      <p>{{ tooFewResponsesByReferencesMsg }}.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, isArray, orderBy } from 'lodash';
import Header from './Header';
import CampaignOverview from './CampaignOverview';
import BestMatch from './BestMatch';
import PotentialAnalytics from './PotentialAnalytics';
import Candidate from './Candidate/Index';
import CandidateDetailsPage from './Print/CandidateDetailsPage';
import { CAMPAIGN_TYPES } from '../../common/constants';
import APCandidateDetails from '../Campaigns/Details/APCandidateDetails'

export default {
  props: {
    pageId: {
      type: String,
      default: 'webreport__fullview',
    },
  },
  components: {
    ReportHeader: Header,
    ReportCampaignOverview: CampaignOverview,
    ReportBestMatch: BestMatch,
    ReportPotentialAnalytics: PotentialAnalytics,
    ReportCandidate: Candidate,
    PrintCandidateDetailsPage: CandidateDetailsPage,
    CandidateDetails: APCandidateDetails
  },
  data() {
    return {
      filteredCandidates: [],
      filteredAttr: [],
      filteredQuestions: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: { name: 'Home' },
        },
        {
          text: 'Campaign ID 2389329839',
          active: true,
        },
        {
          text: 'Report',
          active: true,
        },
      ],
      exapandCollapse: false,
      useCandidateScoresAsBenchmark: true,
      isLegendHidden: false
    };
  },
  computed: {
    ...mapGetters('report', ['report', 'reportCampaign']),
    ...mapGetters('campaign', ['current', 'anonymous', 'campaignCandidates']),
    ...mapGetters('global', ['availableLanguages']),
    campaign() {
      return this.reportCampaign;
    },
    attributes() {
      const result = [];
      const allAttributes = [
        ...attributes.competencies,
        ...attributes.traits,
        ...attributes.values,
      ].filter(
        (a) => a.language === (localStorage.getItem('language') || 'en')
      );

      this.attributesListDefault.forEach((item) => {
        const matchAttribute = allAttributes.find(
          (a) => a.value === item.attribute || a.attribute === item.attribute
        );
        if (matchAttribute) result.push(Object.assign(matchAttribute, item));
      });
      return result;
    },
    questions() {
      return isArray(this.filteredQuestions) && this.filteredQuestions.length
        ? this.filteredQuestions
        : this.report.interview_questions;
    },
    attributesListDefault() {
      return [
        ...get(this.reportCampaign, 'personality_attrs.competencies', []),
        ...get(this.reportCampaign, 'personality_attrs.traits', []),
        ...get(this.reportCampaign, 'personality_attrs.values', []),
      ];
    },
    candidates() {
      const order = this.report.best_matches.map(i => i.candidate_id);
      return orderBy(this.report.candidates, (i) => order.indexOf(i.candidate_id));
    },
    isSelectedCandidate() {
      return this.filteredCandidates.length > 0;
    },
    disableAvgStatisticsCheckbox() {
      return (
        this.report.candidates.filter((c) => c.is_survey_valid).length < 3
      );
    },
    centerValueLabelText() {
      // return this.disableAvgStatisticsCheckbox
        // ? this.$t('report.center_value_label_text_examiz')
        // : this.$t('report.center_value_label_text_group');
        return this.$t('report.center_value_label_text_group');
    },
    showReport() {
      return this.current.campaign_type === CAMPAIGN_TYPES.JOB ?
        this.report.candidates.filter(c => c.is_survey_valid).length > 0 : true;
    },
    tooFewResponsesByReferencesMsg() {
      return this.$t('report.too_few_responses_by_references')
    },
    anonymizeCandidates: {
      get() {
        return this.anonymous
      },
      set(value) {
        this.$store.commit('campaign/ANONYMIZE_CANDIDATES', value)
      },
    }
  },
  methods: {
    getReport(mode = false) {
      this.$emit('getReport', mode);
    },
    changeAttrs(list) {
      this.filteredAttr = this.attributesListDefault.filter(
        (i) => list.indexOf(i.attribute) >= 0
      );
      this.filteredQuestions = this.report.interview_questions.filter(
        (i) => list.indexOf(i.attribute_id) >= 0
      );

      // Force re-rendering child components
      this.$store.commit('report/SET_REPORT', Object.assign({}, this.report));
    },
    changeCandidates(list) {
      this.filteredCandidates = list;
    },
    filterCandidates() {
      this.candidates.forEach(c => {
        console.log(c.candidate_id, c.candidate_full_name, c.high_performance_score, c.high_potential_score)
      })
      console.log('-----------------');
      let counter = 0;
      return this.candidates.filter(() => {
        return this.filteredCandidates.indexOf(counter++) > -1;
      });
    },
    expandAllCollapses() {
      this.$refs.collapsibleCandidate.show = true;
      this.$refs.collapsiblePotential.show = true;
      this.$refs.collapsible.map((c) => (c.show = true));
    },
    goToReport(id, mode) {
      let routeName = 'Printreport'
      if (this.current.is_applicant_portal) {
        if (this.$route.meta.sharedCampaign) {
          routeName = this.current.ap_data.bulk_hiring ? 'PrintreportAPBulkPreview' : 'PrintreportAPPreview';
        } else {
          routeName = this.current.ap_data.bulk_hiring ? 'PrintreportAPBulk' : 'PrintreportAP';
        }
      }

      console.log('this.$route.query', this.$route.query._token)

      const route = this.$router.resolve({
        name: routeName,
        params: { id, mode },
        query: {
          candidates: this.filteredCandidates.sort().join(','),
          use_candidate_scores_as_benchmark: this.useCandidateScoresAsBenchmark,
          hide_legend: this.isLegendHidden,
          _token: this.$route.query._token
        },
      });
      window.open(route.href, '_blank');
    },
    onCandidatesScoreChange(value) {
      this.$store.commit('report/RECALCULATE_REPORT_AVG_VALUES', !value);
    },
    onHideLegendChange(value) {
      this.isLegendHidden = value;
    }
  },
};
</script>
