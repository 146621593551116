<template>
  <thank-you :token="token"></thank-you>
</template>

<script>
import ThankYou from '@/components/ThankYou'

export default {
  props: ['token'],
  components: {
    ThankYou
  }
};
</script>
