<template>
  <div>
    <app-header></app-header>
    <div class="xmz-preview p-3 pt-3 center">
      <router-view />
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../../components/Exit/Header.vue';
import AppFooter from '../../components/Footer';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style scoped>
.xmz-preview {
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>