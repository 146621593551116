<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center border-bottom pb-3">
          <b-icon icon="check-square" variant="primary" font-scale="2"></b-icon>
          <p class="m-0 ml-3 text-center campaign__updated-info">{{ title }}</p>
          <button
            @click="goTo('close')"
            type="button"
            aria-label="Close"
            class="close xmz-close"
          >
            ×
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="xmz-text">{{ $t('create_campaign.what_next') }}</p>
        <div class="d-flex align-items-center justify-content-between">
          <b-button
            class="mx-2 xxl"
            @click="goTo('gotodashboard')"
            variant="primary"
            v-b-tooltip.hover
            :title="$t('create_campaign.to_dashboard_btn_description')"
            >{{ $t('create_campaign.to_dashboard') }}
          </b-button>
          <p class="text-uppercase">{{ $t('create_campaign.or') }}</p>
          <b-button
            v-if="isAnalytics"
            class="mx-2 xxl"
            @click="goTo('gotoreport')"
            variant="primary"
            v-b-tooltip.hover
            :title="$t('create_campaign.to_report')"
            >{{ $t('create_campaign.to_report') }}
          </b-button>
          <b-button
            v-else
            class="mx-2 xxl"
            @click="goTo('gotonewcampaign')"
            variant="primary"
            v-b-tooltip.hover
            :title="$t('create_campaign.to_new_campaign_btn_description')"
            >{{ $t('create_campaign.to_new_campaign') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EXIT_CAMPAIGN_TYPES } from '../../../common/constants';
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    currentCampaign: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isAnalytics() {
      if (this.currentCampaign) {
        return (
          this.currentCampaign.campaign_type === EXIT_CAMPAIGN_TYPES.ANALYTICS
        );
      }
      return false;
    },
  },
  methods: {
    goTo(name) {
      this.$emit(name);
    }
  },
};
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 25px;
}
</style>
