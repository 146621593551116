import { NOT_REGISTED_USER_TYPES, EXAMIZ_APPS } from '@/common/constants'

const isAuthenticated = state => {
  const userToken = state.userToken;
  const role = state.currentUserRole;
  return !!userToken && !["Reference", "Candidate"].includes(role);
};
const isRegistered = state => {
  return state.currentUserRole ? !NOT_REGISTED_USER_TYPES.includes(state.currentUserRole) : false;
};
const isMatch = state => state.currentUserRole === "Recruiter";
const isTalents = state => state.currentUserRole === "JobSeeker";
const userRole = state => state.currentUserRole && state.currentUserRole.toLowerCase();
const authStatus = state => state.authStatus;
const currentLanguage = state => state.currentLanguage;
const currentUserId = state => state.currentUserId;
const isCompanyAdmin = state => state.isCompanyAdmin;
const currentUserCompanyId = state => state.currentUserCompanyId;
const currentUserFirstName = state => state.currentUserFirstName;
const currentUserLastName = state => state.currentUserLastName;
const currentUserCompanyName = state => state.currentUserCompanyName;
const currentUserEmail = state => state.currentUserEmail;
const currentUserCountry = state => state.currentUserCountry;
const currentUserCompanies = state => state.currentUserCompanies;
const currentUserToken = state => state.userToken;
const currentUserSelftestSurveyPassed = state => state.selftestSurveyPassed;
const companyLogoUrl = state => state.companyLogoUrl;
const companyInfo = state => state.companyInfo;
const campaignAttributes = state => state.attributes;
const permanentAttributes = state => state.permanentAttributes;
const talentsAttributes = state => state.talents_attributes;
const redirectTo = state => state.redirectTo;
const showSpinner = state => state.showSpinner;
const spinnerOptions = state => state.spinnerOptions;

// Config
const jobTypes = state => state.jobTypes;
const timeZones = state => state.timeZones;
const titles = state => state.titles;
const availableLanguages = state => state.availableLanguages;
const availableCountries = state => state.availableCountries;
const availableCountriesWithKey = state => state.availableCountriesWithKey;
const languageProficiencyLevels = state => state.languageProficiencyLevels;
const educationGeneralAreas = state => state.educationGeneralAreas;
const jobAreasOld = state => state.jobAreasOld;
const yearsOfExperience = state => state.yearsOfExperience;
const senorityLevel = state => state.senorityLevel;
const educationAreas = state => state.educationAreas;
const educationGroups = state => state.educationGroups;
const educationSpecializations = state => state.educationSpecializations;
const jobAreas = state => state.jobAreas;
const jobGroups = state => state.jobGroups;
const jobSpecializations = state => state.jobSpecializations;
const bulkHiringTypes = state => state.bulkHiringTypes;
const bulkHiringProfiles = state => state.bulkHiringProfiles;

const exitQuestions = state => state.exitQuestions;

const currentApp = () => {
  const app = localStorage.getItem('app');

  switch (app) {
    case EXAMIZ_APPS.EXIT.type:
      return EXAMIZ_APPS.EXIT;
    default:
      return EXAMIZ_APPS.DEFAULT
  }
}

export default {
  isAuthenticated,
  isRegistered,
  isTalents,
  isMatch,
  isCompanyAdmin,
  userRole,
  authStatus,
  currentLanguage,
  currentUserId,
  currentUserCompanyId,
  currentUserFirstName,
  currentUserLastName,
  currentUserEmail,
  currentUserCountry,
  currentUserToken,
  currentUserSelftestSurveyPassed,
  currentUserCompanyName,
  currentUserCompanies,
  companyLogoUrl,
  companyInfo,
  campaignAttributes,
  permanentAttributes,
  talentsAttributes,
  redirectTo,
  showSpinner,
  spinnerOptions,
  // Config
  jobTypes,
  timeZones,
  titles,
  availableLanguages,
  availableCountries,
  availableCountriesWithKey,
  languageProficiencyLevels,
  educationGeneralAreas,
  jobAreasOld,
  yearsOfExperience,
  senorityLevel,
  educationAreas,
  educationGroups,
  educationSpecializations,
  jobGroups,
  jobAreas,
  jobSpecializations,
  bulkHiringTypes,
  bulkHiringProfiles,
  currentApp,
  exitQuestions,
};
