<template>
  <div class="report-footer">
    <span class="report-footer-powered">{{ $t('report.powered_by') }}</span>
    <img
      class="report-footer-logo pb-2 pl-2 pr-2"
      :src="logo"
      :alt="title"
      width="80"
    />
    <span class="report-footer-copy">&copy; {{ year }}</span>
  </div>
</template>

<script>
import franchise from '../conf/franchise';
export default {
  computed: {
    title() {
      return franchise.title;
    },
    logo() {
      return franchise.logo;
    },
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
