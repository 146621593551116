<template>
  <div class="report__exit report" id="printreport__fullview">
    <div class="print__container">
      <b-button
        @click="printReport()"
        v-b-tooltip.hover
        variant="primary"
        :title="$t('exit.report.print_report')"
        class="print__button"
      >
        <span class="mdi mdi-printer"></span>
      </b-button>
    </div>
    <div class="report__content">
      <AnalyticsCoverPage
        v-if="isAnalyticsReport"
        :campaign="campaign"
        :report="report"
        :companyLogoUrl="logo"
      />
      <LeavingPersonCoverPage
        v-else
        :campaign="campaign"
        :report="report"
        :companyLogoUrl="logo"
        :participants="participants"
      />

      <!-- header 2 page -->
      <section
        class="page-break-before-always page-break-inside-avoid page_campaign_summary new"
      >
        <div
          v-if="!isAnalyticsReport"
          class="row report__header pt-4 pl-3 pb-4"
        >
          <div class="col-10">
            <div class="report__header__title">
              <p>{{ $t('exit.report.interview_id') }}:</p>
              <p>{{ campaign.info.internal_id }}</p>
            </div>
            <div class="report__header__title">
              <p>{{ $t('exit.report.leaving_staff') }}:</p>
              <p>
                {{ campaign.admin.first_name }} {{ campaign.admin.last_name }}
              </p>
            </div>
            <div class="report__header__title">
              <p>{{ $t('exit.report.feedback') }}:</p>
              <p>{{ feedback }}</p>
            </div>
            <div class="report__header__title">
              <p>{{ $t('exit.report.staff_id') }}:</p>
              <p>{{ campaign.company_id }}</p>
            </div>
          </div>
          <div class="col">
            <figure>
              <img :src="logo" alt="logo" class="logo" />
            </figure>
          </div>
        </div>
        <div>
          <h1 class="strictly color_lava">
            - {{ $t('exit.report.strictly') }} -
          </h1>
        </div>

        <DetailsPage :report="this.report" />
      </section>

      <!-- strategies page -->
      <section
        class="page-break-before-always page-break-inside-avoid page_campaign_summary new"
        v-for="(strategy, idx) in cascadeStrategies"
        :key="strategy.attr + idx"
      >
        <div class="row" v-if="idx === 0">
          <div class="offset-3 col-6 text-center">
            <h5 class="strategies-title">
              {{ $t('exit.report.building_blocks') }}
            </h5>
          </div>
          <div class="offset-2 col-8 text-center">
            <img
              class="strategies-img"
              src="../../../../images/report/strategies.png"
              alt="front page"
            />
          </div>
        </div>
        <StrategyPage :strategy="strategy" />
      </section>
    </div>
    <ReportFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import logoPulse from '../../../../images/report/exit/pulse_color.svg';
import DetailsPage from './DetailsPage.vue';
import LeavingPersonCoverPage from './LeavingPersonCoverPage';
import AnalyticsCoverPage from './AnalyticsCoverPage';
import {
  EXIT_CAMPAIGN_TYPES,
  EXAMIZ_APPS,
  EXIT_MAX_CHARACTERS_STRATEGY,
} from '../../../common/constants';
import ReportFooter from '@/components/FooterPrint';
import StrategyPage from './StrategyPage.vue';
import ExitReportHelpersMixin from '@/common/ExitReportHelpersMixin';

export default {
  components: {
    DetailsPage,
    LeavingPersonCoverPage,
    AnalyticsCoverPage,
    ReportFooter,
    StrategyPage,
  },
  mixins: [ExitReportHelpersMixin],
  data() {
    return {
      queryStrategies: [],
    };
  },
  created() {
    document.body.classList.add('print__report');
    this.queryStrategies = this.$route.query.strategies.split(',');
    this.setTitle();
  },
  destroyed() {
    document.body.classList.remove('print__report');
  },
  computed: {
    ...mapGetters('exit', [
      'campaign',
      'report',
      'analyticsCampaignOptions',
      'strategies',
    ]),
    ...mapGetters('global', ['exitQuestions', 'companyLogoUrl']),
    logo() {
      return this.companyLogoUrl || logoPulse;
    },
    participants() {
      return this.campaign.exit_campaign_participants;
    },
    location() {
      return [this.campaign.info.city, this.campaign.info.country].join(',');
    },
    feedback() {
      const finishedSurveys =
        (this.campaign.exit_campaign_participants.length *
          this.campaign.progress) /
        100;
      return `${finishedSurveys} / ${this.campaign.exit_campaign_participants.length}`;
    },
    isAnalyticsReport() {
      return this.campaign.campaign_type === EXIT_CAMPAIGN_TYPES.ANALYTICS;
    },
    cascadeStrategies() {
      const strategies = [];
      for (let i = 0; i < this.queryStrategies.length; i++) {
        const strategy = this.queryStrategies[i];
        const sub = strategy.includes('sub');
        const strategyValue = this.strategies[strategy];
        const key =
          strategy.includes('main') || sub
            ? strategy.split('_').reverse()[0]
            : strategy.split('_').slice(2).join('');

        const mainKey = sub
          ? this.queryStrategies
              .filter((main) => main.includes('main') && main.includes(key[0]))
              .toString()
          : '';
        const level = this.problemLevel(strategy, mainKey);
        const lastIndex = strategyValue.lastIndexOf(
          '.',
          EXIT_MAX_CHARACTERS_STRATEGY
        );

        strategies.push({
          mainKey,
          level,
          attr: strategy,
          label:
            this.getKeyName(strategy) ||
            this.complianceRisksTranslations[strategy],
          value: strategyValue.slice(0, lastIndex + 1).replace(/\n\\n/g, '\n'),
        });

        if (strategyValue.length > EXIT_MAX_CHARACTERS_STRATEGY) {
          const char = String.fromCharCode(97 + i);
          strategies.push({
            mainKey,
            level,
            attr: strategy + char,
            value: strategyValue.slice(lastIndex + 1).replace(/\n\\n/g, '\n'),
          });
        }
      }
      return strategies.sort((a, b) => b.level - a.level);
    },
  },
  methods: {
    problemLevel(attr, mainKey) {
      if (mainKey) {
        return this.report.detailed_analytics[mainKey][attr];
      }
      if (attr.includes('main')) {
        return this.report.reasons_for_leaving[attr];
      }
      return this.report.compliance_risks[attr].value;
    },
    setTitle() {
      const datePrint = this.$moment().format('DD/MM/YYYY');
      document.title = `${EXAMIZ_APPS.EXIT.reportTitle} ${datePrint}`;
    },
    printReport() {
      window.print();
    },
  },
};
</script>
