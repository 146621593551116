<template>
  <div :key="key">
    <Spinner v-if="showSpinner" :options="spinnerOptions" />

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus, EventBusEvents } from '../event-bus';
import Spinner from './Spinner';
import { EXAMIZ_APPS } from '../common/constants';
import franchise from '../conf/franchise';

const ALLOWED_APPS = ['exit'];

export default {
  name: 'App',
  components: {
    Spinner,
  },
  data() {
    return {
      key: new Date().getTime(),
    };
  },
  computed: {
    ...mapGetters('global', [
      'isAuthenticated',
      'currentUserEmail',
      'isMatch',
      'showSpinner',
      'spinnerOptions',
    ]),
    loggedIn() {
      return this.$store.getters['global/isAuthenticated'];
    },
  },
  created() {
    this.setFavicon();
    this.setTitle();
    this.$store.dispatch('global/populateAttributes');
    this.$store.dispatch('global/populatePermanentAttributes');

    // Listen to the event.
    EventBus.$on(EventBusEvents.rerenderApp, this.setKey);
  },
  mounted() {
    this.hideAppLoader();

    setTimeout(() => {
      this.attachAbhisiScript();
      this.attahcUserback();
    }, 1000);
  },
  beforeDestroy() {
    EventBus.$off(EventBusEvents.rerenderApp, this.setKey);
  },
  methods: {
    setFavicon() {
      // Find the existing favicon element
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${location.origin}/${franchise.favicon}`;

      // Add or update the link to the favicon in the document head
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    setTitle() {
      const app = localStorage.getItem('app');

      switch (app) {
        case EXAMIZ_APPS.EXIT.type:
          return (document.title = EXAMIZ_APPS.EXIT.title);
        default:
          document.title = EXAMIZ_APPS.DEFAULT.title;
      }
    },
    setKey() {
      this.key = new Date().getTime();
    },
    attachAbhisiScript() {
      const abhisiScript = document.createElement('script');
      abhisiScript.id = 'HelpCFWidgetScript';
      abhisiScript.src =
        'https://my.abhisi.com:443/app/HelpWidget/HelpWidgetScript.js?connectionSettings=b6363f5f-ea44-6fd2-814f-f7ea3f9c92df@abhisimail.com&frontendUrl=https://my.abhisi.com:443&backendUrl=https://abhisibackend.azurewebsites.net/api/';
      document.head.appendChild(abhisiScript);
    },
    attahcUserback() {
      const Userback = window.Userback || {};
      Userback.access_token =
        '4691|6366|Tf1wRXXIvXEbC2mxIG48BNIHXMjal6NTfLAtzf32TjRzbDomzU';
      (function (id) {
        var s = document.createElement('script');
        s.async = 1;
        s.src = 'https://static.userback.io/widget/v1.js';
        var parent_node = document.head || document.body;
        parent_node.appendChild(s);
      })('userback-sdk');
    },
    hideAppLoader() {
      const loaderEl = document.getElementById('loader');
      loaderEl.parentNode.removeChild(loaderEl);
    },
  },
};
</script>
