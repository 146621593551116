<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name"
    v-slot="{ errors }"
    :vid="vid"
    mode="lazy"
  >
    <div class="form-group" :class="errors[0] ? 'form-group-error' : ''">
      <label>{{ label }}</label>
      <Multiselect
        v-model="currentValue"
        selectLabel=""
        deselectLabel=""
        :hideSelected="true"
        :options="options"
        label="text"
        track-by="value"
        :placeholder="placeholder"
      >
      </Multiselect>
      <span v-if="errors[0]" class="error-message">* {{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { Multiselect } from 'vue-multiselect';
import { BIconThreeDots } from 'bootstrap-vue';

export default {
  name: 'LabeledSelect',
  components: {
    ValidationProvider,
    Multiselect,
    BIconThreeDots,
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value || null;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>