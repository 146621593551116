import { CAMPAIGN_TYPES } from "./constants";

const DashboardHelperMixin = {
  computed: {


    tableFields() {
      return [
        {
          key: "select",
          label: "",
          sortable: false
        },
        {
          key: "state",
          label: this.$t("dashboard.status"),
        },
        {
          key: "internal_id",
          label: this.$t("general.id"),
          sortable: true
        },
        {
          key: "name",
          sortable: true,
          label: this.$t("general.name")
        },
        {
          key: "admin",
          label: this.$t("dashboard.recruiter_name")
        },
        {
          key: "start_date",
          sortable: true,
          label: this.$t("dashboard.start_date")
        },
        {
          key: "end_date",
          sortable: true,
          label: this.$t("dashboard.end_date")
        },
        {
          key: "progress",
          sortable: false,
          label: this.$t("dashboard.progress")
        },
        // {
        //   key: "responses",
        //   sortable: false,
        //   label: this.$t("general.responses")
        // },
        /*
        {
          key: "details",
          sortable: false,
          label: this.$t("dashboard.details")
        },
        */
        {
          key: "report",
          sortable: false,
          label: this.$t("dashboard.report")
        },
        {
          key: "edit",
          sortable: false,
          label: this.$t("dashboard.edit")
        },
      ];
    },
    searchFields() {
      return [
        {
          value: "internal_id'",
          text: this.$t("general.id")
        },
        {
          value: "name",
          text: this.$t("general.name")
        },
      ];
    },
    campaignTypes() {
      return [
        { text: this.$t('create_campaign.job_title'), value: CAMPAIGN_TYPES.JOB },
        { text: this.$t('create_campaign.team_title'), value: CAMPAIGN_TYPES.TEAM },
        { text: this.$t('create_campaign.selftest_title'), value: CAMPAIGN_TYPES.SELFTEST },
      ]
    },
    // TODO: Implement countries fetch on API
    countryFilter() {
      return available_countries.map((country) => ({
        text: country[0],
        value: country[1]
      }));
    },
  }
};

export default DashboardHelperMixin;
