<template>
  <test-layout>
    <test-card>
      <component
        v-if="question"
        :is="questionTemplate"
        :key="question.key"
        :question="question"
        :questions-count="questionsCount"
        :question-number="questionNumber"
        @answer="onAnswer"
      ></component>
      <div class="survey survey__content__block" v-if="false">
        <div class="survey__header">
          <h1 class="survey__scenario">{{ $t("match_references.you_did_great") }}</h1>
        </div>

        <p>{{ $t("match_references.final_questions") }}</p>

        <div class="survey__footer">
          <b-btn block class="min-w-200" variant="primary" @click="fewQuestions=false" size="lg">{{ $t("general.next") }}</b-btn>
        </div>
      </div>
    </test-card>
  </test-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { orderBy } from 'lodash';
import ApiSurvey from "../../api/survey";
import QuestionTemplateFirst from "./QuestionTemplateFirst.vue";
import QuestionTemplateSecond from "./QuestionTemplateSecond.vue";
import QuestionTemplateThird from "./QuestionTemplateThird.vue";

export default {
  components: {
    QuestionTemplateFirst,
    QuestionTemplateSecond,
    QuestionTemplateThird,
  },

  props: ["token"],

  data() {
    return {
      fewQuestions: false,
      inProgress: false,
      questions: [],
      questionsCount: 0,
      questionNumber: 0,
      questionIndex: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentUserToken", "currentLanguage"]),
    ...mapGetters({
      surveryCandidate: 'survey/candidate'
    }),
    candidate() {
      return {
        firstName: this.surveryCandidate.first_name,
        lastName: this.surveryCandidate.last_name,
        title: this.surveryCandidate.title
      }
    },
    fullName() {
      return [this.candidate.firstName, this.candidate.lastName].join(" ");
    },
    question() {
      return this.questions[this.questionIndex] || null;
    },
    questionTemplate() {
      if (!this.question) return null;

      const tempalates = {
        1: "QuestionTemplateFirst",
        2: "QuestionTemplateSecond",
        3: "QuestionTemplateThird",
      };

      return tempalates[this.question.type];
    },
  },
  watch: {
    currentLanguage() {
      this.fetchQuestions();
    },
  },
  mounted() {
    this.showTooltips();
    this.fetchQuestions();
  },
  methods: {
    onAnswer(data) {
      const answer = {
        type: this.question.type,
        data: data,
      };
      this.saveAnswer(answer).then((response) => {
        if (Number(response.data.not_answered) === 0) {
          this.finalizeSurvey();
        } else {
          this.nextQuestion();
          if (answer.type === 3) {
            this.questionNumber++;

            this.$nextTick(() => {
              if (this.questionNumber > this.questionsCount) this.fewQuestions = true;
            })
          }
        }
      });
    },
    fetchQuestions() {
      this.inProgress = true;
      this.questionIndex = 0;
      ApiSurvey.fetchSurveyQuestions({
        token: this.token,
        lang: this.currentLanguage
      }).then((resp) => {
        const questions = resp.data.questions;

        if (!questions.length) {
          this.questions = [];
          this.$router.push(`/reference/${this.token}/thanks`);
          return;
        }

        const firstType = {
          type: 1,
          key: "first",
          data: questions
            .filter((q) => q.answer_slider_type === "1")
            .map(this.formatQuestion),
        };

        const secondType = {
          type: 2,
          key: "second",
          data: questions
            .filter((q) => q.answer_slider_type === "2")
            .map(this.formatQuestion),
        };

        const thirdType = orderBy(
          questions
            .filter((q) => q.answer_slider_type === "3")
            .map((question) => ({
              type: 3,
              key: question.key,
              data: this.formatQuestion(question)
            })),
          'data.scenario_key'
          );

        this.questionsCount = resp.data.total_type_3_questions;
        this.questionNumber = this.questionsCount - thirdType.length + 1;

        this.questions = thirdType;

        if (firstType.data.length) {
          this.questions.push(firstType);
        }

        if (secondType.data.length) {
          this.questions.push(secondType);
        }

        this.inProgress = false;
      });
    },
    saveAnswer(answer) {
      return new Promise((resolve, reject) => {
        let request = null;

        if (answer.type === 3) {
          request = ApiSurvey.saveAnswer({
            token: this.$route.params.token,
            campaign_survey_response: answer.data,
          });
        } else if ([1, 2].indexOf(answer.type) >= 0) {
          request = ApiSurvey.saveBatchAnswers({
            token: this.$route.params.token,
            campaign_survey_response: { answers: answer.data },
          });
        }

        if (request) {
          request.then(resolve).catch((errors) => {
            const message = Object.values(errors.response.data.message)[0];
            reject(errors.response.data);
            this.$bvToast.toast(message, {
              autoHideDelay: 4000,
              variant: "warning",
              title: "Oops",
            });
          });
          return;
        }

        resolve();
      });
    },
    finalizeSurvey() {
      ApiSurvey.finalizeSurvey({ token: this.$route.params.token }).then(() => {
        this.$store.commit('survey/SHIFT_IN_PROGRESS_CANDIDATE');
        this.$router.push(`/reference/${this.token}/thanks`);
      });
    },
    nextQuestion() {
      this.questionIndex++;
    },
    showTooltips() {},
    formatQuestion(question) {
      return {
        ...question,
        scenario: this.applyTextVariabels(question.scenario),
        text: this.applyTextVariabels(question.text),
        answer_1_text: this.applyTextVariabels(question.answer_1_text),
        answer_2_text: this.applyTextVariabels(question.answer_2_text),
      };
    },
    applyTextVariabels(text) {
      if (typeof text !== "string") return text;

      return text
        .replace(/%title%/g, this.candidate.title)
        .replace(/%first name%/g, this.candidate.firstName)
        .replace(/%last name%/g, this.candidate.lastName)
        .replace(/%full name%/g, this.fullName);
    },
  },
};
</script>
