<template>
  <div class="coverpage__content">
    <div class="row">
      <div class="col">
        <div class="report__chapter">
          <figure>
            <img :src="campaignCoverIllustration" alt="Campaign Report" />
          </figure>

          <h1>{{ $t('report.campaign_results') }}</h1>

          <div class="col-print-6 col-md-6 col-lg-6 pl-0 pt-5">
            <div class="report__chapter">
              <div class="row">
                <div class="col">
                  <table class="table report__ems__table has__lines">
                    <thead>
                      <tr>
                        <th>{{ $t('report.campaign_statistics') }} </th>
                        <th class="text-right"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{ $t('report.candidates') }}</td>
                        <td class="text-right">{{ candidateCount }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('create_campaign.references')}}</td>
                        <td class="text-right">{{ candidateReferences }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('dashboard.start_date') }}</td>
                        <td class="text-right">{{ campaign.start_date }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('dashboard.end_date') }}</td>
                        <td class="text-right">{{ campaign.end_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="coverpage__attributes">
            <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">{{ $t('report.position') }}</div>
              <div class="coverpage__attribute__value">{{ campaign.job_title_short }}</div>
            </div>
            <!-- <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">Job ID</div>
              <div class="coverpage__attribute__value"></div>
            </div> -->
            <div class="coverpage__attribute__group">
              <div class="coverpage__attribute__label">{{ $t('report.campaign_id') }}</div>
              <div class="coverpage__attribute__value">{{ campaign.campaign_id.toUpperCase() }}</div>
            </div>
          </div>
          <!-- coverpage__attributes -->
        </div>
        <!-- report__chapter -->
      </div>
    </div>

    <div class="campaign__statistics">
      <div class="row">
        <div class="col-md-12 col-lg-12 pt-5 pb-5">
          <div class="toggle">
            <h2 class="report__section__title toggle__trigger">
              <span class="toggle__trigger__text">{{ $t('report.job_requirements') }}</span>
            </h2>
          </div>
        </div>

        <div class="col-4 pb-5 mt-2">
          <div class="bott_gray">
            <table class="table report__ems__table has__lines ">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/expeience_jobs_2.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('applicant_portal.job_area') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in jobSpecializations" :key="key">
                  <td>{{ item.text }}{{ item.required ? '*' : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-4 pb-5 mt-2">
          <div class="bott_gray">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/experience_job_groups.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('applicant_portal.area_of_expertise') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in jobGroups" :key="key">
                  <td>{{ item.text }}{{ item.required ? '*' : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bott_gray mt-2">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th colspan="2"><img src="../../../../images/auth/experience_years_level.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('report.professional_profile') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{{ $t('create_campaign.job_complexity') }}</td>
                  <td style="text-transform: capitalize;">{{ jobComplexityTitle || '-'}}</td>
                </tr>
                <tr>
                  <td>{{ $t('applicant_portal.years_of_experiences') }}</td>
                  <td>{{ yearsOfExperiences }}</td>
                </tr>
                <tr>
                  <td>{{ $t('applicant_portal.international_experience') }}</td>
                  <td>{{ campaign.ap_data.international_experience ? $t('seeker_account.yes') : $t('seeker_account.no')}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div class="col-4 pb-5 mt-2">
          <div class="bott_gray">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/additional_information.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('applicant_portal.certificates') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in campaign.ap_data.certificates" :key="key">
                  <td>{{ item.text }}{{ item.required ? '*' : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->

        <div class="col-4 pb-5 mt-2">
          <div class="bott_gray">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/certificates_knowledge.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('seeker_account.highest_education') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in campaign.ap_data.education_levels" :key="key">
                  <td>{{ item.text }}{{ item.required ? '*' : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bott_gray mt-2">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/languages.svg" width="20" height="20" alt="educations" style="margin-right: 5px">{{ $t('general.languages') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in campaign.ap_data.languages" :key="key">
                  <td >
                    {{ item.text }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bott_gray mt-2">
            <table class="table report__ems__table has__lines">
              <thead>
                <tr>
                  <th><img src="../../../../images/auth/education.svg" width="20" height="20" alt="educations" style="margin-right: 7px">{{ $t('applicant_portal.seniority_level') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, key) in campaign.ap_data.seniority_levels" :key="key">
                  <td>{{ item.text }}{{ item.required ? '*' : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div class="col-4 pb-5">
                <table class="table report__ems__table has__lines">
                  <thead>
                    <tr><th>Job/Task Complexity</th></tr>
                  </thead>

                  <tbody>
                    <tr><td>Medium</td></tr>
                  </tbody>
                </table>
              </div>


              <div class="col-4 pb-5">
                <table class="table report__ems__table has__lines">
                  <thead>
                    <tr><th>Intern. experience</th></tr>
                  </thead>

                  <tbody>
                    <tr><td>Yes</td></tr>
                  </tbody>
                </table>
              </div> -->
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="toggle">
            <h2 class="report__section__title toggle__trigger">
              <span class="toggle__trigger__text"
                >{{ $t('report.job_specific_and_personality_profile') }}</span
              >
            </h2>
          </div>
        </div>

        <!-- report__chapter job__competencies__table -->
        <div class="col-print-6 col-md-6 col-lg-6">
          <print-skills-competencies class="job__competencies__table" :hideTitle="true" :attributes="attributes"></print-skills-competencies>
        </div>

        <div class="col-print-6 col-md-6 col-lg-6">
          <div class="report__chapter">
            <div class="row">
              <div class="col">
                <h3 class="report__chapter__title">
                  <!-- <span>Campaign Details</span> -->
                </h3>
                <table class="table report__ems__table has__lines">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <!-- Campaign statistics -->
                      <th class="text-right"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-if="campaign.info.location">
                      <td>{{ $t('report.location') }}</td>
                      <td class="text-right">{{ campaign.info.location }}</td>
                    </tr>
                    <tr v-if="campaign.info.job_zip">
                      <td>{{ $t('auth_pages.zip_code') }}</td>
                      <td class="text-right">{{ campaign.info.job_zip }}</td>
                    </tr>
                    <tr v-if="campaign.info.job_arrangement_type">
                      <td>{{ $t('report.job_arrangement') }}</td>
                      <td class="text-right">{{ getJobArrangementType }}</td>
                    </tr>
                    <tr v-if="campaign.info.job_arrangement_period">
                      <td>{{ $t('report.lenght') }}</td>
                      <td class="text-right">{{ getJobArrangementPeriod }}</td>
                    </tr>
                    <!-- <tr v-if="campaign.info.job_complexity">
                      <td>Task Complexity</td>
                      <td class="text-right" style="text-transform: capitalize;">{{ jobComplexityTitle }}</td>
                    </tr> -->
                    <tr v-if="campaign.info.remuneration_range_type !== 'no_range'">
                      <td>{{ $t('create_campaign.remuneration_range') }}</td>
                      <td class="text-right">
                        <span v-if="campaign.info.remuneration_range_min">{{ campaign.info.remuneration_range_min }}&nbsp;</span>
                        <span v-if="campaign.info.remuneration_range_min && campaign.info.remuneration_range_max">&nbsp;-&nbsp;</span>
                        <span v-if="campaign.info.remuneration_range_max">{{ campaign.info.remuneration_range_max }}&nbsp;</span>
                        <span v-if="campaign.info.remuneration_range_currency">{{ campaign.info.remuneration_range_currency }}&nbsp;</span>
                        <span v-if="campaign.info.remuneration_range_type">{{ $t(`job_range_type.${campaign.info.remuneration_range_type}`) }}</span>
                      </td>
                    </tr>
                    <tr v-if="campaign.info.job_start_date">
                      <td>{{ $t('create_campaign.job_start_date') }}</td>
                      <td class="text-right">{{ campaign.info.job_start_date }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-print-12 col-md-12 col-lg-6">
          <h3 class="report__chapter__title"><span>{{ $t('report.note') }}</span></h3>
          <p class="campaign__results__info">
            {{ $t('report.campaign_results_info') }}
          </p>
        </div>
      </div>
    </div>
    <!-- campaign__statistics -->
  </div>
</template>

<script>
import CampaignCoverIllustration from '../../../../images/report/print/report_new.svg';
import SkillsCompetencies from '@/views/Report/Print/SkillsCompetencies';
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';

export default {
  mixins: [CampaignHelpersMixin],
  components: {
    PrintSkillsCompetencies: SkillsCompetencies,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {},
    },
    attributes: {
      type: Array,
      required: true,
      default: () => [],
    },
    candidateCount: {
      type: Number,
      required: true,
      default: 0,
    },
    candidateReferences: {
      type: Number,
      required: true,
      default: 0,
    },
    campaignDates: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    jobSpecializations(){
      return this.campaign.ap_data.job_specializations.slice(0, 7)
    },
    jobGroups(){
      return this.campaign.ap_data.job_groups.slice(0, 7)
    },
    campaignCoverIllustration() {
      return CampaignCoverIllustration;
    },
    jobComplexityTitle(){
      return this.jobComplexity.find(i => i.value === this.campaign.info.job_complexity).text
    },
    getJobArrangementType() {
      let arrangement = ''
      switch (this.campaign.info.job_arrangement_type) {
        case "FULL_TIME":
          arrangement = 'job_arrangement_types.full_time';
          break;
        case "PART_TIME":
          arrangement = 'job_arrangement_types.part_time';
          break;
        case "TEMPORARY":
          arrangement = 'job_arrangement_types.temporary';
          break;
        case "PROJECT":
          arrangement = 'job_arrangement_types.project';
          break;
      }
      return this.$t(arrangement);
    },
    getJobArrangementPeriod(){
      let period = ''
      switch (this.campaign.info.job_arrangement_period) {
        case "lt3":
          period = `<3 ${this.$t('general.months')}`;
          break;
        case "btw3-6":
          period = `3-6 ${this.$t('general.months')}`;
          break;
        case "btw6-12":
          period = `6-12 ${this.$t('general.months')}`;
          break;
        case "gt12":
          period = `>12 ${this.$t('general.months')}`;
          break;
      }
      return period
    },
    yearsOfExperiences() {
      if (!this.campaign.ap_data.years_of_experiences.length) return '-';
      return this.campaign.ap_data.years_of_experiences[this.campaign.ap_data.years_of_experiences.length - 1].text;
    }
  }
};
</script>
