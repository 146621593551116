
import {
  JOB_LEVEL,
  PERFORMANCE_LEVEL,
 } from "./constants";
const ExitCampaignHelper = {
  computed: {

    jobLevelExit() {
      return [
        { text: this.$t(`exit.job_level.${JOB_LEVEL.DIRECTOR}`), value: JOB_LEVEL.DIRECTOR },
        { text: this.$t(`exit.job_level.${JOB_LEVEL.MANAGEMENT}`), value: JOB_LEVEL.MANAGEMENT },
        { text: this.$t(`exit.job_level.${JOB_LEVEL.TEAM_LEADER}`), value: JOB_LEVEL.TEAM_LEADER },
        { text: this.$t(`exit.job_level.${JOB_LEVEL.PROFESSIONAL}`), value: JOB_LEVEL.PROFESSIONAL },
        { text: this.$t(`exit.job_level.${JOB_LEVEL.JUNIOR}`), value: JOB_LEVEL.JUNIOR },
        { text: this.$t(`exit.job_level.${JOB_LEVEL.ENTRY}`), value: JOB_LEVEL.ENTRY },
      ]
    },

    performanceLevel() {
      return [
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.TOP_1}`),
          value: PERFORMANCE_LEVEL.TOP_1 },
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.TOP_10}`),
          value: PERFORMANCE_LEVEL.TOP_10 },
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.VERY_GOOD}`),
          value: PERFORMANCE_LEVEL.VERY_GOOD },
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.GOOD}`),
          value: PERFORMANCE_LEVEL.GOOD },
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.AVERAGE}`),
          value: PERFORMANCE_LEVEL.AVERAGE },
        { text: this.$t(`exit.performance_level.${PERFORMANCE_LEVEL.BELOW_AVERAGE}`),
          value: PERFORMANCE_LEVEL.BELOW_AVERAGE },
      ]
    },
    languages(){
      return [
        { text: 'EN', value: 'en' },
        { text: 'DE', value: 'de' },
      ]
    },
  },
};

export default ExitCampaignHelper;
