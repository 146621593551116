<template>
  <div class="auth-page app__talents">
    <app-header></app-header>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../../components/Exit/Header.vue';
import AppFooter from "../../components/Footer";

export default {
  components: {
    AppHeader,
    AppFooter
  }
};
</script>
