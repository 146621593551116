import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { EXIT_CAMPAIGN_STATE } from '@/common/constants'


const state = {
  campaigns: [],
  campaignsMeta: {},
  exitCampaign: {
    id: "",
    last_date: "",
    start_date: "",
    end_date: "",
    time_zone: null,
    info: {},
    admin: {},
    team_leader: {},
    leaving_person: {},
    keywords: [],
    departments: [],
    status: EXIT_CAMPAIGN_STATE.OPEN
  },
  teamMembers: [],
  questions: [],
  leavingPerson: [],
  participantId: null,
  questionsCount: {
    totalQuestions: 0,
    totalUnansweredQuestions: 0
  },
  report: null,
  analyticsCampaignOptions: {},
  strategies: {},
  queryStrategies: []
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
