<template>
  <b-table :items="items" :fields="fields" hover responsive>
    <template v-slot:cell(job_title)="data">
      <div>{{ data.item.info.job_title }}</div>
    </template>
    <template v-slot:cell(is_survey_finished)="data">
      <b-icon
        v-if="data.item.is_survey_finished"
        icon="check-square"
        variant="primary"
        font-scale="1"
      ></b-icon>
    </template>
    <template v-slot:cell(gender)="data">
      {{ getTitle(data.item.gender) }}
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    items: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      test: true,
      fields: [
        {
          key: 'gender',
          label: this.$t('general.title'),
        },
        {
          key: 'name',
          label: this.$t('auth_pages.full_name'),
        },
        {
          key: 'email',
          label: this.$t('general.primary_email'),
        },
        {
          key: 'job_title',
          label: this.$t('dashboard.job_title'),
        },
        {
          key: 'is_survey_finished',
          label: this.$t('exit.done'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('global', ['titles']),
  },
  methods: {
    getTitle(gender) {
      const title = this.titles.find((t) => t.value === gender);
      if (title) return title.text;
      return '';
    },
  },
};
</script>
