<template>
  <div class="survey__header">
    <h3>{{ $t("match_references.final_questions_title", { x: "1", y: "2" }) }}</h3>
    <h5 class="mb-4">{{ scenario }}</h5>

    <test-slider-list
      :questions="question.data"
      :tooltips="rangeTips"
    ></test-slider-list>

    <div class="survey__footer">
      <b-btn
        :disabled="isNextDisabled || disableButton"
        class="btn-lg"
        variant="primary"
        @click="next"
      >
        <b-spinner v-if="isNextDisabled"></b-spinner>
        {{ $t("general.next") }}
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionsCount: {
      type: Number,
      default: 0,
    },
    questionNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isNextDisabled: false,
    };
  },
  computed: {
    scenario() {
      return this.question.data[0].scenario;
    },
    rangeTips() {
      return [
        {
          from: 90,
          label: this.$t("match_references.situation_volume_slider_tooltip"),
        },
      ];
    },
    disableButton() {
      return !!this.question.data.find((q) => q.value === 0);
    },
  },
  methods: {
    next() {
      this.isNextDisabled = true;
      this.$emit(
        "answer",
        this.question.data.map((item) => ({
          question_key: item.key,
          attribute_key: item.attribute_2_id,
          value: item.value,
        }))
      );
    },
  },
};
</script>
