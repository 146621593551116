<template lang="pug">
.volume-bar(ref="bar")
  svg(
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    :viewBox="viewBox"
    preserveAspectRatio="none"
  )
    template(v-if="type === 'bars'")
      clipPath(:id="`bar-${id}`")
        path(:d="`M${progressWidth},0V${height}H0V-${progressWidth}Z`") 
      rect(
        v-for="bar in bars" 
        :x="bar.x" 
        :y="bar.y" 
        :width="bar.width" 
        :height="bar.height + smoothing" 
        :rx="smoothing" 
        :ry="smoothing"
        :fill="backplateColor"
      )
      g(:clip-path="`url(#bar-${id})`")
        rect(
          v-for="bar in bars" 
          :x="bar.x" 
          :y="bar.y" 
          :width="bar.width" 
          :height="bar.height + smoothing" 
          :rx="smoothing" 
          :ry="smoothing"
          :fill="primaryColor"
        )
     

    template(v-else)
      clipPath(:id="`bar-${id}`")
        path(:d="`M${width},0V${height}H0Z`") 
      g(:clip-path="`url(#bar-${id})`")
        rect(x="0" y="0" :width="width" :height="height" :fill="backplateColor")
        rect(
          class="bar"
          x="0"
          y="0"
          :width="progressWidth"
          :height="height"
          :fill="primaryColor"
        )
        rect(
          v-for="bar in bars"
          :x="bar.x + bar.width"
          :width="spacingWidth"
          :height="height"
          fill="#fff"
        )
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "linear"
    },
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: -100
    },
    max: {
      type: Number,
      default: 100
    },
    backplateColor: {
      type: String,
      default: "#EBEBEB"
    },
    primaryColor: {
      type: String,
      default: "#425F96"
    },
    height: {
      type: Number,
      default: 27
    },
    delimiters: {
      type: Number,
      default: 10
    },
    delimitersSize: {
      type: Number,
      default: 6
    },
    smoothing: {
      type: Number,
      default: 6
    }
  },

  data() {
    return {
      id: "",
      timer: null,
      width: 0
    };
  },

  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },

    barsCount() {
      return 10;
    },

    spacingWidth() {
      return this.delimitersSize;
    },

    barWidth() {
      let w = (
        (this.width - this.spacingWidth * (this.barsCount - 1)) / this.barsCount
      );
      if (w < 0) {
        w = 0;
      }
      return w;
    },

    bars() {
      const bars = [];

      for (let index = 0; index < this.barsCount; index++) {
        const width = this.barWidth;
        const height = ((index + 1) / this.barsCount) * this.height;

        bars.push({
          x: (width + this.spacingWidth) * index,
          y: this.height - height,
          width,
          height
        });
      }

      return bars;
    },

    fixedValue() {
      if (this.value > this.max) return this.max;
      if (this.value < this.min) return this.min;
      return this.value;
    },

    barPercentage() {
      return (this.fixedValue / this.max) * 100;
    },

    progressWidth() {
      return (this.barPercentage / 100) * this.width;
    },

    delimeters() {
      return this.max - 1;
    }
  },

  mounted() {
    this.updateDimenshions();
    this.setTimer();

    this.id = this._uid;
  },

  beforeDestroy() {
    this.destroyTimer();
  },

  methods: {
    destroyTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    setTimer() {
      this.destroyTimer();

      this.timer = setInterval(() => {
        this.updateDimenshions();
      }, 1000);
    },

    updateDimenshions() {
      this.width = this.$refs.bar.clientWidth;
    }
  }
};
</script>

<style scoped>
.volume-bar svg {
  width: 100%;
}

.volume-bar .bar {
  transition: all 100ms ease-in-out;
}
</style>
