const jobSeeker = state => state.jobSeeker;
const workItems = state => state.workItems;
const jobTypes = state => state.jobTypes;
const displayExperienceModal = state => state.displayExperienceModal;
const runTest = state => state.runTest;
const form = state => state.form;

export default {
  jobSeeker,
  workItems,
  jobTypes,
  displayExperienceModal,
  runTest,
  form,
};
