<template>
  <ValidationObserver ref="form" tag="div">
    <b-form class="create-campaign-wrp mt-3">
      <b-row>
        <b-col xl="4" lg="4" md="4">
          <!-- essentials ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../images/campaign_icons/resume.svg"
              class="mr-3 mb-3"
              alt="Resume"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.essentials')
            }}</span>
          </div>

          <app-text-input
            :readonly="isActivated"
            :name="$t('create_campaign.internal_job_id')"
            :label="$t('create_campaign.internal_job_id')"
            vid="essentials_job_id"
            type="text"
            v-model="form.info.internal_id"
          ></app-text-input>

          <app-text-input
            :readonly="isActivated"
            :name="$t('exit.create_survey.title')"
            :label="$t('exit.create_survey.title')"
            vid="essentials_title"
            :rules="{
              required: true,
              customRule: {
                isValid: isNameUnique,
                message: $t('validation.title'),
              },
            }"
            type="text"
            v-model="form.name"
            @input="$emit('onNameUniquesChange')"
          ></app-text-input>

          <b-form-group :label="$t('exit.create_survey.keywords')">
            <app-multiselect
              v-model="form.info.keywords"
              :options="keywords_options"
              :taggable="true"
              :searchable="true"
              @add="addTag"
            ></app-multiselect>
          </b-form-group>

          <!-- department business ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../images/campaign_icons/organization.svg"
              class="mr-3 mb-2 mt-2"
              alt="Organization"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.department_business')
            }}</span>
          </div>

          <app-text-input
            :readonly="isActivated"
            :name="$t('exit.create_survey.department_id')"
            :label="$t('exit.create_survey.department_id')"
            vid="department_id"
            type="text"
            v-model="form.info.department_id"
          ></app-text-input>

          <b-form-group :label="$t('exit.create_survey.department')">
            <app-multiselect
              v-model="form.info.departments"
              :options="keywords_departments"
              :taggable="true"
              :searchable="true"
              @add="addDepartments"
            ></app-multiselect>
          </b-form-group>

          <app-text-input
            :readonly="isActivated"
            :name="$t('exit.create_survey.unit_id')"
            :label="$t('exit.create_survey.unit_id')"
            vid="department_unit_id"
            type="text"
            v-model="form.info.unit_id"
          ></app-text-input>

          <app-text-input
            :readonly="isActivated"
            :name="$t('exit.create_survey.unit')"
            :label="$t('exit.create_survey.unit')"
            vid="department_unit"
            type="text"
            v-model="form.info.unit"
          ></app-text-input>

          <ValidationProvider
            vid="competencies"
            rules="required"
            tag="div"
            v-slot="{ errors }"
          >
            <b-form-group :label="$t('exit.create_survey.location')">
              <multiselect
                label="text"
                v-model="form.info.location_country"
                :options="availableCountries"
                :placeholder="$t('multiselect_lib.select_option')"
                :disabled="isFinalized"
              ></multiselect>

              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>

          <app-text-input
            :readonly="isActivated"
            :name="$t('general.city')"
            :label="$t('general.city')"
            vid="department_location2"
            type="text"
            v-model="form.info.location_city"
          ></app-text-input>
        </b-col>

        <b-col xl="4" lg="4" md="4">
          <!-- interview admin ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../images/campaign_icons/schedule.svg"
              class="mr-3 mb-3"
              alt="Schedule"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.interview_admin')
            }}</span>
          </div>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('general.first_name')"
            :label="$t('general.first_name')"
            vid="interview_admin_first_name"
            type="text"
            v-model="form.admin.first_name"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('general.last_name')"
            :label="$t('general.last_name')"
            vid="interview_admin_last_name"
            type="text"
            v-model="form.admin.last_name"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('general.email')"
            :label="$t('general.email')"
            vid="interview_admin_email"
            type="email"
            rules="email"
            v-model="form.admin.email"
          ></app-text-input>

          <!-- team leader ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../images/campaign_icons/stakeholders.svg"
              class="mr-3 mb-2 mt-2"
              alt="Stakeholders"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.team_leader')
            }}</span>
          </div>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('general.title')"
            :label="$t('general.title') + '*'"
            :options="clientTitles"
            v-model="form.team_leader.gender"
            vid="team_leader_title"
            rules="required"
          ></app-select-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('auth_pages.full_name')"
            :label="$t('auth_pages.full_name') + '*'"
            vid="team_leader_first_name"
            rules="required"
            type="text"
            v-model="form.team_leader.name"
          ></app-text-input>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('create_campaign.role')"
            :label="$t('create_campaign.role') + '*'"
            :options="clientContactRoles"
            v-model="form.team_leader.info.job_role"
            rules="required"
            vid="team_leader_role"
          ></app-select-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.stuff_number')"
            :label="$t('exit.create_survey.stuff_number') + '*'"
            vid="team_leader_stuff_number"
            type="text"
            rules="required"
            v-model="form.team_leader.info.stuff_number"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('general.email')"
            :label="$t('general.email') + '*'"
            vid="team_leader_email"
            type="email"
            rules="required|email"
            v-model="form.team_leader.email"
          ></app-text-input>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('general.languages')"
            :label="$t('general.languages')"
            :options="languages"
            v-model="form.team_leader.lang"
            vid="team_leader_lang"
          ></app-select-input>
        </b-col>

        <b-col xl="4" lg="4" md="4">
          <!-- leaving person ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../images/campaign_icons/exit.svg"
              class="mr-3 mb-3"
              alt="Exit"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.leaving_person')
            }}</span>
          </div>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('general.title')"
            :label="$t('general.title') + '*'"
            :options="clientTitles"
            rules="required"
            v-model="form.leaving_person.gender"
            vid="leaving_person_title"
          ></app-select-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('auth_pages.full_name')"
            :label="$t('auth_pages.full_name') + '*'"
            vid="leaving_person_first_name"
            rules="required"
            type="text"
            v-model="form.leaving_person.name"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('general.email')"
            :label="$t('general.email') + '*'"
            vid="leaving_person_email"
            rules="required|email"
            type="email"
            v-model="form.leaving_person.email"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.age')"
            :label="$t('exit.create_survey.age') + '*'"
            vid="leaving_person_age"
            rules="required"
            type="number"
            v-model="form.leaving_person.info.age"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.stuff_id')"
            :label="$t('exit.create_survey.stuff_id')"
            vid="leaving_person_stuff_id"
            type="text"
            v-model="form.leaving_person.info.stuff_id"
          ></app-text-input>

          <app-text-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.job_title')"
            :label="$t('exit.create_survey.job_title') + '*'"
            vid="leaving_person_job_title"
            rules="required"
            type="text"
            v-model="form.leaving_person.info.job_title"
          ></app-text-input>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.job_level')"
            :label="$t('exit.create_survey.job_level') + '*'"
            :options="jobLevelExit"
            rules="required"
            v-model="form.info.job_level"
            vid="leaving_person_job_level"
          ></app-select-input>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('exit.create_survey.performance_level')"
            :label="$t('exit.create_survey.performance_level') + '*'"
            :options="performanceLevel"
            rules="required"
            v-model="form.info.performance_level"
            vid="leaving_person_performance"
          ></app-select-input>

          <ValidationProvider
            :name="$t('exit.create_survey.last_day')"
            class="datepicker-wrp"
            tag="div"
            rules="required"
          >
            <b-form-group
              slot-scope="{ errors }"
              :label="$t('exit.create_survey.last_day') + '*'"
            >
              <b-form-datepicker
                :disabled="isFinalized"
                v-model="form.info.last_day"
                :date-format-options="{
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }"
                size="sm"
              ></b-form-datepicker>
              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>

          <app-select-input
            :readonly="isFinalized"
            :name="$t('general.languages')"
            :label="$t('general.languages')"
            :options="languages"
            v-model="form.leaving_person.lang"
            vid="leaving_person_lang"
          ></app-select-input>
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from '../../../components/CustomMultiselect';
import AppTooltip from '../../../components/Tooltip';
import AppTextInput from '../../../components/TextInput';
import AppSelectInput from '../../../components/SelectInput';
import CampaignHelpersMixin from '../../../common/CampaignHelpersMixin';
import ExitCampaignHelper from '../../../common/ExitCampaignHelper';
import { EXIT_CAMPAIGN_STATE, EXIT_CAMPAIGN_ROLE } from '@/common/constants';
import { mapGetters } from 'vuex';
export default {
  components: {
    AppMultiselect,
    AppTooltip,
    AppTextInput,
    AppSelectInput,
    ExitCampaignHelper,
  },
  mixins: [CampaignHelpersMixin, ExitCampaignHelper],
  props: {
    isNameUnique: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      keywords_options: [],
      keywords_departments: [],
      form: {
        admin: {},
        leaving_person: {
          info: {},
        },
        team_leader: {
          info: {},
        },
        info: {
          keywords: [],
          departments: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters('global', ['availableCountries']),
    ...mapGetters('exit', ['campaign']),
    isActivated() {
      return (
        this.campaign.status !== EXIT_CAMPAIGN_STATE.OPEN &&
        this.campaign.status !== undefined
      );
    },
    isFinalized() {
      return this.campaign.status === EXIT_CAMPAIGN_STATE.FINALIZED;
    },
  },
  created() {
    if (this.campaign.id) {
      this.form = {
        ...this.form,
        ...this.campaign,
        info: {
          ...this.campaign.info,
          keywords: (this.campaign.info.keywords || []).map((name) => ({
            name,
          })),
          departments: (this.campaign.info.departments || []).map((name) => ({
            name,
          })),
          location_country: {
            text: this.availableCountries.find(
              (c) => c.value === this.campaign.info.location_country
            ).text,
            value: this.campaign.info.location_country,
          },
        },
      };
      if (this.campaign.exit_campaign_participants.length) {
        this.form = {
          ...this.form,
          leaving_person: {
            ...this.campaign.exit_campaign_participants.find(
              (i) => i.role === EXIT_CAMPAIGN_ROLE.LEAVING_PERSON
            ),
          },
          team_leader: {
            ...this.campaign.exit_campaign_participants.find(
              (i) => i.role === EXIT_CAMPAIGN_ROLE.TEAM_LEAD
            ),
          },
        };
      }
    }
  },
  methods: {
    addTag(item) {
      this.keywords_options.push(item);
      this.form.info.keywords.push(item);
    },
    addDepartments(item) {
      this.keywords_departments.push(item);
      this.form.info.departments.push(item);
    },
    validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>
