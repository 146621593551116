<template lang="pug">
.tip
  .tip-label
    slot(name="label")
      | {{ tipLabel }}
  .tip-content
    slot
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: ""
    }
  },

  computed: {
    tipLabel() {
      return this.label || this.$t("general.tip_lable");
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/_variables.scss";

.tip {
  display: flex;
  flex-wrap: nowrap;
  padding: 1.15rem 1.65rem;
  border-radius: 6px;
  background: $gray-blue-200;

  & > .tip-label {
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: bold;
  }

  & > .tip-content {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 0.5rem;
  }
}
</style>
