<template>
  <div>
    <p>
      {{ $t('exit.create_survey.leaving_person') }}
      <strong>{{ leavingPerson.name }}</strong>
      <br />
      {{ $t('create_campaign.job_title_short') }}
      <strong>{{ campaign.name }}</strong>
    </p>
    <p class="text-muted py-2">{{ $t('match_candidate.please_check_text') }}</p>
    <b-table
      class="table-lite table-embed my-5"
      :items="form.team_members"
      :fields="tableFields"
    >
      <template v-slot:cell(gender)="data">
        <div>{{ title(data.value) }}</div>
      </template>
      <template v-slot:cell(email)="data">
        <div>{{ data.value }}</div>
      </template>
      <template v-slot:cell(role)="data">
        <div>{{ $t(`exit.role.${data.value}`) }}</div>
      </template>
    </b-table>

    <div class="text-right">
      <b-btn
        class="min-w-140 mr-3"
        variant="outline-primary"
        @click="prev"
        size="xl"
        >{{ $t('general.back') }}</b-btn
      >
      <b-btn class="min-w-140" variant="primary" @click="done" size="xl">{{
        $t('general.next')
      }}</b-btn>
    </div>
  </div>
</template>

<script>
import ExitAddTeamMembersHelpersMixin from '../../../../common/ExitAddTeamMembersHelpersMixin';

export default {
  mixins: [ExitAddTeamMembersHelpersMixin],
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {},
    },
    leavingPerson: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    done() {
      this.$emit('done');
    },
    title(gender) {
      return this.titles.find(i => i.value === gender).text;
    },
  },
};
</script>
