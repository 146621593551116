<template>
  <b-table :items="items" :fields="fields" hover responsive>
    <template v-slot:cell(email)="data">
      <div>{{ data.value[0] }}</div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true,
      default: () => []
    },
    fields: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
