import { merge } from 'lodash';
import i18n from '../../../i18n'

const SET_JOB_SEEKER = (state, jobSeeker) => {
  state.jobSeeker = jobSeeker;
};

const SET_WORK_ITEMS = (state, workItems = []) => {
  workItems.forEach((workItem) => {
    if (!workItem.data.keywords) workItem.data.keywords = [];
    workItem.data.keywords = workItem.data.keywords.map((name) => ({
      name,
    }));
  });
  state.workItems = workItems;
}

const SET_JOB_TYPES = (state, jobTypes = []) => {
  state.jobTypes = jobTypes.map((jobType) => ({
    text: i18n.t(`job_types.${jobType.key}`),
    value: jobType.id
  }))
}

const SET_DISPLAY_EXPERIENCE_MODAL = (state, value) => {
  state.displayExperienceModal = value;
}

const TOGGLE_DISPLAY_EXPERIENCE_MODAL = (state) => {
  state.displayExperienceModal = !state.displayExperienceModal;
}

const SET_FORM = (state, form = {}) => {
  state.form = form
}

const ASSIGN_FORM_LOCATION = (state, location) => {
  state.form.location = location;
}

const ASSIGN_FORM = (state, object = {}) => {
  delete object.location

  state.form = merge(state.form, object);

  if (object.preferred_countries) {
    state.form.preferred_countries = object.preferred_countries;
  }

  if (object.job_types) {
    state.form.job_types = object.job_types;
  }
}

const RESET_FORM = (state) => {
  state.form = {};
}

export default {
  SET_JOB_SEEKER,
  SET_WORK_ITEMS,
  SET_JOB_TYPES,
  SET_DISPLAY_EXPERIENCE_MODAL,
  TOGGLE_DISPLAY_EXPERIENCE_MODAL,
  SET_FORM,
  ASSIGN_FORM,
  ASSIGN_FORM_LOCATION,
  RESET_FORM,
};
