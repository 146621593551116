<template>
  <div class="pt-3">
    <b-row class="mb-5">
      <b-col lg="5">
        <div class="h3"></div>
        <div class="summary-item-wrp">
          <div
            class="summary-item"
            v-for="(item, index) in jobSeekerExperienceArray"
            :key="index"
          >
            <span>{{ item.key }}</span
            ><strong class="ml-3">{{ item.title }}</strong>
          </div>
        </div>
      </b-col>
      <b-col lg="7">
        <div class="summary-right text-center word-break-all">
          <div class="h4 mb-4">{{ $t("seeker_account.documents") }}</div>
          <div class="px-2">
            <b-form-row v-if="documents.length > 0">
              <b-col v-if="resumeDocument" cols="4"
                >
                <a :href="documentDownloadLink(resumeDocument)" :download="resumeDocument.filename">
                  <img
                    :src="icon"
                    alt="CV/Resume"
                    width="80%"
                    v-b-tooltip
                    :title="resumeDocument.filename"
                  />
                </a>
                <p>{{ resumeDocument.type.text }}</p>
              </b-col>
              <b-col v-if="recommendationDocument" cols="4"
                >
                <a :href="documentDownloadLink(recommendationDocument)" :download="recommendationDocument.filename">
                  <img
                    :src="icon"
                    alt="Recommendation letter"
                    width="80%"
                    v-b-tooltip
                    :title="recommendationDocument.filename"
                  />
                </a>                
                <p>{{ recommendationDocument.type.text }}</p>
              </b-col>
              <b-col v-if="otherDocs" cols="4">
                <b-form-row>
                  <b-col
                    v-for="(otherDoc, index) in otherDocs"
                    :key="index"
                    cols="6"
                    >
                    <a :href="documentDownloadLink(otherDoc)" :download="otherDoc.filename">
                      <img
                        :src="icon"
                        alt="Other"
                        width="80%"
                        v-b-tooltip
                        :title="otherDoc.filename"
                      />
                    </a>
                    <p>{{ otherDoc.type && otherDoc.type.text }}</p>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
            <div class="h6 text-center mt-3" v-else>
              {{ $t("seeker_account.no_documents") }}
            </div>
          </div>
        </div>
        <div class="mt-4">
          <b-btn
            class="xxl"
            v-if="currentUserSelftestSurveyPassed"
            variant="primary"
            to="/self-test/results"
            target="_blank"
            >{{ $t("seeker_account.my_score") }}</b-btn
          >
          <b-btn
            class="xxl ml-3"
            v-else
            variant="primary"
            to="/self-test"
            target="_blank"
            >{{ $t("seeker_account.score_test") }}</b-btn
          >
        </div>
      </b-col>
    </b-row>
    <div class="h3 text-center mb-3">
      {{ $t("seeker_account.my_career_details") }}
    </div>
    <div class="table-wrp">
      <app-table :experience-items="form.work_items"></app-table>
    </div>
  </div>
</template>

<script>
import SeekerHelpersMixin from "../../../common/SeekerHelpersMixin";
import icon from "../../../../images/report/performance.svg";
import AppTable from "./CareerTable";
import { mapGetters } from "vuex";
import {resourceAbsUrl} from '../../../common/utils'

export default {
  components: {
    AppTable,
  },
  mixins: [SeekerHelpersMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters("global", ["currentUserSelftestSurveyPassed"]),
    ...mapGetters({
      form: 'jobSeeker/jobSeeker',
    }),
    icon() {
      return icon;
    },
    documents() {
      return this.form.documents || [];
    },
    accosiatedDocuments() {
      return this.form.documents.map((document) => {
        return {
          ...document,
          type:
            this.documentType.find(
              (type) => +type.value === +document.filetype
            ) || null,
        };
      });
    },
    resumeDocument() {
      return (
        this.accosiatedDocuments.find((document) => {
          return document.type && document.type.slug === "cv";
        }) || null
      );
    },
    recommendationDocument() {
      return (
        this.accosiatedDocuments.find((document) => {
          return document.type && document.type.slug === "recommendation";
        }) || null
      );
    },
    otherDocs() {
      return this.accosiatedDocuments.filter((document) => {
        if (
          (this.resumeDocument && this.resumeDocument.id === document.id) ||
          (this.recommendationDocument &&
            this.recommendationDocument.id === document.id)
        ) {
          return false;
        }
        return true;
      });
    },
    jobSeekerExperienceArray() {
      return [
        {
          key: this.$t("seeker_account.country"),
          title: this.country,
        },
        {
          key: this.$t("seeker_account.available"),
          title: this.seekerAvailableIn,
        },
        {
          key: this.$t("seeker_account.language_1"),
          title: this.getLang(this.form.mother_tongue),
        },
        {
          key: this.$t("seeker_account.language_2"),
          title: this.getLang(this.form.second_language),
        },
        // {
        //   key: this.$t("seeker_account.career_step"),
        //   title: this.careerStep()
        // },
        {
          key: this.$t("seeker_account.highest_education"),
          title: this.highestEducation,
        },
        {
          key: this.$t("seeker_account.email"),
          title: this.form.email,
        },
        {
          key: this.$t("seeker_account.phone"),
          title: this.form.phone_number,
        },
        {
          key: this.$t("seeker_account.cell"),
          title: this.form.cell_number,
        },
        {
          key: this.$t("seeker_account.opened_for_job_offers"),
          title: Boolean(this.form.opened_for_jobs) // eslint-disable-line no-extra-boolean-cast
            ? this.$t("seeker_account.yes")
            : this.$t("seeker_account.no"),
        },
        {
          key: this.$t("seeker_account.opened_for_surveys"),
          title: this.$t("seeker_account.yes"),
        },
      ];
    },
    country() {
      if (!this.form.location) return '';
      return `${(this.form.location.local_name || this.form.location.municipality)}, ` +
          `${this.form.location.country_subdivision}, ` +
          `${this.form.location.country}`
    },
    seekerAvailableIn() {
      const availableObj = this.availableIn.find(
        (item) => item.value === this.form.available_in
      );
      if (availableObj && "text" in availableObj) {
        return `${availableObj.text} Weeks`;
      }
      return "-";
    },
    highestEducation() {
      const educationObj = this.highestEducations.find(
        (education) => education.value === this.form.highest_education
      );
      if (educationObj && "text" in educationObj) {
        return educationObj.text;
      }
      return "-";
    },
  },
  methods: {
    getLang(code) {
      const languageObj = this.languages.find((lang) => lang.value === code);
      if (languageObj && "text" in languageObj) {
        return languageObj.text;
      }
    },
    documentDownloadLink(document) {
      let url = document.link;
      if (!(url && /^http/.test(url))) {
        url = window.origin + url;
      }
      return resourceAbsUrl(url);
    },
    // careerStep() {
    //   return "Yes";
    // },
  },
};
</script>
