<template>
  <div class="report__chapter personality__type">

    <b-row>
      <b-col>
        <h3 class="report__chapter__title">
          <span class="mdi mdi-human"></span>
          <span>{{ $t("report.personality_type") }}</span>
        </h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" md="6" lg="6" class="col-print-6" v-for="(item, index) in radialProgress.slice(0, 2)" :key="index">
        <div class="personality__progress__item">
          <report-radial-progress :value="item.score" :color="getColor(item.attribute)"></report-radial-progress>
          <span class="personality__progress__label">{{ getMessageByColor(item.attribute) }}</span>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { RADIAL_PROGRESS_MULTIPLY, PERSONALITY_TYPES_COLORS, PERSONALITY_TYPES_COLORS_TITLES } from "@/common/constants";
import RadialProgress from "@/components/RadialProgress";

export default {
  components: {
    ReportRadialProgress: RadialProgress
  },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    radialProgress() {
      return (
        this.candidate.personality_attrs &&
        this.candidate.personality_attrs.map((item, index) => {
          return {
            color: item.name,
            score: this.multiplyRadialScore(index, item.score),
            attribute: item.attribute
          };
        })
      ) || [];
    },
  },
  methods: {
    multiplyRadialScore(index, value) {
      return index === 1 ? Math.round(value * RADIAL_PROGRESS_MULTIPLY) : value
    },
    getColor(attribute) {
      return PERSONALITY_TYPES_COLORS[attribute] || "transparent";
    },
    getMessageByColor(attribute) {
      return this.$t(PERSONALITY_TYPES_COLORS_TITLES[attribute] || '')
    },
  }
};
</script>
