<template>
  <candidate-details-competencies-table :report_title="$t('report.job_requirements')" :hideTitle="hideTitle" :items="attributes_rating" :fields="fields" :icon="'mdi mdi-hexagon-multiple-outline'" :job_table="false" :max="max"></candidate-details-competencies-table>
</template>

<script>
import { orderBy } from 'lodash';
import CandidateDetailsCompetenciesTable from '@/components/CandidateDetailsCompetenciesTable';

export default {
  props: {
    attributes: {
      type: Array,
      required: true,
      default: () => {}
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CandidateDetailsCompetenciesTable
  },
  data() {
    return {
      max: 100,
      fields: [
          { key: 'name',
            label: this.$t('create_campaign.traits'),
            thClass: 'attribute__name'
          },
        {
          key: 'score',
          thClass: ''
        }
      ]
    }
  },
  computed: {
    attributes_rating() {
      const value = orderBy((
        this.attributes &&
        this.attributes.map((item) => {
          return {
            attribute: item.attribute,
            name: item.text,
            score: item.value
          };
        })
      ), ['score'], ['desc']).slice(0, 8) || [];
      return orderBy(value, 'name', ['asc']);
    }
  }
};
</script>
