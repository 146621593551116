<template>
  <b-row>
    <b-col class="col-print-4" md="6" lg="3" v-for="(item, index) in campaignStatisticData" :key="index">
      <div class="report__card">
        <div class="report__card__value"><span :class="item.icon"></span>{{ item.count }}</div>
        <div class="report__card__title">{{ item.name }}</div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      campaignStatisticData: [
        {
          name: this.$t("report.candidates"),
          count: 0,
          icon: "mdi mdi-account",
        },
        {
          name: this.$t("report.high_performer"),
          count: 0,
          icon: "mdi mdi-account-check",
        },
        {
          name: this.$t("report.high_potential"),
          count: 0,
          icon:"mdi mdi-account-tie",
        },
        {
          name: this.$t("report.rock_star"),
          count: 0,
          icon: "mdi mdi-account-star",
        },
      ]
    }
  },
  mounted() {
      this.setUsersCount();
  },
  methods: {
    getUsersCount(property) {
      let counter = 0;

      if (this.report[property]) counter++

      return counter++;
    },
    setUsersCount() {
      this.campaignStatisticData[0].count = 1;
      this.campaignStatisticData[1].count = this.getUsersCount('pattern_high_performance_fulfilled');
      this.campaignStatisticData[2].count = this.getUsersCount('pattern_high_potential_fulfilled');
      this.campaignStatisticData[3].count = this.getUsersCount('pattern_rock_star_fulfilled');
    }
  }
};
</script>
