<template>
  <div class="circle__progress" ref="progress">
    <div class="circle__progress__value">{{ value }}<span v-if="showPercent">%</span></div>
    <svg class="circle__progress__svg" preserveAspectRatio="xMinYMin" :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" :style="{ strokeWidth: `${width}px` }">
      <circle class="circle__progress__backline" :r="radius" cx="50%" cy="50%" fill="transparent" stroke-dasharray="0" stroke-dashoffset="0" :style="{ strokeDasharray }"></circle>
      <circle class="circle__progress__frontline" :r="radius" cx="50%" cy="50%" fill="transparent" stroke-dasharray="0" stroke-dashoffset="0" stroke-linecap="butt" :style="{ strokeDasharray, strokeDashoffset, stroke: color }"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 8
    },
    color: {
      type: String,
      default: "#EBF5FE"
    },
    showPercent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isInit: false,
      size: 80
    };
  },
  computed: {
    radius() {
      return (this.size - this.width) / 2.5;
    },

    strokeDasharray() {
      return Math.PI * (this.radius * 2);
    },

    strokeDashoffset() {
      if (!this.isInit) {
        return this.strokeDasharray;
      }

      let val = this.value;

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      return ((100 - val) / 100) * this.strokeDasharray;
    }
  },
  mounted() {
    this.calculateSize();
    this.isInit = true;
  },
  methods: {
    calculateSize() {
      this.size = this.$refs.progress.clientWidth || this.size;
    }
  }
};
</script>

<style lang="scss" scoped>


.circle__progress {
  position: relative;
  width: 75px;

  .circle__progress__svg {
    stroke: #eeeff3;
    width: 100%;
    height: auto;
    transform: scaleX(-1) rotate(-90deg) !important;
  }

  .circle__progress__value {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.6rem;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: var(--color-marine);
  }

  .circle__progress__frontline {
    stroke: #4285f4;
  }
}
</style>
