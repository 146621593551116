<template>
  <div class="content__wrapper">
    <b-container fluid>
      <welcome v-if="ready && currentStep === 1"
               :referencesRequested="referencesRequested"
               @next="runReferenceForm"></welcome>

      <add-references v-if="ready && currentStep === 2"
                      :campaign="campaign" :token="token"
                      @populate-reference="populateReference"
                      @remove-reference="removeReference"></add-references>
    </b-container>
  </div>
</template>

<script>
import _get from "lodash/get";
import Welcome from "./Welcome";

import AddReferences from "./AddReferences/Index";
import ApiReference from "../../api/references";

export default {
  components: {
    Welcome,
    AddReferences
  },
  props: ['token', 'step'],
  data() {
    return {
      ready: false,
      inProgress: false,
      currentStep: null,
      campaign: null
    };
  },

  computed: {
    referencesRequested() {
      return _get(this.campaign, "references_requested", 0);
    }
  },

  created() {
    this.currentStep = Number(this.step || 1)
    this.fetchCampaign();
  },

  watch: {
    'campaign.references': {
      deep: true,
      handler() {
        const references = JSON.stringify(this.campaign.references);
        localStorage.setItem(`candidate/${this.token}`, references)
      }
    }
  },

  methods: {
    nextStep() {
      this.currentStep++;
      if (this.currentStep === 3) {
        this.$router.push(`/candidate/${this.token}/congratulations`);
      } else {
        this.$router.push(`/candidate/${this.token}/step/${this.currentStep}`);
      }
    },

    prevStep() {
      this.currentStep--;
      this.$router.push(`/candidate/${this.token}/step/${this.currentStep}`);
    },

    fetchCampaign() {
      this.inProgress = true;
      ApiReference.fetchReferences({ token: this.token }).then(resp => {
        this.campaign = resp.data.campaign;
        const referencesJSON = localStorage.getItem(`candidate/${this.token}`)
        if (this.campaign.references && this.campaign.references.length === 0) {
          if (referencesJSON && referencesJSON.length > 0) {
            this.campaign.references = JSON.parse(referencesJSON)
          }
        }
        if(!this.campaign.references) {
          if (referencesJSON && referencesJSON.length > 0) {
            this.campaign.references = JSON.parse(referencesJSON)
          }
        }
        this.inProgress = false;
        this.ready = true;
      });
    },

    authUser() {
      // this.$store.commit("global/LOGOUT");
      // this.$store.commit("global/AUTH_USER", {
      //   token: this.token,
      //   type: "Candidate"
      // });
      // this.$store.dispatch("global/removeUserToken");
      // this.$store.dispatch("global/authSelfTest", { token: this.token });
    },

    runReferenceForm() {
      // this.authUser();
      this.nextStep();
    },

    populateReference() {
      const params = {
        info: {
          gender: "",
          email: "",
          first_name: "",
          last_name: "",
          alt_email: "",
          deleted: false,
          relationship: ""
        }
      };
      this.campaign.references.push(params);
    },

    removeReference(val) {
      let deletedReference = this.campaign.references[val];
      if (deletedReference.id) {
        this.$set(this.campaign.references[val].info, "deleted", true);
      } else {
        this.campaign.references.splice(val, 1);
      }
    }
  }
};
</script>
