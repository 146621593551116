import { AP_REPORT_SLELECTED_CANDIDATES_LS_KEY } from "../common/constants";

export default class ApCampaignService {

  constructor(campaignId) {
    if (!campaignId) throw new Error('ApCampaignService: campaignId can\'t be null');

    this.candidates = [];
    this.campaignId = campaignId;
  }

  getCandidatesIds() {
    this.candidates = JSON.parse(localStorage.getItem(`${AP_REPORT_SLELECTED_CANDIDATES_LS_KEY}_${this.campaignId}`));
    if (!this.candidates) return null;
    return [...new Set(this.candidates.map(i => i.id))]
  }

  addCandidate(candidate) {
    if (!this.candidates) this.candidates = [];

    this.candidates.push(candidate);
    this.candidates.sort(this.compare)
    this.saveToLS()
  }

  removeCandidate(candidate) {
    if (this.candidates) {
      this.candidates = this.candidates.filter(c => c.id !== candidate.id)
      this.saveToLS()
    }
  }

  clear() {
    localStorage.removeItem(`${AP_REPORT_SLELECTED_CANDIDATES_LS_KEY}_${this.campaignId}`);
  }

  saveToLS() {
    localStorage.setItem(
      `${AP_REPORT_SLELECTED_CANDIDATES_LS_KEY}_${this.campaignId}`,
      JSON.stringify(this.candidates.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i))
    );
  }

  compare(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }
}