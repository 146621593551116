<template>
  <div class="report__chapter only-print">
    <b-row>
      <b-col>
        <h3 class="report__chapter__title"><span class="mdi mdi-file-star-outline"></span><span>{{ $t("report.annex") }}</span></h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <img class="anex__timeline__bg img-fluid" :src="bgIcon" :alt="pageTitle" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BgSvg from "../../../../images/report/print/types.svg";
import franchise from '../../../conf/franchise';

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    },
    report: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    bgIcon() {
      return BgSvg;
    },
     pageTitle() {
      return franchise.pageTitle;
    }
  }
};
</script>
