import axios from "axios";

const fetchReferences = params => {
  return axios.get("/api/v1/campaign_references/references", {
    params: params
  });
};

const populateReferences = params => {
  return axios.post("/api/v1/campaign_references/references", params);
};

const getEndDate = params => {
  return axios.get("/api/v1/campaign_references/end_date", {
    params: params
  });
};

export default {
  fetchReferences,
  populateReferences,
  getEndDate
};
