<template>
  <header class="auth-header">
    <b-container>
      <b-navbar class="px-0 flex-wrap xk-navbar" type="light" variant="light">
        <b-navbar-brand>
          <router-link :to="'#'">
            <b-img :src="headerLogo" fluid="fluid" :alt="title"></b-img>
          </router-link>
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto">
          <app-language-switcher></app-language-switcher>
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import AuthMixin from '@/common/mixins/AuthMixin';
import AppLanguageSwitcher from '../LanguageSwitcher';
import CompanySwitcher from '../CompanySwitcher';
import LogoMatch from '../../../images/logoMatch.svg';
import LogoTalents from '../../../images/logoTalents.svg';
import franchise from '../../conf/franchise';

export default {
  components: {
    AppLanguageSwitcher,
    CompanySwitcher,
  },
  props: {
    authBtn: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [AuthMixin],
  data() {
    return {
      toggleNav: true,
    };
  },
  computed: {
    title() {
      return franchise.title;
    },
    headerLogo() {
      return franchise.logo;
    },
    headerLogoAuth() {
      return this.isMatch ? LogoMatch : LogoTalents;
    },
    hideNavBtn() {
      return !this.isRegistered;
    },
  },
  methods: {
    logout() {
      // Logout from Rails app in background
      this.$store
        .dispatch('global/logout')
        .then(() => {
          this.$router.push('/login');
        })
        .catch((e) => {
          this.$router.push('/login');
        });
    },

    toggleNavSidebar() {
      this.toggleNav = !this.toggleNav;
      this.$emit('toggle-nav', this.toggleNav);
    },
  },
};
</script>
