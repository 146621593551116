<template>
  <div class="survey survey__content__block">

    <div class="survey__header">
      <h1 class="survey__scenario">{{ $t("match_references.intro") }}</h1>
    </div>

    <p>{{ tellUsText }}</p>

    <ValidationObserver v-slot="{ handleSubmit }" tag="div">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider name="acquaintance_period" vid="acquaintance_period" rules="required" mode="lazy">
                <b-form-group slot-scope="{ errors }" :label="$t('match_references.how_long', {firstName, lastName})">
                    <b-form-select v-model="referenceRelation.acquaintance_period" :options="acquaintancePeriods"></b-form-select>
                    <div class="error-message">{{ errors[0] }}</div>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="relationship_type" vid="relationship_type" rules="required" mode="lazy">
                <b-form-group slot-scope="{ errors }" :label="$t('match_references.what_is_relationship')">
                    <b-form-select v-model="referenceRelation.relationship_type" :options="referencesRelationshipTypes"></b-form-select>
                    <div class="error-message">{{ errors[0] }}</div>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="relationship_status" vid="relationship_status" rules="required" mode="lazy">
                <b-form-group slot-scope="{ errors }" :label="$t('match_references.how_you_know')">
                    <b-form-select v-model="referenceRelation.relationship_status" :options="relationshipStatuses"></b-form-select>
                    <div class="error-message">{{ errors[0] }}</div>
                </b-form-group>
            </ValidationProvider>

            <hr>
            <b-row>
                <b-col>
                    <b-button block="block" size="lg" variant="secondary" @click="$emit('close')">{{ $t("general.cancel") }}</b-button>
                </b-col>
                <b-col>
                    <b-button block="block" size="lg" variant="primary" type="submit">{{ $t('general.next') }}</b-button>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
  </div>

</template>

<script>
import ReferencesHelpersMixin from "@/common/ReferencesHelpersMixin";

export default {
  mixins: [ReferencesHelpersMixin],
  props: {
    firstName: {
      type: String,
      required: false
    },
    lastName: {
      type: String,
      required: false
    },
    referenceRelationship: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    referenceRelation: {
      get() {
        return this.referenceRelationship || {};
      },
      set(value) {
        this.$emit('change', {
          referenceRelationship: value
        });
      }
    },
    tellUsText() {
      return this.$t('match_references.tell_us_text', {
        firstName: this.firstName,
        lastName: this.lastName
      })
    }
  },
  methods: {
    onSubmit() {
      this.$emit('next');
    }
  }
};
</script>

<style scoped>
  .survey.survey__content__block {
    max-width: 1024px
  }

  .survey__situation__body__table {
    font-size: 1.6rem
  }
</style>
