<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <div class="form-group" :class="errors[0] ? 'form-group-error' : ''">
      <label>{{ label }}</label>
      <b-form-datepicker
        v-model="currentValue"
        class="custom-datepicker"
        :label-no-date-selected="$t('general.no_date_selected')"
      >
      </b-form-datepicker>
      <span v-if="errors[0]" class="error-message">* {{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: [String],
      default: '',
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value || '';
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>