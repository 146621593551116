<template>
  <section
    class="page-break-inside-avoid page_campaign_summary new"
  >
    <div class="row">
      <div class="col-2">
        <figure>
          <img :src="companyLogoUrl" alt="logo" class="logo" />
        </figure>
      </div>
      <div class="col-7 pr-0">
        <h1 class="strictly color_lava">
          - {{ $t('exit.report.strictly') }} -
        </h1>
        <img
          class="report__exit__front__page"
          src="../../../../images/report/match-report_new.svg"
          alt="front page"
        />
      </div>
      <div class="col-3 report__info__attributes color_blue">
        <p class="report__info__attributes__label">
          {{ $t('exit.create_survey.department') }}
        </p>
        <p
          v-if="campaign.info.departments.length"
          class="report__info__attributes__value"
        >
          {{ campaign.info.departments.join(',') }}
        </p>
        <hr />
        <p class="report__info__attributes__label">
          {{ $t('exit.report.unit') }}
        </p>
        <p v-if="campaign.info.unit" class="report__info__attributes__value">
          {{ campaign.info.unit }}
        </p>
        <hr />
        <p class="report__info__attributes__label">
          {{ $t('exit.report.location') }}
        </p>
        <p
          v-if="campaign.info.city || campaign.info.country"
          class="report__info__attributes__value"
        >
          {{ location }}
        </p>
        <hr />
        <p class="report__info__attributes__label">
          {{ $t('exit.report.interview_title') }}
        </p>
        <p v-if="campaign.name" class="report__info__attributes__value">
          {{ campaign.name }}
        </p>
      </div>
    </div>
    <div class="row report__analytics">
      <div class="col-12">
        <div class="report__analytics__head">
          {{ $t('exit.report.interview_results') }}*
        </div>
        <div class="report__analytics__name">
          {{leavingPerson.name}}
        </div>
        <div class="offset-6 col-6 mb-5">
          <div class="report__analytics__description">
            *{{ $t('exit.report.interview_description') }}
          </div>
        </div>
      </div>
      <div class="offset-1 col-11">
        <div class="report__analytics__filters">
          {{ $t('exit.report.interview_statistics') }}
        </div>
        <div class="report__analytics__filters__line"></div>
      </div>
    </div>

    <!-- Statistics -->
    <div class="row pb-4 report__chapter__exit">
      <div class="offset-2 col-3 mb-5 mt-5">
        <table class="table report__ems__table has__lines">
          <tbody>
            <tr>
              <td>
                <img
                  src="../../../../images/report/references_new.svg"
                  alt="references"
                  width="50px"
                  style="float: right"
                />
              </td>
              <td>{{ participants.length }}</td>
            </tr>
            <tr>
              <td>
                <img
                  src="../../../../images/report/date-start_new.svg"
                  alt="date-start"
                  width="50px"
                  style="float: right"
                />
              </td>
              <td>{{ campaign.start_date }}</td>
            </tr>
            <tr>
              <td>
                <img
                  src="../../../../images/report/date-end_new.svg"
                  alt="date-end"
                  width="50px"
                  style="float: right"
                />
              </td>
              <td>{{ campaign.end_date }}</td>
            </tr>
            <tr class="report__chapter__exit__date">
              <td colspan="2">
                <div class="border__date">
                  <b-icon
                    icon="calendar3 "
                    style="color: #75b9ff; width: 30px"
                  ></b-icon>
                  {{ campaign.info.last_day }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="offset-1 col-2 mt-5 text-right">
        <div class="report__chapter__exit__name">
          {{ $t('exit.report.job') }}
        </div>
        <div class="report__chapter__exit__name_sec">
          {{ $t('exit.report.performance') }}
        </div>
      </div>
      <div class="col-3 mt-5">
        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.title') }}
          </div>
          <div
            v-for="job in participants"
            :key="job.id"
            class="report__chapter__exit__value"
          >
            {{ job.info.job_title }}
          </div>
        </div>
        <div class="mb-5">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.level') }}
          </div>
          <div
            v-for="participant in participants"
            :key="participant.id"
            class="report__chapter__exit__value"
          >
            {{ participant.info.job_level }}
          </div>
        </div>
        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.evaluation') }}
          </div>
          <div
            v-for="participant in participants"
            :key="participant.id"
            class="report__chapter__exit__value"
          >
            <span v-if="participant.info.performance">
              {{ $t(`exit.performance_level.${participant.info.performance}`) }}
            </span>
          </div>
        </div>
        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.performance_peer') }}
          </div>
          <div class="report__chapter__exit__value">
            {{ report.performance.exit_13_1_1 }}
          </div>
        </div>
        <div class="mb-4">
          <div class="report__chapter__exit__title">
            {{ $t('exit.report.potential_peer') }}
          </div>
          <div class="report__chapter__exit__value">
            {{ report.performance.exit_13_1_2 }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {EXIT_CAMPAIGN_ROLE } from '../../../common/constants';
export default {
  props: {
    companyLogoUrl: {
      type: String,
      require: true
    },
    campaign: {
      type: Object,
      require: true
    },
    report: {
      type: Object,
      require: true
    },
    participants: {
      type: Array,
      require: true
    }
  },
  computed:{
    leavingPerson(){
      return this.participants.find(
        (i) => i.role === EXIT_CAMPAIGN_ROLE.LEAVING_PERSON
      )
    },
  }
}
</script>