import Vue from "vue";
import TestLayout from "../components/TestLayout.vue";
import TestCard from "../components/TestCard.vue";
import TestIllustration from "../components/TestIllustration.vue";
import TestHeader from "../components/TestHeader.vue";
import TestCounter from "../components/TestCounter.vue";
import TestCase from "../components/TestCase.vue";
import TestSlider from "../components/TestSlider.vue";
import TestSliderList from "../components/TestSliderList.vue";

Vue.component("TestLayout", TestLayout);
Vue.component("TestCard", TestCard);
Vue.component("TestIllustration", TestIllustration);
Vue.component("TestHeader", TestHeader);
Vue.component("TestCounter", TestCounter);
Vue.component("TestCase", TestCase);
Vue.component("TestSlider", TestSlider);
Vue.component("TestSliderList", TestSliderList);
