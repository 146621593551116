<template>
  <ValidationObserver ref="form" tag="div">
    <b-form class="auth-form auth-form__login mt-5" @submit.prevent="onSubmit">
      <b-alert
        v-model="newPasswordAlert"
        :variant="alertVariant"
        dismissible="dismissible"
      >
        <div class="auth-form-alert">
          <b-img
            class="auth-form-alert-img"
            v-if="alertVariant === 'danger'"
            :src="dangerIcon"
            :alt="pageTitle"
          ></b-img>
          <b-img
            class="auth-form-alert-img"
            v-if="alertVariant === 'success'"
            :src="successIcon"
            :alt="pageTitle"
          ></b-img>
          <div class="ml-3 d-flex align-items-center justify-content-center">
            {{ newPasswordMessage }}
          </div>
        </div>
      </b-alert>
      <div class="auth-page-title">
        {{ $t("auth_pages.choose_new_password") }}
      </div>
      <ValidationProvider
        class="mb-3"
        :name="$t('general.password')"
        vid="password"
        rules="required|min:6"
        mode="lazy"
        tag="div"
        v-slot="{ errors }"
      >
        <div
          class="form-group form-group__custom"
          :class="errors[0] ? 'form-group-error' : ''"
        >
          <input
            class="form-control"
            id="password"
            type="password"
            v-model="form.password"
            placeholder=" "
          /><label class="auth-form-label" for="password">{{
            $t("general.password")
          }}</label>
          <div class="error-message">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        class="mb-3"
        :name="$t('general.password').toLowerCase()"
        vid="password_confirmation"
        rules="required|confirmedBy:@password"
        mode="lazy"
        v-slot="{ errors }"
      >
        <div
          class="form-group form-group__custom"
          :class="errors[0] ? 'form-group-error' : ''"
        >
          <input
            class="form-control"
            id="password_confirmation"
            type="password"
            v-model="form.password_confirmation"
            placeholder=" "
          /><label class="auth-form-label" for="password_confirmation">{{
            $t("auth_pages.repeat_password")
          }}</label>
          <div class="error-message">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
      <b-button
        class="mt-3 button-text"
        size="lg"
        type="submit"
        block="block"
        variant="primary"
      >
        <b-spinner
          v-if="isInProgress"
          variant="light"
          label="Spinning"
        ></b-spinner
        >{{ $t("auth_pages.confirm_new_password") }}
      </b-button>
      <div class="text-center mt-2">
        {{ $t("auth_pages.have_password") }}
        <router-link class="ml-1 forgot-link" to="/">{{
          $t("general.login")
        }}</router-link>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import RegistrationSvg from "../../../images/auth/registration.svg";
import DangerIcon from "../../../images/auth/danger.svg";
import SuccessIcon from "../../../images/auth/success.svg";
import AuthApi from "../../api/auth";
import franchise from '../../conf/franchise';
export default {
  data() {
    return {
      form: {},
      isInProgress: false,
      newPasswordAlert: false,
      newPasswordMessage: "",
      alertVariant: "",
    };
  },
  computed: {
    pageTitle() {
      return franchise.pageTitle;
    },
    registrationBg() {
      return RegistrationSvg;
    },
    successIcon() {
      return SuccessIcon;
    },
    dangerIcon() {
      return DangerIcon;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInProgress = true;
        AuthApi.updatePassword({
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          token: this.$route.params.token,
        })
          .then((response) => {
            this.alertVariant = "success";
            this.$refs.form.reset();
            this.newPasswordMessage = null;
            const data = response.data.data;
            this.$store.commit('global/AUTH_USER', data)
          })
          .catch((e) => {
            this.alertVariant = "danger";
            this.newPasswordMessage = e.response.data.message;
          })
          .finally(() => {
            this.isInProgress = false;
            this.newPasswordAlert = true;
            this.$router.push('/');
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO quick hack
.button-text {
  font-size: 1.3rem;

  span {
    top: 10px;
  }
}
</style>