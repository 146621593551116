import axios from 'axios';

// TODO: Depricated
// const getKeys = () => {
//   return axios.get('/api/v1/campaigns/generate_keys');
// };

const updateBulkState = (companyId, params) => {
  return axios.patch(`/api/v1/companies/${companyId}/campaigns/change_states`, params);
};

const fetchCampaigns = (companyId, pagination) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns`, {
    params: {
      show_archived: pagination.show_archived,
      search_field: pagination.field || null,
      search_value: pagination.value || null,
      per_page: pagination.perPage,
      sort_by: pagination.sortBy,
      order: pagination.order,
      page: pagination.page || 1
    }
  });
};

const getCampaign = (companyId, id) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns/${id}`);
};

const getCampaignPreview = (token) => {
  return axios.get(`/api/v1/campaigns/preview?_token=${token}`);
};

const getReport = (companyId, id, mode, isApplicantPortal = false, candidates) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns/${id}/report`, {
    params: {
      anonymous_mode: mode,
      candidates: candidates || null,
      is_applicant_portal: isApplicantPortal
    }
  });
};

const getReportPreview = (token, candidates) => {
  return axios.get(`/api/v1/campaigns/preview_report?_token=${token}`, {
    params: {
      anonymous_mode: true,
      candidates: candidates || null,
      is_applicant_portal: true
    }
  });
};

const createCampaign = (companyId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns`, { campaign: params });
};

const updateCampaign = (companyId, id, params) => {
  return axios.patch(`/api/v1/companies/${companyId}/campaigns/${id}`, { campaign: params });
};

const updateCampaignComment = (companyId, id, params) => {
  return axios.patch(`/api/v1/companies/${companyId}/campaigns/${id}/update_comment`, params);
};

const generatePreviewToken = (companyId, id, linkExpirationPeriod) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns/${id}/generate_preview_token?link_exp=${linkExpirationPeriod}`);
};

const inviteCampaignExpert = (companyId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns/invite_expert`, params);
};

const fetchCandidates = (companyId, campaignId, page, sortBy, sortDesc, anonymousMode = false, field = '', filter = '', education_keywords = [], job_keywords = [], certificates = []) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns/${campaignId}/candidates?page=${page}&sort_by=${sortBy}&sort_desc=${sortDesc}&anonymous_mode=${anonymousMode}&field=${field}&filter=${filter}&education_keywords=${education_keywords}&job_keywords=${job_keywords}&certificates=${certificates}`);
};

const fetchPreviewCandidates = (token, page, sortBy, sortDesc, education_keywords = [], job_keywords = [], certificates = []) => {
  return axios.get(`/api/v1/campaigns/preview_campaign_candidates?page=${page}&sort_by=${sortBy}&sort_desc=${sortDesc}&education_keywords=${education_keywords}&job_keywords=${job_keywords}&certificates=${certificates}&_token=${token}`);
};

const fetchCandidateAPData = (companyId, campaignId, candidateId, anonymousMode = false) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns/${campaignId}/candidates/${candidateId}/ap_data?anonymous_mode=${anonymousMode}`);
};

const fetchPreviewCandidateAPData = (token, candidateId) => {
  return axios.get(`/api/v1/campaigns/preview_candidate_ap_data/${candidateId}?_token=${token}`);
};

const updateCandidateAP = (companyId, campaignId, candidateId, data) => {
  return axios.patch(`/api/v1/companies/${companyId}/campaigns/${campaignId}/candidates/${candidateId}`, data);
};

const uploadCandidateDocuments = (campaignId, candidateId, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const endPoint = `/api/v1/campaigns/${campaignId}/campaign_applications/${candidateId}/upload_documents`

  const formData = new FormData();

  data.documents.forEach(i => {
    formData.append(['files[]'], i.file);
    formData.append(['documents_type[]'], i.document_type.value);
  });
  formData.append('campaign_id', campaignId);
  formData.append('campaign_candidate_id', candidateId);

  return axios
    .post(endPoint, formData, config)
    .then(res => console.log(res))
    .catch(err => console.log(err));
};

const recalculateReport = (companyId, campaignId, data) => {
  return axios.put(`/api/v1/companies/${companyId}/campaigns/${campaignId}/recalculate_report`, data);
}

const validateCampaignExpertLink = params => {
  return axios.get(`/api/v1/campaigns/validate_expert_link`, {
    params: {
      token: params.token
    }
  });
};

const updateCampaignExpertAttributes = params => {
  return axios.patch('/api/v1/campaigns/update_expert_attrs', {
    campaign: params
  });
};

const getKeywords = value => {
  return axios.get('/api/v1/campaigns/popular_keywords', {
    params: {
      value
    }
  });
};

const cloneCampaign = (companyId, campaignId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns/${campaignId}/clone`, params);
}

const uploadVideoUrls = (companyId, campaignId, formData) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns/${campaignId}/upload_video_url`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default {
  updateBulkState,
  fetchCampaigns,
  getCampaign,
  getCampaignPreview,
  createCampaign,
  updateCampaign,
  updateCampaignComment,
  generatePreviewToken,
  inviteCampaignExpert,
  validateCampaignExpertLink,
  updateCampaignExpertAttributes,
  getReport,
  getReportPreview,
  getKeywords,
  fetchCandidates,
  fetchPreviewCandidates,
  updateCandidateAP,
  fetchCandidateAPData,
  fetchPreviewCandidateAPData,
  recalculateReport,
  cloneCampaign,
  uploadVideoUrls,
  uploadCandidateDocuments
};
