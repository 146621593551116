import axios from 'axios';

const createCampaign = (companyId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/exit_campaigns`, { exit_campaign: params });
};

const updateCampaign = (params, id, companyId) => {
  return axios.patch(`/api/v1/companies/${companyId}/exit_campaigns/${id}`, { exit_campaign: params });
};

const getCampaign = (companyId, id) => {
  return axios.get(`/api/v1/companies/${companyId}/exit_campaigns/${id}`);
};

const fetchCampaigns = (companyId, pagination) => {
  return axios.get(`/api/v1/companies/${companyId}/exit_campaigns`, {
    params: {
      show_archived: pagination.show_archived,
      search_field: pagination.field || null,
      search_value: pagination.value || null,
      per_page: pagination.perPage,
      sort_by: pagination.sortBy,
      order: pagination.order,
      page: pagination.page || 1
    }
  });
};

const getTeamMembers = (token) => {
  return axios.get(`/api/v1/exit_campaigns/team_members`, {
    params: {
      token
    }
  })
}

const addTeamMembers = (data) => {
  return axios.put(`/api/v1/exit_campaigns/team_members`, data)
}

const removeTeamMember = (data) => {
  return axios.delete(`/api/v1/exit_campaigns/team_members`, {
    params: data
  })
}

const getAnswers = (token) => {
  return axios.get(`/api/v1/exit_campaigns/fetch_questions`, {
    params: {
      token
    }
  })
}

const saveAnswers = (data) => {
  return axios.post(`/api/v1/exit_campaigns/answers`, data)
}

const getReport = (companyId, campaignId) => {
  return axios.get(`/api/v1/companies/${companyId}/exit_campaigns/${campaignId}/show_report`);
}

const getAnalyticsCampaignOptions = (companyId) => {
  return axios.get(`/api/v1/companies/${companyId}/exit_campaigns/analytics_options`);
}

const cloneCampaign = (companyId, campaignId, params) => {
  return axios.post(`/api/v1/companies/${companyId}/exit_campaigns/${campaignId}/clone`, params);
}

const updateBulkState = (companyId, params) => {
  return axios.patch(`/api/v1/companies/${companyId}/exit_campaigns/change_states`, params);
};

export default {
  createCampaign,
  updateCampaign,
  getCampaign,
  fetchCampaigns,
  getTeamMembers,
  addTeamMembers,
  removeTeamMember,
  getAnswers,
  saveAnswers,
  getReport,
  getAnalyticsCampaignOptions,
  cloneCampaign,
  updateBulkState,
};
