import Vue from 'vue'
import VueRouter from 'vue-router';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import './modules/paddy-filter';
import './modules/bootstrap';
import './modules/axios';
import './modules/form-wizard';
import './modules/multiselect';
import './modules/vue-slider';
import './modules/vee-validate';
import './modules/moment';
import './modules/vue-google-maps';
import './modules/tip-alert';
import './modules/test-components';
import './modules/xmz-global-components';

import { currentTheme } from '../config/themes';
import('../styles/themes/'+ currentTheme +'/styles.scss')

Vue.config.productionTip = false

Vue.filter('format-date', function(value) {
  let date = new Date(value);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).replace(/ /g, ' ');
  return  formattedDate
})

import { store } from './store/index';
import router from './router';
import App from './components/App.vue';
import i18n from './i18n'

// if (process.env.NODE_ENV !== 'development' && window.port != 8081 ) {
//   Sentry.init({
//     dsn: "https://c4992305f4fb429b903e2fb3bbd4588d@o471181.ingest.sentry.io/5528057",
//     integrations: [
//       new VueIntegration({
//         Vue,
//         tracing: true,
//       }),
//     ],
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

Vue.use(VueRouter);

const app = new Vue({
  router,
  store,
  $validates: true,
  i18n,
  render: h => h(App)
})

// TODO: This is hack because Rails app, change this in future
async function startApp() {
  axios.get(`/api/fe/config?lang=${localStorage.getItem('language') || 'en'}`, {
    headers: {
      'Accept': 'application/json'
    }
  }).then((success) => {
    const keys = Object.keys(success.data);
    for (const key of keys) {
      try {
        window[key] = JSON.parse(success.data[key])
      } catch (err) {
        window[key] = success.data[key]
      }
    }
    app.$mount('#app');
  })
  .catch(err => app.$mount('#app'))
}

startApp()

export { app, router, store };