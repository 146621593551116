<template>
  <thank-you :token="token" :surveyType="surveyType" :hasNextSurvey="hasNextSurvey"></thank-you>
</template>

<script>
import ThankYou from '@/components/ThankYou'
import { mapGetters } from 'vuex';

export default {
  props: ['token'],
  components: {
    ThankYou
  },
  data() {
    return {
      check: false,
      modalShow: false,
      step: 1
    };
  },
  computed: {
    ...mapGetters({
      surveyType: 'survey/type',
      inProgressCandidates: 'survey/inProgressCandidates',
    }),
    hasNextSurvey() {
      return this.inProgressCandidates.length > 0
    }
  },
  methods: {
    onSubmit() {
      this.modalShow = true;
    }
  }
};
</script>
