
<template>
  <div>
    <div v-if="isMatch">
      <app-match></app-match>
    </div>
    <div v-else>
      <app-talents></app-talents>
    </div>
  </div>
</template>

<script>
import AppMatch from "./Match";
import AppTalents from "./Talents";
import AuthApi from "../../api/auth";
import { mapGetters } from "vuex";

export default {
  components: {
    AppMatch,
    AppTalents
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", ["isMatch"])
  }
};
</script>
