<template>
  <div class="login__dialog mt-5">
    <ValidationObserver ref="form" tag="div" class="pt-5">
      <b-form
        class="auth-form auth-form__login mt-5"
        @submit.prevent="onSubmit"
      >
        <b-alert
          v-model="resetPasswordAlert"
          :variant="resetPasswordAlertVariant"
          dismissible
        >
          <div class="d-flex">
            <div
              class="ml-3 d-flex align-items-center justify-content-center mt-2"
            >
              {{ resetPasswordMessage }}
            </div>
          </div>
        </b-alert>
        <div class="auth-page-title">
          {{ $t('auth_pages.forgot_password') }}
        </div>
        <p class="auth-page-description">
          {{ $t('auth_pages.send_password') }}
        </p>
        <b-img
          class="auth-form-img"
          :src="imageSvg"
          alt="Examiz"
          fluid="fluid"
        ></b-img>
        <ValidationProvider
          :name="$t('general.email')"
          rules="required|email"
          vid="email"
          mode="lazy"
          tag="div"
          v-slot="{ errors }"
          ><input
            class="form-control"
            id="email"
            type="text"
            v-model="form.email"
            placeholder=" "
          /><label class="auth-form-label" for="email">{{
            $t('general.email')
          }}</label>
          <div class="error-message" v-if="errors[0]">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
        <div class="forgot-link-wrp mt-3 mb-2">
          {{ $t('auth_pages.have_password') }}
          <router-link class="forgot-link ml-1" to="/">{{
            $t('general.login')
          }}</router-link>
        </div>
        <b-button
          class="mt-3"
          size="lg"
          type="submit"
          block="block"
          variant="primary"
        >
          <b-spinner
            v-if="isInProgress"
            variant="light"
            label="Spinning"
          ></b-spinner
          >{{ $t('auth_pages.reset_password') }}
        </b-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import AuthApi from '../../api/auth';
import AuthHeader from '../../components/Header';
import Image from '../../../images/auth/forgot.svg';
export default {
  components: {
    AuthHeader,
  },
  data() {
    return {
      isInProgress: false,
      form: {},
      resetPasswordAlert: false,
      resetPasswordAlertVariant: '',
      resetPasswordMessage: '',
    };
  },
  computed: {
    imageSvg() {
      return Image;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInProgress = true;
        this.resetPasswordAlert = false;
        this.resetPasswordAlertVariant = '';
        AuthApi.resetPassword(this.form.email)
          .then((response) => {
            this.resetPasswordAlertVariant = 'success';
            this.$refs.form.reset();
            this.resetPasswordMessage = response.data.message;
          })
          .catch((e) => {
            this.resetPasswordAlertVariant = 'danger';
            this.resetPasswordMessage = this.$t(
              'controllers.user_registration.invalid_email'
            );
          })
          .finally(() => {
            this.isInProgress = false;
            this.resetPasswordAlert = true;
          });
      });
    },
  },
};
</script>
