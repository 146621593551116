<template>
  <candidate-details-competencies-table :report_title="$t('report.job_competencies')" :items="best_match" :fields="fields" :icon="'mdi mdi-hexagon-multiple-outline'" :job_table="true" :max="max"></candidate-details-competencies-table>
</template>

<script>
import { orderBy, get } from "lodash";
import CandidateDetailsCompetenciesTable from '@/components/CandidateDetailsCompetenciesTable';

export default {
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    campaign: {
      type: Object,
      required: true,
      default: () => {},
    }
  },
  components: {
    CandidateDetailsCompetenciesTable
  },
  data() {
    return {
      max: 100,
      fields: [
        { key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name'
        },
        {
          key: 'score',
          thClass: ''
        }
      ]
    }
  },
  computed: {
    best_match() {
      const values = orderBy(this.compared.map((item) => {
        return {
          name: item.current.name,
          value: item.current.value,
          score: item.compared.score,
        };
      }), ['value'], ['desc']).slice(0, 8)
      return orderBy(values, ['name'], ['asc'])
    },

    personality_attrs() {
      return [
        ...get(this.campaign, "personality_attrs.competencies", []),
        ...get(this.campaign, "personality_attrs.traits", []),
        ...get(this.campaign, "personality_attrs.values", []),
      ];
    },
    compared() {
      if (!this.candidate.main_attrs || !this.personality_attrs) {
        return [];
      }

      const main_attrs = {};
      this.candidate.main_attrs.forEach((i) => (main_attrs[i.attribute] = i));

      return this.personality_attrs.map((item) => {
        const attr = main_attrs[item.attribute];

        if (attr) {
          item.name = attr.name;
          return {
            current: item,
            compared: attr,
          };
        }

        return {
          current: item,
          compared: {},
        };
      });
    },
  },
};
</script>
