import axios from "axios";

const getJobSeeker = (jobSeekerId) => {
  return axios.get(`/api/v1/job_seekers/${jobSeekerId}`);
};

const updateJobSeeker = (jobSeekerId, params) => {
  return new Promise((done, error) => {
    const queue = [];
    if (params.job_seeker.documents_attributes) {
      params.job_seeker.documents_attributes.forEach((element) => {
        if (element.file) {
          const process = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(element.file);
            reader.onload = () => {
              element.file = reader.result;
              resolve(reader.result);
            };
            reader.onerror = reject;
          });
          queue.push(process);
        }
      });
    }

    Promise.all(queue).then(() => {
      axios
        .patch(`/api/v1/job_seekers/${jobSeekerId}`, params)
        .then(done)
        .catch(error);
    });
  });
};

export default {
  getJobSeeker,
  updateJobSeeker
};
