<template>
  <div class="content__wrapper">
    <b-container fluid>
      <welcome
        :title="candidateTitle"
        :firstName="candidate.first_name"
        :lastName="candidate.last_name"
        @next="next"
      ></welcome>
    </b-container>
  </div>
</template>

<script>
import Welcome from './Welcome';
import { mapGetters } from 'vuex';

export default {
  components: {
    Welcome,
  },
  computed: {
    ...mapGetters('exit', ['questions', 'questionsCount']),
    candidate() {
      return this.surveyCandidate || {};
    },
    candidateTitle() {
      return this.candidate.title;
    },
  },
  mounted() {
   if (!this.questions.length) {
    this.$router.push(`/exit-survey/${this.$route.params.token}/thanks`);
   } else if (this.questionsCount.totalQuestions - this.questionsCount.totalUnansweredQuestions > 0) {
    this.next();
   }
  },

  methods: {
    next() {
      return this.$router.push(
        `/exit-survey/${this.$route.params.token}/questions`
      );
    },
  },
};
</script>
