<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }" mode="lazy">
    <b-form-group :class="errors[0] ? 'form-group-error' : ''">

      <template slot="label">
        <label>{{ label }}
          <span class="create-campaign-info" v-b-tooltip.hover :title="title" v-if="title">
            <app-tooltip></app-tooltip>
          </span>
        </label>
      </template>

      <b-form-input :type="type"
                    v-model="currentValue"
                    :readonly="readonly"
                    :size="size"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :formatter="formatter"
                    ></b-form-input>
      <div class="error-message" v-if="errors[0]">* {{ errors[0] }}</div>

    </b-form-group>
  </ValidationProvider>
</template>

<script>
import AppTooltip from "./Tooltip";

export default {
  name: "TextInput",
  components: {
    AppTooltip,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value || "";
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods:{
    formatter(value) {
      if(this.type === 'number' && this.rules === 'numeric'){
        return parseInt(value)
      }
      return value
    }
  }
};
</script>
