<template>
  <div class="app-wrap" v-bind:class="{ 'app_match': isMatch, 'app_talents': !isMatch }">

    <app-header @toggle-nav="setIsActiveSideNav"></app-header>

    <div class="content__wrapper">
      <app-sidebar-navigation :is-active="isActive"></app-sidebar-navigation>
      <b-container class="xk-view" :class="{'xk-view--is-squeezed': !isActive}">
        <router-view></router-view>
      </b-container>
    </div>

    <app-footer></app-footer>

  </div>
</template>

<script>
import AppMatch from "../../views/Dashboard/Match";
import AppTalents from "../../views/Dashboard/Talents";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import AppSidebarNavigation from "@/components/SidebarNavigation";
import { mapGetters } from "vuex";
export default {
  components: {
    AppHeader,
    AppSidebarNavigation,
    AppMatch,
    AppTalents,
    AppFooter,
  },
  data() {
    return {
      isActive: true
    };
  },
  computed: {
    ...mapGetters("global", ["userRole"]),
    ...mapGetters("global", ["isMatch"])
  },

  methods: {
    setIsActiveSideNav(payload) {
      this.isActive = payload;
    }
  }
};
</script>
