<template>
  <div class="interview__question">
    <b-row>
      <b-col md="12" lg="10">
        <div class="interview__question__content fullwidth pt-md-5 p-lg-0">
          <h3 class="interview__question__topic">{{ $t('report.interview.dont') }}</h3>
          <ul>
            <li v-for="index in 6" :key="index">{{ $t('report.interview.list_text_' + (index + 8)) }}</li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  computed: {}
}
</script>
