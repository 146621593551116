<template>
  <div>
    <!-- quickfilters ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row>
      <b-col class="quickfilter-container">
        <img :src="companyLogo" class="d-none d-lg-block company-logo" />
        <div
          class="quickfilter-wrap"
          v-for="(item, index) in campaignsStats"
          :key="index"
          :class="{
            'quickfilter-is-active':
              paginationDict.value &&
              paginationDict.value.toLowerCase() === item.className &&
              !searchQuery,
          }"
        >
          <button
            @click="filterCampaigns(item)"
            class="quickfilter-item"
            :class="item.className"
          >
            <span class="quickfilter-item-name"
              >{{ item.name }} ({{ item.value }})</span
            >
          </button>
        </div>

        <b-link
          v-if="paginationDict.value && !searchQuery"
          @click="clearFilters"
          class="clear__filters"
          >{{ $t('dashboard.clear_all_filters') }}</b-link
        >
      </b-col>
    </b-row>

    <!-- dashboard table wrapper ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="dashboard-table-wrap">
      <div class="dashboard-table-top d-flex flex-wrap">
        <!-- search campaigns ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="d-flex flex-wrap">
          <div class="search-input-wrp mr-3">
            <b-input-group>
              <b-form-select
                class="dashboard-table-top-select"
                :options="searchFields"
                v-model="searchField"
                @change="onSearchFieldsChange"
              ></b-form-select>

              <b-input-group-append v-if="searchField === 'campaign_type'">
                <b-form-select
                  class="dashboard-table-top-search-select"
                  :options="campaignTypes"
                  v-model="searchSelect"
                  @change="onCampaignTypeChange"
                ></b-form-select>
              </b-input-group-append>

              <b-input-group-append v-if="searchField === 'country'">
                <b-form-select
                  class="dashboard-table-top-search-select"
                  :options="countryFilter"
                  v-model="searchSelect"
                  @change="onCampaignTypeChange"
                ></b-form-select>
              </b-input-group-append>

              <b-input-group-append
                v-if="
                  searchField !== 'campaign_type' && searchField !== 'country'
                "
                ><b-form-input
                  class="dashboard-table-top-search"
                  :placeholder="$t('dashboard.search_placeholder')"
                  v-on:keyup.enter="doSearch"
                  v-model="searchQuery"
                ></b-form-input
              ></b-input-group-append>
              <b-input-group-append
                v-if="
                  searchField !== 'campaign_type' && searchField !== 'country'
                "
                class="clear__search"
                ><b-btn @click="clearSearch"
                  ><i class="mdi mdi-close"></i></b-btn
              ></b-input-group-append>

              <b-input-group-append
                ><b-btn
                  variant="primary"
                  :disabled="!searchQuery || !searchField"
                  @click="doSearch"
                  ><search-icon></search-icon></b-btn
              ></b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <!-- action buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="d-flex flex-wrap">
          <b-button
            v-b-tooltip.hover
            :title="$t('dashboard.cancel_campaign')"
            class="mr-3 btn-action"
            :disabled="campaignsStateArrayEmpty"
            @click="changeBulkCampaignStates('cancel')"
          >
            <span class="mdi mdi-close"></span>
          </b-button>

          <b-button
            v-b-tooltip.hover
            v-if="$route.query['filter'] === 'Archived'"
            :title="$t('dashboard.unarchive_campaign')"
            class="mr-3 btn-action"
            :disabled="campaignsStateArrayEmpty"
            @click="changeBulkCampaignStates('unarchive')"
          >
            <span class="mdi mdi-archive-arrow-up"></span>
          </b-button>

          <b-button
            v-b-tooltip.hover
            v-else
            :title="$t('dashboard.archive_campaign')"
            class="mr-3 btn-action"
            :disabled="campaignsStateArrayEmpty"
            @click="changeBulkCampaignStates('archive')"
          >
            <span class="mdi mdi-archive"></span>
          </b-button>

          <b-button
            v-b-tooltip.hover
            :title="$t('dashboard.close_campaign')"
            class="mr-3 btn-action"
            :disabled="campaignsStateArrayEmpty"
            @click="changeBulkCampaignStates('close')"
          >
            <span class="mdi mdi-timer-off"></span>
          </b-button>
        </div>

        <!-- new campaign button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="d-flex flex-wrap ml-auto">
          <b-btn
            @click="newCampaign = true"
            variant="primary"
            toggle-class="text-decoration-none"
            no-caret
            v-b-tooltip.hover
            :title="$t('dashboard.new_campaign')"
            right
          >
            <i class="mdi mdi-plus-box"></i
            ><span class="sr-only">{{ $t('dashboard.search') }}</span>
          </b-btn>

          <b-modal
            centered
            v-model="newCampaign"
            :title="$t('create_campaign.modal_title')"
            hide-footer
            hide-header
          >
            <app-new-campaign-modal
              :firstBtnTitle="$t('dashboard.manual_candidate_evaluation')"
              :secondBtnTitle="$t('dashboard.use_applicant_portal')"
              @gotofirst="createAP"
              @gotosecond="createDirect"
              @close="() => (newCampaign = false)"
            ></app-new-campaign-modal>
          </b-modal>
        </div>
      </div>

      <!-- campaign table ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="table-wrp">
        <match-table
          :selected="campaignsStateArray"
          :items="campaigns"
          @populateStateArray="populateCampaigsStateArray"
          @open="openCampaignPage"
          @sort="sortingChanged"
        ></match-table>
      </div>
    </div>

    <!-- pagination ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="d-flex justify-content-end mt-5">
      <b-pagination-nav
        v-if="campaigns.length && campaignsMeta.total_pages > 1"
        :number-of-pages="campaignsMeta.total_pages"
        :per-page="paginationDict.perPage"
        :link-gen="linkGen"
        v-model="paginationDict.page"
      >
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { resourceAbsUrl } from '@/common/utils';
import MatchTable from './MatchTable';
import AppFormCampaign from '../FormCampaign/Index';
import AppChooseTypeCampaign from '../FormCampaign/StepChoose';
import ApiCampaign from '../../api/campaign';
import DashboardHelperMixin from '../../common/DashboardHelperMixin';
import SearchIcon from '../../icons/searchIcon';
import { CAMPAIGN_TYPES } from '../../common/constants';
import env from '../../../config/enviroment';
import AppNewCampaignModal from '../../components/NewCampaignModal';
import franchise from '../../conf/franchise';

export default {
  components: {
    MatchTable,
    AppFormCampaign,
    AppChooseTypeCampaign,
    SearchIcon,
    AppNewCampaignModal,
  },
  mixins: [DashboardHelperMixin],
  data() {
    return {
      select: '',
      campaignsStateArray: [],
      modalShow: false,
      isArchivedShow: false,
      text: '',
      searchField: 'job_title_short',
      searchSelect: CAMPAIGN_TYPES.JOB,
      searchQuery: '',
      paginationDict: {},
      resultsFromSearch: false,
      newCampaign: false,
    };
  },
  computed: {
    ...mapGetters('campaign', [
      'campaigns',
      'campaignsMeta',
      'campaignsArchived',
      'current',
    ]),
    ...mapGetters('global', ['companyLogoUrl', 'currentUserCompanyId']),
    companyLogo() {
      return this.companyLogoUrl
        ? resourceAbsUrl(this.companyLogoUrl)
        : franchise.logo;
    },
    campaignsStats() {
      if (!this.campaignsMeta) return [];

      return [
        /*
        {
          value: this.campaignsMeta.total,
          name: this.$t("dashboard.total_campaigns"),
          className: "total",
        },
        */
        {
          value: this.campaignsMeta.open,
          name: this.$t('dashboard.open_campaigns'),
          className: 'open',
        },
        {
          value: this.campaignsMeta.active,
          name: this.$t('dashboard.active_campaigns'),
          className: 'active',
        },
        {
          value: this.campaignsMeta.closed,
          name: this.$t('dashboard.statuses.closed'),
          className: 'closed',
        },
        {
          value: this.campaignsMeta.canceled,
          name: this.$t('dashboard.canceled_campaigns'),
          className: 'canceled',
        },
        {
          value: this.campaignsMeta.finalized,
          name: this.$t('dashboard.finalized_campaigns'),
          className: 'finalized',
        },
        {
          value: this.campaignsMeta.archived,
          name: this.$t('dashboard.statuses.archived'),
          className: 'archived',
        },
      ];
    },
    campaignsStateArrayEmpty() {
      return this.campaignsStateArray.length === 0;
    },
    isProduction() {
      return env.NAME === 'production';
    },
  },
  watch: {
    '$route.query'(newVal) {
      this.fetchCampaigns(newVal);
    },
  },
  mounted() {
    this.fetchCampaigns(this.$route.query);

    if (this.$route.query.openModal) {
      this.$router.replace(this.$route.path);
      this.modalShow = true;
    }
  },
  methods: {
    linkGen(pageNum) {
      let link = '';
      const query = Object.assign({}, this.$route.query);
      query.page = pageNum;
      Object.keys(query).forEach((key, index) => {
        link += index > 0 ? `&${key}=${query[key]}` : `?${key}=${query[key]}`;
      });
      return { path: link };
    },
    doSearch() {
      if (!this.searchQuery || !this.searchField) return;

      this.$router.push({
        path: this.$route.path,
        query: {
          page: 1,
          filter: this.searchQuery,
          field: this.searchField,
        },
      });
    },
    clearSearch() {
      this.searchQuery = '';
      this.searchField = 'job_title_short';
      this.$router.push({
        path: this.$route.path,
        query: {},
      });
    },
    filterCampaigns(item) {
      this.$router.push({
        path: this.$route.path,
        query: {
          page: 1,
          filter: item.className.replace(/^\w/, (c) => c.toUpperCase()),
        },
      });
    },
    clearFilters() {
      this.searchField = 'job_title_short';
      this.searchQuery = '';

      this.$router.push({
        path: this.$route.path,
        query: {},
      });
    },
    sortingChanged(ctx) {
      const sortData = {
        path: this.$route.path,
        query: {
          page: this.$route.page || 1,
          filter: this.$route.query.filter,
          sort: ctx.sortBy,
          order: ctx.sortDesc ? 'desc' : 'asc',
        },
      };

      if (this.$route.query.filter) {
        sortData.query.filter = this.$route.query.filter;
      }
      this.$router.push(sortData);
    },
    fetchCampaigns(query = {}) {
      this.$store.commit('global/SHOW_SPINNER', {
        transparent: true,
      });
      this.paginationDict = {
        show_archived: false,
        field: '',
        value: null,
        perPage: 10,
        sortBy: 'created_at',
        order: 'desc',
        page: this.$route.query.page || 1,
      };

      if (query.filter) {
        this.paginationDict.field = query.field || 'state';
        this.paginationDict.value = query.filter;

        if (query.filter && query.filter.toLowerCase() === 'archived') {
          this.paginationDict.show_archived = true;
        }
      }

      if (query.sort) {
        this.paginationDict.sortBy = query.sort;
        this.paginationDict.order = query.order || 'asc';
      }

      return this.$store
        .dispatch('campaign/fetchCampaigns', this.paginationDict)
        .then(() => {
          if (this.$route.query.page > this.campaignsMeta.total_pages) {
            //this.clearFilters(); // reload page if result 0
          }
          this.$store.commit('global/HIDE_SPINNER');
        });
    },
    openCampaignPage(id) {
      new Promise((resolve) => {
        resolve(this.$store.dispatch('campaign/getCampaign', id));
      }).then(() => {
        if (this.current.state !== 'canceled') {
          this.$router.push(`/campaigns/${id}/edit`);
        } else {
          this.$bvToast.toast(this.$t('dashboard.change_canceled_campaign'), {
            autoHideDelay: 2000,
            variant: 'warning',
            title: 'Error',
          });
        }
      });
    },
    onComplete(value) {
      if (!this.current.info.job_title_short) {
        this.$bvToast.toast(this.$t('dashboard.job_title_should_exist'), {
          autoHideDelay: 5000,
          variant: 'warning',
          title: 'Error',
        });
        return;
      }
      this.createCampaign(value);
    },
    finalizeForm() {
      this.modalShow = false;
      this.fetchCampaigns(this.$route.query);
    },
    closeModal() {
      let confirm_ = confirm('Are you sure?');
      if (confirm_) {
        this.resetModal();
        this.modalShow = false;
      }
    },
    createDirect() {
      this.$router.push({
        name: 'CreateCampaign',
      });
    },
    createAP() {
      location.href = '/campaigns/new/ap';
    },
    useTemplate() {
      this.modalShow = false;
      this.$router.push({
        name: 'Templates',
      });
    },
    resetModal() {
      this.$store.dispatch('campaign/resetCampaign');
    },
    populateCampaigsStateArray(campaignId) {
      const campaignStateIndex = this.campaignsStateArray.indexOf(campaignId);
      if (campaignStateIndex >= 0) {
        this.campaignsStateArray.splice(campaignStateIndex, 1);
      } else {
        this.campaignsStateArray.push(campaignId);
      }
    },
    changeBulkCampaignStates(state) {
      let confirmText =
        state === 'close'
          ? this.$t('dashboard.confirm_text_close')
          : state === 'cancel'
          ? this.$t('dashboard.confirm_text_cancel')
          : state === 'archive'
          ? this.$t('dashboard.confirm_text_archive')
          : state === 'unarchive'
          ? this.$t('dashboard.confirm_text_unarchive')
          : '';
      let confirm_ = confirm(confirmText);
      if (confirm_) {
        ApiCampaign.updateBulkState(this.currentUserCompanyId, {
          campaign_ids: this.campaignsStateArray,
          state: state,
        })
          .then(() => {
            this.campaignsStateArray = [];
            this.fetchCampaigns(this.$route.query);
          })
          .catch((e) => {
            const alertMessage = e.response.data.message;
            this.$bvToast.toast(alertMessage, {
              autoHideDelay: 2000,
              variant: 'warning',
              title: 'Error',
            });
          });
      }
    },
    addKeyword(val) {
      this.current.keywords.push(val);
    },
    onSearchFieldsChange(event) {
      if (event === 'campaign_type') {
        this.searchQuery = CAMPAIGN_TYPES.JOB;
      } else {
        this.searchQuery = '';
      }
    },
    onCampaignTypeChange(event) {
      this.searchQuery = event;
    },
  },
};
</script>
