<template>
  <div class="report__chapter candidate__introheader">
    <b-row>

      <!-- <print-candidate-details :campaign="campaign"></print-candidate-details> -->
      <!-- <div>Page Number {{ index + 1 }}</div> -->

      <!-- // candidate illustration ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col sm="4" lg="4" class="col-print-4">
        <figure class="text-left">
          <img :src="CandidateDetailsSvg" class="report__candidate__illustration" alt="Candidate Illustration">
        </figure>
      </b-col>

      <!-- // candidate best match score ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col sm="8" lg="8" class="col-print-8">
        <div class="report__candidate__bms" :class="candidate.best_match_score === 0 ? 'disabled' : ''">
          <div class="report__candidate__bms__value">{{ candidate.best_match_score }}</div>
          <div class="report__candidate__bms__title underline__highlight">{{ $t('report.best_match_score') }}</div>

          <div v-if="candidate.best_match_score === 0">{{ $t('report.no_analytics_results') }}</div>
          <br>
          <p class="report__candidate__bms__rank underline__highlight">{{ $t("report.candidate_details_description", { rank: (index + 1) }) }}</p>
          <br>
          <p class="report__candidate__bms__high__score underline__highlight">{{ $t("report.high_score") }} {{ report.best_matches[0].score }}</p>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import BgSvg from "../../../../images/report/print/second-page.svg";
import CandidateDetailsSvg from "../../../../images/report/candidate_detail_new.svg";
import PrintCandidateDetails from "./CoverpageCandidate";
import AppFooter from "./PrintHeader";

export default {
  components: {
    PrintCandidateDetails,
    AppFooter
  },
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    },
    report: {
      type: Object,
      required: true,
      default: () => {}
    },
    candidate: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true,
      default: 1
    }
  },
  computed: {
    bgIcon() {
      return BgSvg;
    },

    CandidateDetailsSvg() {
      return CandidateDetailsSvg
    }
  },

};
</script>
