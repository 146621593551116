<template>
  <div class="content__wrapper">
    <div class="xk-view xk-view--no-sidebar container">
      <b-row>
        <b-col>
          <div class="survey survey__content__block">
            <div class="survey__header">
              <h1>{{ $t('match_references.thank_you_page_title') }}</h1>
            </div>

            <b-row>
              <b-col>
                <b-button
                  class="min-w-260"
                  block
                  size="lg"
                  variant="primary"
                  @click="goToSurvey"
                  >{{ $t('exit.continue_on_survey') }} </b-button
                >
              </b-col>
            </b-row>

            <!-- TMP condition -->
            <div class="survey__content__block__divider mt-4">
              <span>{{ $t('general.or') }}</span>
            </div>

            <!-- <b-row>
              <b-col>
                <b-button
                  class="min-w-260"
                  block
                  size="lg"
                  variant="outline-primary"
                  href="http://examiz.com/"
                  >{{ $t('general.exit') }}</b-button
                >
              </b-col>
            </b-row> -->
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToSurvey() {
      this.$router.push(`/exit-survey/${this.$route.params.token}`)
    }
  }
};
</script>