import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { CAMPAIGN_STATE } from '@/common/constants'

const state = {
  campaigns: [],
  campaignsMeta: {},
  currentCampaign: {
    id: "",
    start_date: "",
    end_date: "",
    time_zone: null,
    info: {
      references_requested: 4,
    },
    personality_attrs: {},
    references_requested: [],
    contacts: [],
    keywords: [],
    state: CAMPAIGN_STATE.OPEN
  },
  campaignCandidates: [],
  campaignCandidatesPagination: {},
  campaignCandidatesAPData: {},
  apCampaignRefreshKey: new Date().getTime(),
  anonymizeCandidates: JSON.parse(localStorage.getItem('anonymous') || true),
  showDetails: JSON.parse(localStorage.getItem('details') || true),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
