import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";


const state = {
  type: '',
  candidate: null,
  candidates: [],
  inProgressCandidates: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
