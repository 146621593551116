<template>
  <b-modal
    centered
    :visible="true"
    :title="$t('create_campaign.clone_campaign')"
    hide-footer
  >
    <ValidationObserver ref="form" tag="div">
      <b-form @submit.prevent="onSubmit">
        <div class="pb-2 text-danger">{{ errorMsg }}</div>

        <div class="d-block">
          <div class="form-section">
            <b-form-row v-if="isExitCampaign">
              <b-col sm="12">
                <app-text-input
                  :name="$t('general.name')"
                  :label="$t('general.name') + '*'"
                  rules="required"
                  type="text"
                  vid="job_title_short"
                  v-model="form.name"
                ></app-text-input>

                <app-text-input
                  :name="$t('create_campaign.internal_job_id')"
                  :label="$t('create_campaign.internal_job_id')"
                  vid="internal_id"
                  type="text"
                  v-model="form.internal_id"
                ></app-text-input>
              </b-col>
            </b-form-row>
            <b-form-row v-else>
              <b-col sm="12">
                <app-text-input
                  :name="$t('create_campaign.job_title_short')"
                  :label="$t('create_campaign.job_title_short') + '*'"
                  rules="required"
                  type="text"
                  vid="job_title_short"
                  v-model="form.job_title_short"
                ></app-text-input>

                <b-form-group :label="$t('create_campaign.job_title_long')">
                  <b-form-textarea
                    type="text"
                    v-model="form.job_title_long"
                  ></b-form-textarea>
                </b-form-group>

                <app-text-input
                  :name="$t('create_campaign.internal_job_id')"
                  :label="$t('create_campaign.internal_job_id') + '*'"
                  rules="required"
                  vid="internal_job_id_1"
                  type="text"
                  v-model="form.internal_job_id_1"
                ></app-text-input>
              </b-col>
            </b-form-row>
          </div>
        </div>
        <div class="text-right">
          <b-button
            class="mt-3"
            size="md"
            type="submit"
            block="block"
            variant="primary"
          >
            {{ $t('create_campaign.clone_campaign') }}
            <b-spinner
              v-if="inProgress"
              variant="light"
              label="Spinning"
              style="top: 6px"
            ></b-spinner>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTextInput from '@/components/TextInput';
import { EXIT_CAMPAIGN_TYPES, EXAMIZ_APPS } from '../common/constants';
import Spinner from '@/components/Spinner';

export default {
  components: {
    AppTextInput,
    Spinner,
  },
  props: {
    value: {
      type: Boolean,
    },
    currentCampaign: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      form: {},
      isExitCampaign: false,
      inProgress: false,
      errorMsg: '',
    };
  },
  computed: {
    ...mapGetters('exit', ['campaign']),
    ...mapGetters('campaign', ['current']),
    ...mapGetters('global', ['spinnerOptions']),
  },
  mounted() {
    this.isExitCampaign = !this.currentCampaign.is_applicant_portal;
    this.initForm();
  },
  methods: {
    initForm() {
      if (this.currentCampaign.is_applicant_portal) {
        this.form = {
          internal_job_id_1: this.currentCampaign.info.internal_job_id_1,
          job_title_short: this.currentCampaign.info.job_title_short,
          job_title_long: this.currentCampaign.info.job_title_long,
          id: this.currentCampaign.id,
          applicant_portal: this.currentCampaign.ap_data,
        };
      } else {
        this.isExitCampaign = true;
        this.form = {
          name: '',
          internal_id: '',
          id: this.currentCampaign.id,
        };
      }
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return;
        this.inProgress = true;

        const currentAppType = localStorage.getItem('app');
        if (currentAppType === EXAMIZ_APPS.EXIT.type) {
          this.exitCampaignClone();
        } else {
          this.apCampaignClone();
        }
      });
    },
    exitCampaignClone() {
      this.$store
        .dispatch('exit/cloneCampaign', this.form)
        .then(() => {
          window.location.href =
            this.currentCampaign.campaign_type === EXIT_CAMPAIGN_TYPES.SIMPLE
              ? `/exit/campaigns/${this.campaign.id}/edit`
              : `/exit/campaigns/analytics/${this.campaign.id}/edit`;
          // this.currentCampaign.campaign_type === EXIT_CAMPAIGN_TYPES.SIMPLE
          //   ? this.$router.push(`/exit/campaigns/${this.campaign.id}/edit`)
          //   : this.$router.push(
          //       `/exit/campaigns/analytics/${this.campaign.id}/edit`
          //     );
          // this.inProgress = false;
          // this.$emit('onSubmit', false);
        })
        .catch((e) => {
          if (e.response && e.response.status === 422) {
            this.errorMsg = this.$t('campaign_template.name.error.uniqueness');
          }
          this.inProgress = false;
        });
    },
    apCampaignClone() {
      this.$store.dispatch('campaign/cloneCampaign', this.form).then(() => {
        window.location.href = `/campaigns/ap/${this.current.id}/edit`;
        // this.$router.push(`/campaigns/ap/${this.current.id}/edit`);
        // this.inProgress = false;
        // this.$emit('onSubmit', false);
      });
    },
  },
};
</script>
