<template>

  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }" mode="lazy">
      <b-form-group :label="label" :class="errors[0] ? 'form-group-error' : ''">
          <b-form-select :options="options" v-model="currentValue" :size="size" :disabled="readonly" @change="onChange"></b-form-select>
          <div class="create-campaign-info" v-b-tooltip.hover :title="title" v-if="title">
              <app-tooltip></app-tooltip>
          </div>
          <div class="error-message" v-if="errors[0]">* {{ errors[0] }}</div>
      </b-form-group>
  </ValidationProvider>

</template>

<script>
import AppTooltip from "./Tooltip";
export default {
  name: "TextInput",
  components: {
    AppTooltip
  },
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value || "";
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    }
  }
};
</script>
