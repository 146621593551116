<template>
  <ValidationObserver ref="form" tag="div">
    <b-form class="create-campaign-wrp mt-3">

      <b-row>
        <b-col xl="3" lg="6" md="6">

          <!-- job details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../images/campaign_icons/resume.svg"
              class="mr-3 mb-3"
              alt="Details"
              width="60"
            />
            <span class="form__title">{{ $t("create_campaign.job_details") }}</span>
          </div>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.internal_job_id') + ' #1'"
              :label="$t('create_campaign.internal_job_id') + ' #1*'"
              rules="required"
              vid="internal_job_id_1"
              type="text"
              v-model="info.internal_job_id_1"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.internal_job_id') + ' #2'"
              :label="$t('create_campaign.internal_job_id') + ' #2'"
              vid="internal_job_id_2"
              type="text"
              v-model="info.internal_job_id_2"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.job_title_short')"
              :label="$t('create_campaign.job_title_short') + '*'"
              rules="required"
              type="text"
              vid="job_title_short"
              v-model="info.job_title_short"></app-text-input>

          <ValidationProvider :name="$t('create_campaign.job_title_long')" vid="job_title_long" mode="lazy">
            <b-form-group slot-scope="{ errors }" :label="$t('create_campaign.job_title_long')">
              <b-form-textarea
                  :readonly="isActivated"
                  :class="errors[0] ? 'form-control-error' : ''"
                  type="text"
                  v-model="info.job_title_long"></b-form-textarea>
              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>

          <app-select-input
              :readonly="isActivated"
              :name="$t('create_campaign.job_level')"
              :label="$t('create_campaign.job_level') + '*'"
              :options="jobLevels"
              v-model="info.job_level"
              rules="required"
              vid="job_level"></app-select-input>

          <app-select-input
              :readonly="isActivated"
              :name="$t('create_campaign.job_category')"
              :label="$t('create_campaign.job_category') + '*'"
              rules="required"
              vid="job_category"
              v-model="info.job_category"
              :options="jobCategories"></app-select-input>

          <b-form-group :label="$t('create_campaign.keywords')">
            <app-multiselect
                v-model="current.keywords"
                :options="keywords_options"
                :taggable="true"
                :searchable="true"
                @add="addTag"
                @async-find="asyncFind"></app-multiselect>
          </b-form-group>
        </b-col>

        <b-col xl="3" lg="6" md="6">

          <!-- campaign management details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../images/campaign_icons/partner.svg"
              class="mr-3 mb-3"
              alt="Client"
              width="60"
            />
            <span class="form__title">{{ $t("create_campaign.campaign_management_details") }}</span>
          </div>
          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.client_id')"
              :label="$t('create_campaign.client_id')"
              vid="client_id"
              type="text"
              :title="$t('create_campaign.e_g_contract_number')"
              v-model="info.client_id"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.client_name')"
              :label="$t('create_campaign.client_name') + '*'"
              rules="required"
              vid="client_name"
              type="text"
              :title="$t('create_campaign.client_name_description')"
              v-model="info.client_name"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.department')"
              :label="$t('create_campaign.department')"
              vid="department"
              type="text"
              v-model="info.department"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('create_campaign.location_office')"
              :label="$t('create_campaign.location_office')"
              vid="location"
              type="text"
              v-model="info.location"></app-text-input>
        </b-col>

        <b-col xl="3" lg="6" md="6">

          <!-- client contact ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../images/campaign_icons/on_call.svg"
              class="mr-3 mb-3"
              alt="Contact"
              width="60"
            />
            <span class="form__title">{{ $t("create_campaign.client_contact") }}</span>
          </div>

          <app-select-input
              :name="$t('general.title')"
              :label="$t('general.title') + '*'"
              :options="clientTitles"
              v-model="info.client_contact_title"
              vid="client_contact_title"
              rules="required"></app-select-input>

          <app-text-input
              :name="$t('general.first_name')"
              :label="$t('general.first_name') + '*'"
              :title="$t('create_campaign.can_be_yourself')"
              vid="client_contact_first_name"
              rules="required"
              type="text"
              v-model="info.client_contact_first_name"></app-text-input>

          <app-text-input
              :name="$t('general.last_name')"
              :label="$t('general.last_name') + '*'"
              vid="client_contact_last_name"
              rules="required"
              type="text"
              v-model="info.client_contact_last_name"></app-text-input>

          <app-select-input
              :name="$t('create_campaign.role')"
              :label="$t('create_campaign.role') + '*'"
              :options="clientContactRoles"
              v-model="info.client_contact_role"
              rules="required"
              vid="client_contact_role"></app-select-input>

          <app-text-input
              :name="$t('general.email')"
              :label="$t('general.email') + '*'"
              vid="client_contact_email"
              rules="required|email"
              type="email"
              v-model="info.client_contact_email"></app-text-input>

          <app-text-input
              :name="$t('create_campaign.phone_number')"
              :label="$t('create_campaign.phone_number') + '*'"
              :title="$t('create_campaign.phone_number_description')"
              :rules="{ required: true, regex: /^[+][0-9]{7,15}$/ }"
              type="text"
              vid="client_contact_phone"
              v-model="info.client_contact_phone"></app-text-input>
        </b-col>

        <b-col xl="3" lg="6" md="6">

          <div>
            <img
              src="../../../images/campaign_icons/schedule.svg"
              class="mr-3 mb-3"
              alt="Admin"
              width="60"
            />
            <span class="form__title">{{ $t("create_campaign.campaign_admin") }}</span>
          </div>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.first_name')"
              :label="$t('general.first_name')"
              type="text"
              v-model="info.campaign_admin_1_first_name"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.last_name')"
              :label="$t('general.last_name')"
              type="text"
              v-model="info.campaign_admin_1_last_name"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.email')"
              :label="$t('general.email')"
              type="email"
              rules="email"
              v-model="info.campaign_admin_1_email"></app-text-input>

          <div class="mt-3">
            <img
              src="../../../images/campaign_icons/consultant.svg"
              class="mr-3 mb-3"
              alt="Expert"
              width="60"
            />
            <span class="form__title">{{ $t("create_campaign.subject_matter_expert") }}</span>
          </div>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.first_name')"
              :label="$t('general.first_name')"
              type="text"
              v-model="info.campaign_admin_2_first_name"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.last_name')"
              :label="$t('general.last_name')"
              type="text"
              v-model="info.campaign_admin_2_last_name"></app-text-input>

          <app-text-input
              :readonly="isActivated"
              :name="$t('general.email')"
              :label="$t('general.email')"
              type="email"
              rules="email"
              v-model="info.campaign_admin_2_email"></app-text-input>
        </b-col>
      </b-row>
    </b-form>
<!--
    <b-row>
      <b-col xl="3" lg="3" md="3" offset-md="9" offset-lg="9" offset-xl="9">
        <b-form-group class="mr-3 checkbox-wrap">
          <b-form-checkbox v-model="info.include_analysis" :disabled="isActivated">{{$t("create_campaign.unwanted_behavior")}}</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
-->
  </ValidationObserver>
</template>

<script>
import AppMultiselect from "../../components/CustomMultiselect";
import AppTooltip from "../../components/Tooltip";
import AppTextInput from "../../components/TextInput";
import AppSelectInput from "../../components/SelectInput";
import CampaignHelpersMixin from "../../common/CampaignHelpersMixin";
import { mapGetters } from "vuex";
import ApiCampaign from "../../api/campaign";
export default {
  components: {
    AppMultiselect,
    AppTooltip,
    AppTextInput,
    AppSelectInput,
  },
  mixins: [CampaignHelpersMixin],
  props: {
    campaignId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      keywords_options: [],
      form: {},
    };
  },
  computed: {
    ...mapGetters("global", [
      "currentUserFirstName",
      "currentUserLastName",
      "currentUserEmail",
    ]),
    ...mapGetters("campaign", ["current"]),
    info: {
      get() {
        const info = this.current.info || {};
        const defaultAdmin = {
          campaign_admin_1_first_name: this.currentUserFirstName,
          campaign_admin_1_last_name: this.currentUserLastName,
          campaign_admin_1_email: this.currentUserEmail,
        };
        for (const key in defaultAdmin) {
          if (!(key in info)) {
            info[key] = defaultAdmin[key];
          }
        }
        return info;
      },
      set(value) {
        this.$emit("update:current", {
          ...this.current,
          info: value,
        });
      },
    },
    isActivated() {
      return this.current.state !== "open" && this.current.state !== undefined;
    }
  },
  methods: {
    asyncFind(value) {
      // TODO: Check if we need this function in the future
      // ApiCampaign.getKeywords(value).then((response) => {
      //   this.keywords_options = response.data.campaign_keywords;
      // });
    },
    addTag(item) {
      this.keywords_options.push(item);
      this.$emit("add-keyword", item);
    },
    validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>
