<template>
    <div id="map"></div>
</template>

<script>
import tt from '@tomtom-international/web-sdk-maps';

export default {
  props: {
    location: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      map: null,
      marker: null,
    };
  },
  mounted() {
    this.initMap();
    this.setCurrentLocation();
  },
  methods: {
    initMap() {
      this.map = tt.map({
        key: 'P7ZeyJ76f6tivhAfcR9AmCYDykmaifD2',
        container: 'map',
        style: 'tomtom://vector/1/basic-main',
      });
      this.map.addControl(new tt.NavigationControl());
      this.map.on('click', this.onMapClick);
      this.map.on('render', () => {
        this.map.resize();
      });
    },
    setCurrentLocation() {
      if (this.location && this.location.lat && this.location.lng) {
        const location = new tt.LngLat(this.location.lng, this.location.lat);
        this.setMarker(location);
      } else if (navigator.geolocation && !this.location.length) {
        const position = navigator.geolocation.getCurrentPosition((position) => {
          const location = new tt.LngLat(position.coords.longitude, position.coords.latitude);
          this.setMarker(location);
        });
      }
    },
    setMarker(location) {
      if (!this.marker) this.marker = new tt.Marker();
      this.marker
        .setLngLat([location.lng, location.lat])
        .addTo(this.map);
      this.map.setZoom(10);
      this.map.setCenter(location);
    },
    onMapClick(event) {
      this.$emit('onClick', {
        position: event.lngLat
      })
    }
  },
};
</script>
