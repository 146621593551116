<template>
  <div class="xmz-table-multiselect">
    <multiselect
      selectLabel=""
      deselectLabel=""
      thead-class="d-none"
      v-model="model"
      :disabled="readonly"
      :options="unselectedItems"
      :label="'text'"
      :taggable="taggable"
      :placeholder="placeholder || $t('general.select_option')"
      @tag="addTag"
      @select="onItemSelect"
      @search-change="onSearchChange"
      ref="ms"
    >
      <!-- TODO: Create searching element -->
      <div v-if="isApiSearch && showApiSearchingLoader" slot="noResult" style="text-align: center;font-size: 0.7rem;">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
      </div>
    </multiselect>

    <b-table
      :borderless="true"
      :hover="true"
      :fields="fields"
      :items="selectedItems"
    >
      <template #cell(actions)="row">
        <div class="actions">
          <b-form-select
            v-if="actionsOptions && !readonly"
            v-model="row.item.option"
            :disabled="readonly"
            @change="onSelectChange($event, row)">

              <template>
                <b-form-select-option
                  v-for="(item, key) in actionsOptions"
                  :key="key"
                  :value="item"
                >
                  {{ item.text }}
                </b-form-select-option>
              </template>
          </b-form-select>

          <span v-if="actionsOptions && readonly">{{ row.item.option.text }}</span>

          <b-form-checkbox
            v-if="!actionsOptions"
            v-model="row.item.required"
            :disabled="readonly"
            @change="onCheckboxChange($event, row)">

              <span>{{ $t('general.required') }}</span>
          </b-form-checkbox>

          <b-button
            v-b-tooltip.hover
            v-if="!readonly"
            :title="$t('general.remove')"
            class="btn-action"
            @click="removeItem(row)"
            ><span class="mdi mdi-close"></span>
          </b-button>
        </div>
      </template>
    </b-table>

    <div v-if="!isValid" class="error">{{ $t('validation.required') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
       type: Array,
       default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null
    },
    actionsOptions: {
      type: Array,
      default: null,
    },
    taggable: {
      type: Boolean,
      default: false
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    isApiSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      unselectedItems: [],
      selectedItems: [],
      actionsOption: null,
      fields: [
        {
          key: 'text',
          label: 'data', //todo pass this trought props
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'actions',
          label: 'Actions', // TODO: Translate this
          thStyle: {
            display: 'none',
          },
        },
      ],
      isValid: true,
      selectedAction: null,
      showApiSearchingLoader: false,
      showApiSearchingTimeout: null,
      searchTimeout: null,
      isMounted: false,
    };
  },
  computed: {
    model: {
      get() {
        return [];
      },
      set(val) {
        this.$emit('input', this.selectedItems);
      },
    },
    checkboxLabel() {
      return this.$t('general.required');
    },
  },
  watch: {
    options() {
      this.filterSelectedItems();
    },
  },
  mounted() {
    this.filterSelectedItems();

    if (!this.isMounted) {
      this.selectedItems = this.value;
      this.isMounted = true;
    }
  },
  methods: {
    filterSelectedItems() {
      this.unselectedItems = this.options.filter(
        (option) =>
          !this.selectedItems.find((item) => item.value === option.value)
      );
    },
    onItemSelect(item) {
      const itemObj = Object.assign({}, item);
      if (this.actionsOptions) {
        itemObj.option = this.actionsOptions[0] || null;
      } else {
        itemObj.required = false;
      }

      this.selectedItems.push(itemObj);
      this.filterSelectedItems();

      this.emitChange();
    },
    removeItem(row) {
      this.selectedItems.splice(row.index, 1);
      this.filterSelectedItems();
    },
    onSearchChange(value, id) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.$emit('searchChange', value);
      }, 500);

      if (this.isApiSearch) {
        this.showApiSearchingLoader = true;
      }
    },
    onSelectChange(event, row) {
      const item = this.selectedItems[row.index];
      delete item.required;

      this.emitChange();
    },
    onCheckboxChange(event, row) {
      const item = this.selectedItems[row.index];
      item.required = event;
      delete item.option;
      this.emitChange();
    },
    addTag (newTag) {
      const tag = {
        text: newTag,
        value: newTag,
        isKeyword: true,
        required: false,
      }
      this.selectedItems.push(tag)
      this.$emit('input', this.selectedItems);
    },
    emitChange() {
      this.$emit('change', this.selectedItems);
    },
    hideLoader() {
      this.showApiSearchingLoader = false;
    }
  },
};
</script>

<style lang="scss">
.xmz-table-multiselect {
  table {
    tr {
      td:first-child {
        // padding-top: 0px;
        // padding-left: 5px;
        width: 75%;
        font-weight: 600;
      }
    }
  }

  select {
    height: 25px;
    font-size: 1.25rem;
    margin-right: 10px;
    margin-top: -2px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 300px;
      text-align: right;
    }
  }

  .btn-action {
    width: 20px;
    height: 20px;
    padding: 0px;
    float: right;
  }

  .custom-checkbox {
    min-height: auto;

    .custom-control-label {
      display: block;
      margin-bottom: 0;
    }

    .custom-control-label:before {
      margin-left: -5px;
    }

    .custom-control-label::after {
      margin-left: -5px;
    }
  }

  .error {
    color: #de0632;
  }
}
</style>