<template>
  <div class="report__chapter">
    <div>
      <h3 class="report__chapter__title">
        <span>{{ $t('js_report.talent_name') }}</span>
      </h3>
      <h3>{{ fullName }}</h3>
    </div>

    <div>
      <h3 class="report__chapter__title">
        <span>{{ $t('js_report.assessment_date') }}</span>
      </h3>
      <h3>{{ assessmentDate }}</h3>
    </div>

    <div>
      <h3 class="report__chapter__title">
        <span>{{ $t('js_report.score_id') }}</span>
      </h3>
      <h3># {{ scoreId }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scoreId: {
      type: Number,
      required: false
    },
    assessmentDate: {
      type: String,
      required: false
    },
    fullName: {
      type: String,
      required: true,
      default: '',
    }
  }
};
</script>
