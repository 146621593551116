import { mapGetters } from 'vuex';
import { orderBy } from 'lodash';
import { CONSOLE_LOG_MSG } from '@/common/constants';

const SelfTestMixin = {
  computed: {
    ...mapGetters({
      report: 'report/selfTestReport',
    }),
    ...mapGetters('campaign', ['current']),
    ...mapGetters('global', [
      'currentUserToken',
      'currentLanguage',
      'currentUserId',
      'currentUserFirstName',
      'currentUserLastName',
      'talentsAttributes',
      'isRegistered'
    ]),
    inProgress() {
      return !this.report || !this.report.id;
    },
    personalityType() {
      return orderBy(this.report.personality_type, ["value"], ["desc"]);
    },
    values() {
      return this.report && this.report.values
        ? orderBy(this.report.values, ["value"], ["desc"])
        : [];
    },
    competenciesB1() {
      return this.report && this.report.competencies
        ? this.filterCompetenices(
            orderBy(this.report.competencies["B1"], ["value"], ["desc"])
          )
        : [];
    },
    competenciesB2() {
      return this.report && this.report.competencies
        ? this.filterCompetenices(
            orderBy(this.report.competencies["B2"], ["value"], ["desc"])
          )
        : [];
    },
    traits() {
      return this.report && this.report.traits
        ? this.filterCompetenices(
            orderBy(this.report.traits, ["value"], ["desc"])
          )
        : [];
    },
    fullName() {
      return `${this.currentUserFirstName} ${this.currentUserLastName}`;
    },
    interviewQuestions() {
      return this.report.interview_questions;
    },
    hiddenMsg() {
      return CONSOLE_LOG_MSG;
    }
  },
};

export default SelfTestMixin;
