<template>
  <div class="interview__question">
    <b-row>
      <b-col md="12" lg="10">
        <div class="interview__question__content fullwidth">
          <h3 class="interview__question__topic">{{ $t('report.interview.do') }}</h3>
          <ul>
            <li v-for="index in 8" :key="index">{{ $t('report.interview.list_text_' + index) }}</li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  computed: {}
}
</script>
