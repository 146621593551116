import { CAMPAIGN_STATE } from '@/common/constants'

const SET_CAMPAIGN_LIST = (state, data) => {
  state.campaigns = data.campaigns;
  state.campaignsMeta = data.meta;
};

const SET_CAMPAIGN = (state, data) => {
  state.currentCampaign = data;
};

const RESET_CAMPAIGN = (state) => {
  state.currentCampaign = null;
  state.currentCampaign = Object.assign({}, {
    id: "",
    start_date: "",
    end_date: "",
    time_zone: null,
    info: {
      references_requested: 4
    },
    personality_attrs: {},
    references_requested: [],
    contacts: [],
    keywords: [],
    state: CAMPAIGN_STATE.OPEN
  });
};

const UPDATE_CAMPAIGN = (state, data) => {
  let index = state.campaigns.findIndex((item) => item.id === data.id);
  if (index >= 0) {
    state.campaigns.splice(index, 1, data);
  }
  state.currentCampaign = data;
};

const UPDATE_STATE_CAMPAIGN = (state, data) => {
  state.currentCampaign = data;
};

const USE_TEMPLATE = (state, data) => {
  data.id = '';
  data.info.references_requested = 4;

  state.currentCampaign = data;
};

const UPDATE_AP_REFRESH_KEY = (state) => {
  state.apCampaignRefreshKey = new Date().getTime()
};

const SET_CAMPAIGN_STATE = (state, value) => {
  state.currentCampaign.state = value;
}

const SET_CAMPAIGN_CANDIDATES = (state, candidates) => {
  state.campaignCandidates = candidates
}

const ANONYMIZE_CANDIDATES = (state, value) => {
  state.anonymizeCandidates = value
  localStorage.setItem('anonymous', value)
}

const SET_SHOW_DETAILS = (state, value) => {
  state.showDetails = value
  localStorage.setItem('details', value)
}

const SET_CAMPAIGN_CANDIDATES_PAGINATION = (state, pagination) => {
  state.campaignCandidatesPagination = pagination;
}

const SET_CANDIDATE_AP_DATA = (state, data) => {
  state.campaignCandidatesAPData = Object.assign(
    {},
    state.campaignCandidatesAPData,
    data
  )
}

export default {
  SET_CAMPAIGN_LIST,
  SET_CAMPAIGN,
  RESET_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_STATE_CAMPAIGN,
  USE_TEMPLATE,
  SET_CAMPAIGN_STATE,
  SET_CAMPAIGN_CANDIDATES,
  SET_CAMPAIGN_CANDIDATES_PAGINATION,
  SET_CANDIDATE_AP_DATA,
  UPDATE_AP_REFRESH_KEY,
  ANONYMIZE_CANDIDATES,
  SET_SHOW_DETAILS
};
