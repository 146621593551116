
<template>
  <b-table
    hover
    :fields="fields"
    :items="candidates"
    responsive
    caption-top
    class="survey__situation__body__table"
  >
    <template #table-caption>{{ $t('create_campaign.candidates') }}</template>
    <template #cell(status)="data">
      <b-icon-x-square  v-if="isCandidateInProgress(candidates[data.index].id)"></b-icon-x-square>
      <b-icon-check-circle v-else></b-icon-check-circle>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    candidates: {
      type: Array,
      default: () => [],
    },
    inProgressCandidatesIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'title', label: this.$t('general.title') },
        { key: 'first_name', label: this.$t('general.first_name') },
        { key: 'last_name', label: this.$t('general.last_name') },
        { key: 'status', label: this.$t('dashboard.status') },
      ],
    };
  },
  methods: {
    isCandidateInProgress(id) {
      return this.inProgressCandidatesIds.includes(id);
    },
  },
};
</script>
