import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  report: null,
  reportCampaign: null,
  reportAvgValues: null,
  selfTestReport: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
