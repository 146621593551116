<template>
  <b-container>
    <b-row>

      <b-col md="6">
        <b-img class="auth-page-img" :src="loginBg" fluid="fluid" :alt="pageTitle"></b-img>
      </b-col>

      <b-col lmd="6">
        <div class="auth-page-title mb-2">{{ $t("general.login") }}</div>

        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <b-form class="auth-form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="auth-form-inner">

              <ValidationProvider :name="$t('general.email')" rules="required|email" mode="lazy" vid="email">
                <div class="form-group form-group-input form-group-input__first" slot-scope="{ errors }"><input class="form-control" :class="errors[0] ? 'form-control-error' : ''" type="text" v-model="form.email" id="email" /><label for="email">{{ $t("general.email") }}</label>
                  <div class="error-message" v-if="errors[0]">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>

              <ValidationProvider :name="$t('general.password')" rules="required" mode="lazy">
                <div class="form-group form-group-input form-group-input__last" slot-scope="{ errors }"><input class="form-control" :class="errors[0] ? 'form-control-error' : ''" type="password" v-model="form.password" id="password" /><label for="password">{{ $t("general.password") }}</label>
                  <div class="error-message"
                       v-if="errors[0]">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>

            </div>
            <div class="text-right mt-2 mb-4">
              <router-link class="forgot-link" to="/reset-password">{{ $t("auth_pages.forgot_password_question") }} ?</router-link>
            </div>

            <b-button size="lg" type="submit" block="block" variant="primary">
              <b-spinner v-if="isInProgress" variant="light" small="small" label="Spinning"></b-spinner>{{ $t("header.sign_in") }}</b-button>
          </b-form>

        </ValidationObserver>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import Illustration from "../../../../images/security_new.svg";
import franchise from '../../../conf/franchise';

export default {
  data() {
    return {
      form: {},
      isInProgress: false,
      errorMessage: ""
    };
  },

  computed: {
    pageTitle() {
      return franchise.pageTitle;
    },
    loginBg() {
      return Illustration;
    }
  },

  methods: {
    onSubmit() {
      this.isInProgress = true;
      this.$store
        .dispatch("global/authUser", {
          email: this.form.email,
          password: this.form.password
        })
        .then(() => {
          this.isInProgress = false;
          this.$router.push("/");
        })
        .catch((error) => {
          this.$refs.form.setErrors({
            email: [error]
          });
          this.isInProgress = false;
        });
    }
  }
};
</script>
