export default {
  url: 'sport.examiz.net',
  apiUrl: 'https://sportapi.examiz.net',
  faq: 'https://www.examiz.com/faq',
  baseUrl: 'https://www.examiz.net',
  favicon: 'favicon.ico',
  pageTitle: 'Examiz',
  matchTitle:  'Examiz Match',
  matchReportTitle: 'Examiz MATCH Report',
  pulseTitle: 'Examiz Pulse',
  pulseReportTitle: 'Examiz PULSE Report',
  termsAndConditionLink: 'https://www.examiz.com/terms-and-conditions',
  userAgreement: 'https://www.examiz.com/user-agreement',
  privacyPolicyLink: 'https://www.examiz.com/privacy-policy'
}