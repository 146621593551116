<template>
  <div :class="{transparent: options.transparent}">
    <BSpinner class="spinner" />
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  &.transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .spinner {
    color: var(--primary);
    position: fixed;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    width: 5rem;
    height: 5rem;
    font-size: 4rem;
  }
}
</style>
