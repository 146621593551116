import axios from "axios";

const companyLogoByUrl = () => {
  return axios.get("/api/v1/companies/company_logo_by_url");
};

const getCompanyRecruiters = (companyId) => {
  return axios.get(`/api/v1/companies/${companyId}/recruiters`);
};

const createCompanyRecruiter = (companyId, data) => {
  return axios.post(`/api/v1/companies/${companyId}/recruiters`, {recruiter: data});
};

const updateCompanyRecruiter = (companyId, recruiterId, data) => {
  return axios.put(`/api/v1/companies/${companyId}/recruiters/${recruiterId}`, {recruiter: data});
};

const batchUpdateCompanyRecruiters = (companyId, recruiterIds, data) => {
  return axios.put(`/api/v1/companies/${companyId}/recruiters`, {recruiters: recruiterIds, update: data});
};

const deleteCompanyRecruiter = (companyId, recruiterId) => {
  return axios.delete(`/api/v1/companies/${companyId}/recruiters/${recruiterId}`);
};

const batchDeleteCompanyRecruiters = (companyId, recruiterIds) => {
  return axios.delete(`/api/v1/companies/${companyId}/recruiters`, {data: {recruiters: recruiterIds}});
};

const getCompanyInvitations = (companyId, recruiterIds) => {
  return axios.get(`/api/v1/companies/${companyId}/recruiter_invitations`);
};

const deleteCompanyInvitation = (companyId, invitationId) => {
  return axios.delete(`/api/v1/companies/${companyId}/recruiter_invitations/${invitationId}`)
}

const resendCompanyInvitation = (companyId, invitationId) => {
  return axios.put(`/api/v1/companies/${companyId}/recruiter_invitations/${invitationId}/resend`)
}

const acceptInvitation = (companyId) => {
  return axios.put(`/api/v1/companies/${companyId}/recruiter_invitations/accept`);
}

const getPreviewCampaignCompany = (token) => {
  return axios.get(`/api/v1/campaigns/preview_campaign_company?_token=${token}`);
}

export default {
  companyLogoByUrl,
  getCompanyRecruiters,
  createCompanyRecruiter,
  updateCompanyRecruiter,
  batchUpdateCompanyRecruiters,
  deleteCompanyRecruiter,
  batchDeleteCompanyRecruiters,
  getCompanyInvitations,
  deleteCompanyInvitation,
  resendCompanyInvitation,
  acceptInvitation,
  getPreviewCampaignCompany,
};
