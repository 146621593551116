<template lang="pug">
.survey__counter
  strong {{ number | paddy(2) }}
  | {{ ' ' }} / {{ total | paddy(2) }}
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  }
};
</script>
