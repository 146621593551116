export const languages = [
  {
    label: "EN",
    value: "en"
  },
  {
    label: "DE",
    value: "de"
  }
];
