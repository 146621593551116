<template>
  <b-row>
    <b-col>
      <b-card class="mt-4">
        <b-card-title>{{ currentStepTitle }}</b-card-title>
        <b-card-text><h3>{{ currentStepSubtilie }}</h3></b-card-text>
        <b-card-text class="text-muted">{{ campaignInfo }}</b-card-text>
        <b-card-text>
          <form-wizard class="candidate-wizard" ref="wizard" @on-change="onChange" title="" subtitle="" :color="primaryColor">
            <tab-content @next="next">
              <step-first @next="next" :campaign="campaign" @add="addCandidateReference" @remove="removeCandidateReference"></step-first>
            </tab-content>

            <tab-content title="">
              <step-second @done="done" @prev="prev" :campaign="campaign"></step-second>
            </tab-content>
          </form-wizard>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import StepFirst from "./StepFirst";
import StepSecond from "./StepSecond";
import ApiReference from "../../../api/references";
export default {
  components: {
    StepFirst,
    StepSecond
  },

  props: {
    campaign: {
      type: Object,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isValidated: false,
      stepIndex: 0,
      primaryColor: '#E8710F'
    };
  },

  computed: {
    stepNumber() {
      return this.stepIndex + 1;
    },

    currentStepTitle() {
      return this.$t(`match_candidate.step_${this.stepNumber}`);
    },

    currentStepSubtilie() {
      return this.$t(`match_candidate.wizard_title_step_${this.stepNumber}`);
    },

    campaignInfo() {
      return this.$t("match_candidate.examiz_campaign_id", {
        id: this.campaign.campaign_id
      });
    }
  },

  methods: {
    next() {
      this.$refs.wizard.nextTab();
    },

    prev() {
      this.$refs.wizard.prevTab();
    },

    done() {
      localStorage.setItem("candidate_email", this.campaign['candidate_email']);
      this.onComplete();
    },

    addCandidateReference() {
      this.$emit("populate-reference");
    },

    removeCandidateReference(val) {
      this.$emit("remove-reference", val);
    },

    onComplete() {
      ApiReference.populateReferences({
        token: this.token,
        campaign_reference: { references: this.campaign.references }
      })
        .then(() => {
          this.$router.push(`/candidate/${this.token}/congratulations`);
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            autoHideDelay: 5000,
            variant: "danger",
            title: "Error"
          });
        });
    },

    onChange(prevIndex, nextIndex) {
      this.stepIndex = nextIndex;
    }
  },

  created() {
    this.$store.dispatch("global/fetchTitles");
  },
};
</script>
