<template>
  <div class="report__chapter only-print">
      <b-row>
        <b-col>
          <h3 class="report__chapter__title"><span :class="title === $t('report.interview_questions') ? 'mdi mdi-account-group-outline' : 'mdi mdi-account-group'"></span><span>{{ title }}</span></h3>
        </b-col>
      </b-row>
  </div>
</template>

<script>

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>
