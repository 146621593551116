<template>
<div>

  <div class="dashboard-table-top border-bottom-0 p-0">
    <b-row>
      <b-col>
        <h1 class="campaign__title">{{ info.job_title_short }}</h1>
      </b-col>
    </b-row>
  </div>


  <div class="dashboard-table-top pt-0 pb-4">
    <b-row>
      <b-col lg="12">
        <div class="form__title">
          <img
            src="../../../images/campaign_icons/bootstrap_startup.svg"
            class="mr-3 mb-3 ml-3"
            alt="Details"
            width="60"
          />
          {{ $t('create_campaign.campaign_details') }}
        </div>
      </b-col>

       <b-col lg="2">
        <div class="campaign__attribute__label">{{ $t('create_campaign.campaign_type') }}</div>
        <div class="campaign__attribute__value">{{ campaignType }}</div>
      </b-col>

      <b-col lg="2">
        <div class="campaign__attribute__label">{{ $t('create_campaign.min_references_requested') }}</div>
        <div class="campaign__attribute__value">{{ info.references_requested }}</div>
      </b-col>

      <b-col lg="2">
        <div class="campaign__attribute__label">{{ $t('create_campaign.starting_date') }}</div>
        <div class="campaign__attribute__value">{{ start_date | format-date }}</div>
      </b-col>

      <b-col lg="2">
        <div class="campaign__attribute__label">{{ $t('create_campaign.end_date') }}</div>
        <div class="campaign__attribute__value">{{ end_date | format-date }}</div>
      </b-col>

      <b-col lg="3">
        <div class="campaign__attribute__label">{{ $t('general.timezone') }}</div>
        <div class="campaign__attribute__value">
          {{ timeZone }}
        </div>
      </b-col>

      <b-col lg="3">
        <div class="campaign__attribute__label border-0"><br></div>
        <!-- <div class="campaign__attribute__value">
          <b-form-checkbox id="checkbox-1" v-model="unwanted_behavior" name="checkbox-1">
            {{ $t("create_campaign.unwanted_behavior") }}
          </b-form-checkbox>
        </div> -->
      </b-col>

    </b-row>
  </div>

  <b-row>
    <b-col lg="9">

      <div class="form__title mt-5">
        <img
          src="../../../images/campaign_icons/applicants_screening.svg"
          class="mr-3 ml-3"
          alt="Candidates"
          width="60"
        />
        {{ $t('create_campaign.candidates') }}
      </div>

      <div class="table-wrp text-center">
        <app-table :items="contacts" :fields="contactFields"></app-table>
      </div>

    </b-col>

    <b-col class="aside" lg="3">
      <div class="aside__content">
        <div class="skills-item-wrp">

          <h6 class="aside__title">
            <span>{{ $t("create_campaign.skills_competencies") }}</span>
          </h6>

          <div class="skill__item" v-for="(item, index) in personality_attrs.traits" :key="'traits' + index">
            <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
            <div class="skill__item__value">{{ item.value }}</div>
            <div class="skill__item__progress">
              <div class="skill__item__progressbar" :style="{ width: item.value  + '%' }"></div>
            </div>
          </div>

          <div class="skill__item" v-for="(item, index) in personality_attrs.values" :key="'values' + index">
            <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
            <div class="skill__item__value">{{ item.value }}</div>
            <div class="skill__item__progress">
              <div class="skill__item__progressbar" :style="{ width: item.value + '%' }"></div>
            </div>
          </div>

          <div class="skill__item" v-for="(item, index) in personality_attrs.competencies" :key="'competencies' + index">
            <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
            <div class="skill__item__value">{{ item.value }}</div>
            <div class="skill__item__progress">
              <div class="skill__item__progressbar" :style="{ width: item.value  + '%' }"></div>
            </div>
          </div>

        </div>
      </div>


    </b-col>
  </b-row>
</div>
</template>

<script>
import AppTable from "./Table";
import { mapGetters } from "vuex";
export default {
  components: {
    AppTable
  },
  props: {
    campaignId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters("campaign", ["current"]),
    ...mapGetters("global", ["currentLanguage", "timeZones", "titles"]),
    passive_candidates: {
      get() {
        return this.current.info.passive_candidates || false;
      },
      set(value) {
        this.$store.dispatch("campaign/updateStateCampaign", {
          ...this.current,
          info: {
            ...this.current.info,
            passive_candidates: value
          }
        });
      }
    },
    personality_attrs() {
      return this.current.personality_attrs || {};
    },
    info() {
      return this.current.info || {};
    },
    references_requested() {
      return this.current.references_requested || 4;
    },
    start_date() {
      return this.current.start_date || "";
    },
    end_date() {
      return this.current.end_date || "";
    },
    timeZone() {
      return this.current.time_zone;
    },
    contacts() {
      return this.current.contacts || [];
    },
    contactFields() {
      return [
        {
          key: "title",
          label: this.$t("general.title")
        },
        {
          key: "first_name",
          label: this.$t("general.first_name")
        },
        {
          key: "last_name",
          label: this.$t("general.last_name")
        },
        {
          key: "email_1",
          label: this.$t("general.primary_email")
        },
        {
          key: "email_2",
          label: this.$t("general.secondary_email")
        }
      ];
    },
    unwanted_behavior: {
      get() {
        return this.current.info.include_analysis || false;
      },
      set(value) {
        this.$store.dispatch("campaign/updateStateCampaign", {
          ...this.current,
          info: {
            ...this.current.info,
            include_analysis: value,
          },
        });
      },
    },
    campaignType() {
      return this.$t(`create_campaign.${this.current.campaign_type}_title`);
    }
  },
  watch: {
    contacts() {
      this.setContactsTitle();
    }
  },
  mounted() {
    this.setContactsTitle();
  },
  methods: {
    setContactsTitle() {
      this.contacts.forEach(item => {
        item.title = this.titles.find(t => item.gender === t.value).text
      })
    }
  }
};
</script>
<style type="text/css">
</style>