<template>
  <div class="survey survey__content__block">
    <div class="survey__header">
      <h1>{{ letsGetStartedText }}</h1>
    </div>

    <div class="survey__situation">
      <div class="survey__situation__body">
        <p>{{ text1 }}</p>
        <p>{{ text2 }}</p>
        <!-- <p>{{ text3 }}</p> -->
        <p v-if="showText">{{ text4 }}</p>

        <hr v-if="showTeamTable">
        <TeamSurveyTable v-if="showTeamTable" :candidates="candidates" :inProgressCandidatesIds="inProgressCandidates" />

      </div>
    </div>

    <hr>
    <b-btn size="lg" variant="primary" block="block" :to="link">{{ $t("general.next") }}</b-btn>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CAMPAIGN_TYPES } from '../../common/constants';
import TeamSurveyTable from '@/components/TeamSurveyTable'

export default {
  components: {
    TeamSurveyTable,
  },
  props: {
    firstName: {
      type: String,
      required: false
    },
    lastName: {
      type: String,
      required: false
    },
    link: {
      type: String,
      default: '',
      required: true
    },
  },
  data() {
    return {
      fields: [
        { key: 'title', label: this.$t('general.title') },
        { key: 'first_name', label: this.$t('general.first_name') },
        { key: 'last_name', label: this.$t('general.last_name') },
        { key: 'status', label: this.$t('dashboard.status') },
      ]
    };
  },
  computed: {
    ...mapGetters('survey', ['candidates', 'inProgressCandidates', 'type']),
    showTeamTable() {
      return this.type === CAMPAIGN_TYPES.TEAM;
    },
    showText() {
      return this.type !== CAMPAIGN_TYPES.SELFTEST;
    },
    letsGetStartedText() {
      return this.$t('match_references.lets_get_started');
    },
    text1() {
    const translationKey = this.type === CAMPAIGN_TYPES.SELFTEST ? 'self_test' : 'match_references';
    return this.$t(`${translationKey}.introduction_page.text_1`, {
    firstName: this.firstName,
    lastName: this.lastName,
      })
    },
    text2() {
    const translationKey = this.type === CAMPAIGN_TYPES.SELFTEST ? 'self_test' : 'match_references';
    return this.$t(`${translationKey}.introduction_page.text_2`, {
    firstName: this.firstName,
    lastName: this.lastName,
      })
    },
    text3() {
      return this.$t('match_references.introduction_page.text_3');
    },
    text4() {
      return this.$t('match_references.introduction_page.text_4');
    }
  },
  methods: {
    isInProgressCandidates(id) {
      return (this.inProgressCandidates.includes(id)) ? true : false
    },
  }
};
</script>

<style scoped>
  .survey.survey__content__block {
    max-width: 1024px
  }

  .survey__situation {
    width: 100%;
  }

  .survey__situation__body__table {
    font-size: 1.6rem
  }
</style>
