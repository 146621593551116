<template>
  <div>
    <b-form>
      <b-form-group>
        <b-form-radio class="mb-2" v-model="campaignType" name="some-radios" value="new_campaign">{{ $t('dashboard.new_campaign') }}</b-form-radio>
        <b-form-radio v-model="campaignType" name="some-radios" value="new_templates">{{ $t('dashboard.my_templates') }}</b-form-radio>
      </b-form-group>
    </b-form>
    <b-row class="mt-4">
      <b-col md="6"></b-col>
      <b-col md="6">
        <b-button block="block" variant="primary" @click="toNextStep">{{ $t("general.next") }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      campaignType: "new_campaign"
    };
  },
  methods: {
    toNextStep() {
      this.$emit("next", this.campaignType);
    }
  }
};
</script>
