const campaigns = state => state.campaigns;
const campaignsMeta = state => state.campaignsMeta;
const campaignsArchived = state =>
  state.campaigns.filter(item => item.state !== "archived");
const current = state => state.currentCampaign;
const campaignCandidates = state => state.campaignCandidates;
const campaignCandidatesPagination = state => state.campaignCandidatesPagination;
const campaignCandidatesAPData = state => state.campaignCandidatesAPData;
const apCampaignRefreshKey = state => state.apCampaignRefreshKey;
const anonymous = state => state.anonymizeCandidates;
const showDetails = state => state.showDetails;

export default {
  campaigns,
  campaignsMeta,
  campaignsArchived,
  current,
  campaignCandidates,
  campaignCandidatesPagination,
  campaignCandidatesAPData,
  apCampaignRefreshKey,
  anonymous,
  showDetails
};
