<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score :report_title="$t('report.rock_star_score')"
                                              :talents-attr="talentsAttr"
                                              :attribute-name="'rock_star'"
                                              :current-lang="currentLang"
                                              :score="report.rock_star_score"
                                              :isBlurred="isBlurred"
                                              :items="rock_star_attrs"
                                              :fields="fields"
                                              :icon="'mdi mdi-star'"
                                              :max="max"></candidate-self-report-details-table-score>-->

  <!-- One sider progress bar slider -->
  <candidate-self-report-details-table :report_title="$t('report.rock_star_score')"
                                              :talents-attr="talentsAttr"
                                              :attribute-name="'rock_star'"
                                              :current-lang="currentLang"
                                              :score="report.rock_star_score"
                                              :isBlurred="isBlurred"
                                              :variant="'red2'"
                                              :items="rock_star_attrs"
                                              :fields="fields"
                                              :icon="'mdi mdi-star'"
                                              :max="max"></candidate-self-report-details-table>
</template>

<script>
import { orderBy } from "lodash";
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
//import { calculateTwoSideProgressBarValue } from "@/common/utils";
//import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from "@/common/constants";
//import CandidateSelfReportDetailsTableScore from '../CandidateDetailsTables/CandidateSelfReportDetailsTableScore';
import CandidateSelfReportDetailsTable from '../CandidateDetailsTables/CandidateSelfReportDetailsTable';

export default {
  props: {
    fullName: {
      type: String,
      required: true,
      default: ""
    },
    report: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en'
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {}
    },
    isBlurred: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTable
  },
  data() {
    return {
      max: 100,
      fields: [
        {
          key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name'
        },
        {
          key: 'value',
          label: this.$t('dashboard.progress'),
          thClass: ''
        }
      ],
    };
  },
  computed: {
    /*rock_star_attrs() {
      if (!this.report || !this.report.rock_star) return [];

      const values = this.report.rock_star.map(item => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth
        };
      });

      return orderBy(values, ['value'], ['desc'])
    }*/

    rock_star_attrs() {
      if (!this.report || !this.report.rock_star) return [];

      let values = this.report.rock_star.map(item => {
        return {
          key: item.key,
          value: item.value
        };
      });
      values = values.filter(item => item.value >= SELEFTEST_VALUES_MIN_VALUE)

      return orderBy(values, ['value'], ['desc'])
    }
  }
};
</script>
