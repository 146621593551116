<template>
  <div>
    <b-modal
      centered
      v-model="showInfoModal"
      hide-footer
      hide-header
    >
      <app-info-modal
        @close="() => (showInfoModal = false)"
      ></app-info-modal>
    </b-modal>

    <test-layout>
      <test-card>
        <component
          v-if="question"
          :is="questionTemplate"
          :key="question.key"
          :question="question"
          :questions="filteredQuestions"
          :leavingPerson="leavingPerson"
          :questions-count="question.length"
          :question-number="0"
          :participantId="participantId"
        ></component>
      </test-card>
    </test-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QuestionTemplateFirst from './QuestionTemplateFirst.vue';
import AppInfoModal from './InfoModal.vue'

const QUESTION_TYPES = {
  MAIN: 'exit_main',
  SUB: 'exit_sub',
  CLOSING: 'exit_12',
};

export default {
  components: {
    QuestionTemplateFirst,
    AppInfoModal
  },
  data() {
    return {
      showInfoModal: true
    }
  },
  computed: {
    ...mapGetters('exit', ['questions', 'leavingPerson', 'participantId']),
    filteredQuestions() {
      const mainQuestions = this.questions.filter((q) =>
        q.key.startsWith(QUESTION_TYPES.MAIN)
      );

      let sectionQuestion = null
      const subQuestions = this.questions.filter((q) => {
        if (!sectionQuestion && q.parent_question) sectionQuestion = q.parent_question.key
        return q.parent_question && sectionQuestion === q.parent_question.key
        // if (!sectionQuestion && q.parent_question) sectionQuestion = q.parent_question.key
        // return q.key.startsWith(QUESTION_TYPES.SUB) && sectionQuestion === q.parent_question.key
      });
      const closingQuestions = this.questions.filter((q) =>
        q.key.startsWith(QUESTION_TYPES.CLOSING)
      );

      if (mainQuestions.length) {
        return this.prepareQuestions(mainQuestions);
      } else if (subQuestions.length) {
        return this.prepareQuestions(subQuestions);
      } else if (closingQuestions.length) {
        return this.prepareQuestions(closingQuestions);
      } else {
        return [];
      }
    },
    question() {
      return this.filteredQuestions[0] || null;
    },
    questionTemplate() {
      return QuestionTemplateFirst;
    },
  },
  watch: {
    currentLanguage() {
      // this.fetchQuestions();
    },
    questions() {
      this.checkQuestions();
    },
  },
  methods: {
    prepareQuestions(questions) {
      questions.forEach((q) => {
        if (this.participantId === this.leavingPerson.id) {
          q.answer_2_text = q.answer_2_text_selftest;
        } else {
          q.answer_2_text = q.answer_2_text.replace(
            /%first name%/gi,
            this.leavingPerson.name.split(' ').slice(0, -1).join(' ')
          );
        }
      });
      return questions;
    },
    checkQuestions() {
      if (!this.questions.length) {
        this.$router.push(`/exit-survey/${this.$route.params.token}/thanks`);
      }
    },
  },
};
</script>
