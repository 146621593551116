<template>
  <div class="app-wrap error__404">
      <header>
        <b-container>
          <b-row>
            <b-col>
              <img class="logo fluid-img" :src="logo" alt="Examiz Logo">
            </b-col>
          </b-row>
        </b-container>
      </header>

      <b-container>
        <b-row class="align-items-center">
          <b-col>
            <div class="text-center">
              <b-img :src="illustration" alt="Illustration image" class="illustration"></b-img>
              <h1>{{ $t('errors.error') }}</h1>
              <p class="error__message">{{ $t('errors.invalid_token') }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from "../../components/Footer";
import Logo from "../../../images/logo.svg"
import Illustration from "../../../images/invalid-token_new.svg"

export default {
  components: {
    AppFooter,
  },
  data() {
    return {};
  },
  computed: {
    logo() {
      return Logo;
    },
    illustration() {
      return Illustration;
    },
  }
};
</script>
