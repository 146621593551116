
const campaign = state => state.exitCampaign;
const campaigns = state => state.campaigns;
const campaignsMeta = state => state.campaignsMeta;
const teamMembers = state => state.teamMembers;
const questions = state => state.questions;
const leavingPerson = state => state.leavingPerson;
const participantId = state => state.participantId;
const questionsCount = state => state.questionsCount;
const report = state => state.report;
const analyticsCampaignOptions = state => state.analyticsCampaignOptions;
const strategies = state => state.strategies
const queryStrategies = state => state.queryStrategies

export default {
  campaign,
  campaigns,
  campaignsMeta,
  teamMembers,
  questions,
  leavingPerson,
  participantId,
  questionsCount,
  report,
  analyticsCampaignOptions,
  strategies,
  queryStrategies
};
