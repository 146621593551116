<template>
  <div class="report" :id="pageId">
    <div class="report__buttonbar__fixed">
      <b-row>
        <b-col>
          <b-button-toolbar>
            <div class="report__buttons__wrapper justify-content-end">
              <b-button
                @click="goToReport(id)"
                v-b-tooltip.hover
                :title="$t('report.print_report')"
                class="btn-action report__redirect__btn primary"
              >
                <span class="mdi mdi-printer"></span>
              </b-button>
            </div>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </div>

    <div class="report__content">
      <!-- // 1. campaign summary ////////////////////////////////////////// -->
      <b-row>
        <b-col md="12" lg="6">
          <div class="report__section__grouptitle">
            <span>{{ $t('report.campaign_summary') }}</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div class="toggle">
            <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <h2
              class="report__section__title toggle__trigger"
              v-b-toggle.reasons-for-leaving
            >
              <span class="toggle__trigger__text">
                {{ $t('exit.report.reasons_leaving') }}
              </span>
            </h2>

            <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-collapse
              id="reasons-for-leaving"
              class="toggle__content"
              visible
            >
              <candidate-details-competencies-table
                report_title=""
                v-if="reasonsLeaving.length"
                :items="reasonsLeaving"
                :fields="exitChartColumns"
                icon=""
                :job_table="false"
                :max="max"
                :hideTitle="true"
              >
              </candidate-details-competencies-table>
              <div v-else class="mt-4 ml-5 font-weight-bold">
                {{ $t('exit.report.insufficient_data') }}
              </div>
            </b-collapse>
          </div>

          <div class="toggle">
            <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <h2
              class="report__section__title toggle__trigger"
              v-b-toggle.detailed-analytics
            >
              <span class="toggle__trigger__text">
                {{ $t('exit.report.detailed_analytics') }}
              </span>
            </h2>

            <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-collapse id="detailed-analytics" class="toggle__content">
              <div
                class="mt-5 wrapper col-9"
                v-for="(value, key) in detailedAnalytics"
                :key="key"
              >
                <div>
                  <h5 class="report__analytics__header">
                    {{ value.label }}
                  </h5>
                  <!--  -->
                  <candidate-details-competencies-table
                    v-if="value.values.length"
                    report_title=""
                    :items="value.values"
                    :fields="exitChartColumns"
                    icon=""
                    :job_table="false"
                    :max="max"
                    :hideTitle="true"
                  >
                  </candidate-details-competencies-table>
                  <div v-else class="mt-4 ml-5 font-weight-bold">
                    {{ $t('exit.report.insufficient_data') }}
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="toggle">
            <!-- // toggle title ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <h2
              class="report__section__title toggle__trigger"
              v-b-toggle.report-compliance
            >
              <span class="toggle__trigger__text">
                {{ $t('exit.report.compliance') }}
              </span>
            </h2>

            <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-collapse id="report-compliance" class="toggle__content">
              <div class="report__candidate col-8">
                <b-table
                  v-if="complianceRisks.length"
                  class="report__candidate__attr__table"
                  ref="table"
                  :fields="
                    isAnalyticsReport
                      ? exitChartColumns
                      : complianceRisksColumns
                  "
                  :items="complianceRisks"
                  :borderless="true"
                  responsive="sm"
                >
                  <template #head(value)>
                    <div class="d-flex justify-content-between">
                      <div class="table__head_lower">
                        {{ $t('exit.report.lower') }}
                      </div>
                      <div class="table__head_hight">
                        {{ $t('exit.report.higher') }}
                      </div>
                    </div>
                  </template>

                  <template #head(flags)>
                    <div class="table__head_flags">
                      {{ $t('exit.report.flags') }}
                    </div>
                  </template>

                  <template v-slot:cell(key)="{ item }">
                    <span class="text-truncate">{{ item.label }}</span>
                  </template>

                  <template v-slot:cell(value)="{ item }">
                    <div class="double-progress d-flex">
                      <b-progress
                        class="progress-left"
                        :value="calculateProgressBarLeftValue(item.value.value)"
                        variant="green3"
                        height="1.8rem"
                        :max="max"
                      ></b-progress>
                      <div class="progress__wrp__point"></div>
                      <b-progress
                        class="progress-right"
                        :value="
                          calculateProgressBarRightValue(item.value.value)
                        "
                        variant="success"
                        height="1.8rem"
                        :max="max"
                      ></b-progress>
                    </div>
                  </template>
                  <template v-slot:cell(flags)="{ item }">
                    <span class="text-truncate">{{ item.value.flags }}</span>
                  </template>
                </b-table>
                <div v-else class="mt-4 ml-5 font-weight-bold">
                  {{ $t('exit.report.insufficient_data') }}
                </div>
              </div>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateDetailsCompetenciesTable from '@/components/CandidateDetailsCompetenciesTable';
import ExitReportHelpersMixin from '@/common/ExitReportHelpersMixin';
import { EXIT_CAMPAIGN_TYPES } from '../../../common/constants';

export default {
  components: {
    CandidateDetailsCompetenciesTable,
  },
  mixins: [ExitReportHelpersMixin],
  props: {
    pageId: {
      type: String,
      default: 'webreport__fullview',
    },
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('exit', ['campaign', 'report', 'queryStrategies']),
    isAnalyticsReport() {
      return this.campaign.campaign_type === EXIT_CAMPAIGN_TYPES.ANALYTICS;
    },
  },
  methods: {
    goToReport(id) {
      const route = this.$router.resolve({
        name: 'ExitReportPrint', // ExitReportAnalytics
        params: { id },
        query: {
          strategies: this.queryStrategies.join(',')
        },
      });

      window.open(route.href, '_blank');
    },
  },
};
</script>
