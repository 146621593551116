import VueRouter from 'vue-router';
import { store } from './store/index';
import ApCampaignService from '@/services/ap-campaign.service';

import { EXAMIZ_APPS, EXIT_APPS } from './common/constants'

//auth
import LoginJobSeekerView from './views/Authorization/JobSeeker/Login';
import RegistrationJobSeekerView
  from './views/Authorization/JobSeeker/Registration/Index';
import LoginRecruiterView from './views/Authorization/Recruiter/Login';
// import RegistrationRecruiterView from './views/Authorization/Recruiter/Registration/Index';
import ResetPasswordView from './views/Authorization/ResetPassword';
import NewPasswordView from './views/Authorization/NewPassword';

//Match
import DashboardView from './views/Dashboard/Index';
import TemplatesView from './views/Templates/Index';
import ProfileView from './views/Profile/Index';
import SettingsLayout from './views/Settings/Index';
import DetailsView from './views/Campaigns/Details/Index';
import CreateCampaign from './views/FormCampaign/Index';
import CampaignsLayout from './views/Layouts/CampaignLayout'
import PreviewLayout from './views/Layouts/PreviewLayout'


//Talents
import TalentsView from './views/Talents/Index';
import CongratulationsView from './views/Talents/Congratulations';
import ThankYouView from './views/Talents/ThankYou';
import Promocode from './views/Promocode/Index';

// JobsSeeker
import Account from './views/JobSeeker/Account';
import authApi from './api/auth';

// Self-test
import SelfTestLayout from './views/SelfTest/Layout';
import SelfTestStart from './views/SelfTest/Start';
import SelfTestExample from './views/SelfTest/Example';
import SelfTestSituation from './views/SelfTest/Question';
import SelfTestThanks from './views/SelfTest/Thanks';
import SelfTestResults from './views/SelfReport/Index';

// Reference
import ReferenceView from './views/Reference/Index';
import ExampleView from './views/Reference/Example';
import ReferenceQuestion from './views/Reference/Question';
import ReferenceThankYouView from './views/Reference/ThankYou';

// Layouts account
import AuthorizationLayout from './views/Layouts/AuthorizationLayout';
import AppLayout from './views/Layouts/AppLayout';
import TalentsLayout from './views/Layouts/TalentsLayout';
import ReferenceLayout from './views/Layouts/TalentsLayout';


//Web Report
import Webreport from './views/Report/WebReport';

// Print Report
import Printreport from './views/Report/PrintReport';
import PrintReportAP from './views/Report/PrintReportAP';
import PrintReportAPBulk from './views/Report/PrintReportAPBulk';


// Print Self Report
import SelfTestResultsPrint from './views/SelfReport/SelfTestResultsPrint';

// Error 404
import Error404Layout from './views/Layouts/Error404Layout';
import ErrorInvalidToken from './views/Layouts/ErrorInvalidToken';

// Application Portal Campaign
import ApplicationPortalCampaign from './views/CampaignApplicantPortal/Index';

//Attrs page
import ExpertAttrsView from './views/AttrsPage/Index';

// Exit
import ExitLayout from './views/Layouts/ExitLayout';
import ExitDashboard from './views/Exit/Dashboard/Index';
import ExitCampaign from './views/Exit/Campaign/Index';
import ExitDetailsView from './views/Exit/Details/Index';
import ExitAnalyticsCampaign from './views/Exit/Campaign/Analytics/Index';

// Exit add team members
import ExitTeamMembersLayout from './views/Layouts/ExitTeamMembersLayout';
import ExitTeamMembersView from './views/Exit/TeamMembers/Index';
import ExitCongratulationsView from './views/Exit/TeamMembers/Congratulations';
import ExitThankYouView from './views/Exit/TeamMembers/ThankYou';

// Exit Survey
import ExitSurveyView from './views/Exit/Survey/Index';
import ExitSurveyQuestion from './views/Exit/Survey/Question';
import ExitSurveyThankYou from './views/Exit/Survey/ThankYou';

// Exit Report
import ExitReportPrint from './views/Exit/Report/PrintReport';


const authCandidateOrReference = async (to, from, next) => {
  if (to.path.startsWith('/reference')) {
    await store.dispatch('survey/getSurveyInfo', to.params.token);
  }

  if (store.getters['global/currentUserToken'] || !to.params.token) {
    return next();
  }

  return authApi.loginByToken(to.params.token).then(result => {
    store.commit('global/AUTH_USER', result.data);
    return next();
  });
}

const getAPReport = (to, from, next) => {
  const candidatesIds = new ApCampaignService(to.params.id).getCandidatesIds();
  if (!candidatesIds || !candidatesIds.length) return next('/404');

  store.dispatch(to.meta.sharedCampaign ? 'report/getReportPreview' : 'report/getReport', {
    id: to.params.id,
    mode: to.params.mode,
    candidates: candidatesIds,
    token: to.query._token
  })
    .then((a) => {
      const campaign = store.getters['report/reportCampaign'];
      document.title = `Match_${campaign.job_title_short.replace(/\s+/g, '_')}_${new Date().toISOString().replace('-', '/').split('T')[0].replace('-', '-')}`;
      next()
    })
  // .catch(e => next('/404'))
}

const routes = [
  {
    path: '/preview',
    name: 'CampaignPreview',
    component: PreviewLayout,
    children: [
      {
        path: 'campaign',
        name: 'CampaignPreview',
        component: DetailsView,
        meta: {
          sharedCampaign: true
        },
        beforeEnter: async (to, from, next) => {
          await store.dispatch('global/fetchConfig', to.query.lang)
          await store.dispatch('global/fetchApplicantPortalConfig')
          await store.dispatch('campaign/getCampaignPreview', to.query._token)
          next();
        }
      }
    ]
  },
  {
    path: '/campaigns/:id/:mode/report/print_ap_preview',
    name: 'PrintreportAPPreview',
    component: PrintReportAP,
    meta: {
      sharedCampaign: true
    },
    beforeEnter: getAPReport
  },

  {
    path: '/campaigns/:id/:mode/report/print_ap_bulk_preview',
    name: 'PrintreportAPBulkPreview',
    component: PrintReportAPBulk,
    meta: {
      sharedCampaign: true
    },
    beforeEnter: getAPReport
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true
    },
    component: AppLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          app: EXAMIZ_APPS.DEFAULT
        },
        component: DashboardView,
        beforeEnter: (to, from, next) => {
          store.dispatch('global/fetchTitles');
          const isMatch = store.getters['global/isMatch'];
          const currentUserId = store.getters['global/currentUserId']

          store.dispatch('global/fetchJobTypes')
          if (!isMatch) {
            store.dispatch('jobSeeker/getJobSeeker', currentUserId)
          }

          next();
        }
      },
      {
        path: '/campaigns',
        redirect: '/campaigns/new',
        meta: {
          app: EXAMIZ_APPS.DEFAULT
        },
        name: 'CampaignsLayout',
        component: CampaignsLayout,
        children: [
          {
            path: '/campaigns/:id',
            name: 'Details',
            component: DetailsView,
            props: true,
            meta: {
              requiresUserType: 'match'
            },
          },
          {
            path: '/campaigns/:id/edit',
            name: 'EditCampaign',
            component: CreateCampaign,
            props: true,
            meta: {
              requiresUserType: 'match'
            }
          },
          {
            path: '/campaign/new',
            name: 'CreateCampaign',
            component: CreateCampaign,
            meta: {
              requiresUserType: 'match'
            }
          },
          {
            path: '/campaigns/new/ap',
            name: 'CreateApplicationPortalCampaign',
            component: ApplicationPortalCampaign,
            beforeEnter: async (to, from, next) => {
              await store.dispatch('global/fetchApplicantPortalConfig')
              next();
            }
          },
          {
            path: '/campaigns/ap/:id/edit',
            name: 'CreateApplicationPortalCampaign',
            component: ApplicationPortalCampaign,
            beforeEnter: async (to, from, next) => {
              await store.dispatch('campaign/getCampaign', to.params.id)
              await store.dispatch('global/fetchApplicantPortalConfig')
              next();
            }
          },
        ],
        beforeEnter: (to, from, next) => {
          store.dispatch('global/fetchTitles')
            .then(() => {
              next();
            });
        }
      },
      {
        path: '/templates',
        name: 'Templates',
        component: TemplatesView,
        meta: {
          requiresUserType: 'match',
          app: EXAMIZ_APPS.DEFAULT
        }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: ProfileView
      },
      {
        path: '/settings',
        name: 'Settings',
        component: SettingsLayout,
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('company/getCompanyRecruiters', store.getters['global/currentUserCompanyId'])
            await store.dispatch('company/getCompanyInvitations', store.getters['global/currentUserCompanyId'])
          } catch (err) {
            return next('/login');
          }
          next();
        }
      },
      /**
       * Exit routes
       */
      {
        path: '/exit',
        name: 'ExitLayout',
        component: ExitLayout,
        meta: {
          app: EXAMIZ_APPS.EXIT
        },
        children: [
          {
            path: '/',
            name: 'ExitDashboard',
            component: ExitDashboard
          },
          {
            path: 'campaigns/:id',
            name: 'ExitDetails',
            component: ExitDetailsView,
            props: true,
            meta: {
              requiresUserType: 'match'
            },
            beforeEnter: async (to, from, next) => {
              await store.dispatch('exit/getCampaign', to.params.id)
              next();
            }
          },
          {
            path: 'campaigns/:id/edit',
            name: 'EditCampaign',
            component: ExitCampaign,
            props: true,
            meta: {
              requiresUserType: 'match'
            },
            beforeEnter: async (to, from, next) => {
              await store.dispatch('exit/getCampaign', to.params.id)
              next();
            }
          },
          {
            path: 'campaign/new',
            name: 'ExitCreateCampaign',
            component: ExitCampaign,
            meta: {
              requiresUserType: 'match'
            },
            beforeEnter: (to, from, next) => {
              store.commit('exit/RESET_CAMPAIGN')
              next()
            }
          },
          {
            path: 'campaigns/analytics/:id/edit',
            name: 'EditExitAnalyticsCampaign',
            component: ExitAnalyticsCampaign,
            meta: {
              requiresUserType: 'match'
            },
            beforeEnter: async (to, from, next) => {
              await store.dispatch('exit/getCampaign', to.params.id)
              next();
            }
          },
          {
            path: 'campaign/analytics/new',
            name: 'ExitAnalyticsCampaign',
            component: ExitAnalyticsCampaign,
            meta: {
              requiresUserType: 'match'
            },
          },
        ],
        beforeEnter: (to, from, next) => {
          Promise.all([
            store.dispatch('global/fetchTitles'),
            store.dispatch('global/fetchExitConfig')
          ]).then(() => next())
        }
      },
      /**
       * End Exit routes
       */
      {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
          requiresUserType: 'talents'
        }
      },
    ]
  },
  {
    path: '/exit/campaigns/:id/report/print',
    name: 'ExitReportPrint',
    component: ExitReportPrint,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      store.commit('global/SHOW_SPINNER', {
        transparent: true,
      })
      Promise.all([
        store.dispatch('exit/getReport', to.params.id),
        store.dispatch('exit/getCampaign', to.params.id),
        store.dispatch('global/fetchExitConfig'),
      ])
        .then(() => {
          store.commit('global/HIDE_SPINNER');
          next();
        })
        .catch(() => next('/exit'))
    }
  },
  {
    path: '/team-members',
    name: 'TeamMembers',
    // redirect: '/candidate',
    component: ExitTeamMembersLayout,
    meta: {
      requiresAuth: false
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('exit/getTeamMembers', to.params.token)
        .then(() => {
          next();
        });
    },
    children: [
      {
        path: ':token',
        // props: true,
        name: 'ExitTeamMembers',
        component: ExitTeamMembersView,
      },
      {
        path: ':token/thankyou',
        // props: true,
        name: 'ExitThankYou',
        component: ExitThankYouView,
      }
    ]
  },
  {
    path: '/exit-survey',
    name: 'reference',
    component: ExitTeamMembersLayout,
    beforeEnter: (to, from, next) => {
      store.dispatch('exit/getAnswers', to.params.token)
        .then(() => {
          next();
        });
    },
    children: [
      {
        path: ':token',
        // props: true,
        name: 'Reference',
        component: ExitSurveyView,
      },
      {
        path: ':token/questions',
        name: 'ExitSurveyQuestion',
        component: ExitSurveyQuestion,
      },
      {
        path: ':token/thanks',
        name: 'ReferenceThanks',
        component: ExitSurveyThankYou,
      }
    ]
  },
  {
    path: '/attributes',
    name: 'Attributes',
    component: ExpertAttrsView
  },
  {
    path: '/self-test',
    name: 'self-test',
    props: true,
    component: SelfTestLayout,
    beforeEnter: async (to, from, next) => {
      const token = store.getters['global/currentUserToken'];
      const paramsToken = to.params.token || to.query.token;
      if (!token && !paramsToken) return next('/login')
      if (paramsToken) {
        try {
          const result = await authApi.loginByToken(paramsToken);
          store.commit('global/AUTH_USER', result.data);
        } catch (e) {
          console.log(e)
        }
      }

      // Set not blure parametar if exist in query prams
      if (to.query.nb) localStorage.setItem('nb', to.query.nb);

      store.dispatch('report/getSelfTestReport', {
        token: store.getters['global/currentUserToken'],
        lang: store.getters['global/currentLanguage'],
      }).then(() => {
        next()
      })
    },
    children: [
      {
        path: '/self-test/start',
        name: 'self-test.start',
        component: SelfTestStart,
      },
      {
        path: '/self-test/example',
        name: 'self-test.example',
        component: SelfTestExample,
      },
      {
        path: '/self-test/situation',
        name: 'self-test.situation',
        component: SelfTestSituation,
      },
      {
        path: '/self-test/thanks',
        name: 'self-test.thanks',
        component: SelfTestThanks,
      },
      {
        path: '/self-test/results',
        name: 'self-test.results',
        component: SelfTestResults,
      }
    ]
  },
  {
    path: '/self-test/report/print',
    name: 'SelfTestResultsPrint',
    component: SelfTestResultsPrint,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('report/getSelfTestReport', {
        token: store.getters['global/currentUserToken'],
        lang: store.getters['global/currentLanguage'],
      }).then(() => {
        next()
      })
    }
  },
  {
    path: '/',
    name: 'reference',
    redirect: '/reference',
    component: ReferenceLayout,
    beforeEnter: authCandidateOrReference,
    children: [
      {
        path: '/reference/:token',
        props: true,
        name: 'Reference',
        component: ReferenceView,
      },
      {
        path: '/reference/:token/step/:step?',
        props: true,
        name: 'ReferenceStep',
        component: ReferenceView,
      },
      {
        path: '/reference/:token/example',
        props: true,
        name: 'ExampleWelcome',
        component: ExampleView,
      },
      {
        path: '/reference/:token/situation',
        name: 'situation',
        props: true,
        component: ReferenceQuestion,
      },
      {
        path: '/reference/:token/thanks',
        name: 'ReferenceThanks',
        props: true,
        component: ReferenceThankYouView,
      }
    ]
  },
  {
    path: '/',
    name: 'Talents',
    redirect: '/candidate',
    component: TalentsLayout,
    beforeEnter: authCandidateOrReference,
    children: [
      {
        path: '/candidate/:token',
        props: true,
        name: 'Candidate',
        component: TalentsView,
      },
      {
        path: '/candidate/:token/step/:step?',
        props: true,
        name: 'CandidateStep',
        component: TalentsView,
      },
      {
        path: '/candidate/:token/congratulations',
        props: true,
        name: 'congratulations',
        component: CongratulationsView,
      },
      {
        path: '/candidate/:token/thankyou',
        props: true,
        name: 'thankYou',
        component: ThankYouView,
      }
    ]
  },
  {
    path: '/campaigns/:id/report/view',
    name: 'Webreport',
    component: Webreport,
    beforeEnter: (to, from, next) => {
      store.dispatch('report/getReport', { id: to.params.id, mode: false })
        .then(() => next())
        .catch(() => window.location.href = '/')
      // @todo: Handle error page, something went wrong
    }
  },
  {
    path: '/campaigns/:id/:mode/report/print',
    name: 'Printreport',
    component: Printreport,
    meta: {
      requiresAuth: true,
      app: EXAMIZ_APPS.DEFAULT
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('report/getReport', { id: to.params.id, mode: to.params.mode })
        .then((a) => {
          const campaign = store.getters['report/reportCampaign'];
          document.title = `Match_${campaign.job_title_short.replace(/\s+/g, '_')}_${new Date().toISOString().replace('-', '/').split('T')[0].replace('-', '-')}`;
          next()
        })
      // .catch(e => next('/404'))
    }
  },
  {
    path: '/campaigns/:id/:mode/report/print_ap',
    name: 'PrintreportAP',
    component: PrintReportAP,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: getAPReport
  },

  {
    path: '/campaigns/:id/:mode/report/print_ap_bulk',
    name: 'PrintreportAPBulk',
    component: PrintReportAPBulk,
    meta: {
      requiresAuth: true
    },
    beforeEnter: getAPReport
  },
  {
    path: '/campaigns/:id/:mode/report/print',
    name: 'Printreport',
    component: Printreport,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('report/getReport', { id: to.params.id, mode: to.params.mode })
        .then((a) => {
          const campaign = store.getters['report/reportCampaign'];
          document.title = `Match_${campaign.job_title_short.replace(/\s+/g, '_')}_${new Date().toISOString().replace('-', '/').split('T')[0].replace('-', '-')}`;
          next()
        })
      // .catch(e => next('/404'))
    }
  },
  {
    path: '/',
    name: 'Authorization',
    component: AuthorizationLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginJobSeekerView,
        meta: {
          guest: true,
        }
      },
      {
        path: '/registration',
        name: 'RecruiterRegistration',
        component: RegistrationJobSeekerView,
        meta: {
          guest: true,
        }
      },
      {
        path: '/recruiter/login',
        name: 'RecruiterLogin',
        component: LoginRecruiterView,
        meta: {
          guest: true,
        }
      },
      /*
      {
        path: '/recruiter/registration',
        name: 'Registration',
        component: RegistrationRecruiterView,
        meta: {
          guest: true,
        },
      },
      */
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPasswordView,
        meta: {
          guest: true,
        }
      },
      {
        path: '/new-password/:token',
        name: 'ForgotPassword',
        component: NewPasswordView,
        meta: {
          guest: true,
        }
      }
    ]
  },
  {
    path: '/authorize/:token',
    name: 'Authorize',
    beforeEnter: async (to, from, next) => {
      const paramsToken = to.params.token;
      if (!paramsToken) return next('/login')
      if (paramsToken) {
        try {
          const result = await authApi.loginByToken(paramsToken);
          store.commit('global/AUTH_USER', result.data);
        } catch (e) {
          console.log(e)
        }
      }

      next(to.query.redirect || '/dashboard')
    },
  },
  {
    path: '/invalid-token',
    name: 'Invalid Token',
    component: ErrorInvalidToken,
  },
  {
    path: '/promocode',
    name: 'Promocode',
    component: Promocode
  },
  {
    path: '/companies/:companyId/invitations/accept',
    name: 'CompanyInvitation',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('global/loadUserInfo');
        if (store.getters['global/isRegistered']) {
          await store.dispatch('company/acceptInvitation', to.params.companyId);
          localStorage.setItem('company-id', to.params.companyId);
          localStorage.removeItem('role');

          setTimeout(() => {
            // Reload page
            window.location.href = '/';
          }, 1)
        } else {
          throw new Error();
        }
      } catch (err) {
        console.log({ e: err })
        if (err.response && err.response.status === 422) {
          return next('/')
        }
        store.commit('global/LOGOUT');
        next(`/login?redirect=/companies/${to.params.companyId}/invitations/accept`)
      }
    }
  },
  {
    path: '*',
    name: 'error404',
    component: Error404Layout,
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

const ROLE_HOME_PATH = {
  recruiter: '/',
  jobseeker: '/talents-test'
};


const ALLOWED_APPS = ['exit']
const currentApp = localStorage.getItem('app')
const manageApps = (to, from, next) => {
  if (to.meta && to.meta.app && ALLOWED_APPS.includes(currentApp) && !to.path.startsWith('/' + currentApp)) {
    return next('/' + currentApp);
  }
}

router.beforeEach(async (to, _from, next) => {

  manageApps(to, _from, next)


  const isAuthenticated = store.getters['global/isAuthenticated'];
  const isMatch = store.getters['global/isMatch'];
  const isTalents = store.getters['global/isTalents'];
  const userRole = store.getters['global/userRole'];
  const userToken = store.getters['global/currentUserToken'];
  const redirectTo = store.getters['global/redirectTo'];

  const candidateWithToken = async () => {
    return !!((userRole.toLowerCase() === 'candidate' ||
      userRole.toLowerCase() === 'reference') &&
      userToken);
  };

  if (to.matched.some(m => m.meta.requiresAuth)) {
    try {
      await store.dispatch('global/loadUserInfo');

      if (isMatch) {
        const companies = store.getters['global/currentUserCompanies'];
        const companyId = localStorage.getItem('company-id') || companies[0].company_id;
        await store.dispatch('global/loadCompanyInfo', companyId);
      }

      if (candidateWithToken() && redirectTo) {
        store.dispatch('global/redirectTo', null);
        return next(redirectTo);
      }
    } catch (err) {
      store.commit('global/LOGOUT');
      return next('/login');
    }
  }

  if (
    (to.matched.some(m => m.meta.requiresUserType === 'match') && !isMatch) ||
    (to.matched.some(m => m.meta.requiresUserType === 'talents') && !isTalents)
  ) {
    return next(ROLE_HOME_PATH[userRole]);
  }

  next();
});

export default router;
