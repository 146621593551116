<template>
  <div class="pt-4">
    <p class="mb-0">
      {{ $t("seeker_account.please_upload", { file_size: "3Mb" }) }}
    </p>
    <ValidationObserver ref="form" tag="div">
      <b-form class="candidates-wrp">
        <div
          class="candidates-item flex-wrap pt-3 py-1"
          v-for="(item, index) in filteredDocuments"
          :key="index"
        >
          <div>
            <div class="mr-2 pt-4 mt-3">
              <span
                @click="removeDocument(item)"
                v-b-tooltip.hover
                :title="$t('seeker_account.delete_document')"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path fill="#68808d" d="M4.5 8.1h9V10h-9zM9 0c1.6 0 3.2.4 4.6 1.2a8.5 8.5 0 013.2 3.2A8.9 8.9 0 0118 9a8.9 8.9 0 01-1.2 4.6 8.5 8.5 0 01-3.2 3.2A8.9 8.9 0 019 18a8.9 8.9 0 01-4.6-1.2 8.7 8.7 0 01-3.2-3.3A8.8 8.8 0 010 9c0-1.6.4-3.1 1.2-4.5a8.9 8.9 0 013.3-3.3A8.8 8.8 0 019 0zm0 16.2a7 7 0 003.6-1 7 7 0 002.6-2.6 7 7 0 001-3.6 7 7 0 00-1-3.6 7 7 0 00-2.6-2.6 7 7 0 00-3.6-1 7 7 0 00-3.6 1 7 7 0 00-2.6 2.6 7 7 0 00-1 3.6 7 7 0 001 3.6 7 7 0 002.6 2.6 7 7 0 003.6 1z"/></svg>
              </span>
              <span class="ml-2">{{ index + 1 + "." }}</span>
            </div>
          </div>
          <div class="mr-3">
            <app-text-input
              :name="$t('seeker_account.document_name')"
              :label="$t('seeker_account.document_name')"
              :vid="'document_name' + index"
              :readonly="true"
              :rules="{ regex: /.(doc|docx|pdf)$/ }"
              v-model="item.filename"
            ></app-text-input>
          </div>
          <div class="mr-3">
            <app-select-input
              :name="$t('seeker_account.document_type')"
              :label="$t('seeker_account.document_type')"
              mode="lazy"
              :vid="'document_type' + index"
              rules="required"
              :options="documentType"
              v-model="item.filetype"
            ></app-select-input>
          </div>
          <div class="mr-3">
            <app-file-input
              @file="fileFunc(item, $event)"
              name="document"
              :vid="`document ${index + 1}`"
              :accept-file-types="documentFileTypes"
              :rules="{ size: 3072 }"
            ></app-file-input>
          </div>
        </div>
      </b-form>
      <b-btn class="xxl mt-3" variant="primary" @click="addDocument">{{
        $t("seeker_account.add_document")
      }}</b-btn>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SeekerHelpersMixin from "../../../common/SeekerHelpersMixin";
import AppSelectInput from "../../../components/SelectInput";
import AppFileInput from "../../../components/FileInput";
import AppTextInput from "../../../components/TextInput";
export default {
  components: {
    AppSelectInput,
    AppTextInput,
    AppFileInput,
  },
  mixins: [SeekerHelpersMixin],
  // props: {
  //   form: {
  //     type: Object,
  //     default: () => {},
  //     required: true,
  //   },
  // },
  data() {
    return {
      documentFileTypes:
        "text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
       form: {
         documents_attributes: []
       }
    };
  },
  computed: {
    ...mapGetters({
      jobSeeker: 'jobSeeker/jobSeeker',
    }),
    documents() {
      return this.form.documents_attributes || [];
    },
    filteredDocuments() {
      return this.documents.filter((item) => !item._destroy);
    },
  },
  mounted() {
    this.form.documents_attributes = this.jobSeeker.documents || [];
    this.$store.commit('jobSeeker/ASSIGN_FORM', this.form)
  },
  methods: {
    addDocument() {
      const params = {
        file: null,
        filename: "",
        filetype: null,
        _destroy: false,
      };
      this.form.documents_attributes.push(params);
    },
    removeDocument(item) {
      const index = this.form.documents_attributes.indexOf(item);
      if (!("id" in item)) {
        this.form.documents_attributes.splice(index, 1);
        return;
      }
      if (index >= 0) {
        this.form.documents_attributes[index]._destroy = true;
      }
    },
    fileFunc(item, file) {
      const index = this.form.documents_attributes.indexOf(item);
      if (index < 0) {
        return;
      }

      this.form.documents_attributes[index].file = file;
      this.form.documents_attributes[index].filename = file.name;
    },
    validateForm() {
      this.$store.commit('jobSeeker/ASSIGN_FORM', this.form)
      return this.$refs.form.validate();
    },
  },
};
</script>
