<template>
  <test-layout>
    <test-card>
      <!--      <transition name="fade">
        <app-tip class="first-tip" @close="tipShow1 = !tipShow1"
                 :text="$t('match_references.handle_tip')"
                 v-if="tipShow1"></app-tip>
      </transition>

      <transition name="fade">
        <app-tip class="second-tip" @close="tipShow2 = !tipShow2"
                 :text="$t('match_references.situation_tip')"
                 v-if="tipShow2"></app-tip>
      </transition>-->

      <div class="survey survey__content__block">
        <h3 class="example_headline">{{ $t('match_references.example') }}</h3>
        <span v-if="isSelftestSurvery">
          <test-header>
            {{ $t('self_test.example_page.scenario_text') }}
          </test-header>

          <test-case>
            {{ $t('self_test.example_page.situation_text') }}
          </test-case>
        </span>

        <span v-else>
          <test-header>
            {{
              $t(
                'match_references.example_page.scenario_text',
                this.candidateTitleAndFullName
              )
            }}
          </test-header>

          <test-case>
            {{
              $t(
                'match_references.example_page.situation_text',
                this.candidateTitleAndFullName
              )
            }}
          </test-case>
        </span>

        <test-slider
          class="mt-4"
          v-model="barValue"
          :label-left="
            $t('match_references.example_page.slider_left_text', {
              name: candidateFullName,
            })
          "
          :label-right="
            $t('match_references.example_page.slider_right_text', {
              name: candidateFullName,
            })
          "
          :rangeTips="rangeTips"
          backplateColor="#4E5360"
          :example-page="true"
        >
        </test-slider>

        <div class="survey__footer">
          <b-row>
            <b-col class="col-6 text-left">
              <b-btn size="lg" variant="outline-secondary" :to="backLink">{{
                $t('general.back')
              }}</b-btn>
            </b-col>

            <b-col class="col-6 text-right">
              <b-btn
                class="example-next-button"
                size="lg"
                variant="primary"
                @click="done"
                v-b-tooltip.hover
                :title="$t('self_test.example_page.next_btn_tooltip')"
                >{{ $t('general.next') }}
              </b-btn>
            </b-col>
          </b-row>
        </div>
      </div>
    </test-card>

    <b-modal v-model="modalNext" hide-footer hide-header centered>
      <div v-if="isSelftestSurvery" class="text-center px-md-4">
        <h3 class="mb-4">{{ $t('self_test.lets_get_started') }}</h3>
        <p v-html="$t('self_test.get_started_modal_text')"></p>
        <b-btn
          class="mt-4 min-w-140"
          variant="primary"
          size="xl"
          :to="nextLink"
          >{{ $t('general.next') }}</b-btn
        >
      </div>
      <div v-else class="text-center px-md-4">
        <h3 class="mb-4">{{ $t('match_references.lets_get_started') }}</h3>
        <p>
          {{
            $t('match_references.remember_text', {
              firstName: candidateTitleAndFullName.firstName,
            })
          }}
        </p>
        <p>{{ $t('match_references.enjoy') }}</p>
        <b-btn
          class="mt-4 min-w-140"
          variant="primary"
          size="xl"
          :to="nextLink"
          >{{ $t('general.next') }}</b-btn
        >
      </div>
    </b-modal>
  </test-layout>
</template>

<script>
import { EXAMPLE_QUESTION_CLASS_NAME } from '@/common/constants';
import AppVolumeBar from '../../components/VolumeBar';
import AppTip from '../../components/Tip';
import ApiSurvey from '../../api/survey';
import { mapGetters } from 'vuex';
import { CAMPAIGN_TYPES } from '../../common/constants';

const tooltips = [
  { from: 0, to: 0, label: 'match_references.example_volume_slider.tooltip_1' },
  {
    from: -30,
    to: -10,
    label: 'match_references.example_volume_slider.tooltip_2',
  },
  {
    from: -80,
    to: -50,
    label: 'match_references.example_volume_slider.tooltip_3',
  },
  { to: -80, label: 'match_references.example_volume_slider.tooltip_4' },
  {
    from: 10,
    to: 30,
    label: 'match_references.example_volume_slider.tooltip_2',
  },
  {
    from: 50,
    to: 80,
    label: 'match_references.example_volume_slider.tooltip_5',
  },
  { from: 80, label: 'match_references.example_volume_slider.tooltip_6' },
];

export default {
  components: {
    AppVolumeBar,
    AppTip,
  },
  props: ['token'],
  data() {
    return {
      barValue: 0,
      modalNext: false,
      tipShow1: false,
      tipShow2: false,
      candidateFirstName: null,
      candidateLastName: null,
    };
  },
  computed: {
    ...mapGetters({
      surveryType: 'survey/type',
      surveryCandidate: 'survey/candidate',
    }),
    isSelftestSurvery() {
      return this.surveryType === CAMPAIGN_TYPES.SELFTEST;
    },
    candidate() {
      return {
        firstName: this.surveryCandidate.first_name,
        lastName: this.surveryCandidate.last_name,
        title: this.surveryCandidate.title,
      };
    },
    candidateTitleAndFullName() {
      return {
        title: this.candidate.title,
        firstName: this.candidate.firstName,
        lastName: this.candidate.lastName,
      };
    },
    candidateFullName() {
      return `${this.candidateTitleAndFullName.firstName} ${this.candidateTitleAndFullName.lastName}`;
    },
    rangeTips() {
      return tooltips.map((item) => ({
        ...item,
        label: this.$t(item.label, { name: this.candidateFullName }),
      }));
    },
    nextLink() {
      return `/reference/${this.token}/situation`;
    },
    backLink() {
      return `/reference/${this.token}/step/3`;
    },
  },
  mounted() {
    document.body.classList.add(EXAMPLE_QUESTION_CLASS_NAME);
    this.showTooltips();
  },
  beforeDestroy() {
    document.body.classList.remove(EXAMPLE_QUESTION_CLASS_NAME);
  },
  methods: {
    showTooltips() {
      setTimeout(() => {
        if (this.barValue === 0) this.tipShow1 = true;
      }, 10000);
      setTimeout(() => (this.tipShow2 = true), 25000);
    },
    done() {
      this.modalNext = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.survey.survey__content__block {
  width: 1024px;
}

.first-tip,
.second-tip {
  position: absolute;
  z-index: 1;
  top: 20px;
  max-width: 360px;
}

.first-tip {
  left: 20px;
}

.second-tip {
  right: 20px;
}
</style>
