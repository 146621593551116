<template>
  <header class="auth-header">
    <b-container>
      <b-navbar class="px-0 flex-wrap xk-navbar" type="light" variant="light">
        <b-button
          :hidden="hideNavBtn"
          class="toggle__sidenav__button"
          @click="toggleNavSidebar"
        >
          <span
            class="mdi"
            :class="{
              'mdi-menu': this.toggleNav,
              'mdi-close': !this.toggleNav,
            }"
          ></span>
        </b-button>

        <b-navbar-brand>
          <router-link :to="hideNavBtn ? '/login' : ''">
            <template v-if="isAuthenticated">
              <!-- <b-img :src="headerLogoAuth" :style="logoSize" fluid="fluid" :alt="title"></b-img> -->
            </template>

            <template v-else>
              <b-img :src="headerLogo" :style="logoSize" fluid="fluid" :alt="title"></b-img>
            </template>
          </router-link>
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto">
          <AppsSwitcher v-if="isRegistered" />

          <CompanySwitcher />

          <app-language-switcher v-if="!isRegistered"></app-language-switcher>

          <div class="ml-3" v-if="authBtn">
            <template v-if="!isAuthenticated">
              <router-link to="/login" v-if="$route.path === '/registration'">
                <b-button variant="primary">{{
                  $t('header.sign_in')
                }}</b-button>
              </router-link>

              <!-- INFO: Sign up is disabled until further notice -->
              <!-- <router-link to='/registration' v-if="$route.path === '/login'">
                <b-button variant='primary'>{{ $t('header.sign_up') }}</b-button>
              </router-link> -->
            </template>
          </div>

          <b-dropdown
            v-if="isAuthenticated && !hideNavBtn"
            variant="link"
            right
            toggle-class="text-decoration-none"
            no-caret
            class="account__button"
          >
            <template #button-content>
              <span class="mdi mdi-account-circle h-34"></span>
            </template>
            <b-dropdown-item to="/profile">{{
              $t('auth_pages.profile')
            }}</b-dropdown-item>
            <b-dropdown-item @click="logout">{{
              $t('header.logout')
            }}</b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import AuthMixin from '@/common/mixins/AuthMixin';
import AppLanguageSwitcher from './LanguageSwitcher';
import CompanySwitcher from './CompanySwitcher';
import AppsSwitcher from './AppsSwitcher';
import { EXAMIZ_APPS } from '../common/constants';
import franchise from '../conf/franchise';

export default {
  components: {
    AppLanguageSwitcher,
    CompanySwitcher,
    AppsSwitcher,
  },
  props: {
    authBtn: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [AuthMixin],
  data() {
    return {
      toggleNav: true,
    };
  },
  computed: {
    title() {
      return franchise.title;
    },
    headerLogo() {
      return franchise.logo;
    },
    headerLogoAuth() {
      return this.currentApp.type === EXAMIZ_APPS.DEFAULT.type
        ? franchise.logoMatch
        : franchise.logoPulse;
    },
    hideNavBtn() {
      return !this.isRegistered;
    },
    logoSize() {
      return franchise.logoSize ? { height: franchise.logoSize} : {};
    }
  },
  methods: {
    logout() {
      // Logout from Rails app in background
      this.$store
        .dispatch('global/logout')
        .then(() => {
          this.$router.push('/login');
        })
        .catch((e) => {
          this.$router.push('/login');
        });
    },
    toggleNavSidebar() {
      this.toggleNav = !this.toggleNav;
      this.$emit('toggle-nav', this.toggleNav);
    },
  },
};
</script>
