<template>
  <candidate-details-competencies-table :report_title="$t('create_campaign.standard_attributes')" :items="permanent_rating" :fields="fields" :icon="'mdi mdi-hexagon-multiple'" :job_table="false" :max="max"></candidate-details-competencies-table>
</template>

<script>
import { orderBy } from 'lodash';
import CandidateDetailsCompetenciesTable from '@/components/CandidateDetailsCompetenciesTable';

export default {
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    CandidateDetailsCompetenciesTable
  },
  data() {
    return {
      max: 100,
      fields: [
          { key: 'name',
            label: this.$t('create_campaign.traits'),
            thClass: 'attribute__name'
          },
        {
          key: 'score',
          thClass: ''
        }
      ]
    }
  },
  computed: {
    permanent_rating() {
      const value = (
        this.candidate.permanent_attrs &&
        this.candidate.permanent_attrs.map((item) => {
          return {
            attribute: item.attribute,
            name: item.name,
            score: item.score
          };
        })
      ) || [];

      return orderBy(value, 'name', ['asc']);
    }
  }
};
</script>
