<template>
  <form-wizard
    @on-change="onChange"
    errorColor="#8b96a4"
    color="#E8710F"
    :title="wizardTitle"
    :start-index="startIndex"
    subtitle=""
    ref="wizard"
    stepSize="xs">
    <template slot="footer" slot-scope="props">

      <div class="d-flex justify-content-end flex-wrap">
        <b-btn class="mr-3 xxl"
               variant="outline-secondary"
               v-if="props.activeTabIndex > 0"
               @click="props.prevTab()">{{ $t("general.back") }}</b-btn>

        <b-btn class="btn-sm xxl"
               variant="primary"
               v-if="!props.isLastStep"
               @click="props.nextTab()">
          <b-spinner v-if="isInProgress"
                     variant="light"
                     small="small"
                     label="Spinning"></b-spinner>{{ $t("general.next") }}
        </b-btn>

        <b-btn class="xxl"
               variant="primary"
               v-else
               @click="toPreview">{{ isUserAccountActivated }}</b-btn>
      </div>
    </template>

    <tab-content v-for="form in formList"
                :key="form.name"
                :title="form.title"
                :icon="form.icon"
                :before-change="() => tabChange(form.name)">
      <component :is="`app-step-${form.name}`"
                :ref="`${form.name}Form`"
                :form="{}"
                @change="formChange"></component>
    </tab-content>
  </form-wizard>
</template>

<script>
import AppStepFirst from "./StepFirst";
import AppStepSecond from "./StepSecond";
import AppStepThird from "./StepThird";
import AppStepFourth from "./StepFourth";
import ApiJobSeeker from "../../../api/jobSeeker";
import { mapGetters } from "vuex";
const forms = [
  { name: "first", title: "Step 1", icon: "check" },
  { name: "second", title: "Step 2", icon: "check" },
  { name: "third", title: "Step 3", icon: "check" },
  { name: "fourth", title: "Step 4", icon: "check" },
];
export default {
  components: {
    AppStepFirst,
    AppStepSecond,
    AppStepThird,
    AppStepFourth,
  },
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isInProgress: false,
    };
  },
  computed: {
    ...mapGetters("global", ["currentUserId"]),
    ...mapGetters({jobSeekerForm: "jobSeeker/form"}),
    ...mapGetters('jobSeeker', ['jobSeeker']),
    wizardTitle() {
      return this.$t(`seeker_account.wizard_title_step_${this.index}`);
    },
    formList() {
      return forms;
    },
    isUserAccountActivated() {
      return (this.jobSeeker.experience_profile_finished) ? this.$t('general.update') : this.$t("general.activate");
    }
  },
  methods: {
    formChange(newForm) {
      this.$emit("change", newForm);
    },
    tabChange(name) {
      return new Promise((res) => {
        const formName = `${name}Form`;
        const reference = this.$refs[formName][0];
        const hasValidator = reference && "validateForm" in reference;
        const isLastStep = this.index === forms.length - 2;
        const saveData = (callback) => {
          if (!isLastStep) {
            callback(true);
          } else {
            this.isInProgress = true;
            this.updateJobSeeker()
              .then((response) => {
                this.$store.dispatch('jobSeeker/getJobSeeker', this.currentUserId)

                callback(true);
              })
              .catch((error) => {
                console.log('error', error)
                this.$bvToast.toast(this.$t('auth_pages.resend_email_alert_fail'), {
                  autoHideDelay: 2000,
                  variant: "warning",
                  title: "Error",
                });
                return callback(false);
              })
              .finally(() => {
                this.isInProgress = false;
              });
          }
        };

        if (hasValidator) {
          reference.validateForm().then((valid) => {
            if (!valid) {
              res(false);
              return;
            }
            saveData(res);
          });
        } else {
          saveData(res);
        }
      });
    },
    onChange(prevIndex, nextIndex) {
      this.$emit("update-index", nextIndex);
    },
    updateJobSeeker() {
      const seekerObj = {
        job_seeker: Object.assign({}, this.jobSeekerForm),
      };

      seekerObj.job_seeker.job_types = seekerObj.job_seeker.job_types.map(item => item.value);
      seekerObj.job_seeker.preferred_countries = seekerObj.job_seeker.preferred_countries.map(
        (i) => i.value
      );
      return ApiJobSeeker.updateJobSeeker(this.currentUserId, seekerObj);
    },
    toPreview() {
      const profileFinishedForm = {
        job_seeker: {
          personal_data: {
            experience_profile_finished: true,
          },
        },
      };
      return ApiJobSeeker.updateJobSeeker(
        this.currentUserId,
        profileFinishedForm
      )
        .then((response) => {
          this.$store.dispatch('jobSeeker/getJobSeeker', this.currentUserId)
          this.$emit("close-modal");
        })
        .catch(() => {});
    },
  },
};
</script>
