import { mapGetters } from "vuex";
import { TEAM_MEMBERS_ROLE } from "./constants";
const ReferencesHelpersMixin = {
  computed: {
    ...mapGetters('global', ['titles']),
    tableFields() {
      return [
        {
          key: "gender",
          label: this.$t("general.title")
        },
        {
          key: "role",
          label: this.$t("create_campaign.role")
        },
        {
          key: "name",
          label: this.$t("general.full_name")
        },
        {
          key: "email",
          label: this.$t("general.email")
        },
      ];
    },
    acquaintancePeriods() {
      return [
        this.$t("match_references.intro_page.1_3_month"),
        this.$t("match_references.intro_page.up_to_6_month"),
        this.$t("match_references.intro_page.up_to_1_year"),
        this.$t("match_references.intro_page.more_than_1_year")
      ];
    },
    candidateRelationshipTypes() {
      return [
        this.$t("match_references.intro_page.peer"),
        this.$t("match_references.intro_page.colleague_worker"),
        this.$t("match_references.intro_page.contractor"),
        this.$t("match_references.intro_page.supervisor_boss")
      ]
    },
    referencesRelationshipTypes() {
      return this.candidateRelationshipTypes.concat([
        this.$t("match_references.intro_page.friend"),
        this.$t("match_references.intro_page.family_member")
      ]);
    },
    relationshipStatuses() {
      return [
        this.$t("match_references.intro_page.good_times"),
        this.$t("match_references.intro_page.challenging_or_difficult_times"),
        this.$t("match_references.intro_page.both_good_and_difficult_times")
      ];
    },
    role() {
      return [
        { text: this.$t(`exit.role.${TEAM_MEMBERS_ROLE.LEAVING_PERSON}`), value: TEAM_MEMBERS_ROLE.LEAVING_PERSON },
        { text: this.$t(`exit.role.${TEAM_MEMBERS_ROLE.TEAM_LEADER}`), value: TEAM_MEMBERS_ROLE.TEAM_LEADER },
        { text: this.$t(`exit.role.${TEAM_MEMBERS_ROLE.LINE_MANAGER}`), value: TEAM_MEMBERS_ROLE.LINE_MANAGER },
        { text: this.$t(`exit.role.${TEAM_MEMBERS_ROLE.TEAM_MEMBER}`), value: TEAM_MEMBERS_ROLE.TEAM_MEMBER },
        { text: this.$t(`exit.role.${TEAM_MEMBERS_ROLE.CONSULTANT}`), value: TEAM_MEMBERS_ROLE.CONSULTANT }
      ]
    },
  }
};

export default ReferencesHelpersMixin;
