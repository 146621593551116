import { mapGetters } from 'vuex';
const ConfigMixin = {
  computed: {
    ...mapGetters('global', [
      'titles',
    ]),
    titlesSelect() {
      return this.titles.map(title => ({
        text: title.text,
        value: title.value,
      }))
    },
  },
  created() {
    this.$store.dispatch('global/fetchTitles');
  }
};

export default ConfigMixin;
