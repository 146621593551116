<template>
    <div class="survey survey__content__block">

      <test-header
        :message="question.data.scenario"
        :image="`${question.data.scenario_key}`">
      </test-header>

      <test-case
        :message="question.data.text"
        counter="counter"
        :total="questionsCount"
        :number="questionNumber">
      </test-case>

      <test-slider
        class="mt-4"
        :labelLeft="question.data.answer_1_text"
        :labelRight="question.data.answer_2_text"
        :range-tips="rangeTips"
        v-model="question.data.value">
      </test-slider>

      <div class="survey__footer">
        <b-btn :disabled="isNextDisabled || question.data.value === 0" class="btn-lg" variant="primary" @click="next">
          <b-spinner v-if="isNextDisabled"></b-spinner>
          {{ $t("general.next") }}
        </b-btn>
      </div>

    </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionsCount: {
      type: Number,
      default: 0,
    },
    questionNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: 0,
      isNextDisabled: false,
    };
  },
  computed: {
    rangeTips() {
      return [
        {
          from: 0,
          to: 0,
          label: this.$t("match_references.example_volume_slider.tooltip_1"),
        },
        {
          to: -90,
          label: this.$t("match_references.situation_volume_slider_tooltip"),
        },
        {
          from: 90,
          label: this.$t("match_references.situation_volume_slider_tooltip"),
        },
      ];
    },
    attributeKey() {
      return this.question.data.value < 0 ? "attribute_1_id" : "attribute_2_id";
    },

    attribute() {
      return this.question.data[this.attributeKey];
    },
  },
  methods: {
    next() {
      this.isNextDisabled = true;
      this.$emit("answer", {
        question_key: this.question.data.key,
        attribute_key: this.attribute,
        value: Math.abs(this.question.data.value),
      });
    },
  },
};
</script>
