<template>
  <div>

    <!-- // print version -->

    <div class="print__version d-none">
      <b-row>
        <b-col size="6">
          <report-core-competencies :candidate="candidate"></report-core-competencies>
        </b-col>
        <b-col size="6">
          <report-best-match :candidate="candidate" :campaign="campaign"></report-best-match>
        </b-col>
      </b-row>

      <report-personality-type :candidate="candidate"></report-personality-type>
      <report-performance :candidate="candidate"></report-performance>
      <report-potential :candidate="candidate"></report-potential>
      <report-rock-star :candidate="candidate"></report-rock-star>
    </div>


    <!-- // screen version -->

    <div class="screen__version">
      <report-core-competencies :candidate="candidate"></report-core-competencies>
      <report-best-match :candidate="candidate" :campaign="campaign"></report-best-match>
      <report-personality-type :candidate="candidate"></report-personality-type>
      <report-performance :candidate="candidate"></report-performance>
      <report-potential :candidate="candidate"></report-potential>
      <report-rock-star :candidate="candidate"></report-rock-star>
    </div>
  </div>
</template>

<script>
import CoreCompetencies from "./CoreCompetencies";
import BestMatch from "./BestMatch";
import PersonalityType from "./PersonalityType";
import Performance from "./Performance";
import Potential from "./Potential";
import RockStar from "./RockStar";

export default {
  components: {
    ReportCoreCompetencies: CoreCompetencies,
    ReportBestMatch: BestMatch,
    ReportPersonalityType: PersonalityType,
    ReportPerformance: Performance,
    ReportPotential: Potential,
    ReportRockStar: RockStar
  },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {}
    },
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>
