<template>
  <div class="content__wrapper">
    <div class="xk-view xk-view--no-sidebar container">
      <b-row>
        <b-col>
          <div class="survey survey__content__block">
            <div class="survey__header">
              <h1>{{ $t('match_references.thank_you_page_title') }}</h1>
            </div>
            <!--
            <h5 class="pb-3">
              {{ $t('match_references.thank_you_page_subtitle') }}
            </h5>

            <p>
              <i18n path="match_references.thank_you_page_description" tag="p"
                ><strong>{{ $t('applicant_portal.examiz_score') }}</strong></i18n
              >
            </p>

            <i18n
              path="match_references.thank_you_page_description_price"
              tag="p"
              class="pb-3"
            >
              <template v-slot:name><strong>{{ $t('applicant_portal.examiz_score') }}</strong></template>
              <template v-slot:cost><strong>$75</strong></template>
            </i18n>
            -->
            <TeamSurveyTable v-if="showTeamTable" :candidates="candidates" :inProgressCandidatesIds="inProgressCandidates" />

            <div class="survey__footer">
              <b-button
                v-if="surveyType === 'team' && hasNextSurvey"
                class="min-w-260"
                block
                size="lg"
                variant="primary"
                :href="`/reference/${$route.params.token}`"
                >{{ $t('match_references.team_survey_next') }}</b-button
              >

              <!-- <b-button
                v-if="surveyType === 'team' && !hasNextSurvey"
                class="min-w-260"
                block
                size="lg"
                variant="primary"
                :href="`/self-test?token=${this.token}`"
                >{{ $t('match_references.redeem_personality_test') }}</b-button
              >

              <b-button
                v-if="surveyType === 'selftest'"
                class="min-w-260"
                block
                size="lg"
                variant="primary"
                :href="`/self-test?token=${this.token}`"
                >{{ $t('match_references.redeem_personality_test') }}</b-button
              >

              <b-button
                v-if="surveyType === 'job'"
                class="min-w-260"
                block
                size="lg"
                variant="primary"
                :href="`/self-test?token=${this.token}`"
                >{{ $t('match_references.redeem_personality_test') }}</b-button
              > -->
            </div>

            <!-- TMP condition -->
            <div v-if="surveyType === 'team' && hasNextSurvey" class="survey__content__block__divider">
              <span>{{ $t('general.or') }}</span>
            </div>

            <!-- <b-row>
              <b-col>
                <b-button
                  class="min-w-260"
                  block
                  size="lg"
                  variant="outline-primary"
                  href="http://examiz.com/"
                  >{{ $t('general.exit') }}</b-button>
              </b-col>
            </b-row> -->
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CAMPAIGN_TYPES } from '@/common/constants';
import TeamSurveyTable from '@/components/TeamSurveyTable'

export default {
  components: {
    TeamSurveyTable,
  },
  props: {
    token: {
      type: String,
      default: '',
    },
    surveyType: {
      type: String,
      default: CAMPAIGN_TYPES.JOB, // TODO: Add this to constants
    },
    hasNextSurvey: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('survey', ['candidates', 'inProgressCandidates', 'type']),
    showTeamTable() {
      return this.type === CAMPAIGN_TYPES.TEAM;
    }
  }
};
</script>