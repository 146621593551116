<template>
  <b-row class="potential__analytics">
    <b-col
      sm="12"
      md="8"
      lg="8"
      class="potential__analytics__content col-print-8"
    >
      <!-- // chart canvas ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="chart__canvas justify-content-center">
        <div class="chart__area chart__area__tl"></div>
        <div class="chart__area chart__area__tr"></div>
        <div class="chart__area chart__area__bl"></div>
        <div class="chart__area chart__area__br"></div>
        <div class="chart__area chart__area__tr__corner"></div>

        <!-- // chart candidates ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <span
          class="mdi mdi-checkbox-blank chart__candidate__item"
          v-for="(item, index) in potentialAnalyticsSelectedUsers"
          :key="index"
          :class="getNumericIcon(item.analyticChartClasses, index)"
          :style="{
            left: item.performance + '%',
            bottom: item.potential + '%',
          }"
        >
        </span>

        <div
          class="chart__candidate__item"
          :style="{ left: 0, bottom: 0 }"
          v-if="potentialFiltered.length > 0"
        >
          <div class="chart__candidate__item__inner">
            <strong v-for="item in potentialFiltered" :key="item.index"
              >{{ item.index + 1 }}<span>,</span></strong
            >
          </div>
        </div>
      </div>

      <!-- // chart axis left ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="chart__axis__left">
        <div class="chart__axis__left__label">
          <span class="label__text">{{ $t('report.potential') }}</span>
          <!--<span class="label__weaker">{{ $t('report.relative_weaker') }}</span>
          <span class="label__stronger">{{
            $t('report.relative_stronger')
          }}</span>-->
        </div>
      </div>

      <!-- // chart axis bottom ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="chart__axis__bottom">
        <div class="chart__axis__bottom__label">
          <span class="label__text">{{ $t('report.performance') }}</span>
          <!--<span class="label__weaker">{{ $t('report.relative_weaker') }}</span>
          <span class="label__stronger">{{
            $t('report.relative_stronger')
          }}</span>-->
        </div>
      </div>
    </b-col>

    <!-- // chart legend ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-col sm="12" md="4" lg="4" class="chart__legend col-print-4">
      <b-table
        class="report__ems__table"
        ref="table"
        :fields="['index', 'candidate']"
        :items="potentialAnalyticsSelectedUsers"
        :borderless="true"
        responsive="sm"
        fixed="fixed"
        filter="true"
      >
        <template #head(index)>
          <div class="table__head">
            <span>{{ $t('create_campaign.candidates') }}</span>
          </div>
        </template>

        <template #table-colgroup>
          <col width="28px" />
          <col />
        </template>

        <template #head(candidate)>
          <div class="table__head"></div>
        </template>

        <template v-slot:cell(index)="{ item, index }">
          <div class="chart__legend__item">
            <span
              class="mdi"
              :class="getNumericIcon(item.analyticChartClasses, index)"
            ></span>
          </div>
        </template>

        <template v-slot:cell(candidate)="{ item }">
          <div class="chart__legend__item">
            <span class="chart__legend__name">{{ item.candidate }}</span>
          </div>
        </template>
      </b-table>

      <table class="table report__ems__table">
        <thead>
          <tr>
            <th colspan="2" v-if="!hideLegend">
              <div class="chart__legend__title" v-if="isPrintedReport">{{ $t('report.legend') }} </div>
              <div class="chart__legend__title" v-else>
                <b-form-checkbox class="chart__checkbox" v-model="showLegend" @change="onHideLegendChange">{{ $t('report.legend').toUpperCase() }}</b-form-checkbox>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="showLegend">
          <tr>
            <td class="chart__legend__item legend__icon">
              <span class="mdi mdi-checkbox-blank is__rockstar"></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t('report.rock_star') }}
              </div>
            </td>
          </tr>

          <tr>
            <td class="chart__legend__item legend__icon">
              <span
                class="mdi mdi-checkbox-blank chart__legend__index is__highpotential"
              ></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t('report.high_potential') }}
              </div>
            </td>
          </tr>

          <tr>
            <td class="chart__legend__item legend__icon">
              <span
                class="mdi mdi-checkbox-blank chart__legend__index is__highperformer"
              ></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t('report.high_performer') }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { calculateTwoSideProgressBarValue } from '@/common/utils';
import { ATTRIBUTES_KEYS_MATCH } from '@/common/constants';

export default {
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    filteredCandidates: {
      type: Array,
      default: () => [],
    },
    hideLegend: {
      type: Boolean,
      default: false
    },
    isPrintedReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      max: 100,
      fields: [
        {
          key: 'candidate',
          thClass: '',
        },
      ],
      showLegend: true
    };
  },
  computed: {
    ...mapGetters('report', ['reportAvgValues']),
    potentialAnalyticsSelectedUsers() {
      return this.potentialAnalytics.filter((item) => {
        if (item.showOnPrint) {
          return item;
        }
      });
    },
    potentialAnalytics() {
      return get(this.report, 'potential_analytics', []).map(
        (item, index) => {
          const showOnPrint = this.filteredCandidates
            ? this.filteredCandidates.indexOf(index) > -1
            : false;
          return {
            showOnPrint: showOnPrint,
            candidate: item.candidate,
            performance: this.setChartPositionAxis(item.performance, 'HIGH_PERFORMER_KEY'),
            potential: this.setChartPositionAxis(item.potential, 'HIGH_POTENTIAL_KEY'),
            enabler: item.enabler,
            leader: item.leader,
            index,
            highPerformance: item.pattern_high_performance_fulfilled,
            highPotential: item.pattern_high_potential_fulfilled,
            star: item.pattern_rock_star_fulfilled,
            classes: {
              hide: !showOnPrint,
              is__rockstar: item.pattern_rock_star_fulfilled,
              is__highperformer: item.pattern_high_performance_fulfilled,
              is__highpotential: item.pattern_high_potential_fulfilled,
              'no-print': !showOnPrint,
            },
            analyticChartClasses: {
              is__highperformer: item.pattern_high_performance_fulfilled,
              is__highpotential: item.pattern_high_potential_fulfilled,
              is__rockstar: item.pattern_rock_star_fulfilled,
            },
          };
        }
      );
    },
    potentialFiltered() {
      return this.potentialAnalytics.filter(
        (elem) => elem.potential === null && elem.performance === null
      );
    },
  },
  watch: {
    filteredCandidates() {
      // Force rerendering child components
      this.$store.commit('report/SET_REPORT', Object.assign({}, this.report));
    },
  },
  mounted() {
    this.showLegend = !this.hideLegend;
  },
  methods: {
    setChartPositionAxis(value, key) {
      const progressBarValue = calculateTwoSideProgressBarValue(
        value,
        this.reportAvgValues[ATTRIBUTES_KEYS_MATCH[key]]
      );
      // Scale to chart, lef and right 50%
      return progressBarValue / 2 + 50;
    },
    getPositionNumber(index) {
      return index + 1;
    },
    getNumericIcon(classObject, index) {
      classObject[
        'mdi-numeric-' + this.getPositionNumber(index) + '-box'
      ] = true;
      return classObject;
    },
    onHideLegendChange(value) {
      this.$emit('hideLegendChange', !value);
    }
  },
};
</script>
