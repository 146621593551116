<template>
  <div class="row">
    <div class="col">
      <p class="xmz-text">{{ $t("exit.create_survey.contributing_factor") }}</p>
      <div class="text-right">
        <b-button
          class="mt-3"
          size="md"
          type="submit"
          block="block"
          variant="primary"
          @click="close"
        >
          {{ $t('exit.create_survey.understand') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 25px;
}
</style>
