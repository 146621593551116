<template>
  <ValidationObserver ref="form" tag="div">
    <div class="step__item__content">
      <div class="step__item__index">{{ $t('match_candidate.step_4') }}</div>
      <div class="step__item__title">{{ $t('applicant_portal.review_activate') }}</div>
      <div class="step__item__description">
        {{ $t('applicant_portal.are_you_ready') }}
      </div>
      <b-button
        v-if="!showEditBtn"
        variant="primary"
        size="md"
        v-b-toggle.step-4
        class="step__item__trigger"
        :disabled="isDisabled"
        >{{ $t('general.setup') }}</b-button
      >
      <b-button
        v-if="showEditBtn"
        variant="outline-primary"
        size="md"
        v-b-toggle.step-4
        class="step__item__trigger"
        >{{ $t('dashboard.edit') }}</b-button
      >
      <b-collapse ref="step-4" id="step-4" class="steptoggle__content mb-0">
        <b-row>
          <b-col lg="12">
            <!-- assessment scope ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <!-- <div class="form__title">Assessment Scope</div> -->

            <b-form>
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('applicant_portal.timeline') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/schedule.svg"
                      alt="Timeline"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">Timeline</div> -->
                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-row>
                    <b-col md="6" sm="12">
                      <ValidationProvider
                        :name="$t('create_campaign.starting_date')"
                        class="datepicker-wrp"
                        rules="required"
                        tag="div"
                      >
                        <b-form-group
                          slot-scope="{ errors }"
                          :label="$t('create_campaign.starting_date') + '*'"
                        >
                          <b-form-datepicker
                            v-model="form.start_date"
                            :date-format-options="{
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            }"
                            :min="new Date()"
                            size="sm"
                            :disabled="isActivated"
                          ></b-form-datepicker>

                          <div class="error-message">{{ errors[0] }}</div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6" sm="12">
                      <ValidationProvider
                        :name="$t('create_campaign.end_date')"
                        :rules="{
                          required: true,
                          after: { limit: form.start_date, included: true },
                        }"
                        class="datepicker-wrp"
                        tag="div"
                      >
                        <b-form-group slot-scope="{ errors }">
                          <legend class="col-form-label">
                            {{ $t('create_campaign.end_date') }}*
                            <span
                              v-b-tooltip.hover
                              :title="$t('create_campaign.end_date_description')"
                            >
                              <i class="mdi mdi-information-outline"></i>
                            </span>
                          </legend>
                          <b-form-datepicker
                            v-model="form.end_date"
                            :date-format-options="{
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            }"
                            :min="new Date()"
                            size="sm"
                          ></b-form-datepicker>
                          <div class="error-message">{{ errors[0] }}</div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                      <ValidationProvider
                        :name="$t('create_campaign.starting_date')"
                        rules="required"
                        tag="div"
                      >
                        <b-form-group slot-scope="{ errors }">
                          <legend class="col-form-label">
                            {{ $t('general.timezone') }}*
                          </legend>
                          <multiselect
                            v-model="form.time_zone"
                            :options="timeZones"
                            :disabled="isActivated"
                            label="name"
                            :class="{ 'multiselect-error': !!errors[0] }"
                            :selectLabel="''"
                            :deselectLabel="''"
                            :selectedLabel="$t('general.selected')"
                          ></multiselect>

                          <div class="error-message">{{ errors[0] }}</div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <hr>
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('applicant_portal.assessment') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/hiring.svg"
                      alt="Assessment"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">Assasment</div> -->

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-row>
                    <b-col>
                      <legend>
                        <label for="references_requested"
                          >{{ $t('create_campaign.campaign_type') }}*</label
                        >
                        <span
                          v-b-tooltip.hover
                          :title="$t('create_campaign.campaign_type_tooltip')"
                          class="ml-2 mr-3"
                        >
                          <app-tooltip></app-tooltip>
                        </span>
                      </legend>

                      <app-select-input
                        id="$t('create_campaign.campaign_type')"
                        v-model="form.campaign_type"
                        :name="''"
                        :options="aplicantPortalCampaignTypes"
                        :readonly="isActivated"
                        rules="required"
                        size="sm"
                        tag="div"
                      ></app-select-input>
                    </b-col>

                    <b-col>
                      <legend>
                        <label for="references_requested"
                          >{{
                            $t('create_campaign.min_references_requested')
                          }}*</label
                        >
                        <span
                          v-b-tooltip.hover
                          :title="
                            $t(
                              'create_campaign.min_references_requested_description'
                            )
                          "
                          class="ml-2 mr-3"
                        >
                          <app-tooltip></app-tooltip>
                        </span>
                      </legend>
                      <app-select-input
                        id="$t('create_campaign.min_references_requested')"
                        v-model="form.info.references_requested"
                        :name="$t('create_campaign.min_references_requested')"
                        :options="references"
                        rules="required"
                        size="sm"
                        :readonly="disableMinReferencesInput || isActivated"
                        tag="div"
                      ></app-select-input>
                    </b-col>
                  </b-row>

                  <b-row v-if="isActivated">
                    <b-col>
                      <div class="form__title">{{ $t('applicant_portal.public_link') }}</div>
                      <CopyLink :slug="current.slug" />
                    </b-col>
                  </b-row>

                  <b-row class="mt-3" v-if="isActivated">
                    <b-col>
                      <div class="form__title">{{ $t('create_campaign.magic_link') }}</div>
                      <CopyLink :slug="current.slug" :mode="'e'" />
                    </b-col>
                  </b-row>
                </div>

                <div class="col-12 pt-4 d-flex justify-content-end">
                  <b-button
                    class="wide"
                    variant="outline-primary"
                    size="md"
                    @click="saveCampaign()"
                    >{{ $t('general.update') }}</b-button
                  >
                  <b-button
                    class="wide ml-3"
                    v-if="!isActivated"
                    variant="primary"
                    size="md"
                    @click="activateCampaign()"
                    >{{ $t('create_campaign.activate_campaign') }}</b-button
                  >&nbsp;
                </div>
              </div>


            </b-form>
          </b-col>
        </b-row>
      </b-collapse>
    </div>

    <b-modal
      centered
      v-model="showActivatedModal"
      :title="$t('create_campaign.modal_title')"
      hide-footer
      hide-header>
      <ActivatedModal
        :slug="current.slug"
        @gotodashboard="closeEditCampaign"
        @gotonewcampaign="createNewCampaign"
        @close="() => showActivatedModal = false"
        :title="''" />
    </b-modal>
  </ValidationObserver>
</template>

<script>
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';
import { CAMPAIGN_STATE, CAMPAIGN_TYPES } from '../../common/constants';

import AppSelectInput from '../../components/SelectInput';
import AppTooltip from '../../components/Tooltip';
import CopyLink from '../../components/CopyLink';
import ActivatedModal from './ActivatedModal';

export default {
  components: {
    AppSelectInput,
    AppTooltip,
    CopyLink,
    ActivatedModal,
  },
  name: 'ReviewAndActivate',
  mixins: [CampaignHelpersMixin],
  props: {
    isActivated: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    timeZones: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      references: [3, 4, 5, 6, 7],
      form: {
        info: {},
      },
      disableMinReferencesInput: false,
      isValid: false,
      showActivatedModal: false,
    };
  },
  computed:{
    showEditBtn() {
      return this.isActivated || this.isValid;
    }
  },
  watch: {
    'form.campaign_type'(newVal, oldVal) {
      this.disableMinReferencesInput = newVal !== CAMPAIGN_TYPES.JOB;
      this.form.info.references_requested = this.disableMinReferencesInput
        ? 1
        : 3;
    },
  },
  created() {
    if (this.current.id || (this.campaign && this.campaign.info && this.campaign.info.name)) {
      this.form.campaign_type = this.current.campaign_type;
      this.form.start_date = this.current.start_date;
      this.form.end_date = this.current.end_date;
      this.disableMinReferencesInput =
        this.form.campaign_type !== CAMPAIGN_TYPES.JOB;
      this.form.info.references_requested =
        this.current.info.references_requested || 3;

      this.$emit('currentForm', this.form);
    }
  },
  mounted() {
    this.$store.dispatch('global/fetchTimeZones').then(() =>
      this.$nextTick(() => {
        this.selectTimezone();
      })
    );
  },
  methods: {
    setIsValid(value) {
      this.isValid = value;
    },
    selectTimezone() {
      const currentTimeZone =
        this.current.time_zone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZone = this.timeZones.find(
        (tz) => tz.value === currentTimeZone
      );
      this.form = Object.assign({}, this.form, { time_zone: timeZone });
    },
    saveCampaign() {
      // this.$refs.form.validate().then((res) => {
      //   if (res) {
          const emitData = {
            id: 3,
            isValidForm: true,
            step: 4,
            disabled: false,
            data: this.form,
          };
          this.$emit('save', emitData);
      //   }
      // });
    },
    activateCampaign() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.form.state = CAMPAIGN_STATE.ACTIVE;
          this.$emit('activate', this.form);
        }
      });
    },
    toggleActivatedModal() {
      this.showActivatedModal = !this.showActivatedModal;
    },
    closeEditCampaign() {
      this.$router.push({ name: "Dashboard" });
    },
    createNewCampaign() {
      // TODO: solve hack! create new campaign is on the same route as campaign edit
      // So we need to refresh the page to shown correct create new campaign screen
      this.$router.push({ name: "CreateCampaign" });
      this.$router.go();
    },
  },
};
</script>
