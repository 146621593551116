<template>
  <b-modal centered :visible="true" hide-header hide-footer>
    <div>
      <div class="row">
        <div class="col">
          <div class="d-flex align-items-center border-bottom pb-3">
            <b-icon
              icon="check-square"
              variant="primary"
              font-scale="2"
            ></b-icon>
            <p class="m-0 ml-3 text-center campaign__updated-info">
              {{ $t('applicant_portal.unsaved_step') }}
            </p>
            <button
              @click="close"
              type="button"
              aria-label="Close"
              class="close xmz-close"
            >
              ×
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="xmz-text">
            {{ $t('applicant_portal.unsaved_data') }} {{ unsavedStep.step }}
          </p>

          <div class="d-flex align-items-center justify-content-center">
            <b-button
              class="mx-2 xxl"
              @click="close"
              variant="primary"
              v-b-tooltip.hover
              >{{ $t('general.back') }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    unsavedStep: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.xmz-close {
  position: absolute;
  right: 12px;
}

.xmz-text {
  font-size: 2rem;
  text-align: center;
  padding: 20px 20px 10px;
}
</style>
