const SET_TYPE = (state, type) => {
  state.type = type;
};

const SET_CANDIDATE = (state, candidate) => {
  state.candidate = candidate;
};

const SET_CANDIDATES = (state, candidates) => {
  state.candidates = candidates;
};

const SET_IN_PROGRESS_CANDIDATES = (state, candidates) => {
  state.inProgressCandidates = candidates
}

const SHIFT_IN_PROGRESS_CANDIDATE = (state) => {
  state.inProgressCandidates.shift()
}

export default {
  SET_TYPE,
  SET_CANDIDATE,
  SET_CANDIDATES,
  SET_IN_PROGRESS_CANDIDATES,
  SHIFT_IN_PROGRESS_CANDIDATE,
};
