<template>
  <b-row>
    <b-col>
      <b-card class="mt-4">
        <b-card-title>{{ currentStepTitle }}</b-card-title>
        <b-card-text>
          <h3>{{ currentStepSubtitle }}</h3>
        </b-card-text>
        <b-card-text class="text-muted">{{ campaignInfo }}</b-card-text>
        <b-card-text>
          <form-wizard
            class="candidate-wizard"
            ref="wizard"
            @on-change="onChange"
            :color="primaryColor"
          >
            <tab-content @next="next">
              <step-first
                @next="next($event)"
                :campaign="campaign"
                :teamMembers="teamMembers"
                :leavingPerson="leavingPerson"
              ></step-first>
            </tab-content>

            <tab-content>
              <step-second
                :leavingPerson="leavingPerson"
                @done="onComplete"
                @prev="prev"
                :campaign="campaign"
                :form="form"
              ></step-second>
            </tab-content>
          </form-wizard>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import StepFirst from './StepFirst';
import StepSecond from './StepSecond';
export default {
  components: {
    StepFirst,
    StepSecond,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepIndex: 0,
      primaryColor: '#E8710F',
      form: {
        team_members: []
      }
    };
  },
  computed: {
     leavingPerson() {
      return this.teamMembers.find(t => t.role === 'leaving_person') // TODO: add leaving_person config
    },
    stepNumber() {
      return this.stepIndex + 1;
    },

    currentStepTitle() {
      return this.$t(`match_candidate.step_${this.stepNumber}`);
    },

    currentStepSubtitle() {
      return this.$t(`exit.team_members.wizard_title_step_${this.stepNumber}`);
    },

    campaignInfo() {
      return this.$t('match_candidate.examiz_campaign_id', {
        // id: this.campaign.campaign_id
      });
    },
  },
  created() {
    this.$store.dispatch('global/fetchTitles');
  },
  methods: {
    next(data) {
      if (data) this.form = data
      this.$refs.wizard.nextTab();
    },
    prev() {
      this.$refs.wizard.prevTab();
    },
    onComplete() {
      this.$store.dispatch('exit/addTeamMembers', {
        ...this.form,
        token: this.$route.params.token
      }).then(() => {
        this.$router.push(`/team-members/${this.$route.params.token}/thankyou`);
      })
    },
    onChange(prevIndex, nextIndex) {
      this.stepIndex = nextIndex;
    },
  },
};
</script>
