import { mapGetters } from "vuex";
const SeekerHelpersMixin = {
  computed: {
    ...mapGetters("global", ["currentLanguage"]),
    languages() {
      return window.available_locales.map((locale) => ({
        text: locale.text,
        value: locale.value,
        common: locale.common
      }));
    },
    countries() {
      return window.available_countries.map((country) => ({
        text: country[0],
        value: country[1]
      }));
    },
    highestEducations() {
      return window.seeker_educations.map((education) => ({
        text: education[`label_${this.currentLanguage.toLowerCase()}`],
        value: education.value
      })).sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)); // TODO: Sort on BE
    },
    jobArea() {
      return window.seeker_job_areas.map((area) => ({
        text: area[`label_${this.currentLanguage.toLowerCase()}`],
        value: area.value
      }));
    },
    jobLevels() {
      return window.seeker_job_grades.map((item) => ({
        text: item[`label_${this.currentLanguage.toLowerCase()}`],
        value: "" + item.value
      }));
    },
    companySize() {
      return window.seeker_company_sizes.map((companySize) => ({
        text: companySize[`label_${this.currentLanguage.toLowerCase()}`],
        value: companySize.value
      }));
    },
    yearsOfExperience() {
      return window.seeker_job_periods.map((jobPeriod) => ({
        text: jobPeriod[`label_${this.currentLanguage.toLowerCase()}`],
        value: jobPeriod.value
      }));
    },
    lastPromotion() {
      return window.seeker_job_promotions.map((jobPromo) => ({
        text: jobPromo[`label_${this.currentLanguage.toLowerCase()}`],
        value: jobPromo.value
      }));
    },
    documentType() {
      return window.seeker_document_types.map((docType) => ({
        text: docType[`label_${this.currentLanguage.toLowerCase()}`],
        value: docType.value,
        slug: docType.slug
      }));
    },
    availableIn() {
      return window.seeker_availabilities.map((availability) => ({
        text: availability[`label_${this.currentLanguage.toLowerCase()}`],
        value: availability.value
      }));
    },
    offerRadius() {
      return window.seeker_job_radiuses.map((jobRadius) => ({
        text: jobRadius[`label_${this.currentLanguage.toLowerCase()}`],
        value: jobRadius.value
      }));
    },
    interestedIn() {
      return this.$store.getters['global/jobTypes'].map((jobType) => ({
        text: this.$t(`job_types.${jobType.key}`),
        value: jobType.id
      }));
    },
    summaryFields() {
      return [
        {
          key: "job_area",
          label: this.$t("seeker_account.job_area")
        },
        {
          key: "job_name",
          label: this.$t("seeker_account.job_name")
        },
        {
          key: "job_level",
          label: this.$t("create_campaign.job_level")
        },
        {
          key: "keywords",
          label: this.$t("seeker_account.keywords")
        },
        {
          key: "company_size",
          label: this.$t("create_campaign.company_size")
        },
        {
          key: "years_of_experience",
          label: this.$t("seeker_account.years_of_experience")
        },
        {
          key: "most_important_job",
          label: this.$t("seeker_account.most_important_job")
        },
        {
          key: "current_job",
          label: this.$t("seeker_account.current_job")
        }
      ];
    }
  }
};

export default SeekerHelpersMixin;
