<template>
  <div
    class="d-flex align-item-center justify-content-center language-switcher"
  >
    <b-dropdown variant="link" right>
      <template v-slot:button-content>
        <span>{{ currentLanguageItem }}</span>
      </template>

      <b-dropdown-item
        v-for="item in languagesList"
        @click="changeLocale(item.value)"
        :key="item.value"
        :disabled="item.value === currentLanguage"
        >{{ item.label }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { languages } from '@/common/languages';
import { EventBus, EventBusEvents } from '@/event-bus';
import {
  localeChanged,
} from 'vee-validate';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'global/currentLanguage',
    }),
    languagesList() {
      return languages;
    },
    currentLanguageItem() {
      const lang = languages.find((item) => item.value === this.currentLanguage)
      return lang ? lang.label : 'en';
    },
  },
  methods: {
    changeLocale(language) {
      this.$store.commit('global/SHOW_SPINNER');

      // Dispatch locale change event
      // this.$i18n.locale = language;
      // EventBus.$emit(EventBusEvents.localeChanged);

      this.$store.dispatch('global/setLanguage', language).then(async () => {
        location.reload();
        // await this.$store.dispatch('global/fetchConfig', language);
        // await this.$store.dispatch('global/fetchTitles');

        // this.$nextTick(async () => {
        //   // Populate translations
        //   this.$store.commit('global/POPULATE_ATTRIBUTES');
        //   this.$store.commit('global/POPULATE_TALENTS_ATTRIBUTES');
        //   this.$store.commit('global/POPULATE_PERMANENT_ATTRIBUTES');

        //   let rerenderApp = true;
        //   if (this.$route.name === 'CreateCampaign') {
        //     rerenderApp = false;
        //   } else if (this.$route.name === 'EditCampaign') {
        //     this.$store.dispatch(
        //       'campaign/populateCampaignTranslations',
        //       this.$store.getters['campaign/current']
        //     );
        //     rerenderApp = false;
        //   } else if (this.$route.name === 'Report') {
        //     this.$store.dispatch('report/getReport', {
        //       id: this.$route.params.id,
        //       mode: false,
        //     });
        //   } else if (this.$route.name.startsWith('self-test')) {
        //     await this.$store.dispatch('report/getSelfTestReport', {
        //       token: this.$store.getters['global/currentUserToken'],
        //       lang: this.$store.getters['global/currentLanguage'],
        //     });
        //   } else if (this.$route.path.startsWith('/reference')) {
        //     await this.$store.dispatch('survey/getSurveyInfo', this.$route.params.token);
        //   }

        //   this.$store.commit('global/HIDE_SPINNER');
        //   if (rerenderApp) EventBus.$emit(EventBusEvents.rerenderApp);

        //   // Update active forms translations
        //   this.updateForms(this.$root);
        // });
      });
    },
    updateForms(root) {
      root.$children.forEach(element => {
        if (element.$options['_componentTag'] === 'ValidationObserver' &&
            element.flags &&
            element.flags.validated) {

          element.validate();
        }
        this.updateForms(element)
      });
    }
  },
};
</script>