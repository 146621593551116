<template>
  <div class="login__page">
    <app-header></app-header>

    <b-container class="h-100">
      <div class="login__dialog d-flex align-items-center h-100">
        <ValidationObserver ref="form" tag="div">
          <div class="text-center">
            <h1 class="xk-headline text-center">
              {{ $t('general.redeem_your_code') }}
            </h1>
          </div>

          <b-form>
            <div class="form-group form-group__custom">
              <label for="title">{{ $t('general.title') }}</label>
              <app-select-input
                :name="$t('general.title')"
                mode="lazy"
                id="title"
                rules="required"
                v-model="form.gender"
                :options="titlesSelect"
                vid="title"
              ></app-select-input>
            </div>

            <ValidationProvider
              vid="firstname"
              rules="required"
              mode="lazy"
              v-slot="{ errors }"
            >
              <div class="form-group form-group__custom">
                <label for="firstname">{{ $t('general.first_name') }}</label>
                <input
                  class="form-control"
                  id="firstname"
                  rules="required"
                  type="text"
                  v-model="form.first_name"
                  :placeholder="$t('general.first_name')"
                />
                <div class="error-message" v-if="errors[0]">
                  * {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider
              vid="lastname"
              rules="required"
              mode="lazy"
              v-slot="{ errors }"
            >
              <div class="form-group form-group__custom">
                <label for="lastname">{{ $t('general.last_name') }}</label>
                <input
                  class="form-control"
                  id="lastname"
                  v-model="form.last_name"
                  rules="required"
                  type="text"
                  :placeholder="$t('general.last_name')"
                />
                <div class="error-message" v-if="errors[0]">
                  * {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider
              vid="email"
              rules="required|email"
              mode="lazy"
              v-slot="{ errors }"
            >
              <div class="form-group form-group__custom">
                <label for="email">{{ $t('general.email') }}</label>
                <input
                  class="form-control"
                  id="email"
                  v-model="form.email"
                  type="email"
                  rules="required|email"
                  :placeholder="$t('general.email')"
                />
                <div class="error-message" v-if="errors[0]">
                  * {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider
              vid="promocode"
              rules="required"
              mode="lazy"
              v-slot="{ errors }"
            >
              <div class="form-group form-group__custom promocode">
                <label for="promocode">{{ $t('user_registration.promo_code') }}</label>
                <span class="mdi mdi-barcode"></span>
                <input
                  class="form-control input__big"
                  id="promocode"
                  v-model="form.code"
                  rules="required"
                  type="text"
                  autocomplete="off"
                  maxlength="8"
                  :placeholder="'STPRMCDE'"
                />
                <div class="error-message" v-if="errors[0]">
                  * {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>

            <div class="error-message server" v-if="serverErrorMsg">
              {{ serverErrorMsg }}
            </div>

            <hr />

            <b-button
              @click="redeemPromoCode"
              variant="primary"
              block
              class="btn-lg"
            >
              <b-spinner v-if="showSpinner" variant="light" label="Spinning">
              </b-spinner
              >{{ $t('general.next') }}
            </b-button>
          </b-form>
        </ValidationObserver>
      </div>
    </b-container>

    <app-footer></app-footer>
  </div>
</template>

<script>
import AuthMixin from '@/common/mixins/AuthMixin';
import ConfigMixin from '@/common/mixins/ConfigMixin';
import AppFooter from '@/components/Footer';
import AppHeader from '@/components/Header';
import AppSelectInput from '../../components/SelectInput';

export default {
  mixins: [AuthMixin, ConfigMixin],
  components: {
    AppFooter,
    AppHeader,
    AppSelectInput,
  },
  data() {
    return {
      showSpinner: false,
      serverErrorMsg: '',
      form: {
        gender: 'male'
      },
    };
  },
  created() {
    const code = this.$route.query.code;
    if (code) this.form.code = code;
  },
  methods: {
    redeemPromoCode() {
      this.$refs.form.validate().then((valid) => {
        if (!valid) return;

        this.showSpinner = true;

        this.$store
          .dispatch(
            'promocode/redeemPromoCode',
            Object.assign(this.form, {
              lang: this.currentLanguage,
            })
          ).then((res) => {
            this.showSpinner = false;
            window.location.href = res.data.url;
          })
          .catch((err) => {
            this.showSpinner = false;
            this.showErrorMessage(err);
          });
      });
    },
    showErrorMessage(err) {
      let msg = '';
      if (err.response && err.response.data && err.response.data.code) {
        msg = this.$t(`xmz_server_errors.${err.response.data.code}`);
      } else {
        msg = this.$t(`xmz_server_errors.${err}`);
      }
      this.serverErrorMsg = msg.includes('xmz_server_errors') ? '' : msg;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'styles/variables';

.login__page {
  background-color: transparentize($xk-color-graphit, 0.96);
}

.login__dialog {
  label {
    margin-bottom: 6px;
  }

  p {
    color: $xk-color-graphit;
  }

  .promocode {
    position: relative;

    .mdi {
      font-size: 4.2rem;
      color: $xk-color-marine;
      position: absolute;
      top: 39px;
      display: block;
      right: 10px;
    }

    input {
      font-family: $xk-font-mono;
      text-transform: uppercase;
      font-size: 2.2rem;
      font-weight: bold;
      padding-right: 48px;
    }
  }

  .server {
    font-size: 1.5rem;
  }
}
</style>
