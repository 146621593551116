<template>
  <b-row class="h-100">
    <b-col class="col-6-lg d-lg-flex d-none h-100 container-bg"></b-col>

    <b-col class="col-6-lg h-100 bg-white">
      <ValidationObserver ref="form" tag="div" class="login__dialog">
        <h1 class="xk-headline pt-md-4">{{ $t('general.registration') }}</h1>
        <img
          class="illustration"
          :src="illustration"
          alt="Security Illustration"
          width="150"
        />

        <b-form
          class="auth-form auth-form__login mt-5"
          @submit.prevent="toNextStep"
        >
          <!--<p class="auth-page-description">{{ $t('auth_pages.provide_a_user_name_text') }}</p>-->

          <ValidationProvider
            class="mb-3"
            :name="$t('general.email')"
            vid="email"
            rules="required|email"
            mode="lazy"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="form-group form-group__custom"
              :class="errors[0] ? 'form-group-error' : ''"
            >
              <label for="email">{{ $t('general.email') }}</label>
              <input
                id="email"
                class="form-control input__big"
                type="email"
                v-model="data.email"
                :placeholder="$t('general.email')"
              />
              <div class="error-message" v-if="errors[0]">
                * {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            class="mb-3"
            :name="$t('general.password')"
            rules="required|min:6"
            vid="password"
            mode="lazy"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="form-group form-group__custom"
              :class="errors[0] ? 'form-group-error' : ''"
            >
              <label for="password">{{ $t('general.password') }}</label
              ><input
                class="form-control input__big"
                id="password"
                :class="errors[0] ? 'form-control-error' : ''"
                type="password"
                v-model="password"
                :placeholder="$t('general.password')"
              />
              <div class="error-message" v-if="errors[0]">
                * {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            class="mb-3"
            :name="$t('general.password').toLowerCase()"
            vid="password_confirmation"
            rules="required|confirmedBy:@password"
            mode="lazy"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="form-group form-group__custom"
              :class="errors[0] ? 'form-group-error' : ''"
            >
              <label for="password_confirmation">{{
                $t('auth_pages.repeat_password')
              }}</label
              ><input
                class="form-control input__big"
                id="password_confirmation"
                type="password"
                v-model="password_confirmation"
                :placeholder="$t('auth_pages.repeat_password')"
              />
              <div class="error-message" v-if="errors[0]">
                * {{ errors[0] }}
              </div>
            </div>

            <hr />

            <ValidationProvider
              :rules="{ required: { allowFalse: false } }"
              vid="terms"
            >
              <b-checkbox
                class="term-checkbox mb-1"
                v-model="acceptTermsAndCondition"
              >
                <i18n path="match_candidate.agree_terms" tag="span">
                  <template v-slot:terms_and_condition_link>
                    <a :href="termsAndConditionLink" target="_blank"
                      >{{ $t('match_candidate.terms_and_condition_link') }}
                    </a>
                  </template>
                  <template v-slot:user_agreement>
                    <a :href="userAgreementLink" target="_blank"
                      >{{ $t('match_candidate.user_agreement') }}
                    </a>
                  </template>
                </i18n>
              </b-checkbox>
            </ValidationProvider>

            <ValidationProvider
              :rules="{ required: { allowFalse: false } }"
              vid="privacy"
            >
              <b-checkbox
                class="term-checkbox mb-1"
                v-model="acceptDataProtectionAndPrivacy"
              >
                <i18n path="match_candidate.agree_cookies" tag="span"
                  ><template v-slot:data_protection_and_privacy_link
                    ><a :href="privacyPolicyLink" target="_blank">{{
                      $t('match_candidate.data_protection_and_privacy_link')
                    }}</a></template
                  ></i18n
                >
              </b-checkbox>
              <b-alert
                class="alert-light mt-3"
                variant="danger"
                :show="hasErrors(errors) &amp;&amp; (!acceptTermsAndCondition || !acceptDataProtectionAndPrivacy)"
                >{{ $t('match_candidate.agree_error') }}</b-alert
              >
            </ValidationProvider>
          </ValidationProvider>

          <hr />

          <b-button type="submit" block variant="primary" class="btn-lg">
            <b-spinner
              v-if="isInProgress"
              variant="light"
              label="Spinning"
            ></b-spinner
            >{{ $t('header.sign_up') }}</b-button
          >
        </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>

<script>
import Illustration from '../../../../../images/security_new.svg';
import AuthApi from '../../../../api/auth';
import franchise from '../../../../conf/franchise';

export default {
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      check: false,
      acceptTermsAndCondition: false,
      acceptDataProtectionAndPrivacy: false,
      isInProgress: false,
    };
  },
  created() {
    document.body.classList.add('candidate__welcome');
  },
  computed: {
    name: {
      get() {
        return this.data.name || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          name: value,
        });
      },
    },
    password: {
      get() {
        return this.data.password || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          password: value,
        });
      },
    },
    password_confirmation: {
      get() {
        return this.data.password_confirmation || '';
      },
      set(value) {
        this.$emit('change', {
          ...this.data,
          password_confirmation: value,
        });
      },
    },
    illustration() {
      return Illustration;
    },
    termsAndConditionLink() {
      return franchise.termsAndConditionLink;
    },
    userAgreement() {
      return franchise.userAgreementLink;
    },
    privacyPolicyLink() {
      return franchise.privacyPolicyLink;
    },
  },
  methods: {
    hasErrors(errors) {
      return !!Object.values(errors).reduce((r, i) => [...r, ...i], []).length;
    },
    toNextStep() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isInProgress = true;
        AuthApi.userValidate(this.data.email)
          .then(() => {
            this.$emit('next');
          })
          .catch((e) => {
            let errors = e.response.data.error;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.isInProgress = false;
          });
      });
    },
  },
};
</script>
