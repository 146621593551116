<template>
  <article class="campaign">

    <b-alert class="custom-alert" variant="warning" v-model="alertShow">
      <ul class="mb-0">
        <li v-for="(item, key) in alertMessage" :key="key">{{ item[0] }}</li>
      </ul>
    </b-alert>

    <b-modal centered v-model="showDraftModal" hide-footer no-close-on-backdrop>
      <app-save-draft-modal></app-save-draft-modal>
    </b-modal>

    <b-modal
      centered
      v-model="saveModal"
      :title="$t('create_campaign.modal_title')"
      hide-footer
      hide-header>
      <app-save-modal
        @gotodashboard="closeEditCampaign"
        @gotonewcampaign="createNewCampaign"
        @close="() => saveModal = false"
        :title="titleText"></app-save-modal>
    </b-modal>

    <b-modal
      centered
      v-model="useModal"
      hide-footer
      hide-header
      :title="$t('create_campaign.choose_template')"
      no-close-on-backdrop>
      <div class="close-modal" @click="useModal = !useModal">
        <modal-exit-icon></modal-exit-icon>
      </div>
      <app-use-template-modal @hide="useModal = !useModal"></app-use-template-modal>
    </b-modal>

    <b-modal
      centered
      v-model="saveTemplateModal"
      :title="$t('create_campaign.modal_title')"
      hide-footer
      hide-header
      no-close-on-backdrop>
      <app-save-template
        :current="current"
        @close="saveTemplateModal = !saveTemplateModal"></app-save-template>
    </b-modal>

    <!-- // campaign info header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row>
      <b-col>
        <header class="campaign__info__header">
          <div class="campaign__info__item">{{ $t('report.campaign_id') }}: {{ campaignId }}</div>
          <div class="campaign__info__item">{{ $t('create_campaign.internal_job_id') }} #1: {{ info.internal_job_id_1 }}</div>
          <div class="campaign__info__item">{{ $t('dashboard.job_title') }}: {{ info.job_title_short }}</div>
        </header>
      </b-col>
    </b-row>

    <!-- // form wizard ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <form-wizard
      @on-change="onChange"
      errorColor="#8b96a4"
      color="#E8710F"
      :title="wizardTitle"
      subtitle=""
      :start-index="startIndex"
      ref="wizard"
      stepSize="xs">

      <!-- // footer & buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <template slot="footer" slot-scope="props">
        <div class="d-flex flex-wrap align-items-end">

          <!-- // back button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-btn
            class="mr-2 xxl"
            :disabled="loading"
            variant="outline-secondary"
            v-if="props.activeTabIndex > 0"
            @click="props.prevTab()">{{ $t("general.back") }}</b-btn>

          <!-- // save as template button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-btn
            class="mx-2 xxl"
            v-if="props.activeTabIndex === 1"
            :disabled="loading"
            @click="saveTemplateModal = !saveTemplateModal"
            variant="outline-secondary">
            <span class="mdi mdi-puzzle-plus"></span>
            <span>&nbsp;{{ $t("create_campaign.save_as_template") }}</span>
          </b-btn>

          <div class="ml-auto">

            <!-- // use template button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="mx-2 xxl"
              :disabled="loading"
              variant="outline-secondary"
              v-if="props.activeTabIndex === 0"
              @click="useModal = !useModal">
              <span class="mdi mdi-puzzle-check"></span>
              <span class="ml-2">{{ $t("create_campaign.use_template") }}</span>
            </b-btn>

            <!-- // save button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="mx-2 xxl"
              :disabled="loading"
              variant="primary"
              @click="saveDraft"
              v-if="!props.isLastStep">{{ $t("general.save") }}</b-btn>

            <!-- // next button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="ml-2 xxl"
              variant="primary"
              v-if="!props.isLastStep"
              @click="props.nextTab()"
              :disabled="loading">{{ $t("general.next") }}</b-btn>

            <!-- // activate button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="ml-2 xxl"
              variant="primary"
              v-else
              @click="onComplete"
              v-b-tooltip.hover
              :disabled="loading"
              :title="activateBtnTooltip">{{ props.isLastStep ? activateBtnText : $t("general.next") }}</b-btn>
          </div>
        </div>
      </template>

      <!-- // wizard steps ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <tab-content :before-change="() => validateForm('first')" icon="check" title="Step 1">
        <app-step-first :current.sync="current" :campaign-id="campaignId" @add-keyword="addKeyword" ref="firstForm"></app-step-first>
      </tab-content>

      <tab-content :before-change="() => validateForm('second')" icon="check" title="Step 2">
        <app-step-second ref="secondForm" :campaign-id="campaignId"></app-step-second>
      </tab-content>

      <tab-content :before-change="() => validateForm('third')" icon="check" title="Step 3">
        <app-step-third ref="thirdForm" :campaign-id="campaignId"></app-step-third>
      </tab-content>

      <tab-content icon="check" title="Step 4">
        <app-step-fourth :campaign-id="campaignId"></app-step-fourth>
      </tab-content>

    </form-wizard>
  </article>
</template>

<script>
import copyToClipboard from "../../common/copyToClipboard";
import ModalExitIcon from "../../icons/modalExit";
import UseTemplateIcon from "../../icons/useTemplate";
import AppStepFirst from "./StepFirst";
import AppStepSecond from "./StepSecond";
import AppStepThird from "./StepThird";
import AppStepFourth from "./StepFourth";
import AppSaveTemplate from "./SaveTemplate";
import AppUseTemplateModal from "./UseTemplateModal";
import AppSaveModal from "./SaveModal";
import AppSaveDraftModal from "./SaveDraftModal";
import * as moment from "moment";
import { mapGetters } from "vuex";
import { CAMPAIGN_STATE } from '@/common/constants'

export default {
  components: {
    AppStepFirst,
    AppStepSecond,
    AppStepThird,
    AppStepFourth,
    AppSaveTemplate,
    AppSaveModal,
    AppSaveDraftModal,
    AppUseTemplateModal,
    ModalExitIcon,
    UseTemplateIcon,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    alertMessage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isValidated: false,
      index: 0,
      startIndex: 0,
      saveTemplateModal: false,
      alertShow: false,
      saveModal: false,
      showDraftModal: false,
      useModal: false,
      loading: false,
      titleText: "",
    };
  },
  computed: {
    ...mapGetters("campaign", ["current"]),
    wizardTitle() {
      return this.$t(`create_campaign.wizard_title_step_${this.index + 1}`);
    },
    activateBtnText() {
      return this.current.id && this.current.state === CAMPAIGN_STATE.ACTIVE
        ? this.$t("create_campaign.update_campaign")
        : this.$t("create_campaign.activate_campaign");
    },
    activateBtnTooltip() {
      return this.current.id && this.current.state === CAMPAIGN_STATE.ACTIVE
        ? this.$t("create_campaign.edit_campaign_btn_description")
        : this.$t("create_campaign.activate_campaign_btn_description");
    },
    campaignId() {
      if (this.current.id) {
        return this.current.id.split("-")[0].toUpperCase();
      }
      return null;
    },
    campaignLink() {
      if (this.current.id) {
        return `${document.location.origin}/portal/${this.current.id}`;
      }
      return null;
    },
    info: {
      get() {
        const info = this.current.info || {};
        const defaultAdmin = {
          campaign_admin_1_first_name: this.currentUserFirstName,
          campaign_admin_1_last_name: this.currentUserLastName,
          campaign_admin_1_email: this.currentUserEmail,
        };
        for (const key in defaultAdmin) {
          if (!(key in info)) {
            info[key] = defaultAdmin[key];
          }
        }
        return info;
      },
      set(value) {
        this.$emit("update:current", {
          ...this.current,
          info: value,
        });
      },
    },
  },
  created() {
    if (!this.id) {
      return this.$store.dispatch("campaign/resetCampaign");
    }
    this.$store.dispatch("campaign/getCampaign", this.id).then(() => {
      if (this.current.id) {
        this.startIndex = 3;
        this.index = 3;
      }
    });
  },
  mounted() {
    this.current
    if (this.current.id) {
      this.checkTabs();
    }
  },
  destroyed() {
    this.$store.dispatch("campaign/resetCampaign");
  },
  methods: {
    toClipboard() {
      copyToClipboard(this.campaignLink);
    },
    checkTabs() {
      let tabs = this.$refs.wizard.tabs;
      tabs.forEach((element) => {
        element.checked = true;
      });
    },
    createCampaign(predefinedState, showSaveModal = true) {
      this.loading = true;
      let state = null;
      const now = moment();
      if (predefinedState) {
        state = predefinedState;
      } else {
        state = moment(this.current.start_date).isAfter(now.endOf("day"))
          ? CAMPAIGN_STATE.OPEN
          : CAMPAIGN_STATE.ACTIVE;
      }
      this.current.info.campaign_id = this.campaignId;
      this.current.info.application_link = this.campaignLink;
      let keywords =
        this.current.keywords && this.current.keywords.length
          ? this.current.keywords.map((item) => item.name)
          : [];
      const params = {
        state: state,
        start_date: this.current.start_date,
        end_date: this.current.end_date,
        time_zone: this.current.time_zone,
        info: this.current.info,
        contacts: this.current.contacts,
        personality_attrs: this.current.personality_attrs,
        keywords: keywords,
        campaign_type: this.current.campaign_type
      };
      this.$store
        .dispatch("campaign/createCampaign", params)
        .then(() => {
          this.loading = false;
          if (this.current.state === CAMPAIGN_STATE.OPEN) {
            this.saveModal = showSaveModal;
            this.titleText = this.$t("create_campaign.campaign_updated_successfully");
            this.$router.push(`/campaigns/${this.current.id}/edit`);
          } else {
            this.$router.push({
              name: "Dashboard",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          const alertMessage = error.response.data.error;
          this.$bvToast.toast(alertMessage, {
            autoHideDelay: 2000,
            variant: "warning",
            title: "Error",
          });
        });
    },
    updateCampaign(id, data, predefinedState, showSaveModal = true) {
      this.loading = true;
      this.current.info.campaign_id = this.campaignId;
      this.current.info.application_link = this.campaignLink;
      let keywords =
        this.current.keywords && this.current.keywords.length
          ? this.current.keywords.map((item) => item.name)
          : [];
      const params = {
        state: predefinedState,
        start_date: this.current.start_date,
        end_date: this.current.end_date,
        time_zone: this.current.time_zone,
        info: this.current.info,
        contacts: this.current.contacts,
        personality_attrs: this.current.personality_attrs,
        keywords: keywords,
        id: this.current.id,
        campaign_type: this.current.campaign_type,
      };
      this.$store
        .dispatch("campaign/updateCampaign", params)
        .then(() => {
          this.loading = false;
          this.saveModal = showSaveModal;
        })
        .catch((error) => {
          this.loading = false;
          const alertMessage = Object.values(error.response.data.error);
          this.$bvToast.toast(alertMessage[0], {
            autoHideDelay: 2000,
            variant: "warning",
            title: "Error",
          });
        });
    },
    onComplete() {
      this.modalTitleText();

      if (this.current.id) {
        this.updateCampaign(this.current.id, this.current, CAMPAIGN_STATE.ACTIVE);
      } else {
        this.createCampaign(CAMPAIGN_STATE.ACTIVE);
      }
    },
    saveDraft(showModal = true) {
      this.showDraftModal = showModal;

      if (this.current.id) {
        return this.updateCampaign(
          this.current.id,
          this.current,
          this.current.state,
          false
        );
      }
      this.createCampaign(CAMPAIGN_STATE.OPEN, false);
    },
    onChange(prevIndex, nextIndex) {
      this.index = nextIndex;
      if ((!this.current.state || this.current.state === CAMPAIGN_STATE.OPEN) && nextIndex > prevIndex) {
        this.saveDraft(false);
      }
    },
    validateForm(name) {
      const forms = {
        first: this.$refs.firstForm,
        second: this.$refs.secondForm,
        third: this.$refs.thirdForm,
      };
      // Check references and reference method and validate else return valid
      return name in forms && forms[name].validateForm
        ? forms[name].validateForm()
        : true;
    },
    addKeyword(value) {
      this.current.keywords.push(value);
    },
    modalTitleText() {
      this.titleText =
        this.current.id && this.current.state === CAMPAIGN_STATE.OPEN
          ? this.$t("create_campaign.activated_campaign")
          : this.$t("create_campaign.campaign_updated_successfully");
    },
    closeEditCampaign() {
      this.$router.push({ name: "Dashboard" });
    },

    createNewCampaign() {
      // TODO: solve hack! create new campaign is on the same route as campaign edit
      // So we need to refresh the page to shown correct create new campaign screen
      this.$router.push({ name: "CreateCampaign" });
      this.$router.go();
    },
  },
};
</script>
