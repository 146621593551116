import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  jobSeeker: null,
  workItems: [],
  jobTypes: [],
  displayExperienceModal: false,
  runTest: true,
  form: {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
