<template>
  <div>
    <div><span>{{ $t('general.email') }}:</span> <strong class="ml-1">{{ currentUserEmail }}</strong></div>
    <div><span>{{ $t('general.first_name') }}:</span> <strong class="ml-1">{{ currentUserFirstName }}</strong></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters('global', ['currentUserEmail', 'currentUserFirstName'])
    }
  };
</script>
