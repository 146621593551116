<template>
  <label class="checkbox">
    <input type="checkbox" v-model="checkValue" /><span></span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    checkValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
