<template>
  <ValidationObserver ref="form" tag="div">
    <b-form class="create-campaign-wrp mt-3">
      <b-row>
        <b-col xl="4" lg="4" md="4">
          <!-- essentials ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../../images/campaign_icons/resume.svg"
              class="mr-3 mb-3"
              alt="Resume"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.essentials')
            }}</span>
          </div>

          <app-text-input
            :readonly="isActivated"
            :name="$t('create_campaign.internal_job_id')"
            :label="$t('create_campaign.internal_job_id')"
            vid="essentials_job_id"
            type="text"
            v-model="form.info.internal_id"
          ></app-text-input>

          <app-text-input
            :readonly="isActivated"
            :name="$t('exit.create_survey.title')"
            :label="$t('exit.create_survey.title') + '*'"
            vid="essentials_title"
            :rules="{
              required: true,
              customRule: {
                isValid: isNameUnique,
                message: $t('validation.title'),
              },
            }"
            type="text"
            v-model="form.name"
            @input="$emit('onNameUniquesChange')"
          ></app-text-input>

          <!-- department business ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../../images/campaign_icons/organization.svg"
              class="mr-3 mb-3 mt-5"
              alt="Organization"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.department_business')
            }}</span>
          </div>

          <b-form-group :label="$t('exit.create_survey.department')">
            <ExitMultiselect
              v-model="form.info.departments"
              :options="analyticsCampaignOptions.departments"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.unit')">
            <ExitMultiselect
              v-model="form.info.units"
              :options="analyticsCampaignOptions.units"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.keywords')">
            <ExitMultiselect
              v-model="form.info.keywords"
              :options="analyticsCampaignOptions.keywords"
            />
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="4">
          <!-- interview admin ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../../images/campaign_icons/applicants_screening.svg"
              class="mr-3 mb-3"
              alt="Screening"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.report.people')
            }}</span>
          </div>

          <b-form-group :label="$t('exit.create_survey.job_title')">
            <ExitMultiselect
              v-model="form.info.job_names"
              :options="analyticsCampaignOptions.job_names"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.job_level')">
            <ExitMultiselect
              v-model="form.info.job_levels"
              :options="jobLevels"
              label="text"
              trackBy="value"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.performance_level')">
            <ExitMultiselect
              v-model="form.info.performance_levels"
              :options="performanceLevels"
              label="text"
              trackBy="value"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.report.gender')">
            <ExitMultiselect
              v-model="form.info.genders"
              :options="genders"
              :extendedHeight="false"
              label="text"
              trackBy="value"
            />
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.performance_level')">
            <br />
            <vue-slider
              v-model="form.info.examiz_score"
              dotSize="12"
              :height="3"
              :lazy="true"
              :interval="1"
              :marks="marks"
              :tooltip="'always'"
              :tooltip-placement="'top'"
            >
            </vue-slider>
          </b-form-group>

          <b-form-group :label="$t('exit.create_survey.age')">
            <br />
            <vue-slider
              v-model="form.info.age"
              dotSize="12"
              :height="3"
              :lazy="true"
              :interval="1"
              :marks="marks"
              :tooltip="'always'"
              :tooltip-placement="'top'"
            >
            </vue-slider>
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="4">
          <!-- leaving person ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <div>
            <img
              src="../../../../../images/campaign_icons/user_location.svg"
              class="mr-3 mb-3"
              alt="Location"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.location')
            }}</span>
          </div>

          <b-form-group :label="$t('dashboard.country')">
            <ExitMultiselect
              v-model="form.info.countries"
              :options="countries"
              label="text"
              trackBy="value"
            />
          </b-form-group>

          <b-form-group :label="$t('general.city')">
            <ExitMultiselect
              v-model="form.info.cities"
              :options="analyticsCampaignOptions.cities"
            />
          </b-form-group>

          <div>
            <img
              src="../../../../../images/campaign_icons/schedule.svg"
              class="mr-3 mb-3 mt-5"
              alt="Schedule"
              width="60"
            />
            <span class="form__title">{{
              $t('exit.create_survey.time_period')
            }}</span>
          </div>

          <ValidationProvider class="datepicker-wrp" tag="div">
            <b-form-group
              slot-scope="{ errors }"
              :label="$t('dashboard.start_date')"
            >
              <b-form-datepicker
                v-model="form.start_date"
                :date-format-options="{
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }"
                size="sm"
              ></b-form-datepicker>
              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider class="datepicker-wrp" tag="div" rules="required">
            <b-form-group
              slot-scope="{ errors }"
              :label="$t('dashboard.end_date') + '*'"
            >
              <b-form-datepicker
                v-model="form.end_date"
                :date-format-options="{
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }"
                size="sm"
              ></b-form-datepicker>
              <div class="error-message">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from '../../../../components/CustomMultiselect';
import ExitMultiselect from '../../../../components/Exit/ExitMultiselect.vue';

import AppTooltip from '../../../../components/Tooltip';
import AppTextInput from '../../../../components/TextInput';
import AppSelectInput from '../../../../components/SelectInput';
import CampaignHelpersMixin from '../../../../common/CampaignHelpersMixin';
import ExitCampaignHelper from '../../../../common/ExitCampaignHelper';
import { EXIT_CAMPAIGN_STATE } from '@/common/constants';
import { mapGetters } from 'vuex';
import { EXIT_CAMPAIGN_TYPES } from '../../../../common/constants';
export default {
  components: {
    ExitMultiselect,
    AppMultiselect,
    AppTooltip,
    AppTextInput,
    AppSelectInput,
    ExitCampaignHelper,
  },
  mixins: [CampaignHelpersMixin, ExitCampaignHelper],
  props: {
    isNameUnique: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      marks: [0, 100],
      form: {
        campaign_type: EXIT_CAMPAIGN_TYPES.ANALYTICS,
        // Admin is always current user
        admin: {},
        info: {
          examiz_score: [55, 95],
          age: [25, 55],
          countries: [],
          performance_levels: [],
          genders: [],
          job_levels: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters('global', [
      'currentUserFirstName',
      'currentUserLastName',
      'currentUserEmail',
      'availableCountries',
      'availableCountriesWithKey',
    ]),
    ...mapGetters('exit', ['campaign', 'analyticsCampaignOptions']),
    jobLevels() {
      return this.analyticsCampaignOptions.job_levels.map((item) => ({
        text: this.$t(`exit.job_level.${item}`),
        value: item,
      }));
    },
    performanceLevels() {
      return this.analyticsCampaignOptions.performance_levels.map((item) => ({
        text: this.$t(`exit.performance_level.${item}`),
        value: item,
      }));
    },
    countries() {
      return this.analyticsCampaignOptions.countries.reduce((prev, curr) => {
        const country = this.availableCountries.find((c) => c.value === curr);
        if (country) prev.push(country);
        return prev;
      }, []);
    },
    genders() {
      return this.titles.map((t) => ({
        value: t.value,
        text: this.$t(`general.genders.${t.value}`),
      }));
    },
    isActivated() {
      return (
        this.campaign.status !== EXIT_CAMPAIGN_STATE.OPEN &&
        this.campaign.status !== undefined
      );
    },
  },
  created() {
    this.form.admin = {
      first_name: this.currentUserFirstName,
      last_name: this.currentUserLastName,
      email: this.currentUserEmail,
    };

    if (this.campaign.id) {
      this.form = {
        ...this.campaign,
        info: {
          ...this.campaign.info,
          countries: (this.campaign.info.countries || []).map((key) => ({
            value: key,
            text: this.availableCountriesWithKey[key],
          })),
          performance_levels: (this.campaign.info.performance_levels || []).map(
            (item) => ({
              value: item,
              text: this.$t(`exit.performance_level.${item}`),
            })
          ),
          genders: (this.campaign.info.genders || []).map((item) => ({
            value: item,
            text: this.$t(`general.genders.${item}`),
          })),
          job_levels: (this.campaign.info.job_levels || []).map((item) => ({
            value: item,
            text: this.$t(`exit.job_level.${item}`),
          })),
        },
      };
    }
  },
  methods: {
    addTag(item) {
      this.keywords_options.push(item);
      this.form.info.keywords.push(item);
    },
    validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>

