const TemplatesHelpersMixin = {
  computed: {
    tableFields() {
      return [
        {
          key: "num",
          sortable: true,
          label: "#"
        },
        {
          key: "name",
          sortable: true,
          label: this.$t("templates.template_name")
        },
        {
          key: "job_category",
          sortable: true,
          label: this.$t("templates.job_category")
        },
        {
          key: "created_at",
          sortable: true,
          label: this.$t("templates.created_at")
        },
        {
          key: "favorite",
          sortable: true,
          label: this.$t("templates.favorite")
        },
        {
          key: "use_template",
          sortable: false,
          label: this.$t("templates.use_template")
        },
        {
          key: "edit",
          sortable: false,
          label: this.$t("dashboard.edit")
        },
        {
          key: "remove",
          sortable: false,
          label: this.$t("templates.remove")
        }
      ];
    },
    searchFields() {
      return [
        {
          value: "id",
          text: this.$t("general.id")
        },
        {
          value: "name",
          text: this.$t("templates.template_name")
        },
        {
          value: "job_category",
          text: this.$t("templates.job_category")
        }
      ];
    }
  }
};

export default TemplatesHelpersMixin;
