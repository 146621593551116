<template>
  <ValidationObserver ref="form">
    <div>
      <h6 class="text-uppercase">{{ $t('create_campaign.job_details') }}</h6>
      <p>
        {{ $t('match_candidate.candidate_name') }} <strong>{{ campaign.candidate_full_name }}</strong>
        <br>
        {{ $t('create_campaign.job_title_short') }} <strong>{{ campaign.job_title_short }}</strong>
      </p>
      <div class="text-muted mt-4 mb-5">
        <i18n path="match_references.please_add_a_least" tag="div" class="pb-1">
          <strong>{{ referencesRequested }}</strong></i18n>
        <div>{{ $t('match_references.add_more_persons') }}</div>
      </div>
      <div class="my-3">
        <div class="candidates-item pt-3" v-for="(reference, index) in actualReferences" :key="index">
          <div class="pt-3 mt-3 mr-2">
            <span v-b-tooltip.hover :title="$t('match_references.delete_person')" @click="removeCandidateReference(reference)">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path fill="#68808d" d="M4.478 8.113h9.044v1.784H4.478zM9 0c1.644 0 3.166.411 4.565 1.233a8.53 8.53 0 013.202 3.202A8.851 8.851 0 0118 9a8.851 8.851 0 01-1.233 4.565 8.53 8.53 0 01-3.202 3.202A8.851 8.851 0 019 18a8.851 8.851 0 01-4.565-1.233 8.691 8.691 0 01-3.202-3.224A8.812 8.812 0 010 9c0-1.63.411-3.144 1.233-4.543a8.858 8.858 0 013.224-3.224A8.812 8.812 0 019 0zm.005 16.236a7.038 7.038 0 003.637-.996 7.011 7.011 0 002.598-2.598 7.038 7.038 0 00.996-3.637 7.037 7.037 0 00-.996-3.637 7.011 7.011 0 00-2.598-2.598 7.038 7.038 0 00-3.637-.996 7.038 7.038 0 00-3.637.996A7.011 7.011 0 002.77 5.368a7.038 7.038 0 00-.996 3.637c0 1.299.332 2.511.996 3.637a7.011 7.011 0 002.598 2.598 7.037 7.037 0 003.637.996z"/></svg>
            </span>
          </div>
          <div class="pt-3 mt-3 mr-2">{{ `${index + 1}.` }}</div>
          <div class="candidates-item-field candidates-item-field__select">
            <app-select-input :name="$t('general.title')" :vid="`title${index}`"
                              :label="$t('general.title')" rules="required"
                              tag="div" mode="lazy" :options="titles"
                              v-model="reference.info.gender"
                              ></app-select-input>
          </div>
          <div class="candidates-item-field candidates-item-field__select">
            <app-select-input :name="$t('general.language')" :vid="`title${index}`"
                              :label="$t('general.language')" rules="required"
                              tag="div" mode="lazy" :options="languages"
                              v-model="reference.info.lang"></app-select-input>
          </div>
          <div class="candidates-item-field">
            <app-text-input name="$t('general.first_name')"
                            :label="$t('general.first_name')" mode="lazy"
                            :vid="`first_name${index}`" type="text"
                            rules="required"
                            v-model="reference.info.first_name"></app-text-input>
          </div>
          <div class="candidates-item-field">
            <app-text-input :name="$t('general.last_name')"
                            :label="$t('general.last_name')" mode="lazy"
                            type="text" :vid="`last_name${index}`"
                            rules="required"
                            v-model="reference.info.last_name"></app-text-input>
          </div>
          <div class="candidates-item-field">
            <app-text-input :name="$t('general.email')"
                            :label="$t('general.email')" mode="lazy"
                            :vid="`email${index}`"
                            :rules="{required: true, email: true, is_not: campaign.candidate_email }"
                            type="email" v-model="reference.info.email"
                            @input="findEmails(reference.info.email)"></app-text-input>
            <app-text-input :name="$t('general.alternative_email')"
                            :label="$t('general.email') + ' 2'" mode="lazy"
                            :vid="`alternative_email${index}`"
                            :rules="`email|notConfirmedBy:@email${index}`"
                            type="email" v-model="reference.info.alt_email"
                            @input="findEmails(reference.info.alt_email)"></app-text-input>
          </div>
          <div class="candidates-item-field">
            <app-select-input :name="$t('match_references.relationship')"
                              :vid="`relationship${index}`"
                              :label="$t('match_references.relationship')"
                              rules="required" tag="div" mode="lazy"
                              :options="referencesRelationshipTypes"
                              v-model="reference.info.relationship"></app-select-input>
          </div>
        </div>
      </div>
      <ValidationProvider :name="referencesRequestedErrMsg"
                          :rules="{ minAmountItems: referencesRequested }"
                          mode="lazy">
        <template slot-scope="{ errors }"><input type="hidden"
                                                 :value="actualReferences.length"/>
          <b-alert class="alert-light" :show="!!errors.length" variant="danger">
            {{ errors[0] }}
          </b-alert>
        </template>
      </ValidationProvider>
      <div class="row justify-content-between">
        <div class="col-auto">
          <b-btn class="min-w-140" variant="outline-primary" @click="add" size="xl"> +
            {{ $t('match_references.add_reference') }}
          </b-btn>
        </div>
        <div class="col-auto">
          <b-btn class="min-w-140" variant="primary" @click="next" size="xl">
            {{ $t('general.next') }}
          </b-btn>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import CampaignHelpersMixin from "../../../common/CampaignHelpersMixin";
import ReferencesHelpersMixin from "../../../common/ReferencesHelpersMixin";
import AppSelectInput from "../../../components/SelectInput";
import AppTextInput from "../../../components/TextInput";
import { languages } from "@/common/languages";
import { mapGetters } from "vuex";

export default {
  components: {
    AppSelectInput,
    AppTextInput
  },

  mixins: [CampaignHelpersMixin, ReferencesHelpersMixin],

  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      duplicatedEmails: false
    };
  },

  computed: {
    ...mapGetters("global", ["titles"]),
    referencesRequested() {
      return +this.campaign.references_requested;
    },

    actualReferences() {
      return this.campaign.references
        .map((item, index) => ({
          ...item,
          _originalIndex: index
        }))
        .filter((item) => !item.info.deleted);
    },

    languages() {
      return languages.map(lang => ({value: lang.value, text: lang.label})) || [];
    },

    referencesRequestedErrMsg() {
      return `${this.referencesRequested} ${this.$t('create_campaign.references')}`;
    }
  },

  methods: {
    add() {
      this.$emit("add");
    },

    next() {
      this.validate().then((result) => {
        if (!result) return;
        this.$emit("next");
      });
    },

    validate() {
      return this.$refs.form.validate();
    },

    findEmails(email) {
      const arr = this.actualReferences.filter(
        (item) => item.info.email === email || item.info.alt_email === email
      );
      this.duplicatedEmails = !!(arr && arr.length >= 2);
    },
    removeCandidateReference(reference) {
      this.$emit("remove", reference._originalIndex);
    },
    validateForm() {
      if (this.duplicatedEmails) {
        this.$bvToast.toast(this.$t("create_campaign.email_error_msg"), {
          autoHideDelay: 5000,
          variant: "warning",
          title: "Error"
        });
        return false;
      } else {
        return this.$refs.form.validate();
      }
    }
  }
};
</script>
