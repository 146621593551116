<template>
  <ValidationObserver class="pt-4 use-template-form" ref="form" tag="div">
    <ValidationProvider
      :name="$t('create_campaign.choose_template')"
      vid="choose_template"
      rules="required"
    >
      <b-form-group
        slot-scope="{ errors }"
        :label="$t('create_campaign.choose_template')"
      >
        <multiselect
          v-model="selectTemplate"
          :options="options"
          label="name"
          track-by="name"
          placeholder=""
          @search-change="asyncFind"
          :selectLabel="$t('general.select')"
          :deselectLabel="$t('general.remove')"
        ></multiselect>
        <div class="error-message">{{ errors[0] }}</div>
      </b-form-group>
    </ValidationProvider>
    <b-btn variant="primary" @click="useTemplate">{{
      $t('general.use')
    }}</b-btn>
  </ValidationObserver>
</template>

<script>
import ApiTemplate from '../../api/template';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectTemplate: '',
      options: [],
      templatesList: [],
    };
  },
  computed: {
    ...mapGetters('global', ['currentUserCompanyId']),
    ...mapGetters('campaign', ['current']),
  },
  created() {
    this.asyncFind();
  },
  methods: {
    asyncFind: _.debounce(function (query) {
      const obj = {
        value: query,
      };
      if (query !== '') {
        ApiTemplate.fetchTemplates(this.currentUserCompanyId, obj).then(
          (response) => {
            this.templatesList = response.data.campaign_templates;
            this.options = this.templatesList.map((item) => {
              return {
                id: item.id,
                name: item.info.name,
              };
            });
          }
        );
      }
    }, 500),
    useTemplate() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        const currentTemplate = this.templatesList.find(
          (item) => item.id === this.selectTemplate.id
        );
        this.$store.dispatch('campaign/useTemplate', currentTemplate);
        this.$emit('hide');

        /*
         * HACK: Force rerender campaign after template is used to populate atributes
         */
        this.$nextTick(() => {
          this.$store.commit('campaign/SET_CAMPAIGN', Object.assign({}, this.current));
        })
      });
    },
  },
};
</script>
