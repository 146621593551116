<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score :report_title="$t('create_campaign.standard_attributes')"
                                        :talents-attr="talentsAttr"
                                        :attribute-name="'competencies'"
                                        :score="-1"
                                        :current-lang="currentLang"
                                        :items="core_competencies"
                                        :fields="fields"
                                        :icon="'mdi mdi-hexagon-multiple'"
                                        :max="max"></candidate-self-report-details-table-score>-->

  <!-- One sider progress bar slider -->
  <candidate-self-report-details-table-score :report_title="$t('create_campaign.standard_attributes')"
                                        :talents-attr="talentsAttr"
                                        :attribute-name="'competencies'"
                                        :score="-1"
                                        :current-lang="currentLang"
                                        :variant="'violet'"
                                        :items="core_competencies"
                                        :fields="fields"
                                        :icon="'mdi mdi-hexagon-multiple'"
                                        :max="max"></candidate-self-report-details-table-score>
</template>

<script>
import { orderBy } from 'lodash';
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
//import { calculateTwoSideProgressBarValue } from '@/common/utils';
//import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from '@/common/constants';
//import CandidateSelfReportDetailsTableScore from '../CandidateDetailsTables/CandidateSelfReportDetailsTableScore';
import CandidateSelfReportDetailsTableScore from '../CandidateDetailsTables/CandidateSelfReportDetailsTable';

export default {
  props: {
    competencies: {
      type: Array,
      required: true,
      default: () => {}
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en'
    }
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTableScore
  },
  data() {
    return {
      max: 100,
      fields: [
          { key: 'key',
            label: this.$t('create_campaign.traits'),
            thClass: 'attribute__name'
          },
        {
          key: 'value',
          thClass: ''
        }
      ]
    }
  },
  computed: {
    //Two sided progress bar slider - calcaulation
    /*core_competencies() {
      if (!this.competencies) return [];

      const values = this.competencies.map(item => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth
        };
      });

      return orderBy(values, ['value'], ['desc'])
    }*/

    //One sided progress bar slider
    core_competencies() {
      if (!this.competencies) return [];

      let values = this.competencies.map(item => {
        return {
          key: item.key,
          value: item.value
        };
      });
      values = values.filter(item => item.value >= SELEFTEST_VALUES_MIN_VALUE)

      return orderBy(values, ['value'], ['desc'])
    }
  }
};
</script>
