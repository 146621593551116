<template>
  <b-input-group :key="keyVal" class="mt-3 xmz-copy-link">
    <template #append>
      <b-input-group-text>
        <!-- TODO: Translate tooltip -->
        <b-button @click="copy" v-b-tooltip.focus title="Copied">
          <i class="mdi mdi-content-copy"></i>
        </b-button>
      </b-input-group-text>
    </template>
    <b-form-input
      ref="linkInput"
      v-model="link"
      :readonly="true"
    ></b-form-input>
  </b-input-group>
</template>

<script>
export default {
  props: {
    keyVal: {
      key: String,
      default: ''
    },
    slug: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'q'
    },
    staticLink: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      link: '',
    };
  },
  mounted() {
    if (this.staticLink) {
      this.link = this.staticLink;
    } else {
      this.createLink();
    }
  },
  methods: {
    createLink() {
      this.link =
        `${location.protocol}//` +
        `${location.host.replace('app', 'jobs').replace('8081', '3000')}/apply/${this.mode}/` +
        `${this.slug}`;
    },
    copy() {
      navigator.clipboard.writeText(this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.xmz-copy-link {
  button {
    color: #4e5360;
    background-color: #e9eaeb;
    border: none;
  }

  .input-group-text {
    padding: 0;
  }
}
</style>
