<template>
  <div class="pt-4">
    <ValidationObserver ref="form" tag="div">
      <b-form>

        <h2 class="form__title">{{ $t("seeker_account.highest_education") }} & {{ $t("general.language") }}</h2>

        <div class="experience__container">
          <b-row>
            <b-col md="4">
              <app-select-input :name="$t('seeker_account.add_highest_education')"
                                :label="$t('seeker_account.add_highest_education')"
                                vid="highest_education"
                                mode="lazy"
                                rules="required"
                                :options="highestEducations"
                                v-model="form.personal_data.highest_education"></app-select-input>
            </b-col>
            <b-col md="4">
              <app-select-input :name="$t('seeker_account.tongue')"
                                :label="$t('seeker_account.tongue')"
                                vid="mother_tongue"
                                mode="lazy"
                                rules="required"
                                :options="languagesOptions"
                                v-model="form.personal_data.mother_tongue"></app-select-input>
            </b-col>
            <b-col md="4">
              <app-select-input :name="$t('seeker_account.languages')"
                                :label="$t('seeker_account.languages')"
                                :options="languagesOptions"
                                vid="second_language"
                                mode="lazy"
                                v-model="form.personal_data.second_language"></app-select-input>
            </b-col>
          </b-row>
        </div>

        <h2 class="form__title">{{ $t("seeker_account.experience") }}</h2>

        <div v-if="form.work_items_attributes.length === 0" class="emptystate">
          <i class="mdi mdi-information-outline"></i>
          <p>{{ $t("seeker_account.experience_empty_note") }}</p>
        </div>

        <div v-if="form.work_items_attributes.length > 0" class="experience__container">

          <div class="experience__item" v-for="(item, index) in form.work_items_attributes" :key="index">
            <b-row>

              <b-col lg="4">
                <app-text-input :name="$t('seeker_account.job_name') + index"
                                :label="$t('seeker_account.job_name')"
                                :vid="'job_name' + index"
                                :title="$t('seeker_account.job_name_tip')"
                                mode="lazy"
                                type="text"
                                rules="required"
                                v-model="item.data.job_name"
                                v-b-tooltip.hover></app-text-input>
              </b-col>

              <b-col lg="4">
                <app-select-input :name="$t('seeker_account.job_area') + index"
                                  :label="$t('seeker_account.job_area')"
                                  mode="lazy"
                                  :vid="'jobArea' + index"
                                  rules="required"
                                  :options="jobArea"
                                  v-model="item.data.job_area"></app-select-input>
              </b-col>

              <b-col lg="4">
                <app-select-input :name="$t('create_campaign.job_level') + index"
                                  :label="$t('create_campaign.job_level')"
                                  :vid="'job_level' + index"
                                  mode="lazy"
                                  rules="required"
                                  :options="jobLevels"
                                  v-model="item.data.job_level"></app-select-input>
              </b-col>

              <b-col lg="4">
                <app-select-input :name="$t('seeker_account.size_company') + index"
                                  :label="$t('seeker_account.size_company')"
                                  :vid="'company_size' + index"
                                  mode="lazy"
                                  rules="required"
                                  :options="companySize"
                                  v-model="item.data.company_size"></app-select-input>
              </b-col>

              <b-col lg="4">
                <app-select-input :name="$t('seeker_account.years_of_experience') + index"
                                  :label="$t('seeker_account.years_of_experience')"
                                  :vid="'experience' + index"
                                  mode="lazy"
                                  rules="required"
                                  :options="yearsOfExperience"
                                  v-model="item.data.experience"></app-select-input>
              </b-col>

              <b-col lg="4">
                <app-select-input :name="$t('seeker_account.last_promotion') + index"
                                  :label="$t('seeker_account.last_promotion')"
                                  :vid="'last_promotion' + index"
                                  mode="lazy"
                                  :options="lastPromotionOptions"
                                  v-model="item.data.last_promotion"></app-select-input>
              </b-col>

              <b-col lg="4">
                <b-form-group :label="$t('seeker_account.keywords')"
                              :name="$t('seeker_account.keywords') + index">
                  <app-multiselect v-model="item.data.keywords"
                                   :options="keywords_options"
                                   :taggable="true"
                                   :searchable="true"
                                   tag-placeholder=""
                                   @add="addTag(index, $event)"></app-multiselect>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <label class="pb-2">{{ $t("seeker_account.most_significant") }} & {{ $t("seeker_account.current_position") }}</label>
                <div class="custom-control custom-radio">
                  <input class="custom-control-input"
                         type="radio"
                         v-model="mostSignificant"
                         :value="index"
                         @change="onMostSignificantChange"
                         name="most_significant"
                         :id="'most_significant' + index"/>
                  <label class="custom-control-label custom-control-label__auth" :for="'most_significant' + index">{{ $t("seeker_account.most_significant") }}</label>
                </div>

                <div class="custom-control custom-radio">
                  <input class="custom-control-input"
                         type="radio"
                         v-model="currentPosition"
                         :value="index"
                         @change="onCurrentPositionChange"
                         name="current_position"
                         :id="'current_position' + index"/>
                  <label class="custom-control-label custom-control-label__auth" :for="'current_position' + index">{{ $t("seeker_account.current_position") }}</label>
                </div>
              </b-col>

              <b-col lg="4" class="text-right align-self-end">
                <b-button class="btn-action" variant="secondary" v-b-tooltip.hover :title="$t('seeker_account.delete_experience')" @click="removeExperience(index)">
                  <span class="mdi mdi-trash-can"></span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>

        <b-btn class="mt-3" variant="primary" @click="addExperience">{{ $t("seeker_account.add_experience") }}</b-btn>

        <ValidationProvider :name="experienceRequestedErrMsg" rules="minAmountItems:1" mode="lazy">

          <div class="form-group form-group-input mt-3" slot-scope="{ errors }">
            <input type="hidden" :value="experienceLength" />
            <div class="error-message ml-0 mt-1" v-if="errors">{{ errors[0] }}</div>
          </div>

        </ValidationProvider>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { findIndex } from 'lodash'
import AppTextInput from '../../../components/TextInput';
import AppSelectInput from '../../../components/SelectInput';
import SeekerHelpersMixin from '../../../common/SeekerHelpersMixin';
import AppMultiselect from '../../../components/CustomMultiselect';
export default {
  components: {
    AppTextInput,
    AppSelectInput,
    AppMultiselect,
  },
  mixins: [SeekerHelpersMixin],
  data() {
    return {
      keywords_options: [],
      form: {
        work_items_attributes: [],
        personal_data: {}
      },
      mostSignificant: -1,
      currentPosition: -1,
    };
  },
  computed: {
    ...mapGetters({
      workItems: 'jobSeeker/workItems',
      jobSeeker: 'jobSeeker/jobSeeker',
    }),
    languagesOptions() {
      const languages = [...this.languages];
      languages.sort((lang1, lang2) => lang2.common - lang1.common);

      return [
        { value: null, text: this.$t('seeker_account.no_language') },
        ...languages,
      ];
    },
    lastPromotionOptions() {
      return [
        { value: null, text: this.$t('seeker_account.no_promotion') },
        ...this.lastPromotion,
      ];
    },
    experienceLength() {
      return this.form.work_items_attributes.length;
    },
    filteredExperience() {
      return this.workItems.filter((item) => !item._destroy);
    },
    experienceRequestedErrMsg() {
      return `1 ${this.$t('seeker_account.experience')}`;
    }
  },
  mounted() {
    this.form.work_items_attributes = [].concat(this.workItems);
    this.form.personal_data = {
      highest_education: this.jobSeeker.highest_education || '',
      mother_tongue: this.jobSeeker.mother_tongue || '',
      second_language: this.jobSeeker.second_language || '',
    }

    this.mostSignificant = findIndex(this.workItems, item => !!item.data.most_significant)
    this.currentPosition = findIndex(this.workItems, item => !!item.data.current_position)

    this.$store.commit('jobSeeker/ASSIGN_FORM', this.form)
  },
  methods: {
    addTag(index, item) {
      this.keywords_options.push(item);
      this.form.work_items_attributes[index].data.keywords.push(item);
    },
    addExperience() {
      const params = {
        data: {
          job_area: '',
          job_level: '',
          company_size: '',
          job_name: '',
          experience: '',
          last_promotion: '',
          keywords: [],
          most_significant: false,
          current_position: false,
        },
        _destroy: false,
      };

      if (!this.form.work_items_attributes.length) {
        this.mostSignificant = 0;
        this.currentPosition = 0;
      }
      this.form.work_items_attributes.push(params);
    },
    removeExperience(index) {
      this.form.work_items_attributes.splice(index, 1);
    },
    validateForm() {
      this.$store.commit('jobSeeker/ASSIGN_FORM', this.form)
      return this.$refs.form.validate();
    },
    onMostSignificantChange(index) {
      this.form.work_items_attributes.forEach((item, index) => {
        if (index === this.mostSignificant) {
           item.data.most_significant = true;
        } else {
          item.data.most_significant = false;
        }
      });
    },
    onCurrentPositionChange(index) {
      this.form.work_items_attributes.forEach((item, index) => {
        if (index === this.currentPosition) {
          item.data.current_position = true;
        } else {
          item.data.current_position = false;
        }
      });
    }
  },
};
</script>
