import * as orangeColors from '../../styles/themes/orange/_colors.scss'
import * as violetColors  from '../../styles/themes/violet/_colors.scss'
import { EXAMIZ_APPS } from '@/common/constants'

const app = localStorage.getItem('app');
let theme = 'orange'
let colors = orangeColors

switch (app) {
  case EXAMIZ_APPS.EXIT.type:
    theme = 'violet';
    colors = violetColors
    break;
}

export const currentTheme = theme;

export {colors};