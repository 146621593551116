import axios from 'axios';
import { PROMO_CODE_PREFIXES } from '@/common/constants'

import ApiSurvey from '@/api/survey';


const getSurveyInfo = ({ commit }, token) => {
  return ApiSurvey.getInfo(token).then((res) => {
    const info = res.data;
    commit('SET_TYPE', res.data.survey_type)


    if (info.survey_type === 'team') {
      commit('SET_CANDIDATES', info.candidates)
      commit('SET_IN_PROGRESS_CANDIDATES', info.in_progress_candidates)
      if (info.in_progress_candidates.length > 0) {
        const candidateId = info.in_progress_candidates[0];
        const candidate = info.candidates.find( c => c.id === candidateId);
        commit('SET_CANDIDATE', candidate)
      }
    } else {
      commit('SET_CANDIDATE', info.candidate)
    }
 });
}

export default {
  getSurveyInfo,
};
