<template>
  <div class="container">
    <div class="row justify-content-center survey__height my-5">
      <b-col>
        <div class="survey">
          <!--
          <transition name="fade">
            <app-tip class="first-tip" @close="tipShow1 = !tipShow1" :text="$t('self_test.handle_tip')" v-if="tipShow1"></app-tip>
          </transition>

          <transition name="fade">
            <app-tip class="second-tip" @close="tipShow2 = !tipShow2" :text="$t('self_test.situation_tip')" v-if="tipShow2"></app-tip>
          </transition>
          -->

          <test-header>
            <span class="example_headline">{{ $t('match_references.example') }}</span>
            {{ $t('self_test.example_page.scenario_text') }}
          </test-header>

          <test-case>
            {{ $t('self_test.example_page.situation_text') }}
          </test-case>

          <test-slider
            class="mt-4"
            v-model="barValue"
            :label-left="$t('self_test.example_page.slider_left_text')"
            :label-right="$t('self_test.example_page.slider_right_text')"
            :rangeTips="rangeTips"
            backplateColor="#4E5360"
            :example-page="true">
          </test-slider>

          <div class="survey__footer">
            <b-row>
              <b-col class="col-6 text-left">
                <b-btn class="btn-lg" variant="outline-secondary" to="/dashboard">{{ $t('general.back') }}</b-btn>
              </b-col>

              <b-col class="col-6 text-right">
                <b-btn class="btn-lg example-next-button" variant="primary" @click="done" v-b-tooltip.hover :title="$t('self_test.example_page.next_btn_tooltip')">{{ $t("general.next") }}</b-btn>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-modal v-model="modalNext" hide-footer="hide-footer" hide-header="hide-header" centered="centered">
        <div class="text-center px-md-4">
          <h3 class="mb-4">{{ $t('self_test.lets_get_started') }}</h3>
          <p v-html="$t('self_test.get_started_modal_text')"></p>
          <b-btn class="mt-4 min-w-140" variant="primary" size="xl" to="/self-test/situation">{{ $t('general.next') }}</b-btn>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { EXAMPLE_QUESTION_CLASS_NAME } from '../../common/constants';
import AppVolumeBar from "../../components/VolumeBar";
import AppTip from "../../components/Tip";

const tooltips = [
  { from: -30, to: -10, label: "self_test.example_volume_slider.tooltip_1" },
  { from: -80, to: -50, label: "self_test.example_volume_slider.tooltip_2" },
  { to: -80, label: "self_test.example_volume_slider.tooltip_3" },
  { from: 10, to: 30, label: "self_test.example_volume_slider.tooltip_1" },
  { from: 50, to: 80, label: "self_test.example_volume_slider.tooltip_4" },
  { from: 80, label: "self_test.example_volume_slider.tooltip_5" }
];

export default {
  components: {
    AppVolumeBar,
    AppTip
  },
  data() {
    return {
      height: 27,
      interval: null,
      barValue: 0,
      min: -100,
      max: 100,
      modalNext: false,
      tipShow1: false,
      tipShow2: false
    };
  },
  computed: {
    rangeTips() {
      return tooltips.map((item) => ({
        ...item,
        label: this.$t(item.label)
      }));
    }
  },
  mounted() {
    document.body.classList.add(EXAMPLE_QUESTION_CLASS_NAME)
    this.showTooltips();
  },
  beforeDestroy() {
    document.body.classList.remove(EXAMPLE_QUESTION_CLASS_NAME)
  },
  methods: {
    showTooltips() {
      setTimeout(() => (this.tipShow1 = true), 3000);
      setTimeout(() => (this.tipShow2 = true), 10000);
    },
    done() {
      this.modalNext = true;
    }
  }
};
</script>

<style lang="scss" scoped>


.card-test-layout {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.first-tip,
.second-tip {
  position: absolute;
  z-index: 1;
  top: 20px;
  max-width: 360px;
}

.first-tip {
  left: 20px;
}

.second-tip {
  right: 20px;
}
</style>
