import { mapGetters } from 'vuex';
const ApplicantPortalMixin = {
  computed: {
    ...mapGetters('global', [
      'availableLanguages',
      'languageProficiencyLevels',
      'educationGeneralAreas',
      'jobAreasOld',
      'yearsOfExperience',
      'senorityLevel',
    ]),
    jobAreaSpecializationsOld() {
      const jobAreasOld = [].concat.apply([], [...this.jobAreasOld.map(a => a.jobAreaSpecializations)]);
      return jobAreasOld.map(item => {
        return {
          text: item.title,
          value: item.id,
          jobAreaId: item.job_area_id
        }
      })
    }
  },
};

export default ApplicantPortalMixin;
