<template>
  <div class="row justify-content-center">
    <b-col>
      <div class="survey survey__content__block">

        <!-- survey header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="survey__header">
          <h1>{{ $t("self_test.lets_get_started") }}</h1>
        </div>

        <!-- survey content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="survey__situation">
          <div class="survey__situation__body survey__situation__body-selfstart">
            <i18n path="self_test.introduction_page.text_1" tag="p"><strong>{{ candidate }}</strong></i18n>
            <i18n path="self_test.introduction_page.text_2" tag="p"><strong>{{ candidate }}</strong></i18n>
          </div>
        </div>

        <!-- survey footer ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="survey__footer">
          <b-btn size="lg" variant="primary" to="/self-test/example">{{ $t("general.next") }}</b-btn>
        </div>

      </div>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    questions: {
      type: Array,
      default: null,
    },
    candidate: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", ["currentUserToken", "currentLanguage"]),
  },
};
</script>

<style lang="scss" scoped>
.lets-start-container {
  max-width: 660px;
  margin: 0 auto;

  & p {
    font-size: 16px;
  }
}

.term-checkbox {
  color: var(--gray-500);

  & label {
    font-size: 14px;
    padding-top: 0.2em;
  }
}

.next-btn {
  max-width: 360px;
  margin: 0 auto;
}
</style>
