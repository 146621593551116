
import ApiCampaign from '../../../api/exit';

const createCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.createCampaign(companyId, params).then((response) => {
    commit('SET_CAMPAIGN', response.data);
  })
};

const updateCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.updateCampaign(params, params.id, companyId).then((response) => {
    const campaign = response.data;
    commit('UPDATE_CAMPAIGN', campaign);
  });
};

const updateStateCampaign = ({ commit }, params) => {
  commit('UPDATE_STATE_CAMPAIGN', params);
};

const resetCampaign = ({ commit }) => {
  commit('RESET_CAMPAIGN');
};

const getCampaign = ({ commit, rootGetters }, id) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.getCampaign(companyId, id).then((response) => {
    const campaign = response.data;
    commit('SET_CAMPAIGN', campaign);
    commit('RESET_QUERY_STRATEGIES');
  }).catch(e => window.location.href = '/');
};

const fetchCampaigns = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.fetchCampaigns(companyId, params).then((response) => {
    commit('SET_CAMPAIGN_LIST', response.data || []);
  });
};

const getTeamMembers = ({ commit, rootGetters }, token) => {
  // const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.getTeamMembers(token).then((response) => {
    commit('SET_CAMPAIGN', response.data);
    commit('SET_TEAM_MEMBERS', response.data);
    commit('global/CHANGE_LANGUAGE', response.data.lang, { root: true })
  });
};

const addTeamMembers = ({ commit }, data) => {
  // const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.addTeamMembers(data)
};

const removeTeamMember = ({ commit }, data) => {
  return ApiCampaign.removeTeamMember(data)
}

const getAnswers = ({ commit }, token) => {
  return ApiCampaign.getAnswers(token).then(response => {
    commit('SET_QUESTIONS', response.data.questions)
    commit('SET_LEAVING_PERSON', response.data.leaving_person)
    commit('SET_PARTICIPANT_ID', response.data.participant_id)
    commit('SET_QUESTIONS_COUNT', {
      totalQuestions: response.data.total_questions,
      totalUnansweredQuestions: response.data.total_unanswered_questions
    })
    commit('global/CHANGE_LANGUAGE', response.data.lang, { root: true })
  })
}

const saveAnswers = ({ commit }, data) => {
  return ApiCampaign.saveAnswers(data).then(response => {
    commit('SET_QUESTIONS', response.data.questions)
    commit('SET_LEAVING_PERSON', response.data.leaving_person)
    commit('SET_PARTICIPANT_ID', response.data.participant_id)
    commit('SET_QUESTIONS_COUNT', {
      totalQuestions: response.data.total_questions,
      totalUnansweredQuestions: response.data.total_unanswered_questions
    })
  })
}

const getReport = ({ commit, rootGetters }, campaignId) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  commit('SET_REPORT', null)
  return ApiCampaign.getReport(companyId, campaignId).then(response => {
    commit('SET_STRATEGIES', response.data.strategies)
    commit('SET_REPORT', response.data.report)
  })
}

const getAnalyticsCampaignOptions = ({ commit, rootGetters }) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.getAnalyticsCampaignOptions(companyId).then(response => {
    commit('SET_ANALYTICS_CAMPAIGN_OPTIONS', response.data.options)
  })
}

const cloneCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.cloneCampaign(companyId, params.id, params).then((response) => {
    commit('SET_CAMPAIGN', response.data);
  })
};

export default {
  createCampaign,
  updateCampaign,
  resetCampaign,
  updateStateCampaign,
  getCampaign,
  fetchCampaigns,
  getTeamMembers,
  addTeamMembers,
  removeTeamMember,
  getAnswers,
  saveAnswers,
  getReport,
  getAnalyticsCampaignOptions,
  cloneCampaign
};
