
import Vue from 'vue'
import Vuex from 'vuex';

import globalModule from "./modules/global/index";
import campaignModule from "./modules/campaign/index";
import reportModule from "./modules/report/index";
import jobSeekerModule from "./modules/job-seeker/index";
import companyModule from "./modules/company/index";
import promocodeModule from './modules/promocode/index';
import surveyModule from './modules/survey/index';
import exitModule from './modules/exit/index';


Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    global: globalModule,
    campaign: campaignModule,
    report: reportModule,
    jobSeeker: jobSeekerModule,
    company: companyModule,
    promocode: promocodeModule,
    survey: surveyModule,
    exit: exitModule
  }
});
