<template>
  <div class="aside__content h-100">

    <!--  // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <h6 class="aside__title"><span>{{ $t("create_campaign.campaign_details") }}</span></h6>

    <div class="campaign__attributes">

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("dashboard.status") }}</div>
        <div class="campaign__attribute__value">
          <div :class="'table-label table-label__' + currentState">{{ $t(`dashboard.statuses.${currentState}`) }}</div>
        </div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("report.campaign_id") }}</div>
        <div class="campaign__attribute__value">{{ campaignId }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("create_campaign.internal_job_id") }}</div>
        <div class="campaign__attribute__value">{{ info.internal_job_id_1 }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("dashboard.client_name") }}</div>
        <div class="campaign__attribute__value">{{ info.client_name }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("create_campaign.min_references_requested") }}</div>
        <div class="campaign__attribute__value">{{ info.references_requested }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("dashboard.received_responses") }}</div>
        <div class="progress__bar">
          <div class="progress__value">{{ calculateCampaignProgress(referencesPassed, referencesRequested) + '%' }}</div>
          <div class="progress__line"
               v-bind:style="{ width: calculateCampaignProgress(referencesPassed, referencesRequested) + '%' }"
               v-bind:class="{ 'progress__line--finished': calculateCampaignProgress(referencesPassed, referencesRequested) === 100  }"></div>
          <div class="progress__background"></div>
        </div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("create_campaign.starting_date") }}</div>
        <div class="campaign__attribute__value">{{ startDate | format-date }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("create_campaign.end_date") }}</div>
        <div class="campaign__attribute__value">{{ endDate | format-date }}</div>
      </div>

      <div class="campaign__attribute__item">
        <div class="campaign__attribute__label">{{ $t("dashboard.recruiter_name") }}</div>
        <div class="campaign__attribute__value">{{ recruiterName }}</div>
      </div>
    </div>

    <!--  // skill profile ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <h6 class="aside__title"><span>{{ $t("create_campaign.skills_competencies") }}</span></h6>

    <div class="skill__profile">
      <div v-if="showNoCompetencies" class="emptystate">
        <i class="mdi mdi-information-outline"></i>
        <p>No competencies available yet.</p>
      </div>

      <div v-if="!showNoCompetencies" class="skills-item-wrp">

        <div class="skill__item" v-for="(item, index) in personalityAttrs.traits" :key="'traits' + index">
          <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
          <div class="skill__item__value">{{ item.value }}</div>
          <div class="skill__item__progress">
            <div class="skill__item__progressbar" :style="{ width: item.value + '%' }"></div>
          </div>
        </div>

        <div class="skill__item" v-for="(item, index) in personalityAttrs.values" :key="'values' + index">
          <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
          <div class="skill__item__value">{{ item.value }}</div>
          <div class="skill__item__progress">
            <div class="skill__item__progressbar" :style="{ width: item.value + '%'  }"></div>
          </div>
        </div>

        <div class="skill__item" v-for="(item, index) in personalityAttrs.competencies" :key="'competencies' + index">
          <div class="skill__item__label">{{ item[`name_${currentLanguage}`] }}</div>
          <div class="skill__item__value">{{ item.value }}</div>
          <div class="skill__item__progress">
            <div class="skill__item__progressbar" :style="{ width: item.value + '%'  }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {calculateCampaignProgress} from "@/common/utils"

export default {
  props: {
    current: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    personalityAttrs() {
      return this.current.personality_attrs || {};
    },
    info() {
      return this.current.info || {};
    },
    referencesRequested() {
      return this.current.references_requested || 4;
    },
    referencesPassed() {
      return this.current.references_passed;
    },
    campaignId() {
      if (!this.id) {
        return null;
      }
      return this.id.split("-")[0].toUpperCase();
    },
    currentState() {
      return this.current && this.current.state ? this.current.state.toLowerCase() : '';
    },
    startDate() {
      return this.current.start_date || "";
    },
    endDate() {
      return this.current.end_date || "";
    },
    recruiterName() {
      return this.current.recruiter_name || "";
    },
    showNoCompetencies() {
      return !this.personalityAttrs.competencies
    }
  },
  methods: {
    calculateCampaignProgress: calculateCampaignProgress
  }
}
</script>
