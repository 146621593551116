import ApiJobSeeker from "../../../api/jobSeeker";

const getJobSeeker = ({ commit }, id) => {
  return ApiJobSeeker.getJobSeeker(id)
    .then((response) => {
      const jobSeeker = response.data.job_seeker;

      commit('SET_JOB_SEEKER', jobSeeker);
      commit('SET_WORK_ITEMS', jobSeeker.work_items);
      commit('SET_JOB_TYPES', jobSeeker.job_types)
      commit('SET_DISPLAY_EXPERIENCE_MODAL', !jobSeeker.experience_profile_finished)
    })
}

const updateJobSeeker = ({ commit }, palyload) => {
  // TODO: This is tmp hack resolve it on BE
  if (palyload.body && palyload.body.work_items_attributes) {
    palyload.body.work_items_attributes = palyload.body.work_items_attributes.map((item) => ({
      ...item,
      data: {
        ...item.data,
        keywords: item.data.keywords.map((keyword) => keyword.name),
      },
    }));
  }

  return ApiJobSeeker.updateJobSeeker(palyload.id, { job_seeker: palyload.body })
    .then(response => {
      const jobSeeker = response.data.job_seeker;

      commit('SET_JOB_SEEKER', jobSeeker);
      commit('SET_WORK_ITEMS', jobSeeker.work_items);
      commit('SET_JOB_TYPES', jobSeeker.job_types)
      commit('SET_DISPLAY_EXPERIENCE_MODAL', !jobSeeker.experience_profile_finished)
    }).catch(err => console.log(err))
}

export default {
  getJobSeeker,
  updateJobSeeker,
};
