<template>
  <div>
    <input
      ref="fileInput"
      type="file"
      @change="handleFileUpload"
      accept=".csv"
      style="display: none"
    />
    <b-button @click="openFileInput" :disabled="!canUploadFile"
      >Import</b-button
    >
    <b-button
      @click="downloadCsvTemplate"
      variant="outline-secondary"
      class="ml-2"
    >
      <b-icon icon="download" align-v="center" />
    </b-button>
  </div>
</template>

<script>
import { BButton, BIcon } from 'bootstrap-vue';

export default {
  props: {
    headings: {
      type: Array,
      required: true,
    },
  },
  components: {
    BButton,
    BIcon,
  },
  data() {
    return {
      canUploadFile: true,
      importedData: null,
      importError: null,
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.canUploadFile = false;
      this.importedData = null;
      this.importError = null;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const rows = e.target.result.split('\n');
        const headings = this.headings.map((heading) => {
          return { key: heading.trim() };
        });
        const data = [];
        for (let i = 1; i < rows.length - 1; i++) {
          const row = {};
          const values = rows[i].split(',');
          for (let j = 0; j < values.length; j++) {
            const heading = headings[j];
            const value = values[j].trim();
            const key = heading.key;
            if (key === 'email') {
              if (!this.isValidEmail(value)) {
                this.importError = `Invalid email in row ${i}: ${value}`;
                this.resetComponent();
                return;
              }
            } else if (key === 'alternative_email') {
              if (value !== '' && !this.isValidEmail(value)) {
                this.importError = `Invalid alternative email in row ${i}: ${value}`;
                this.resetComponent();
                return;
              }
            }
            row[key] = value;
          }
          data.push(row);
        }
        this.importedData = data;
        this.$emit('imported-data', data);
        this.resetComponent();
      };
      reader.readAsText(file);
    },

    resetComponent() {
      this.canUploadFile = true;
      this.$refs.fileInput.value = '';
    },
    isValidEmail(email) {
      // A simple regex to check if the email has a valid format
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },
    downloadCsvTemplate() {
      const dummyData = `male,en,John,Doe,john.doe@example.com,john.doe.alt@example.com
female,de,Sophie,Müller,sophie.mueller@example.com,
male,en,William,Smith,william.smith@example.com,
female,de,Lena,Schmidt,lena.schmidt@example.com,
male,en,Michael,Johnson,michael.johnson@example.com,`;
      const headings = this.headings.join(',');
      const csvContent = headings + '\n' + dummyData;
      const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'template.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>