<template>
  <article class="campaign">
    <b-alert class="custom-alert" variant="warning" v-model="alertShow">
      <ul class="mb-0">
        <li v-for="(item, key) in alertMessage" :key="key">{{ item[0] }}</li>
      </ul>
    </b-alert>
    <b-modal centered v-model="showDraftModal" hide-footer no-close-on-backdrop>
      <app-save-draft-modal></app-save-draft-modal>
    </b-modal>

    <b-modal
      centered
      v-model="saveModal"
      :title="$t('create_campaign.modal_title')"
      hide-footer
      hide-header
    >
      <app-save-modal
        @gotodashboard="closeEditCampaign"
        @gotonewcampaign="createNewCampaign"
        @close="() => (saveModal = false)"
        :title="titleText"
      ></app-save-modal>
    </b-modal>

    <ModalCloneAPandExitCampaign
      v-if="showCloneModal"
      :currentCampaign="campaign"
      @onSubmit="() => (showCloneModal = false)"
      :title="''"
    />

    <!-- // campaign info header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row v-if="campaignId">
      <b-col>
        <header class="campaign__info__header">
          <div class="campaign__info__item">
            {{ $t('report.campaign_id') }}: {{ campaignId }}
          </div>
        </header>
      </b-col>
    </b-row>

    <!-- // form wizard ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <form-wizard
      errorColor="#8b96a4"
      :color="colorPrimary"
      :title="wizardTitle"
      subtitle=""
      :start-index="startIndex"
      ref="wizard"
      stepSize="xs"
    >
      <!-- // footer & buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <template slot="footer" slot-scope="props">
        <div class="d-flex flex-wrap align-items-end">
          <!-- // back button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <b-btn
            class="mr-2 xxl"
            :disabled="loading"
            variant="outline-secondary"
            v-if="props.activeTabIndex > 0"
            @click="props.prevTab()"
            >{{ $t('general.back') }}</b-btn
          >

          <div class="ml-auto">
            <!-- // clone button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="mx-2 xxl"
              :disabled="false"
              v-if="campaign.id"
              variant="outline-secondary"
              @click="cloneCampaign"
            >
              <span class="mdi mdi-puzzle-check"></span>
              <span class="ml-2">{{
                $t('create_campaign.clone_campaign')
              }}</span>
            </b-btn>

            <!-- // next button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="ml-2 xxl"
              variant="primary"
              v-if="!props.isLastStep"
              @click="props.nextTab()"
              :disabled="loading"
              >{{ $t('general.next') }}</b-btn
            >

            <!-- // activate button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <b-btn
              class="ml-2 xxl"
              variant="primary"
              v-else-if="!isFinalized"
              @click="onComplete"
              v-b-tooltip.hover
              :disabled="loading"
              :title="activateBtnTooltip"
              >{{
                props.isLastStep ? activateBtnText : $t('general.next')
              }}</b-btn
            >
          </div>
        </div>
      </template>

      <!-- // wizard steps ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <tab-content
        :before-change="() => validateForm('first')"
        icon="check"
        :title="$t('match_candidate.step_1')"
      >
        <app-step-first
          ref="firstForm"
          :isNameUnique="isNameUnique"
          @onNameUniquesChange="isNameUnique = true"
        ></app-step-first>
      </tab-content>

      <tab-content
        :before-change="() => validateForm('second')"
        icon="check"
        :title="$t('match_candidate.step_2')"
      >
        <app-step-second
          ref="secondForm"
          :campaign-id="campaignId"
        ></app-step-second>
      </tab-content>
    </form-wizard>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXIT_CAMPAIGN_STATE, EXIT_CAMPAIGN_ROLE } from '@/common/constants';
import ModalExitIcon from '../../../icons/modalExit';
import AppStepFirst from './StepFirst';
import AppStepSecond from './StepSecond';
import AppSaveModal from './SaveModal';
import { EXIT_CAMPAIGN_TYPES } from '../../../common/constants';
import ModalCloneAPandExitCampaign from '../../../components/ModalCloneAPandExitCampaign';
import { colors } from '../../../../config/themes/index';

export default {
  components: {
    AppStepFirst,
    AppStepSecond,
    ModalExitIcon,
    AppSaveModal,
    ModalCloneAPandExitCampaign,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    alertMessage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isNameUnique: true,
      index: 0,
      startIndex: 0,
      saveTemplateModal: false,
      alertShow: false,
      saveModal: false,
      showCloneModal: false,
      showDraftModal: false,
      useModal: false,
      loading: false,
      titleText: '',
      colorPrimary: colors.primary,
      form: {
        info: {
          keywords: [],
          departments: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters('global', ['currentLanguage', 'currentUserCompanyId']),
    ...mapGetters('exit', ['campaign']),

    wizardTitle() {
      return this.$t(`exit.wizard_title_step_${this.index + 1}`);
    },
    activateBtnText() {
      return this.campaign.id &&
        this.campaign.status === EXIT_CAMPAIGN_STATE.ACTIVE
        ? this.$t('create_campaign.update_campaign')
        : this.$t('create_campaign.activate_campaign');
    },
    activateBtnTooltip() {
      return this.campaign.id &&
        this.campaign.status === EXIT_CAMPAIGN_STATE.ACTIVE
        ? this.$t('create_campaign.edit_campaign_btn_description')
        : this.$t('create_campaign.activate_campaign_btn_description');
    },
    campaignId() {
      if (this.campaign.id) {
        return this.campaign.id;
      }
      return null;
    },
    isFinalized() {
      return this.campaign.status === EXIT_CAMPAIGN_STATE.FINALIZED;
    },
  },
  created() {
    if (this.campaign.status !== EXIT_CAMPAIGN_STATE.OPEN) {
      this.startIndex = 1;
      this.index = 1;
    }
  },
  methods: {
    prepareCampaignPayload(status) {
      // On create status is always EXIT_CAMPAIGN_STATE.OPEN
      const { leaving_person, team_leader, info, ...rest } = this.form;
      return {
        ...rest,
        status,
        campaign_type: EXIT_CAMPAIGN_TYPES.SIMPLE,
        info: {
          ...info,
          keywords: (info.keywords || []).map((i) => i.name),
          departments: (info.departments || []).map((i) => i.name),
          location_country: info.location_country.value,
        },
        exit_campaign_participants_attributes: [
          {
            ...team_leader,
            role: EXIT_CAMPAIGN_ROLE.TEAM_LEAD,
            lang: team_leader.lang || this.currentLanguage,
          },
          {
            ...leaving_person,
            role: EXIT_CAMPAIGN_ROLE.LEAVING_PERSON,
            lang: leaving_person.lang || this.currentLanguage,
          },
        ],
      };
    },
    onComplete() {
      this.loading = true;

      this.$refs.secondForm.validateForm().then((res) => {
        if (res) {
          const { start_date, end_date, time_zone } =
            this.$refs.secondForm.form;
          this.$store
            .dispatch('exit/updateCampaign', {
              status: EXIT_CAMPAIGN_STATE.ACTIVE,
              campaign_type: EXIT_CAMPAIGN_TYPES.SIMPLE,
              start_date,
              end_date,
              time_zone,
              id: this.campaign.id,
            })
            .then(() => {
              this.loading = false;
              this.modalTitleText();
              this.saveModal = true;
            })
            .catch((error) => {
              this.loading = false;
              const alertMessage = Object.values(error.response.data.error);
              this.$bvToast.toast(alertMessage[0], {
                autoHideDelay: 2000,
                variant: 'warning',
                title: 'Error',
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    onChange(prevIndex, nextIndex) {
      if (prevIndex > nextIndex) return;

      if (nextIndex === 1) {
        this.$refs.secondForm.populateForm();
      }
      this.loading = true;
      const action = this.campaign.id
        ? 'exit/updateCampaign'
        : 'exit/createCampaign';
      const params = this.prepareCampaignPayload(
        this.campaign.status || EXIT_CAMPAIGN_STATE.OPEN
      );
      return new Promise((resolve) => {
        this.$store
          .dispatch(action, params)
          .then(() => {
            this.loading = false;
            if (!this.form.id) {
              this.$refs.secondForm.populateForm();
              this.$nextTick(() => {
                this.$router.push(`/exit/campaigns/${this.campaign.id}/edit`);
              });
            }
            resolve(true);
          })
          .catch((error) => {
            this.loading = false;
            const alertMessage = Object.values(error.response.data.error);
            if (error.response && error.response.status === 422) {
              this.isNameUnique = false;
            } else {
              this.$bvToast.toast(alertMessage[0], {
                autoHideDelay: 2000,
                variant: 'warning',
                title: 'Error',
              });
            }
            resolve(false);
          });
      });
    },
    validateForm(name) {
      const forms = {
        first: this.$refs.firstForm,
        second: this.$refs.secondForm,
      };

      // Check references and reference method and validate else return valid
      if (name in forms && forms[name].validateForm) {
        return forms[name].validateForm().then(async (res) => {
          if (res) {
            this.form = {
              ...forms[name].form,
            };
            return await this.onChange();
          }
        });
      }
      return false;
    },
    modalTitleText() {
      this.titleText =
        this.campaign.id && this.campaign.status === EXIT_CAMPAIGN_STATE.OPEN
          ? this.$t('create_campaign.activated_campaign')
          : this.$t('create_campaign.campaign_updated_successfully');
    },
    closeEditCampaign() {
      this.$router.push({ name: 'ExitDashboard' });
    },
    createNewCampaign() {
      location.href = '/exit/campaign/new';
    },
    cloneCampaign() {
      this.showCloneModal = !this.showCloneModal;
    },
  },
};
</script>
