
<template>
  <div class="d-flex justify-content-center table-icon">
    <span
      role="button"
      class="mdi mdi-comment-edit-outline h-34"
      :class="{ iconlink: currentValue }"
      @click="modalShow = !modalShow"
    ></span>
    <b-modal v-model="modalShow" ok-title="save" centered @ok="updateCampaign">
      {{ $t('dashboard.comment') }}
      <b-form-textarea
        type="text"
        rows="8"
        maxLength="500"
        v-model="currentValue"
      >
      </b-form-textarea>
    </b-modal>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value || '';
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    updateCampaign() {
      this.$emit('change', this.currentValue);
    },
  },
};
</script>
