<template>
  <div>

    <div class="interview__question">
      <b-row>
        <b-col md="12" lg="9">
          <h2 class="report__chapter__title">
            <span class="mdi mdi-text-to-speech"></span>
            <span>{{ $t('report.preparing.title') }}</span>
          </h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" lg="9">
          <div class="interview__question__content">
            <b-img :src="jobPreparationJpg" alt="Job Preparation" fluid></b-img>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" lg="9">
          <div class="interview__question__content">
            <p class="interview__question__example variant__label">{{ $t('report.preparing.sub_title') }}</p>
            <p class="interview__question__example">{{ $t('report.preparing.list_title_1') }}</p>
            <ul>
              <li>{{ $t('report.preparing.list_text_1') }}</li>
              <li>{{ $t('report.preparing.list_text_2') }}</li>
              <li>{{ $t('report.preparing.list_text_3') }}</li>
            </ul>
            <hr>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" lg="9">
          <div class="interview__question__content">
            <p class="interview__question__example">{{ $t('report.preparing.list_title_2') }}</p>
            <ul>
              <li>{{ $t('report.preparing.list_text_4') }}</li>
              <li>{{ $t('report.preparing.list_text_5') }}</li>
            </ul>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import JobPreparation from '../../../../images/report/preparing-practicing_new.jpg'

export default {
  computed: {
    jobPreparationJpg() {
      return JobPreparation
    }
  }
}
</script>
