<template>
  <article class="campaign">
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <!-- campaign header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <header class="vue-form-wizard campaign__header">
      <h1 class="campaign__title">{{ campaign.name }}</h1>
    </header>

    <!-- action buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="dashboard-table-wrap">
      <div class="dashboard-table-top d-flex flex-wrap">
        <div class="d-flex flex-wrap">
          <b-button
            @click="edit"
            v-b-tooltip.hover
            :title="$t('dashboard.edit_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-pencil"></span>
          </b-button>

          <b-button
            @click="updateState('archived')"
            :disabled="campaign.status === 'archived'"
            v-b-tooltip.hover
            :title="$t('dashboard.archive_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-archive"></span>
          </b-button>

          <b-button
            @click="updateState('closed')"
            :disabled="
              campaign.status === 'closed' ||
              campaign.status === 'archived' ||
              campaign.status === 'finalized'
            "
            v-b-tooltip.hover
            :title="$t('dashboard.close_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-timer-off"></span>
          </b-button>
        </div>
      </div>
    </div>
    <!-- campaign content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
    <b-row>
      <b-col lg="9">
        <b-tabs>
          <b-tab v-if="isAnalyticsReport" :title="$t('dashboard.details')">
            <AnalyticsCampaignDetails :campaign="campaign" />
          </b-tab>
          <b-tab
            v-else
            :title="$t('create_campaign.candidates')"
            ref="candidatesTabRef"
          >
            <b-row>
              <b-col>
                <ExitParticipantsTable
                  :items="campaign.exit_campaign_participants"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            v-show="campaign.report_present"
            ref="reportTabRef"
            id="container"
            :title="$t('dashboard.report')"
            @click="getReportData()"
          >
            <b-row>
              <b-col v-if="inProgress">
                <!-- v-if="current.report_present" -->
                <Spinner
                  v-if="inProgress"
                  :options="spinnerOptions"
                  class="tab__spinner"
                />
              </b-col>
              <b-col v-else>
                <div v-if="isEmptyReport" class="emptystate">
                  <i class="mdi mdi-information-outline"></i>
                  <p>{{ $t('applicant_portal.no_report_available') }}</p>
                </div>
                <div v-else>
                  <web-report :id="id"></web-report>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            v-show="campaign.report_present"
            ref="strategyRef"
            :title="$t('exit.report.strategy_modules')"
            @click="getReportData()"
          >
            <b-row>
              <b-col v-if="inProgress">
                <Spinner
                  v-if="inProgress"
                  :options="spinnerOptions"
                  class="tab__spinner"
                />
              </b-col>
              <b-col v-else>
                <div v-if="isEmptyReport" class="emptystate">
                  <i class="mdi mdi-information-outline"></i>
                  <p>{{ $t('applicant_portal.no_report_available') }}</p>
                </div>
                <StrategyTable v-else :id="id" />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>

      <!--  // aside ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col lg="3" class="aside">
        <div class="aside__content campaign__detail__screen">
          <!--  // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <h6 class="aside__title">
            <span>{{ $t('create_campaign.campaign_details') }}</span>
          </h6>

          <div class="campaign__attributes">
            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.status') }}
              </div>
              <div class="campaign__attribute__value">
                <div :class="'table-label table-label__' + currentState">
                  {{ $t(`dashboard.statuses.${currentState}`) }}
                </div>
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('exit.create_survey.internal_id') }}
              </div>
              <div class="campaign__attribute__value">
                {{ info.internal_id }}
              </div>
            </div>
            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.client_name') }}
              </div>
              <div class="campaign__attribute__value">
                {{ campaign.name }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.starting_date') }}
              </div>
              <div class="campaign__attribute__value">
                {{ campaign.start_date }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.end_date') }}
              </div>
              <div class="campaign__attribute__value">
                {{ campaign.end_date }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.recruiter_name') }}
              </div>
              <div class="campaign__attribute__value">
                {{ campaign.admin.first_name }} {{ campaign.admin.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import ExitParticipantsTable from './ExitParticipantsTable';
import AnalyticsCampaignDetails from './AnalyticsCampaignDetails';
import Spinner from '@/components/Spinner';
import WebReport from '../Report/WebReport';
import { EXIT_CAMPAIGN_TYPES } from '../../../common/constants';
import StrategyTable from './StrategyTable.vue';

export default {
  components: {
    Spinner,
    ExitParticipantsTable,
    WebReport,
    AnalyticsCampaignDetails,
    StrategyTable,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: { name: 'ExitDashboard' },
        },
        {
          text: 'Campaign Detail',
          active: true,
        },
      ],
      inProgress: true,
      showTableSpinner: true,
    };
  },
  computed: {
    ...mapGetters('exit', ['campaign', 'report']),
    ...mapGetters('global', ['spinnerOptions']),
    info() {
      return this.campaign.info || {};
    },
    currentState() {
      return this.campaign && this.campaign.status
        ? this.campaign.status.toLowerCase()
        : '';
    },
    isAnalyticsReport() {
      return this.campaign.campaign_type === EXIT_CAMPAIGN_TYPES.ANALYTICS;
    },
    isEmptyReport() {
      return (
        this.campaign.report_present && isEmpty(this.report.reasons_for_leaving)
      );
    },
  },
  mounted() {
    this.breadcrumbs.push({ text: this.campaign.name, active: true });

    if (this.$route.hash === '#report') {
      // TMP hack
      setTimeout(() => {
        this.$refs.reportTabRef.activate();
        this.getReportData();
      }, 500);
    }
  },
  methods: {
    edit() {
      if (this.isAnalyticsReport) {
        this.$router.push(`/exit/campaigns/analytics/${this.id}/edit`);
      } else {
        this.$router.push(`/exit/campaigns/${this.campaign.id}/edit`);
      }
    },
    updateState(newState) {
      this.$store.dispatch('exit/updateCampaign', {
        status: newState,
        id: this.campaign.id,
      });
    },
    getReportData() {
      this.inProgress = true;
      this.$store
        .dispatch('exit/getReport', this.campaign.id)
        .then(() => (this.inProgress = false))
        .catch(() => (this.inProgress = false));
    },
  },
};
</script>
