<template>
  <div>

    <!-- modals ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-modal
      centered
      v-model="modalEditShow"
      size="xl"
      hide-header="hide-header"
      no-close-on-backdrop="no-close-on-backdrop"
      body-class="no-padding"
      scrollable="scrollable"
      @ok="handleOk">

      <app-edit-template
        :current="currentTemplate"
        @add-keyword="populateKeyword"
        ref="editForm"></app-edit-template>

      <template v-slot:modal-footer>

        <b-button
          class="float-right"
          variant="secondary"
          size="sm"
          @click="modalEditShow = !modalEditShow">{{ $t("general.cancel") }}</b-button>

        <b-button
          class="float-right"
          variant="primary"
          size="sm"
          @click="createUpdateTemplate">{{ buttonName }}</b-button>

      </template>
    </b-modal>

    <!-- template table wrapper ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div class="dashboard-table-wrap">
      <div class="dashboard-table-top d-flex flex-wrap">

        <!-- search campaigns ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="search-input-wrp mr-3">
          <b-input-group>
            <b-form-input
              class="ml-2 dashboard-table-top-search"
              :placeholder="$t('dashboard.search_placeholder')"
              v-on:keyup.enter="doSearch"
              v-model="paginationDict.value"></b-form-input>

            <b-input-group-append class="clear__search">
              <b-btn><i class="mdi mdi-close"></i></b-btn>
            </b-input-group-append>

            <b-input-group-append>
              <b-form-select
                class="dashboard-table-top-select"
                :options="searchFields"
                v-model="paginationDict.field">
              </b-form-select>
            </b-input-group-append>

            <b-input-group-append>
              <b-btn variant="primary" @click="fetchTemplates">
                <search-icon></search-icon>
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </div>

        <!-- action buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <b-button
          v-b-tooltip.hover
          :title="$t($t('dashboard.new_template'))"
          variant="primary"
          class="mr-3 btn-action primary"
          @click="openNewTemplateModal">
          <span class="mdi mdi-puzzle-plus"></span>
        </b-button>
      </div>

      <div class="table-wrp">
        <app-table
          :items="templates"
          @remove="deleteTemplate"
          @edit="openEditTemplate"
          @favorite="toggleFavorite"
          @use="useTemplate"
          @sort="sortingChanged">
        </app-table>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <b-pagination-nav
        v-if="templates.length"
        :link-gen="linkGen"
        :number-of-pages="templatesMeta.total_pages"
        :per-page="templatesMeta.per_page"
        v-model="paginationDict.page"
        @input="fetchTemplates">
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import _isArray from 'lodash/isArray';
import ConfigMixin from '@/common/mixins/ConfigMixin';
import AppTable from './Table';
import AppFormCampaign from '../FormCampaign/Index';
import AppEditTemplate from './Edit';
import ApiTemplate from '../../api/template';
import TemplatesHelpersMixin from '../../common/TemplatesHelpersMixin';
import SearchIcon from '../../icons/searchIcon';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppTable,
    AppFormCampaign,
    AppEditTemplate,
    SearchIcon,
  },
  mixins: [TemplatesHelpersMixin, ConfigMixin],
  data() {
    return {
      options: [],
      templates: [],
      templatesMeta: {},
      modalShow: false,
      modalEditShow: false,
      campaignId: '',
      campaignLink: '',
      currentTemplate: {
        id: '',
        info: {},
        personality_attrs: {},
        keywords: [],
      },
      paginationDict: {
        field: 'name',
        value: null,
        perPage: 10,
        sortBy: 'id',
        order: 'desc',
        page: this.$route.query.page || 1,
      },
    };
  },
  computed: {
    ...mapGetters('global', ['campaignAttributes', 'currentUserCompanyId']),
    buttonName() {
      const labelKey = this.currentTemplate.id
        ? 'general.update'
        : 'general.create';
      return this.$t(labelKey);
    },
  },
  mounted() {
    this.fetchTemplates().then(() => {
      if (this.$route.query.page > this.templatesMeta.total_pages) {
        this.paginationDict.page = this.templatesMeta.total_pages;
        this.$router.push({
          path: this.$route.path + '?page=' + this.templatesMeta.total_pages,
        });
        this.fetchTemplates();
      }
    });
  },
  methods: {
    fetchData() {
      this.fetchTemplates();
    },
    linkGen(pageNum) {
      return { path: `?page=${pageNum}` };
    },
    openNewTemplateModal() {
      this.resetModal();
      this.modalEditShow = true;
    },
    openEditTemplate(data) {
      this.currentTemplate = data;
      this.modalEditShow = true;
    },
    clearSearchArea() {
      this.paginationDict = {
        ...this.paginationDict,
        field: null,
        value: null,
      };
      this.fetchTemplates();
    },
    sortingChanged(ctx) {
      this.paginationDict = {
        ...this.paginationDict,
        sortBy: ctx.sortBy,
        order: ctx.sortDesc ? 'desc' : 'asc',
      };
      this.fetchTemplates();
    },
    fetchTemplates() {
      return ApiTemplate.fetchTemplates(this.currentUserCompanyId, this.paginationDict).then(
        (response) => {
          response.data.campaign_templates.forEach((template) => {
            this.populateTranslations(template, this.campaignAttributes);
          });
          this.templatesMeta = response.data.meta;
          this.templates = response.data.campaign_templates;
          this.templates.forEach(
            (t, i) =>
              (t.num =
                (this.paginationDict.page - 1) * this.paginationDict.perPage +
                i +
                1)
          );
        }
      );
    },
    useTemplate(data) {
      this.$router
        .push({
          name: 'CreateCampaign',
          params: {
            use_template: 1,
          },
        })
        .then(() => {
          this.$store.dispatch('campaign/useTemplate', data);
        });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.editForm.onSubmit();
    },
    toggleFavorite(id) {
      ApiTemplate.toggleFavorite(this.currentUserCompanyId, id)
        .then(() => {
          this.fetchTemplates().then(() => {
            this.modalEditShow = false;
            this.modalShow = false;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    populateTranslations: (template, attributes) => {
      ['values', 'competencies', 'traits'].forEach((key) => {
        if (!template.personality_attrs || !template.personality_attrs[key]) {
          return;
        }
        template.personality_attrs[key].forEach((attr) => {
          if (!attributes[key][attr.attribute]) {
            return;
          }
          const keys = Object.keys(attributes[key][attr.attribute]);
          keys.forEach((k) => {
            if (/name_\w/.test(k)) {
              attr[k] = attributes[key][attr.attribute][k];
            }
          });
        });
      });
    },

    createUpdateTemplate() {
      const commitRequest = () => {
        const template = this.currentTemplate;

        const data = {
          id: template.id || null,
        };

        data.keywords = _isArray(template.keywords)
          ? template.keywords.map((item) => item.name)
          : [];

        data.info = {
          ...template.info,
          favorite: template.info.favorite,
        };

        data.personality_attrs = template.personality_attrs;

        ApiTemplate.save(this.currentUserCompanyId, data)
          .then(() => {
            this.fetchTemplates().then(() => {
              this.modalEditShow = false;
              this.modalShow = false;
            });
          })
          .catch((error) => {
            if (this.$refs.editForm) {
              this.$refs.editForm.pushError(error.response.data.error);
            }
          });
      };

      this.$refs.editForm.validateForm().then((result) => {
        if (result) {
          commitRequest();
        }
      });
    },

    deleteTemplate(id) {
      let confirm_ = confirm('Are you sure to remove this template?');
      if (confirm_) {
        ApiTemplate.deleteTemplate(this.currentUserCompanyId, id).then(() => {
          let index = this.templates.findIndex((item) => item.id === id);
          this.templates.splice(index, 1);
          this.fetchTemplates().then(() => {
            if (this.$route.query.page > this.templatesMeta.total_pages) {
              this.paginationDict.page = this.templatesMeta.total_pages;
              this.$router.push({
                path:
                  this.$route.path + '?page=' + this.templatesMeta.total_pages,
              });
              this.fetchTemplates();
            }
          });
        });
      }
    },
    closeModal() {
      let confirm_ = confirm('Are you sure?');
      if (confirm_) {
        this.resetModal();
        this.modalShow = false;
      }
    },
    resetModal() {
      this.currentTemplate = {
        info: {
          name: '',
          favorite: false,
        },
        personality_attrs: {
          values: [],
          traits: [],
          competencies: [],
        },
        keywords: [],
        references_requested: [],
        contacts: [],
        start_date: '',
        end_date: '',
      };
    },
    populateKeyword(val) {
      this.currentTemplate.keywords.push(val);
    },
    setCampaignId(val) {
      this.campaignId = val;
    },
    setCampaignLink(val) {
      this.campaignLink = val;
    },
  },
};
</script>
