<template>
  <div class="login__page">
    <app-header></app-header>

    <b-container>
      <div class="content__wrapper">
        <b-container fluid>
          <div class="h-100">
            <router-view></router-view>
          </div>
        </b-container>
      </div>
    </b-container>

    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '@/components/Footer';
import AppHeader from '@/components/Header';
import AuthMixin from '@/common/mixins/AuthMixin';
export default {
  components: {
    AppFooter,
    AppHeader,
  },
  mixins: [AuthMixin],
  created() {
    if (this.isRegistered) {
      this.$router.push('/');
    } else {
      // Clear user data if exist
      this.$store.commit('global/LOGOUT');
    }
  },
};
</script>
<style scoped>
.content__wrapper {
  height: auto;
}
@include media-breakpoint-down(md) {
  .content__wrapper {
    height: auto;
  }
}
</style>