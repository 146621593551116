<template>
  <b-row class="potential__analytics" v-if="(report.high_performance_score && report.high_potential_score) >= 65">
    <b-col sm="12" md="8" lg="8" class="col-print-8 potential__analytics__content">
      <!-- // chart canvas ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
      <div class="chart__canvas justify-content-center">
        <div class="chart__area chart__area__tl"></div>
        <div class="chart__area chart__area__tr"></div>
        <div class="chart__area chart__area__bl"></div>
        <div class="chart__area chart__area__br"></div>
        <div class="chart__area chart__area__tr__corner"></div>

        <!-- // chart candidates ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <span
          class="mdi mdi-checkbox-blank chart__candidate__item"
          :class="setCandidateClass()"
          :style="{
            left: setChartPositionAxis(report.high_performance_score) + '%',
            bottom: setChartPositionAxis(report.high_potential_score) + '%',
          }"
        >
        </span>
      </div>

      <!-- // chart axis left ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="chart__axis__left">
        <div class="chart__axis__left__label">
          <span class="label__text">{{ $t("report.potential") }}</span>
          <span class="label__weaker">{{
            $t("report.relative_weaker")
          }}</span>
          <span class="label__stronger">{{
            $t("report.relative_stronger")
          }}</span>
        </div>
      </div>

      <!-- // chart axis bottom ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <div class="chart__axis__bottom">
        <div class="chart__axis__bottom__label">
          <span class="label__text">{{ $t("report.performance") }}</span>
          <span class="label__weaker">{{
            $t("report.relative_weaker")
          }}</span>
          <span class="label__stronger">{{
            $t("report.relative_stronger")
          }}</span>
        </div>
      </div>
    </b-col>

    <!-- // chart legend ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-col sm="12" md="4" lg="4" class="col-print-4 chart__legend">
      <!-- <table class="table report__ems__table">
        <thead>
          <tr>
            <th colspan="2">{{ $t("report.candidate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="chart__legend__item legend__icon">
              <span class="mdi mdi-numeric-1-box"></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">{{ fullName }}</div>
            </td>
          </tr>
        </tbody>
      </table> -->

      <table class="table report__ems__table" v-if="!hidePotentialAnalyticsLegend">
        <thead>
          <tr>
            <th colspan="2">{{ $t("report.legend") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="chart__legend__item legend__icon">
              <span class="mdi mdi-checkbox-blank is__rockstar"></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t("report.rock_star") }}
              </div>
            </td>
          </tr>

          <tr>
            <td class="chart__legend__item legend__icon">
              <span
                class="mdi mdi-checkbox-blank chart__legend__index is__highpotential"
              ></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t("report.high_potential") }}
              </div>
            </td>
          </tr>

          <tr>
            <td class="chart__legend__item legend__icon">
              <span
                class="mdi mdi-checkbox-blank chart__legend__index is__highperformer"
              ></span>
            </td>

            <td class="chart__legend__item">
              <div class="chart__legend__name">
                {{ $t("report.high_performer") }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from "@/common/constants";
import { calculateTwoSideProgressBarValue } from "@/common/utils";

export default {
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    fullName: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    hidePotentialAnalyticsLegend() {
      // return (!this.report.pattern_rock_star_fulfilled && !this.report.pattern_high_potential_fulfilled && !this.report.pattern_high_performance_fulfilled)
      return true
    }
  },
  methods: {
    setChartPositionAxis(value) {
      const progressBarValue = calculateTwoSideProgressBarValue(
        value,
        TALENT_TWO_SIDE_SLIDER_BREAK_POINT
      );
      // Scale to chart, lef and right 50%
      return progressBarValue / 2 + 50;
    },
    setCandidateClass() {
      // if (this.report.pattern_rock_star_fulfilled) return "is__rockstar";
      // if (this.report.pattern_high_potential_fulfilled) return "is__highpotential";
      // if (this.report.pattern_high_performance_fulfilled) return "is__highperformer";
      return null
    },
  },
};
</script>
