<template>
  <div class="survey__situation">
    <div class="survey__situation__body">
      <slot>{{ message }}</slot>
    </div>
    <test-counter v-if="counter" :number="number" :total="total"></test-counter>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    counter: {
      type: Boolean,
      dafault: false,
    },
    number: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
};
</script>
