<template>
  <test-layout>
    <test-card v-if="!allDone">
      <component
        v-if="question"
        :is="questionTemplate"
        :key="question.key"
        :question="question"
        :questions-count="questionsCount"
        :question-number="questionNumber"
        @answer="onAnswer"></component>

      <div class="card card-flat card-elevate-lg max-w-400 mx-auto" v-if="allDone">
        <div class="p-4">
          <h3 class="text-center">{{ $t("self_test.outstanding") }}</h3>
          <p class="text-center">{{ $t("self_test.review_analysis_text") }}</p>

          <div class="text-center mt-5">
            <b-button class="min-w-200" variant="primary" to="/self-test/results" size="xl">{{ $t("general.next") }}</b-button>
          </div>
        </div>
      </div>

    </test-card>
  </test-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { prepareQuestions } from "@/common/questions";
import ApiSurvey from "../../api/survey";

import QuestionTemplateFirst from "./QuestionTemplateFirst.vue";
import QuestionTemplateSecond from "./QuestionTemplateSecond.vue";
import QuestionTemplateThird from "./QuestionTemplateThird.vue";

export default {
  components: {
    QuestionTemplateFirst,
    QuestionTemplateSecond,
    QuestionTemplateThird,
  },
  data() {
    return {
      allDone: false,
      inProgress: false,
      questions: [],
      questionIndex: 0,
      questionsCount: 0,
      questionNumber: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentUserToken", "currentLanguage"]),
    fullName() {
      return [this.candidate.firstName, this.candidate.lastName].join(" ");
    },
    question() {
      return this.questions[this.questionIndex] || null;
    },
    questionTemplate() {
      if (!this.question) return null;

      const tempalates = {
        1: "QuestionTemplateFirst",
        2: "QuestionTemplateSecond",
        3: "QuestionTemplateThird",
      };

      return tempalates[this.question.type];
    },
  },
  watch: {
    currentLanguage() {
      this.fetchQuestions();
    },
  },
  created() {
    this.fetchCandidateInfo();
    this.fetchQuestions();
  },
  methods: {
    onAnswer(data) {
      const answer = {
        type: this.question.type,
        data: data,
      };

      this.saveAnswer(answer).then((response) => {
        if (Number(response.data.not_answered) === 0) {
          this.finalizeSurvey();
        } else {
          this.nextQuestion();
        }
      });
    },
    fetchCandidateInfo() {
      this.candidate = {
        ...this.candidate,
        title: localStorage.title,
        firstName: localStorage.firstName,
        lastName: localStorage.lastName,
      };
    },
    fetchQuestions() {
      this.inProgress = true;
      ApiSurvey.fetchSelfTestSurveyQuestions({
        token: this.currentUserToken,
      }).then((resp) => {
        const questions = resp.data.questions;

        if (!questions.length) {
          this.questions = [];
          this.$router.push("/self-test/results");
          this.inProgress = false;
          return;
        }

        this.questionsCount = resp.data.total_type_3_questions;
        const thirdTypeQuestionsCount = (questions.filter((q) => q.answer_slider_type === "3")).length
        this.questionNumber = this.questionsCount - thirdTypeQuestionsCount + 1;

        this.questionIndex = 0;
        this.questions = prepareQuestions(questions);
        this.inProgress = false;
      });
    },
    saveAnswer(answer) {
      return new Promise((resolve, reject) => {
        let request = null;

        if (answer.type === 3) {
          request = ApiSurvey.saveSelfTestAnswer({
            token: this.currentUserToken,
            selftest_survey_response: answer.data,
          });
          this.questionNumber++;
        } else if ([1, 2].indexOf(answer.type) >= 0) {
          request = ApiSurvey.saveSeflTestBatchAnswers({
            token: this.currentUserToken,
            selftest_survey_response: { answers: answer.data },
          });
        }

        if (request) {
          request.then(resolve).catch((errors) => {
            const message = Object.values(errors.response.data.message)[0];
            reject(errors.response.data);
            this.$bvToast.toast(message, {
              autoHideDelay: 4000,
              variant: "warning",
              title: "Oops",
            });
          });
          return;
        }

        resolve();
      });
    },
    finalizeSurvey() {
      ApiSurvey.finalizeSelfTestSurvey({ token: this.currentUserToken }).then(
        () => {
          this.allDone = true;
          this.$router.push("/self-test/results");
        }
      );
    },
    nextQuestion() {
      this.questionIndex++;
    },
  },
};
</script>
