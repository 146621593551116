<template>
  <div class="survey__header">
    <h1 class="survey__scenario"><slot>{{ message }}</slot></h1>
    <test-illustration :image="image"></test-illustration>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: ""
    }
  }
};
</script>
