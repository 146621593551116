import {
  MATCH_TWO_SIDE_SLIDER_BREAK_POINT,
  REPORT_SCALE_MIN_VALUE,
  REPORT_SCALE_MIN_SCORE,
  REPORT_SCALE_THRESHOLD,
  REPORT_SCALE_SCALED_RANGE,
  REPORT_SCALE_RANGE,
} from '../../../common/constants';

const calculateAvgValue = (values, candidate, property, defaultCenterValue, isLast) => {
  if (!values[property]) values[property] = [];

  if (defaultCenterValue) values[property].push[MATCH_TWO_SIDE_SLIDER_BREAK_POINT]
  else values[property].push(Math.abs(candidate[property] || 0));
}

const calculateAvgValueForArr = (values, candidate, property, defaultCenterValue, isLast = false) => {
  if (candidate[property]) {
    candidate[property].forEach(attr => {

      if (!values[attr.attribute]) values[attr.attribute] = [];

      if (defaultCenterValue) values[attr.attribute].push(MATCH_TWO_SIDE_SLIDER_BREAK_POINT)
      else values[attr.attribute].push(Math.abs(attr.score || 0))
    });
  }
}

const scaleValue = (score) => {
  if (score < REPORT_SCALE_THRESHOLD) {
    score = score >= REPORT_SCALE_MIN_SCORE ? score : REPORT_SCALE_MIN_SCORE
    const position = Math.abs(REPORT_SCALE_MIN_SCORE - score);
    return Math.round(REPORT_SCALE_SCALED_RANGE * position / REPORT_SCALE_RANGE) + REPORT_SCALE_MIN_VALUE
  }
  return score;
}

const scaleMatchLowValues = (report) => {
  report.best_matches.forEach((item) => {
    item.score = scaleValue(item.score)
  });

  report.candidates.forEach((candidate) => {
    [
      'best_match_score',
      'enabler_score',
      'high_performance_score',
      'high_performance_attrs',
      'high_potential_attrs',
      'high_potential_score',
      'leader_score',
      'rock_star_score',
      'main_attrs',
      'other_attrs',
      'performance_attrs',
      'permanent_attrs',
      'personality_attrs',
      'rock_star_attrs'].forEach(key => {
        if (typeof candidate[key] === 'object') {
          candidate[key].forEach(item => {
            item.score = scaleValue(item.score)
          })
        } else {
          candidate[key] = scaleValue(candidate[key])
        }
      })
  });
}

const scaleTalentLowValues = (report) => {
  [
    'enabler_score',
    'high_performance_score',
    'high_potential_score',
    'high_performance',
    'high_potential',
    'leader_score',
    'rock_star_score',
    'personality_type',
    'rock_star',
    'traits',
    'values'].forEach(key => {
      if (typeof report[key] === 'object') {
        report[key].forEach(item => {
          item.value = scaleValue(item.value)
        })
      } else {
        report[key] = scaleValue(report[key])
      }
    });

    if (report.competencies && report.competencies['B1']) {
      report.competencies['B1'].forEach(item => {
        item.value = scaleValue(item.value)
      })
    }

    if (report.competencies && report.competencies['B2']) {
      report.competencies['B2'].forEach(item => {
        item.value = scaleValue(item.value)
      })
    }
}

const calculateAvgValues = (candidates, useDefaultCenterValue = true) => {
  const values = {};
  const candidatesNum = candidates.length;
  const numOfValidCandidates = candidates.filter(c => c.is_survey_valid).length;
  const defaultCenterValue = numOfValidCandidates < 3 || useDefaultCenterValue ? true : false;

  candidates.forEach((candidate, index) => {
    calculateAvgValue(values, candidate, 'best_match_score', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValue(values, candidate, 'enabler_score', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValue(values, candidate, 'leader_score', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValue(values, candidate, 'rock_star_score', defaultCenterValue, index === candidatesNum - 1)

    calculateAvgValueForArr(values, candidate, 'high_performance_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'high_potential_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'main_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'other_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'performance_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'permanent_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'personality_attrs', defaultCenterValue, index === candidatesNum - 1)
    calculateAvgValueForArr(values, candidate, 'rock_star_attrs', defaultCenterValue, index === candidatesNum - 1)
  });

  for (const key in values) {
    const arr = values[key];
    values[key] = (Math.round(arr.reduce((a, b) => a + b, 0) / (arr.length || 1))) || MATCH_TWO_SIDE_SLIDER_BREAK_POINT
  }

  return values;
}

const setCandidatesHighValues = (report) => {
  report.bestPerformanceScore = 0;
  report.bestPotentialScore = 0;
  report.bestRockStarScore = 0;
  report.candidates.forEach(c => {
    report.bestPerformanceScore = Math.max(report.bestPerformanceScore, c.high_performance_score || 0);
    report.bestPotentialScore = Math.max(report.bestPotentialScore, c.high_potential_score || 0);
    report.bestRockStarScore = Math.max(report.bestRockStarScore, c.rock_star_score || 0);
  })
}

const RECALCULATE_REPORT_AVG_VALUES = (state, useDefaultCenterValue = true) => {
  state.reportAvgValues = calculateAvgValues(state.report.candidates, useDefaultCenterValue);
  // Rerender report
  state.report = Object.assign({}, state.report);
}

const SET_REPORT = (state, report) => {
  state.reportAvgValues = calculateAvgValues(report.candidates)
  scaleMatchLowValues(report);
  setCandidatesHighValues(report);

  report.candidates = report.candidates
    .sort((a, b) => report.best_matches
    .findIndex(x => x.candidate_id === a.candidate_id) - report.best_matches.findIndex(x => x.candidate_id === b.candidate_id))

    state.report = report;
};

const SET_REPORT_CAMPAIGN = (state, campaign) => {
  state.reportCampaign = campaign;
};

const SET_SELF_TEST_REPORT = (state, report) => {
  scaleTalentLowValues(report);
  state.selfTestReport = report;
}

export default {
  SET_REPORT,
  SET_REPORT_CAMPAIGN,
  SET_SELF_TEST_REPORT,
  RECALCULATE_REPORT_AVG_VALUES,
};
