/**
 * const foo = paddy(14, 5); // 00014
 * const bar = paddy(2, 4, '#'); // ###2
 *
 * @param {number} num
 * @param {number} padlen
 * @param {string} padchar
 * @param {boolean} cutOverLen
 */
const paddy = (num, padlen = 0, padchar = "0", cutOverLen = false) => {
  const numStr = "" + num;
  if (!cutOverLen && numStr.length >= padlen) {
    return numStr;
  }
  const pad = new Array(1 + padlen).join(padchar);
  return (pad + num).slice(-pad.length);
};

export default paddy;
