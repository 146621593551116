<template>
  <div class="content__wrapper">
    <b-container fluid>
      <welcome
        v-if="ready && currentStep === 1"
        :referencesRequested="referencesRequested"
        @next="nextStep"
      ></welcome>

      <add-team-members
        v-if="ready && currentStep === 2"
        :campaign="campaign"
        :token="token"
        :teamMembers="teamMembers"
        @populate-reference="populateReference"
        @remove-reference="removeReference"
      ></add-team-members>
    </b-container>
  </div>
</template>

<script>
import _get from 'lodash/get';
import Welcome from './Welcome';

import AddTeamMembers from './AddTeamMembers/Index';
import { mapGetters } from 'vuex';

export default {
  components: {
    Welcome,
    AddTeamMembers,
  },
  props: ['token', 'step'],
  data() {
    return {
      ready: false,
      inProgress: false,
      currentStep: 1,
    };
  },
  computed: {
    ...mapGetters('exit', ['campaign', 'teamMembers']),
    referencesRequested() {
      return _get(this.campaign, 'references_requested', 0);
    },
  },
  mounted() {
    this.ready = true;
    if (this.teamMembers.length > 2) {
      this.currentStep++
    }
  },
  methods: {
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
    fetchCampaign() {
      this.inProgress = true;
      this.$store
        .dispatch('exit/getTeamMembers', this.$route.params.token)
        .then(() => {
          this.inProgress = false;
          this.ready = true;
          this.$router.push(`/team-members/${this.token}/step/${1}`);
        });
    },
    populateReference() {
      const params = {
        info: {
          gender: '',
          email: '',
          first_name: '',
          last_name: '',
          alt_email: '',
          deleted: false,
          relationship: '',
        },
      };
      this.campaign.references.push(params);
    },
    removeReference(val) {
      let deletedReference = this.campaign.references[val];
      if (deletedReference.id) {
        this.$set(this.campaign.references[val].info, 'deleted', true);
      } else {
        this.campaign.references.splice(val, 1);
      }
    },
  },
};
</script>
